import React from "react";
import { Row, Col, } from "react-bootstrap";
import BtnIconBadgeCountLink from "../buttons/BtnIconBadgeCountLink";

const ProfileBtnsHorizontal = ({

    isDark,
    isMobileOnly,

}) => {

    return (
        <>
            <Row className="align-items-start justify-content-between gy-3 gx-1 g-lg-0 gx-lg-2 mt-3 mt-lg-0" style={{ fontSize: "1rem", }}>
                
                
                <Col xs="4" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="activity"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="create-outline"
                            iconClasses=""
                            btnClasses="shadow-sm rounded-pill border border-2 border-primary mb-1"
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/profile/activity"}
                        />
                    </div>
                </Col>
                <Col xs="4" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="about"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="information-circle-outline"
                            iconClasses=""
                            btnClasses="shadow-sm rounded-pill border border-2 border-primary mb-1"
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/profile/about"}
                        />
                    </div>
                </Col>
                <Col xs="4" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="clips"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="film-outline"
                            iconClasses=""
                            btnClasses="shadow-sm rounded-pill border border-2 border-primary mb-1"
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/profile/clips"}
                        />
                    </div>
                </Col>
                <Col xs="4" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="uploads"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="images-outline"
                            iconClasses=""
                            btnClasses="shadow-sm rounded-pill border border-2 border-primary mb-1"
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/profile/uploads"}
                        />
                    </div>
                </Col>
                <Col xs="4" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="friends"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="checkmark-circle-outline"
                            iconClasses=""
                            btnClasses="shadow-sm rounded-pill border border-2 border-primary mb-1"
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/profile/friends"}
                        />
                    </div>
                </Col>
                <Col xs="4" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="groups"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="sync-outline"
                            iconClasses=""
                            btnClasses="shadow-sm rounded-pill border border-2 border-primary mb-1"
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/profile/groups"}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ProfileBtnsHorizontal;