import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import FadeIn from "react-fade-in/lib/FadeIn";
//import AppNameInline from "../text/AppNameInline";
import BtnIcon from "../buttons/BtnIcon";
import BtnIconLink from "../buttons/BtnIconLink";


const CollectionColumn = ({
    children,
    className,
    xs,
    sm,
    md,
    lg,
    xl,
    heading,
    smallHeading,
    //iconName,
    label,
    isDark,
    appOpenUrl
}) => {

    return (
        <>
            <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={`mb-3 mb-lg-4 px-1 px-lg-3 ${(className && className !== "") && className}`}>

                <Container fluid className={`${isDark ? "bg-md2-dark text-light" : "bg-white text-dark"} position-relative border border-light-dark rounded-2 shadow-sm pt-1 pb-2`} style={{ overflow: "hidden" }}>
                    <Row className="justify-content-between align-items-center gx-1">
                        <Col xs="auto">
                            <h4 className={"d-flex align-items-baseline justify-content-start mb-0 pt-2 pb-3"}>
                                {heading} {smallHeading && smallHeading !== "" && smallHeading}
                            </h4>
                        </Col>
                        <Col xs="auto">
                            <Row className="align-items-center justify-content-end gx-1">
                                <Col xs="auto">
                                    <BtnIcon
                                        bsVariant={(isDark ? "icon-light" : "icon-dark")}
                                        label={"Section settings..."}
                                        iconSize={20}
                                        iconName="ellipsis-vertical-outline"
                                        iconClasses=""
                                        btnClasses="ms-1"
                                        handleClick={(e) => {
                                            e.preventDefault();
                                            alert("This feature is under development ");
                                        }}
                                    />
                                </Col>
                                <Col className="pe-0" xs="auto">
                                    <BtnIconLink
                                        bsVariant={isDark ? "icon-light" : "icon-dark"}
                                        label={label}
                                        iconSize={20}
                                        iconName="open-outline"
                                        iconClasses=""
                                        btnClasses=""
                                        href={appOpenUrl}
                                        target=""
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {children}
                </Container>

            </Col>
        </>
    )

};

export default CollectionColumn;