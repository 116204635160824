import React, { useEffect, useRef } from "react";
import Config from "../data/Config";
import gtag from 'ga-gtag';
//import BannerTopDefault from "../banners/BannerTopDefault";
//import { Container,  } from 'react-bootstrap';
import HomeVisitorContent from "../content/HomeVisitorContent";
import LinkConnectFollowComingSoon from "../banners/LinkConnectFollowComingSoon";
import HomeVisitorContentComingSoon from "../content/HomeVisitorContentComingSoon";

const Home = ({
    isAuthenticated,
    content
}) => {

    const gtagSent = useRef(false);

    useEffect(() => {

        if (!gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Visitor Home',
                page_title: 'Visitor Home',
            });

            gtagSent.current = true;

        }


    }, [gtagSent]);

    return (
        <>
            <LinkConnectFollowComingSoon />

            {(window.location.pathname.includes("/home") || window.location.hostname.includes("localhost")) ? (

                
                    <HomeVisitorContentComingSoon
                        isAuthenticated={isAuthenticated}
                    />

            ) : (

                <HomeVisitorContent
                    isAuthenticated={isAuthenticated}
                />
            )}

        </>
    );

};

export default Home;