import React from "react";
import { Badge } from "react-bootstrap";

//import BtnIcon from "../buttons/BtnIcon";

const ActiveMemberCardSm = ({
    //isDark,
    // iconName,
    // iconSize,
    // bsIconVariant,
    blur,
    data,
    handleClick,
    status,
    //i,
}) => {

    const getStatusColor = () => {

        let color = "";

        switch (status) {

            case "online":
                color = "primary";
                break;
            case "recentlyOnline":
                color = "yellow";
                break;
            case "offline":
                color = "danger";
                break;
            default:
                color = "light";
                break;
        }

        return color;
    };

    const getStatusText = () => {

        let text = "";

        switch (status) {

            case "online":
                text = "Online Now...";
                break;
            case "recentlyOnline":
                text = "Recently Online...";
                break;
            case "offline":
                text = "Offline...";
                break;
            default:
                text = "Status Unknown...";
                break;
        }

        return text;
    };

    const getStatusIcon = () => {

        let name = "";

        switch (status) {

            case "online":
                name = "checkmark-circle-sharp";
                break;
            case "recentlyOnline":
                name = "time-sharp";
                break;
            case "offline":
                name = "ban-sharp";
                break;
            default:
                name = "checkmark-circle-sharp";
                break;
        }

        return name;
    };

    return (

        <div
            className="position-relative rounded-1"
            style={{ cursor: "pointer" }}
            onClick={() => {
                handleClick({
                    imgs: data.full,
                });
            }}
        >
            {data && data.thumbs.map((item, i) => {
                return (
                    <div key={i}>
                        <div
                            className={`${blur ? "img-blur" : ""} position-relative rounded-1 shadow-sm d-flex align-items-end justify-content-end`}
                            style={{
                                aspectRatio: "1 / 1",
                                backgroundImage: `url(${item.img})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center"
                            }}
                        >
                            <div
                                className="d-flex align-items-center position-absolute top-0 end-0 me-1 mt-1"
                                style={{
                                    zIndex: 5,
                                }}
                            >
                                <Badge
                                    bg={"dark"}
                                    text={getStatusColor()}
                                    title={getStatusText()}
                                    className="rounded-circle shadow-sm"
                                    style={{
                                        fontSize: ".85rem",
                                        paddingTop: 3,
                                        paddingBottom: 2,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                    }}
                                >
                                    <ion-icon name={getStatusIcon()}></ion-icon>
                                </Badge>
                            </div>
                        </div>                        
                    </div>
                );
            })}
        </div>

    );

};

export default ActiveMemberCardSm;