import React, { useEffect, useRef, useState } from "react";
import {
    //isDesktop,
    isMobileOnly,
    isMobile,
    isTablet,
} from "react-device-detect";
import Cookies from 'universal-cookie';
import gtag from 'ga-gtag';
import Config from "../data/Config";
import {
    Row, Col, Form
} from "react-bootstrap";

//import BtnIcon from "../components/buttons/BtnIcon";
import BtnStackedLink from "../components/buttons/BtnStackedLink";
import MainModal from "../components/shared/MainModal";
import CollectionRow from "../components/collections/CollectionRow";
import CollectionHeaderOnly from "../components/collections/CollectionHeaderOnly";
import CollectionColumn from "../components/collections/CollectionColumn";
import CollectionColumnNoHeader from "../components/collections/CollectionColumnNoHeader";
import CollectionColumnNoFrame from "../components/collections/CollectionColumnNoFrame";
import SliderInsideScrollRow from "../components/collections/SliderInsideScrollRow";
import MediaPostCard from "../components/cards/MediaPostCard";
import LightboxMain from "../components/lightbox/LightboxMain";
import LinkScreenFrameWithProfile from "../components/shared/LinkScreenFrameWithProfile";
import AdSelectorCategories from "../components/ads/AdSelectorCategories";
import AdSelectorSide from "../components/ads/AdSelectorSide";
import PageSortFilter from "../components/shared/PageSortFilter";

const LinkMedia = ({
    user,
    isDark,
    setShowProfileModal,
    setShowProfileSelector
}) => {

    const [_showMainModal, _setShowMainModal] = useState(false);
    const [_mainModalContent, _setMainModalContent] = useState(<></>);
    //const [_removeNavSpacing, _setRemoveNavSpacing] = useState(false);
    const [_mainModalMaxW, _setMainModalMaxW] = useState("");
    const [_showSliders, _setShowSliders] = useState(true);
    const [_blur, _setBlur] = useState(true);
    const [_showAds, _setShowAds] = useState(true);
    const [_showLightbox, _setShowLightbox] = useState(false);
    const [_lightBoxContent, _setLightBoxContent] = useState(null);
    const [pageSearchTerm, setPageSearchTerm] = useState("");


    const _memberPhotoVideoPostData = Config.memberPhotoVideoPosts; //memberClips

    const _gtagSent = useRef(false);
    const _cookies = useRef(new Cookies());
    const _cookieOptions = {
        maxAge: 3153600000,
        sameSite: true,
        path: window.location.origin.replace("www.", "")
    }

    useEffect(() => {

        console.log(pageSearchTerm);

    }, [pageSearchTerm]);

    useEffect(() => {

        if (_showLightbox) {
            document.body.classList.add("lightbox-open");
        } else {
            document.body.classList.remove("lightbox-open");
        }
    })

    useEffect(() => {

        const sectionViewCookie = _cookies.current.get("sectionView", { path: window.location.origin.replace("www.", "") });

        if (sectionViewCookie === "slider") {

            !_showSliders && _setShowSliders(true);

        } else if (sectionViewCookie === "grid") {

            _showSliders && _setShowSliders(false);

        }

    }, [_showSliders, _cookies]);

    const setSliders = () => {

        if (_showSliders) {

            _cookies.current.set("sectionView", "grid", { _cookieOptions });
            _setShowSliders(false);

        } else {

            _cookies.current.set("sectionView", "slider", { _cookieOptions });
            _setShowSliders(true);
        }

    };

    useEffect(() => {
        if (!_gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Link Clips',
                page_title: 'Link',
            });

            _gtagSent.current = true;
        }
    }, [_gtagSent]);

    const updateScrollPos = (target, scrollTo, type) => {

        target.scroll({ top: scrollTo, behavior: type });
    }

    const showLightBoxWindow = (data) => {

        _setLightBoxContent(data.imgs);
        _setShowLightbox(true);
    }

    return (
        <>
            <LinkScreenFrameWithProfile user={user} isDark={isDark} showCarousel={_showAds} setShowProfileModal={setShowProfileModal} setShowProfileSelector={setShowProfileSelector}>
                <div className="mb-3 ms-1">
                    <CollectionHeaderOnly
                        appName="link.uploads"
                        tagline={<>Link with other couples and singles</>}
                        isDark={isDark}
                        showAds={_showAds}
                        setShowAds={_setShowAds}
                        showScrollBtn={true}
                        showSliders={_showSliders}
                        setShowSliders={setSliders}
                        showBlurBtn={true}
                        blur={_blur}
                        setBlur={_setBlur}
                        iconName="images-outline"
                        appUrl={"/link"}
                    />
                </div>

                <Row className="justify-content-between flex-nowrap gx-0">
                    <Col lg="9">
                        {/* SEARCH/CREATE */}
                        <CollectionRow className={"align-items-center"}>
                            <CollectionColumnNoHeader
                                xs="12"
                                sm={null}
                                md={null}
                                lg={"8"}
                                xl={null}
                                isDark={isDark}
                            >
                                <div
                                    className="position-relative"
                                    style={{
                                        //cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        void (0);
                                    }}
                                >
                                    <Form.Control
                                        size="lg"
                                        placeholder="Search by member name, comments, etc..."
                                        type="text"
                                        autoCapitalize="off"
                                        className={(isDark ? "bg-md-dark border-dark text-light" : "") + " link-field rounded-1 form-control text-start mt-2 mb-2"}
                                        value={pageSearchTerm}
                                        onChange={(e) => {
                                            setPageSearchTerm(e.target.value);
                                        }}
                                    />

                                </div>

                            </CollectionColumnNoHeader>
                            <CollectionColumnNoFrame
                                xs="12"
                                sm={null}
                                md={null}
                                lg={"4"}
                                xl={null}
                                isDark={isDark}
                                heading={<>Create Clip</>}
                                smallHeading={null}
                                label={"See all clips in the link app..."}
                            >
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={isDark ? "dark" : "light"}
                                        bsSize={""}
                                        btnClasses="rounded-pill border border-2 border-primary shadow-sm"
                                        label={"Create/Upload"}
                                        sublabel={!isMobile ? "Get started making your own..." : null}
                                        iconSize={!isMobile ? 34 : 26}
                                        iconName="add-outline"
                                        useLink={true}
                                        href="/"
                                        target=""
                                    />
                                </div>

                            </CollectionColumnNoFrame>
                        </CollectionRow>
                        {/* SORT */}
                        <PageSortFilter
                            isDark={isDark}
                        />
                        {/* ROWS */}
                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<span className="text-primary">Your Media</span>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                                iconName={"images-outline"}
                                label={"See all member updates in the link app..."}
                                appName="events"
                            >
                                {_showSliders ? (
                                    <SliderInsideScrollRow showScrollIcons={false} isDark={isDark}>
                                        {/* SCROLL VIEW */}
                                        <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                            {_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                                return (
                                                    <Col key={i} className="mb-1" xs="6" sm="4" lg="3">
                                                        <MediaPostCard
                                                            i={i}
                                                            isDark={isDark}
                                                            isMobile={isMobile}
                                                            blur={_blur}
                                                            setBlur={_setBlur}
                                                            user={user}
                                                            data={item}
                                                            handleClick={showLightBoxWindow}
                                                            status={i < 8 ? "online" : "recentlyOnline"}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </SliderInsideScrollRow>
                                ) : (

                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                        {/* GRID VIEW */}
                                        {!_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-4" xs="6" sm="4" lg="3">
                                                    <MediaPostCard
                                                        i={i}
                                                        isDark={isDark}
                                                        isMobile={isMobile}
                                                        blur={_blur}
                                                        setBlur={_setBlur}
                                                        user={user}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                )}
                            </CollectionColumn>
                        </CollectionRow>

                        {_showAds && (
                            <div className="pb-3">
                                <AdSelectorCategories
                                    isDark={isDark}
                                    isMobileOnly={isMobileOnly}
                                    isTablet={isTablet}
                                    useAlt={false}
                                />
                            </div>

                        )}

                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<>New!</>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                                iconName={"images-outline"}
                                label={"See all member updates in the link app..."}
                                appName="events"
                            >
                                {_showSliders ? (
                                    <SliderInsideScrollRow showScrollIcons={false}>
                                        {/* SCROLL VIEW */}
                                        <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                            {_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                                return (
                                                    <Col key={i} className="mb-1" xs="6" sm="4" lg="3">
                                                        <MediaPostCard
                                                            i={i}
                                                            isDark={isDark}
                                                            isMobile={isMobile}
                                                            blur={_blur}
                                                            setBlur={_setBlur}
                                                            user={user}
                                                            data={item}
                                                            handleClick={showLightBoxWindow}
                                                            status={i < 8 ? "online" : "recentlyOnline"}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </SliderInsideScrollRow>
                                ) : (

                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                        {/* GRID VIEW */}
                                        {!_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-4" xs="6" sm="4" lg="3">
                                                    <MediaPostCard
                                                        i={i}
                                                        isDark={isDark}
                                                        isMobile={isMobile}
                                                        blur={_blur}
                                                        setBlur={_setBlur}
                                                        user={user}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                )}
                            </CollectionColumn>
                        </CollectionRow>

                        {_showAds && (
                            <div className="pb-3">
                                <AdSelectorCategories
                                    isDark={isDark}
                                    isMobileOnly={isMobileOnly}
                                    isTablet={isTablet}
                                    useAlt={false}
                                />
                            </div>

                        )}

                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<>Most Viewed</>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                                iconName={"images-outline"}
                                label={"See all member updates in the link app..."}
                                appName="events"
                            >
                                {_showSliders ? (
                                    <SliderInsideScrollRow showScrollIcons={false}>
                                        {/* SCROLL VIEW */}
                                        <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                            {_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                                return (
                                                    <Col key={i} className="mb-1" xs="6" sm="4" lg="3">
                                                        <MediaPostCard
                                                            i={i}
                                                            isDark={isDark}
                                                            isMobile={isMobile}
                                                            blur={_blur}
                                                            setBlur={_setBlur}
                                                            user={user}
                                                            data={item}
                                                            handleClick={showLightBoxWindow}
                                                            status={i < 8 ? "online" : "recentlyOnline"}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </SliderInsideScrollRow>
                                ) : (

                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                        {/* GRID VIEW */}
                                        {!_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-4" xs="6" sm="4" lg="3">
                                                    <MediaPostCard
                                                        i={i}
                                                        isDark={isDark}
                                                        isMobile={isMobile}
                                                        blur={_blur}
                                                        setBlur={_setBlur}
                                                        user={user}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                )}
                            </CollectionColumn>
                        </CollectionRow>

                        {_showAds && (
                            <div className="pb-3">
                                <AdSelectorCategories
                                    isDark={isDark}
                                    isMobileOnly={isMobileOnly}
                                    isTablet={isTablet}
                                    useAlt={false}
                                />
                            </div>

                        )}

                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<>Most Reactions</>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                                iconName={"images-outline"}
                                label={"See all member updates in the link app..."}
                                appName="events"
                            >
                                {_showSliders ? (
                                    <SliderInsideScrollRow showScrollIcons={false}>
                                        {/* SCROLL VIEW */}
                                        <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                            {_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                                return (
                                                    <Col key={i} className="mb-1" xs="6" sm="4" lg="3">
                                                        <MediaPostCard
                                                            i={i}
                                                            isDark={isDark}
                                                            isMobile={isMobile}
                                                            blur={_blur}
                                                            setBlur={_setBlur}
                                                            user={user}
                                                            data={item}
                                                            handleClick={showLightBoxWindow}
                                                            status={i < 8 ? "online" : "recentlyOnline"}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </SliderInsideScrollRow>
                                ) : (

                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                        {/* GRID VIEW */}
                                        {!_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-4" xs="6" sm="4" lg="3">
                                                    <MediaPostCard
                                                        i={i}
                                                        isDark={isDark}
                                                        isMobile={isMobile}
                                                        blur={_blur}
                                                        setBlur={_setBlur}
                                                        user={user}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                )}
                            </CollectionColumn>
                        </CollectionRow>

                        {_showAds && (
                            <div className="pb-3">
                                <AdSelectorCategories
                                    isDark={isDark}
                                    isMobileOnly={isMobileOnly}
                                    isTablet={isTablet}
                                    useAlt={false}
                                />
                            </div>

                        )}

                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<>Most Comments</>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                                iconName={"images-outline"}
                                label={"See all member updates in the link app..."}
                                appName="events"
                            >
                                {_showSliders ? (
                                    <SliderInsideScrollRow showScrollIcons={false}>
                                        {/* SCROLL VIEW */}
                                        <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                            {_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                                return (
                                                    <Col key={i} className="mb-1" xs="6" sm="4" lg="3">
                                                        <MediaPostCard
                                                            i={i}
                                                            isDark={isDark}
                                                            isMobile={isMobile}
                                                            blur={_blur}
                                                            setBlur={_setBlur}
                                                            user={user}
                                                            data={item}
                                                            handleClick={showLightBoxWindow}
                                                            status={i < 8 ? "online" : "recentlyOnline"}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </SliderInsideScrollRow>
                                ) : (

                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                        {/* GRID VIEW */}
                                        {!_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-4" xs="6" sm="4" lg="3">
                                                    <MediaPostCard
                                                        i={i}
                                                        isDark={isDark}
                                                        isMobile={isMobile}
                                                        blur={_blur}
                                                        setBlur={_setBlur}
                                                        user={user}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                )}
                            </CollectionColumn>
                        </CollectionRow>
                    </Col>
                    {!isMobile && (
                        <Col className="" lg="auto" style={{ width: 300 }}>
                            <AdSelectorSide
                                isDark={isDark}
                            />
                        </Col>
                    )}
                </Row>
            </LinkScreenFrameWithProfile>

            <MainModal
                showNav={true}
                isDark={isDark}
                //removeNaveSpacing={_removeNavSpacing}
                modalColor={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} shadow`}
                bsModalSize=""
                dialogClassName={(`${_mainModalMaxW && _mainModalMaxW !== "" ? ` ${_mainModalMaxW}` : ""}`)}
                contentClassName={`rounded-0${isDark ? " border border-dark" : " border border-secondary"}`}
                showModal={_showMainModal}
                closeModal={() => {
                    _setShowMainModal(false);
                    _setMainModalContent(<></>);
                    _setMainModalMaxW("");
                }}
            >
                {_showMainModal && (
                    <>
                        {_mainModalContent}
                    </>
                )}

            </MainModal>

            {_showLightbox && (
                <LightboxMain
                    lightBoxContent={_lightBoxContent}
                    user={user}
                    isDark={isDark}
                    showComments={true}
                    updateScrollPos={updateScrollPos}
                    setShowLightbox={_setShowLightbox}
                    blur={_blur}
                />
            )}
        </>
    );

};

export default LinkMedia;