import React from "react";
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const BtnStd = ({
    bsVariant,
    bsSize,
    btnClasses,
    label,
    labelSize,
    iconSize,
    iconName,
    handleClick,
}) => {

    return (
        <>
            <Button
                variant={bsVariant}
                size={bsSize}
                className={btnClasses}
                onClick={handleClick}
            >
                <div className="d-flex align-items-center justify-content-center">
                    {iconName && iconName !== "" && (
                        <div
                            className={(label && label !== "") ? "pe-1" : ""}
                            style={{
                                lineHeight: "1em",
                                height: "1em",
                                fontSize: iconSize
                            }}
                        >
                            <ion-icon name={iconName}></ion-icon>
                        </div>
                    )}
                    {(label && label !== "") && (
                        <div style={{ fontSize: labelSize ? labelSize : "" }}>{label}</div>
                    )}
                </div>
            </Button>
        </>
    );

};

BtnStd.propTypes = {
    bsVariant: PropTypes.string,
    bsSize: PropTypes.string,
    btnClasses: PropTypes.string,
    label: PropTypes.any,
    iconSize: PropTypes.number,
    iconName: PropTypes.string,
    handleClick: PropTypes.func,
}

BtnStd.defaultProps = {
    bsVariant: "primary",
    bsSize: "",
    btnClasses: "",
    label: "",
    iconSize: 20,
    iconName: "add-circle-outline",
};

export default BtnStd;

