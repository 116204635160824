import React, { useState, useRef } from "react";
//import Config from "../../../data/Config";
//import { Row, } from "react-bootstrap";
import ScrollAreaHorizontal from "../shared/ScrollAreaHorizontal";
import BtnIcon from "../buttons/BtnIcon";


const SliderInsideScrollRow = ({
    children,
    showScrollIcons,
    isDark,
}) => {

    const scrollContainer = useRef();

    const [scrollToPos, setScrollToPos] = useState(0);
    //const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
    //const [rightArrowDisabled, setRightArrowDisabled] = useState(false);

    const scroller = (dir) => {

        //console.log(dir);

        const containerW = scrollContainer.current.offsetWidth;

        console.log(containerW);

        console.log(scrollToPos + " POS || W: " + containerW);

        

        if (dir === "left") {

            if (scrollToPos === 0) return;

            //console.log(scrollToPos - containerW);

            setScrollToPos(scrollToPos - containerW);

        } else {

            if (scrollToPos >= containerW) return;

            //console.log(scrollToPos + containerW);

            setScrollToPos(scrollToPos + containerW);

        }

    };

    return (
        <>
            {(showScrollIcons && false) && (
                <div
                    ref={scrollContainer}
                    className="text-center position-absolute top-0 bottom-0 start-0 end-0 d-flex justify-content-between align-items-center"
                    style={{ zIndex: 10, pointerEvents: "none", overflow: "visible" }}
                >
                    <div className="ms-3" style={{ pointerEvents: "auto" }}>
                        <BtnIcon
                            bsVariant={(isDark ? "dark" : "light")}
                            label={"Left"}
                            iconSize={18}
                            iconName="arrow-back-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-secondary" : "border-secondary") + " opacity-100 border border-2 shadow"}
                            handleClick={(e) => {
                                e.preventDefault();
                                scroller("left");
                            }}
                        />
                    </div>
                    <div className="me-3" style={{ pointerEvents: "auto" }}>
                        <BtnIcon
                            bsVariant={(isDark ? "dark" : "light")}
                            label={"Right"}
                            iconSize={18}
                            iconName="arrow-forward-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-secondary" : "border-secondary") + " opacity-100 border border-2 shadow"}
                            handleClick={(e) => {
                                e.preventDefault();
                                scroller("right");
                            }}
                        />
                    </div>
                </div>
            )}

            <ScrollAreaHorizontal scrollToPos={scrollToPos}>
                {children}
            </ScrollAreaHorizontal>
        </>
    );


};

export default SliderInsideScrollRow;