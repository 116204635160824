import React, { useEffect, useRef } from "react";
import Config from "../data/Config";
import gtag from 'ga-gtag';
import FadeIn from "react-fade-in/lib/FadeIn";
import LinkConnectFollow from "../banners/LinkConnectFollow";
import FollowIntroContent from "../content/FollowIntroContent";
import AppNameInlineIcon from "../components/text/AppNameInlineIcon";

const FollowIntro = ({
    isAuthenticated,
}) => {

    const gtagSent = useRef(false);

    useEffect(() => {

        if (!gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Follow Intro',
                page_title: 'Follow Intro',
            });

            gtagSent.current = true;

        }


    }, [gtagSent]);

    return (
        <>
            <FadeIn>
                <LinkConnectFollow>
                    <h2 className={"d-flex justify-content-center align-items-center m-0"}>
                        <AppNameInlineIcon name={"follow"} showCopyright={true} iconName={"people-circle-outline"} iconsSize="1.25em" />
                    </h2>
                </LinkConnectFollow>
            </FadeIn>
            <FollowIntroContent
                isAuthenticated={isAuthenticated} 
            />
        </>
    );

};

export default FollowIntro;