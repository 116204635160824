import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import FadeIn from 'react-fade-in';
import {
    isDesktop,
    isMobile,
    //isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import Config from "../../data/Config";
import AppMenu from "../menus/appMenu/AppMenu";
import UserMenu from "../menus/userMenu/UserMenu";
import SearchMenu from "../menus/searchMenu/SearchMenu";
import MobileNotAuthMenu from "../menus/mobileMenus/MobileNotAuthMenu";
import BtnIcon from "../buttons/BtnIcon";
import BtnStdLink from "../buttons/BtnStdLink";
import AppNameInlineIcon from "../text/AppNameInlineIcon";
import CartWindow from "../cart/CartWindow";

import AppNameInline from "../text/AppNameInline";
import OBAName from "../text/OBAName";


const PageNavTop = ({
    logo,
    logoAlt,
    logoMaxW,
    btnIconBsVariant,
    isAuthenticated,
    isDark,
    setIsDark,
    setShowLogin,
    setShowLogout,
    toggleChat,
    setShowCreateAcct,
    setShowNotificationsMenu,
    showNotificationsMenu,
    setShowSettingsModal,
    setShowProfileSelector,
    user,
    ribbon,
    //links,
}) => {

    const [showAppMenu, setShowAppMenu] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [showSearchMenu, setShowSearchMenu] = useState(false);
    const [showCartWindow, setShowCartWindow] = useState(false);
    const [showMobileNotAuthMenu, setShowMobileNotAuthMenu] = useState(false);
    const [_showRibbon, _setShowRibbon] = useState(false);

    const _ribbonShown = useRef();

    const btnIconSize = 26;
    const btnIconSizeMobile = 24;
    const navListData = Config.AppList;

    const cookies = useRef(new Cookies());

    const ribbonOptions = useRef({
        //maxAge: 86400,
        sameSite: true,
        path: window.origin.replace("www.", "")
    });

    useEffect(() => {

        const ribbonShownCookie = cookies.current.get("ribbonShown", ribbonOptions.current);
        // console.log("Cookie value: ");

        // console.log(ribbonShownCookie);

        if (ribbonShownCookie) {

            _ribbonShown.current = true;

        }

        if (!_showRibbon && !_ribbonShown.current) {

            setTimeout(() => {

                _setShowRibbon(true);

                cookies.current.set("ribbonShown", true, ribbonOptions.current);

            }, 2000);

        }

    }, [_showRibbon, _ribbonShown, ribbonOptions])

    const MyAppNavListItem = ({
        item,
        ribbon,
        ribbonColor

    }) => {

        return (
            <>
                <NavLink /*end*/ id={`User-MyApp-Web-NavTop-${item.heading}`} className={(!item.selected ? "text-light-gray " : "") + "rounded-pill position-relative d-block ps-2 pe-3 py-1 py-lg-2 my-linked"} to={item.url}>
                    <AppNameInlineIcon
                        name={item.heading}
                        fontWeight={400}
                        showCopyright={false}
                        iconName={item.iconName}
                        iconSize="1.5rem"
                    />
                    {item.badgeCount > 0 && (
                        <Badge className="position-absolute rounded-pill shadow-sm text-dark" style={{ bottom: 2, right: 4, fontSize: ".75rem", zIndex: 10000, pointerEvents: "none" }} bg="primary">{item.badgeCount}</Badge>
                    )}

                    {ribbon && ribbon !== "" && (
                        <div className="position-absolute shadow text-uppercase" style={{ zIndex: 1, bottom: 2, right: 4 }}>
                            <div
                                className={`${ribbonColor && ribbonColor !== "" ? ribbonColor : "bg-secondary"}  text-light py-0 px-1 rounded-1`}
                                style={{
                                    fontSize: ".75rem",
                                    fontWeight: 400,
                                    lineHeight: "1.3em"
                                }}
                            >
                                {ribbon}
                            </div>
                        </div>
                    )}
                </NavLink>
            </>


        )
    }

    const checkMenus = (menuToShow) => {

        if (showAppMenu) setShowAppMenu(false);
        if (showUserMenu) setShowUserMenu(false);
        if (showSearchMenu) setShowSearchMenu(false);
        if (showCartWindow) setShowCartWindow(false);
        if (showMobileNotAuthMenu) setShowMobileNotAuthMenu(false);
        if (menuToShow === setShowCartWindow) toggleChat(true);

        if (menuToShow) menuToShow(true);
    }

    return (
        <>
            <nav className="fixed-top bg-dark text-light text-center py-1 px-0 px-lg-3 shadow">
                <Container fluid className="p-1">
                    <Row className="gx-1 gx-sm-1">
                        <Col xs="auto" className="position-relative d-flex align-items-center justify-content-start">
                            <Row className="d-flex align-items-center justify-content-start gx-1 gx-sm-2">
                                {isAuthenticated && !isDesktop && (
                                    <Col xs="auto">
                                        <FadeIn>
                                            <BtnIcon
                                                bsVariant={btnIconBsVariant}
                                                label="one.bad.apple apps"
                                                iconSize={!isMobileOnly ? btnIconSize : btnIconSizeMobile}
                                                iconName="apps-outline"
                                                iconClasses=""
                                                btnClasses={showAppMenu ? "active" : ""}
                                                handleClick={(e) => {

                                                    if (showAppMenu) {

                                                        setShowAppMenu(false);

                                                    } else {

                                                        checkMenus(setShowAppMenu);

                                                    }
                                                }}
                                            />
                                        </FadeIn>
                                    </Col>
                                )}

                                {isMobile && (
                                    <>
                                        {!isAuthenticated && (
                                            <>
                                                <Col xs="auto">
                                                    <BtnIcon
                                                        bsVariant={btnIconBsVariant}
                                                        iconSize={!isMobileOnly ? btnIconSize : btnIconSizeMobile}
                                                        iconName="apps-outline"
                                                        iconClasses=""
                                                        btnClasses={showMobileNotAuthMenu ? "active" : ""}
                                                        handleClick={() => {
                                                            if (showMobileNotAuthMenu) {
                                                                setShowMobileNotAuthMenu(false);
                                                            } else {
                                                                checkMenus(setShowMobileNotAuthMenu);
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </>
                                        )}
                                    </>
                                )}
                                {(logo && logo !== "") && (
                                    <>
                                        <Col xs="auto">
                                            <div className="position-relative" style={{ fontWeight: 400 }}>
                                                <Link to="/">
                                                    <img
                                                        src={logo}
                                                        className="img-fluid"
                                                        style={{ maxWidth: logoMaxW }}
                                                        alt={logoAlt}
                                                        title="one.bad.apple home"
                                                    />
                                                </Link>

                                                {/* <div className="position-absolute bottom-0 end-0" style={{ marginBottom: -10, marginRight: -15 }}>
                                                <Badge className="position-absolute rounded-pill shadow-sm text-light border border-1 border-primary" style={{ bottom: 2, right: 4, fontSize: ".75rem", fontWeight: 400, zIndex: 10000, pointerEvents: "none" }} bg="dark">alpha</Badge>
                                            </div> */}
                                            </div>
                                        </Col>
                                        <Col className="align-self-center" xs="auto">
                                            <span style={{ fontSize: ".95rem" }}>
                                                <AppNameInline name="alpha" showCopyright={false} />
                                            </span>
                                            {/* <span style={{ fontSize: ".95rem" }}>
                                                <Badge className="rounded-pill shadow-sm text-light border border-2 border-primary" style={{ bottom: 2, right: 4, fontSize: ".75rem", fontWeight: 400, zIndex: 10000, pointerEvents: "none" }} bg="dark">alpha</Badge>
                                            </span> */}
                                        </Col>
                                    </>

                                )}
                            </Row>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-start align-self-start">
                            <Row className="align-items-center nav-top-links gx-2">
                                {(!isMobile) && (
                                    <>
                                        {!isAuthenticated && (
                                            <>
                                                <Col xs="auto">
                                                    <FadeIn>
                                                        <div style={{ fontSize: "1.4rem", height: "1em" }}>
                                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                                        </div>

                                                    </FadeIn>
                                                </Col>
                                                <Col xs="auto">
                                                    <FadeIn>
                                                        <NavLink id={"Visitor-Web-NavTop-home"} end className="rounded-pill d-block px-2 px-lg-3 py-1 py-lg-2 my-linked" to="/">
                                                            <AppNameInlineIcon
                                                                name={"home"}
                                                                fontWeight={null}
                                                                showCopyright={false}
                                                                iconName={"globe-outline"}
                                                                iconSize="1.6rem"
                                                            />
                                                        </NavLink>
                                                    </FadeIn>
                                                </Col>
                                                
                                                <Col xs="auto">
                                                    <FadeIn>
                                                        <NavLink id={"Visitor-Web-NavTop-news"} className="rounded-pill d-block px-2 px-lg-3 py-1 py-lg-2 my-linked" to="/news">
                                                            <AppNameInlineIcon
                                                                name={"news"}
                                                                fontWeight={null}
                                                                showCopyright={false}
                                                                iconName={"newspaper-outline"}
                                                                iconSize="1.6rem"
                                                            />
                                                        </NavLink>
                                                    </FadeIn>
                                                </Col>
                                                <Col xs="auto">
                                                    <FadeIn>
                                                        <NavLink id={"Visitor-Web-NavTop-contact"} className="rounded-pill d-block px-2 px-lg-3 py-1 py-lg-2 my-linked" to="/contact">
                                                            <AppNameInlineIcon
                                                                name={"contact"}
                                                                fontWeight={null}
                                                                showCopyright={false}
                                                                iconName={"mail-outline"}
                                                                iconSize="1.6rem"
                                                            />
                                                        </NavLink>
                                                    </FadeIn>
                                                </Col>
                                                <Col xs="auto">
                                                    <FadeIn>
                                                        <NavLink id={"Visitor-Web-NavTop-info"} end className="rounded-pill d-block px-2 px-lg-3 py-1 py-lg-2 my-linked" to="/info">
                                                            <AppNameInlineIcon
                                                                name={"info"}
                                                                fontWeight={null}
                                                                showCopyright={false}
                                                                iconName={"information-circle-outline"}
                                                                iconSize="1.6rem"
                                                            />
                                                        </NavLink>
                                                    </FadeIn>
                                                </Col>
                                                {(window.location.pathname.includes("/home") || window.location.hostname.includes("localhost")) && (
                                                    <Col xs="auto">
                                                        <FadeIn>
                                                            <NavLink id={"Visitor-Web-NavTop-info"} end className="rounded-pill d-block px-2 px-lg-3 py-1 py-lg-2 my-linked" to="/promotions/free-rtic/signup">
                                                                <AppNameInlineIcon
                                                                    name={"free.rtic"}
                                                                    fontWeight={null}
                                                                    showCopyright={false}
                                                                    iconName={"arrow-redo-outline"}
                                                                    iconSize="1.6rem"
                                                                />
                                                            </NavLink>
                                                        </FadeIn>
                                                    </Col>
                                                )}
                                                
                                            </>
                                        )}
                                        {isAuthenticated && (
                                            <>
                                                <Col xs="auto">
                                                    <FadeIn>
                                                        <div style={{ fontSize: "1.4rem", height: "1em" }}>
                                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                                        </div>

                                                    </FadeIn>
                                                </Col>
                                                {navListData.map((item, i) => {

                                                    //if (!item.selected) return (null);

                                                    return (
                                                        <Col key={i} xs="auto" className={(!item.selected ? "text-secondary " : "") + "position-relative"}>
                                                            <FadeIn>
                                                                <MyAppNavListItem
                                                                    item={item}
                                                                    //ribbon={item.selected ? null : item.promoBadge}
                                                                    ribbon={null}
                                                                />
                                                            </FadeIn>
                                                        </Col>
                                                    )
                                                })}
                                            </>
                                        )}
                                    </>
                                )}
                            </Row>
                        </Col>
                        <Col xs="auto" className="d-flex align-items-center justify-content-end">
                            <Row className="d-flex align-items-center justify-content-start gx-0 gx-sm-1">
                                {!isMobileOnly && !isAuthenticated && false && (
                                    <Col className="position-relative overflow-hidden pe-3" xs="auto" style={{ fontWeight: 500 }}>
                                        <BtnStdLink
                                            bsVariant="dark"
                                            bsSize={"xs"}
                                            btnClasses="rounded-pill border border-2 border-primary text-light shadow-sm pe-3"
                                            iconClasses=""
                                            label={<><span className="text-primary" style={{ fontWeight: 600 }}>FREE</span> 30 DAY TRIAL</>}
                                            iconSize={16}
                                            iconName="arrow-redo-sharp"
                                            useLink={true}
                                            href="/signup/freetrial"
                                            target=""
                                        />
                                    </Col>
                                )}
                                <Col className="position-relative" xs="auto">
                                    <FadeIn>
                                        {(user && user.photoURL && user.photoURL !== "") ? (
                                            <img
                                                src={user.photoURL}
                                                className="img-fluid rounded-1 mx-1"
                                                alt={
                                                    `Profile for ${user.email}`
                                                }
                                                style={{
                                                    maxWidth: isMobile ? 28 : 32,
                                                    cursor: "pointer",
                                                    border: "2px solid rgba(255,255,255,.2)"
                                                }}
                                                onClick={() => {

                                                    if (showUserMenu) {

                                                        setShowUserMenu(false);

                                                    } else {

                                                        checkMenus(setShowUserMenu);

                                                    }
                                                }}
                                            />
                                        ) : (
                                            <BtnIcon
                                                bsVariant={btnIconBsVariant}
                                                iconSize={isDesktop ? btnIconSize : btnIconSizeMobile}
                                                iconName="person-outline"
                                                iconClasses=""
                                                btnClasses={showUserMenu ? " active" : ""}
                                                handleClick={() => {

                                                    if (showUserMenu) {

                                                        setShowUserMenu(false);

                                                    } else {

                                                        checkMenus(setShowUserMenu);

                                                    }

                                                }}
                                            />
                                        )}

                                        {isAuthenticated && (
                                            <Badge
                                                className="position-absolute rounded-pill shadow-sm text-dark"
                                                style={{
                                                    bottom: -6, right: -5, fontSize: ".75rem", zIndex: 1, pointerEvents: "none"
                                                }}
                                                bg="primary">

                                            </Badge>
                                        )}
                                    </FadeIn>
                                </Col>

                                {isAuthenticated && (
                                    <>
                                        {!isMobileOnly && (
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={btnIconBsVariant}
                                                    iconSize={isDesktop ? btnIconSize : btnIconSizeMobile}
                                                    iconName="search-outline"
                                                    iconClasses=""
                                                    btnClasses={showSearchMenu ? " active" : ""}
                                                    handleClick={() => {

                                                        if (showSearchMenu) {

                                                            setShowSearchMenu(false);

                                                        } else {

                                                            checkMenus(setShowSearchMenu);

                                                        }
                                                    }}
                                                />

                                            </Col>
                                        )}
                                        <Col className="position-relative" xs="auto">
                                            <FadeIn>
                                                <BtnIcon
                                                    bsVariant={btnIconBsVariant}
                                                    iconSize={isDesktop ? btnIconSize : btnIconSizeMobile}
                                                    iconName="notifications-outline"
                                                    iconClasses=""
                                                    btnClasses={showNotificationsMenu ? " active" : ""}
                                                    handleClick={() => {

                                                        if (showNotificationsMenu) {

                                                            setShowNotificationsMenu(!showNotificationsMenu);

                                                        } else {

                                                            checkMenus(setShowNotificationsMenu);

                                                        }
                                                    }}
                                                />
                                                <Badge
                                                    className="position-absolute rounded-pill shadow-sm border border-dark text-dark"
                                                    style={{
                                                        bottom: 0, right: 0, fontSize: ".75rem", zIndex: 1, pointerEvents: "none"
                                                    }}
                                                    bg="primary">
                                                    6
                                                </Badge>
                                            </FadeIn>
                                        </Col>
                                    </>

                                )}

                                {isAuthenticated && (
                                    <Col className="position-relative" xs="auto">
                                        <FadeIn>
                                            <BtnIcon
                                                bsVariant={btnIconBsVariant}
                                                iconSize={isDesktop ? btnIconSize : btnIconSizeMobile}
                                                iconName="cart-outline"
                                                iconClasses=""
                                                btnClasses={showCartWindow ? " active" : ""}
                                                handleClick={() => {
                                                    if (showCartWindow) {

                                                        setShowCartWindow(false);

                                                    } else {

                                                        checkMenus(setShowCartWindow);

                                                    }
                                                }}
                                            />
                                            <Badge
                                                className="position-absolute rounded-pill shadow-sm border border-dark text-dark"
                                                style={{
                                                    bottom: 0, right: 0, fontSize: ".75rem", zIndex: 1, pointerEvents: "none"
                                                }}
                                                bg="primary">
                                                3
                                            </Badge>
                                        </FadeIn>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row >
                </Container>
            </nav >

            {!isAuthenticated && isMobile && (
                <MobileNotAuthMenu
                    showMobileNotAuthMenu={showMobileNotAuthMenu}
                    setShowMobileNotAuthMenu={setShowMobileNotAuthMenu}
                />
            )}
            {
                isAuthenticated && (
                    <>
                        {(isMobileOnly || true) && (

                            <div
                                className={
                                    `${(_showRibbon && !_ribbonShown.current) ? "show-menu" : ""} ${(true) ? "bg-light text-dark border-2 border-bottom border-md-dark " : ""}${isMobileOnly ? "isMobileOnly " : ""}ribbon-menu position-fixed start-0 end-0 top-0 d-flex align-items-center shadow nav-offset`
                                }
                                style={{ zIndex: 30, fontWeight: 300, textShadow: "2px 2px 4px rgba(0,0,0,.1)" }}
                            >
                                <div
                                    className="d-flex align-items-center position-absolute end-0 bottom-0 me-2 mb-3"
                                >
                                    <BtnIcon
                                        bsVariant={"icon-dark"}
                                        iconSize={18}
                                        iconName="arrow-up-outline"
                                        iconClasses=""
                                        btnClasses={""}
                                        handleClick={(e) => {

                                            _ribbonShown.current = true;
                                            _setShowRibbon(false);
                                        }}
                                    />
                                </div>
                                <Container fluid className="px-2 px-sm-0">
                                    <Row className={`${isDark ? "bg-gray-light text-dark border-light-dark" : "bg-gray-light text-dark border-light-dark"} align-items-center justify-content-center border-top border-1 py-2`}>
                                        <Col className="d-flex justify-content-center" xs="auto">
                                            <h3 style={{ fontWeight: 500 }} className="mb-2">
                                                <OBAName /> <span><AppNameInline name="beta.special" showCopyright={false} /></span>
                                            </h3>
                                        </Col>
                                        <Col className="d-flex justify-content-center" xs="12">
                                            <p className="m-0" style={{ fontSize: "1.1rem", fontWeight: 300 }}>Join now, help us test our features and build our network... in turn, get the full experience for the low<span className="ps-1" style={{ fontWeight: 500 }}>limited time</span> rate of <span className="ps-1" style={{ fontWeight: 500 }}>$9.99/mo</span></p>
                                        </Col>
                                        <Col
                                            onClick={(e) => {
                                                e.preventDefault(); e.stopPropagation();
                                            }}
                                            className="d-flex justify-content-center pt-3"
                                            sm="12"
                                        >
                                            <BtnStdLink
                                                bsVariant="dark"
                                                bsSize={""}
                                                btnClasses="rounded-pill border border-2 border-primary text-light shadow-sm pe-3"
                                                iconClasses=""
                                                label={<><span className="text-primary" style={{ fontWeight: 600 }}>FREE</span> 30 DAY TRIAL</>}
                                                iconSize={16}
                                                iconName="arrow-redo-sharp"
                                                useLink={true}
                                                href="/signup/freetrial"
                                                target=""
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        )}
                        <SearchMenu
                            showSearchMenu={showSearchMenu}
                            setShowSearchMenu={setShowSearchMenu}
                        />

                        {!isDesktop && (

                            <AppMenu
                                showAppMenu={showAppMenu}
                                setShowAppMenu={setShowAppMenu}
                                isAuthenticated={isAuthenticated}
                            />

                        )}

                        <CartWindow
                            showCartWindow={showCartWindow}
                            setShowCartWindow={setShowCartWindow}
                        />

                    </>

                )
            }
            <UserMenu
                showUserMenu={showUserMenu}
                checkMenus={checkMenus}
                setShowUserMenu={setShowUserMenu}
                isAuthenticated={isAuthenticated}
                isDark={isDark}
                setIsDark={setIsDark}
                setShowCreateAcct={setShowCreateAcct}
                setShowLogin={setShowLogin}
                setShowLogout={setShowLogout}
                setShowSettingsModal={setShowSettingsModal}
                setShowProfileSelector={setShowProfileSelector}
                user={user}
            />
        </>
    );

};

PageNavTop.propTypes = {
    logo: PropTypes.string,
    logoAlt: PropTypes.string,
    logoMaxW: PropTypes.number,
    btnIconBsVariant: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    setShowLogin: PropTypes.func,
    setShowLogout: PropTypes.func
}

PageNavTop.defaultProps = {

};

export default PageNavTop;

