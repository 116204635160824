import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FadeIn from "react-fade-in/lib/FadeIn";
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';


const BannerTopDefault = () => {

    return (
        <>
            <FadeIn>
                <section
                    className="pt-2 pt-sm-5 pb-2 pb-sm-3 d-flex align-items-center"
                    style={{
                        backgroundImage: "url('https://storage.googleapis.com/onebadappleprototype.appspot.com/temp_imgs/abstract-orange-smooth-wave-lines_1940_Gray.jpg')", backgroundRepeat: "no-repeat",
                        backgroundPositionX: isMobileOnly ? "center" : "left",
                        backgroundPositionY: "center",
                        backgroundSize: ""
                    }}>

                    <Container fluid className="container-1200">
                        <Row className="justify-content-center">
                            <Col xs="auto" className="position-relative">
                                <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo_1470_shadow.png" className="img-fluid" alt="Logo for one.bad.apple" />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </FadeIn>

        </>
    );

};

export default BannerTopDefault;