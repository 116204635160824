import React from "react";
//import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
//import { isMobileOnly } from "react-device-detect";
import FadeIn from "react-fade-in/lib/FadeIn";
//import BtnStdLink from "../components/buttons/BtnStdLink";
import OBAName from "../components/text/OBAName";
//import AppNameInlineIcon from "../components/text/AppNameInlineIcon";
//import AppNameInline from "../components/text/AppNameInline";

import AppsListInlineContent from "./AppsListInlineContent";

const HomeVisitorContentComingSoon = ({
    isAuthenticated
}) => {

    return (
        <>
            {/* <section className="bg-light text-dark py-4 py-lg-5">
                <Container fluid className="container-1200 py-lg-5">
                    <FadeIn>Video Here...</FadeIn>
                </Container>
                
            </section> */}
            <section className="bg-md-dark text-light py-4 py-lg-5">
                <Container fluid className="container-1200">
                    <FadeIn>

                        {/* <h1 className="mb-5 text-center">
                            Features
                        </h1> */}

                        <AppsListInlineContent />
                        
                        
                    </FadeIn>
                </Container>
            </section >
            <section className="bg-light text-dark py-4 py-5">
                <Container fluid className="container-1200">
                    <FadeIn>
                        <Row className="">
                            <Col lg="5">
                                IMGS...
                            </Col>
                            <Col lg="7">
                                <h1 className="mb-4 text-start">
                                    Meet Others &amp; Communicate Your Way
                                </h1>
                                <p className="" style={{ lineHeight: "1.8em" }}>
                                    Get rid of multiple apps to communicate the way you want to - REAL TIME! Have your messages seen and heard with <OBAName fontWeight={500} />'s custom, interactive features all in the convenience of one app that can be accessed on any device.
                                </p>
                                <ul className="" style={{ lineHeight: "1.7em" }}>
                                    <li>Find like minded couples/singles and keep up with current profiles</li>
                                    <li>Share events, parties, updates, image/video uploads to your personal profile</li>
                                    <li>Upload private content only those you selected can see</li>
                                    <li>Make multiple profiles/logins for couples accounts</li>
                                    <li>Real-time text chat with image and video uploads</li>
                                    <li>LIVE VIDEO chats for a more real-time experience</li>
                                    <li>Find private profiles you want to follow</li>
                                    <li>Create a private profile for others to follow</li>                                    
                                </ul>
                            </Col>

                        </Row>
                    </FadeIn>

                </Container>
            </section>
            <section className="bg-md-dark text-light py-4 py-5">
                <Container fluid className="container-1200">
                    <FadeIn>
                        <Row>

                            <Col lg="7">
                                <h1 className="mb-4 text-start">
                                    Customize & Secure Everything
                                </h1>
                                <p className="" style={{ lineHeight: "1.8em" }}>
                                    <OBAName fontWeight={500} /> knows that each of us have our preferences. That's why we let you choose your experience. From viewing preferences to privacy &amp; security, you're always in control with our settings and security options.
                                </p>
                                <ul className="" style={{ lineHeight: "1.7em" }}>
                                    <li>Choose from a light or dark theme for better contrast</li>
                                    <li>View content with compact sliders or grids</li>
                                    <li>Use an image blur effect to add privacy in public settings</li>
                                    <li>Create profiles to allow multiple logins and simultanious interaction</li>
                                    <li>Set uploaded images or videos to public or private and limit who can see them</li>
                                    <li>Text chats are saved to profiles only to ensure privacy</li>
                                    <li>Live video chats are not saved to central location to enhance privacy</li>
                                </ul>
                            </Col>
                            <Col lg="5" className="d-flex justify-content-end">
                                IMGS...
                            </Col>
                        </Row>

                    </FadeIn>

                </Container>
            </section>
            <section className="bg-light text-dark py-4 py-lg-5">
                <Container fluid className="container-1200 py-lg-5">
                    <FadeIn>

                        {/* <h1 className="mb-4 text-start">
                            <OBAName />
                        </h1> */}
                        <Row>
                            <Col lg="5">
                                IMGS...
                            </Col>
                            <Col lg="7">
                                <h1 className="mb-4 text-start d-flex align-items-center">
                                    Any Device, Anywhere, Anytime
                                </h1>
                                <p className="fs-4" style={{ lineHeight: "1.8em" }}>
                                    Connected to mobile data or WIFI? Then you're connected to <OBAName fontWeight={500} />. Our app is compatible with any device that has a web browser. Better yet... any web browser!
                                </p>
                                <h3>Operating Systems</h3>
                                <ul className="" style={{ lineHeight: "1.7em" }}>
                                    <li><ion-icon name="logo-windows"></ion-icon> Windows - Desktop/Laptop</li>
                                    <li><ion-icon name="logo-apple"></ion-icon> Mac/iOS - Desktop/Laptop, iPad, iPhone</li>
                                    <li><ion-icon name="logo-android"></ion-icon> Android - Laptop, Tablets, Phones</li>
                                </ul>
                                <h3>Browsers</h3>
                                <ul className="" style={{ lineHeight: "1.7em" }}>
                                    <li><ion-icon name="logo-chrome"></ion-icon> Chrome</li>
                                    <li><ion-icon name="logo-edge"></ion-icon> Edge</li>
                                    <li><ion-icon name="logo-firefox"></ion-icon> Firefox</li>
                                    <li><ion-icon name="logo-apple"></ion-icon> Safari</li>
                                    <li>...</li>
                                </ul>
                                <p className="fs-4" style={{ lineHeight: "1.8em" }}>
                                    Plus many more...!
                                </p>
                                
                            </Col>

                        </Row>

                    </FadeIn>
                </Container>
            </section >
            {/* <section className="bg-light text-dark py-4 py-5">
                <Container fluid className="container-1200 py-lg-5">
                    <FadeIn>
                        Section...
                    </FadeIn>

                </Container>
            </section> */}
        </>
    );

};


export default HomeVisitorContentComingSoon;