import React, { useEffect, useRef, useState } from "react";
import {
    isMobile,
    isMobileOnly,
    isTablet,
    isDesktop,
} from "react-device-detect";
import Cookies from 'universal-cookie';
import gtag from 'ga-gtag';
import Config from "../data/Config";
import {
    Row, Col, Form
} from "react-bootstrap";

import BtnIcon from "../components/buttons/BtnIcon";
import BtnStdLink from "../components/buttons/BtnStdLink";
import MainModal from "../components/shared/MainModal";
import CollectionRow from "../components/collections/CollectionRow";
import CollectionColumn from "../components/collections/CollectionColumn";
import CollectionHeaderOnly from "../components/collections/CollectionHeaderOnly";
import CollectionColumnNoHeader from "../components/collections/CollectionColumnNoHeader";
import SliderInsideScrollRow from "../components/collections/SliderInsideScrollRow";
import ChatProfileVertical from "../components/profile/ChatProfileVertical";
import LightboxMain from "../components/lightbox/LightboxMain";

import MemberCard from "../components/cards/MemberCard";
import GroupCard from "../components/cards/GroupCard";

//import NavLeft from "../components/connect/fullChat/NavLeft";
//import NavTopRecentChats from "../components/connect/fullChat/NavTopRecentChats";
import RecentChatList from "../components/connect/RecentChatList";
import ChatPlayerAlertMngr from "../components/chatAlerts/ChatPlayerAlertMngr";
//import NavTopMsgList from "../components/connect/fullChat/NavTopMsgList";
import MsgList from "../components/connect/MsgList";
import AdSelectorHub from "../components/ads/AdSelectorHub";
//import AdSelectorSide from "../components/ads/AdSelectorSide";

import ConnectScreenFrameWithProfile from "../components/shared/ConnectScreenFrameWithProfile";
//import BtnStackedLink from "../components/buttons/BtnStackedLink";
//import CollectionColumnNoFrame from "../components/collections/CollectionColumnNoFrame";


const ConnectPreview = ({
    user,
    isDark,
    setShowProfileModal,
    setShowSettingsModal,
    showNotificationsMenu,
    setShowNotificationsMenu,
    setShowProfileSelector,
    toggleLive,
    toggleChat,
    showChatWindow,
    setShowFlirtModal,
}) => {

    const [_showMainModal, _setShowMainModal] = useState(false);
    const [_mainModalContent, _setMainModalContent] = useState(<></>);
    const [_mainModalMaxW, _setMainModalMaxW] = useState("");
    const [_showSliders, _setShowSliders] = useState(true);
    const [_blur, _setBlur] = useState(false);
    const [_showAds, _setShowAds] = useState(true);
    //const [showMinMsgs, setShowMinMsgs] = useState(false);
    const [_showFullWidth, _setShowFullWidth] = useState(false);
    //const [pageSearchTerm, setPageSearchTerm] = useState("");
    const [_showChatAlert, _setShowChatAlert] = useState(false);
    const [_playCallingAnimation, _setPlayCallingAnimation] = useState(false);
    const [_showLightbox, _setShowLightbox] = useState(false);
    const [_lightBoxContent, _setLightBoxContent] = useState(null);

    const [_chatAlertType, _setChatAlertType] = useState("");
    const [_showChatList, _setShowChatList] = useState(false);
    //const [_useChatMic, _setUseChatMic] = useState(true);
    //const [_useVideo, _setUseVideo] = useState(true);
    const [_showVideoPlayer, _setShowVideoPlayer] = useState(false);
    //const [_videoAspect9by16, _setVideoAspect9by16] = useState(isMobile);

    const _activeMembers = Config.memberStatusList; //Active Members

    const _player = useRef();
    // const _endCallSound = useRef();
    // const _startCallSound = useRef();

    const _gtagSent = useRef(false);
    const _cookies = useRef(new Cookies());
    const _cookieOptions = {
        maxAge: 3153600000,
        sameSite: true,
        path: window.location.origin.replace("www.", "")
    }

    useEffect(() => {

        if (_showLightbox || _showFullWidth) {
            document.body.classList.add("lightbox-open");
        } else {
            document.body.classList.remove("lightbox-open");
        }

        _setShowLightbox(false);
        _setLightBoxContent(null);

    }, [_lightBoxContent, _lightBoxContent, _showLightbox, _showFullWidth]);

    useEffect(() => {

        const sectionViewCookie = _cookies.current.get("sectionView", { path: window.location.origin.replace("www.", "") });

        if (sectionViewCookie === "slider") {

            !_showSliders && _setShowSliders(true);

        } else if (sectionViewCookie === "grid") {

            _showSliders && _setShowSliders(false);
        }

    }, [_showSliders, _cookies]);

    const setSliders = () => {

        if (_showSliders) {

            _cookies.current.set("sectionView", "grid", { _cookieOptions });
            _setShowSliders(false);

        } else {

            _cookies.current.set("sectionView", "slider", { _cookieOptions });
            _setShowSliders(true);
        }

    };

    useEffect(() => {
        if (!_gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Connect Activity',
                page_title: 'Connect',
            });

            _gtagSent.current = true;
        }
    }, [_gtagSent]);

    const updateScrollPos = (target, scrollTo, type) => {

        target.scroll({ top: scrollTo, behavior: type });
    }

    const showLightBoxWindow = (data) => {

        _setLightBoxContent(data.imgs);
        _setShowLightbox(true);
    }

    return (
        <>
            <ConnectScreenFrameWithProfile
                user={user}
                isDark={isDark}
                showCarousel={_showAds}
                showFullWidth={_showFullWidth}
                setShowProfileModal={setShowProfileModal}
                setShowSettingsModal={setShowSettingsModal}
                showNotificationsMenu={showNotificationsMenu}
                setShowNotificationsMenu={setShowNotificationsMenu}
                setShowProfileSelector={setShowProfileSelector}
            >

                <div className="ms-1 pb-3">
                    <CollectionHeaderOnly
                        appName="connect"
                        tagline={<>Chat live and connect with others</>}
                        isDark={isDark}
                        showAds={_showAds}
                        setShowAds={_setShowAds}
                        showAdsBtn={true}
                        showScrollBtn={true}
                        showSliders={_showSliders}
                        setShowSliders={setSliders}
                        showBlurBtn={true}
                        blur={_blur}
                        setBlur={_setBlur}
                        iconName="chatbubbles-outline"
                    />
                </div>

                {/* NEW MESSENGER */}
                <CollectionRow className={"position-relative align-items-stretch"}>
                    <CollectionColumnNoHeader
                        xs="12"
                        sm={null}
                        md={null}
                        lg={null}
                        xl={null}
                        isDark={isDark}
                        className={"pb-0"}
                    >
                        <div
                            className={(_showFullWidth ? `position-fixed start-0 top-0 end-0 bottom-0 py-1 px-2 px-sm-4 py-sm-3 ${isMobileOnly ? "isMobileOnly " : ""}${isDark ? "bg-md2-dark" : "bg-light"}` : "position-relative")}
                            style={{
                                zIndex: _showFullWidth ? 1035 : 1,

                            }}
                        >
                            <>
                                {_showChatAlert && (
                                    <div
                                        className={(isDark ? "text-light" : "text-dark") + " d-flex flex-column align-items-center justify-content-center position-absolute top-0 end-0 bottom-0 start-0 rounded-1"}
                                        style={{
                                            backgroundColor: isDark ? "rgba(0, 0, 0, .6)" : "rgba(255, 255, 255, .6)",
                                            //backgroundColor: "rgba(0, 0, 0, .6)",
                                            zIndex: 10,
                                            //pointerEvents: "none"
                                            margin: -15,
                                        }}
                                    >
                                        <ChatPlayerAlertMngr
                                            isDark={isDark}
                                            toggleChat={toggleChat}
                                            toggleLive={toggleLive}
                                            setShowVideoPlayer={_setShowVideoPlayer}
                                            //setUseVideo={_setUseVideo}
                                            //setVideoAspect9by16={_setVideoAspect9by16}
                                            chatAlertType={_chatAlertType}
                                            setShowChatAlert={_setShowChatAlert}
                                            setChatAlertType={_setChatAlertType}
                                            setShowChatList={_setShowChatList}
                                            setPlayCallingAnimation={_setPlayCallingAnimation}
                                            playCallingAnimation={_playCallingAnimation}
                                            profileName={"EandK-Having-Fun"}
                                            profileRelationship={"Couple (M/F)"}
                                            profileImage={"https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg"}
                                        />
                                    </div>
                                )}
                                <Row ref={_player} className="align-items-center justify-content-between gx-2 mt-1 mb-0">
                                    <Col xs="auto" lg={"auto"}>
                                        <h4 className="m-0">Connect Messages</h4>
                                    </Col>
                                    <Col className=" d-flex align-items-center justify-content-end" xs="auto">
                                        <Row className="align-items-center gx-1 gx-sm-2">
                                            <Col xs="auto">
                                                <BtnStdLink
                                                    bsVariant="dark"
                                                    bsSize={isMobile ? "xs" : "sm"}
                                                    btnClasses="rounded-pill border border-2 border-primary text-light shadow-sm pe-3"
                                                    iconClasses=""
                                                    label={<><span className="text-primary d-none d-sm-inline" style={{ fontWeight: 600 }}>New</span> Chat</>}
                                                    iconSize={18}
                                                    iconName="arrow-redo-sharp"
                                                    useLink={false}
                                                    handleClick={() => {

                                                        return;
                                                        //_setShowChatList(!_showChatList);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                    label={_showChatList ? "Hide list..." : "Show list..."}
                                                    iconSize={26}
                                                    iconName={_showChatList ? "reorder-four-outline" : "reorder-four-outline"}
                                                    iconClasses=""
                                                    btnClasses={_showChatList ? "active" : ""}
                                                    handleClick={() => {
                                                        _setShowChatList(!_showChatList);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                    label={_showFullWidth ? "Contract window..." : "Expand window..."}
                                                    iconSize={26}
                                                    iconName={_showFullWidth ? "contract-outline" : "expand-outline"}
                                                    iconClasses=""
                                                    btnClasses=""
                                                    handleClick={(e) => {
                                                        _setShowFullWidth(!_showFullWidth);
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                                <Row
                                    className="position-relative overflow-hidden align-items-stretch gx-3 py-2"
                                >
                                    <div
                                        className={(isDark ? "bg-md2-dark " : "bg-light ") + (_showChatList ? "show-menu " : "") + "chat-list position-absolute top-0 bottom-0 d-flex flex-column py-3"}
                                        style={{
                                            zIndex: 8,
                                            width: "95vw",
                                            maxWidth: 400,
                                        }}
                                    >
                                        {/* CHAT LIST */}
                                        <div className="pb-1 mb-2">
                                            <Row className="justify-content-between align-items-center g-1 ps-3 pe-2">
                                                <Col xs="auto">
                                                    <h4 className="d-flex align-items-center justify-content-start m-0">
                                                        Recent Chats
                                                    </h4>
                                                </Col>
                                                <Col xs="auto">
                                                    <BtnIcon
                                                        bsVariant={"icon-light"}
                                                        label="Minimize recent chats..."
                                                        iconSize={20}
                                                        iconName="arrow-back-outline"
                                                        iconClasses=""
                                                        btnClasses=""
                                                        handleClick={() => {
                                                            _setShowChatList(false);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="position-relative" style={{ height: "100%", fontSize: ".9rem" }}>

                                            {_showChatList && (
                                                <div className="my-3">
                                                    <RecentChatList
                                                        isDark={isDark}
                                                        showMinMsgs={false}
                                                        setShowMinMsgs={null}
                                                        isExpanded={!isMobileOnly}
                                                        updateScrollPos={updateScrollPos}
                                                        setShowChatAlert={null}
                                                    />
                                                </div>
                                            )}

                                        </div>
                                    </div>

                                    {isDesktop && (
                                        <Col lg={"2"}>

                                            {/* CHAT PROFILE DESKTOP */}
                                            <ChatProfileVertical
                                                pageName="connect"
                                                blur={_blur}
                                                isDark={isDark}
                                                showLightBoxWindow={showLightBoxWindow}
                                                setShowFlirtModal={setShowFlirtModal}
                                                setShowChatAlert={_setShowChatAlert}
                                                setChatAlertType={_setChatAlertType}
                                                setPlayCallingAnimation={_setPlayCallingAnimation}
                                                setShowProfileModal={setShowProfileModal}
                                                toggleChat={toggleChat}
                                                toggleLive={toggleLive}
                                                showVideoPlayer={_showVideoPlayer}
                                            />
                                        </Col>
                                    )}

                                    <Col lg>
                                        <div className={(isDark ? "bg-dark" : "bg-light") + " rounded-1"}>
                                            <Row className="align-items-center justify-content-end gx-1 gx-sm-2 pt-1 pb-3 pb-lg-1">
                                                <Col xs="auto">
                                                    <BtnIcon
                                                        bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                        label="Members..."
                                                        iconSize={24}
                                                        iconName={"person-add-outline"}
                                                        iconClasses=""
                                                        btnClasses=""
                                                        handleClick={(e) => {
                                                            void (0);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs="auto">
                                                    <BtnIcon
                                                        bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                        label="Start a video chat..."
                                                        iconSize={24}
                                                        iconName={"videocam-outline"}
                                                        iconClasses=""
                                                        btnClasses=""
                                                        handleClick={(e) => {
                                                            toggleLive();
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs="auto">
                                                    <BtnIcon
                                                        bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                        label="Back to recent messages..."
                                                        iconSize={24}
                                                        iconName="ellipsis-vertical-outline"
                                                        iconClasses=""
                                                        btnClasses=""
                                                        handleClick={(e) => {
                                                            e.preventDefault();
                                                            alert("This feature is under development ");
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <div
                                                className="position-relative overflow-hidden d-flex flex-column px-2"
                                                style={{
                                                    aspectRatio: isMobile ? "9 / 16" : "16 / 9",
                                                }}
                                            >

                                                <div className="position-relative" style={{ height: "100%" }}>
                                                    {/* CONNECT MSG LIST */}
                                                    <div
                                                        style={{
                                                            fontSize: ".9rem"
                                                        }}
                                                        onClick={(e) => {
                                                            _showChatAlert(true);
                                                        }}
                                                    >
                                                        <MsgList
                                                            isDark={isDark}
                                                            updateScrollPos={updateScrollPos}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-center pt-2 pb-1 ps-2 pe-0">
                                                    {/* CONNECT MSG INPUT */}
                                                    <Row className="g-1 p-0">
                                                        <Col>
                                                            <Form.Control
                                                                placeholder="what's on your mind?..."
                                                                className={
                                                                    (isDark ? "bg-md-dark border-0 " : "") + "text-light m-0"
                                                                }
                                                                size=""
                                                            />
                                                        </Col>
                                                        <Col xs="auto">
                                                            <BtnIcon
                                                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                                label="Post your message..."
                                                                iconSize={26}
                                                                iconName="arrow-forward-outline"
                                                                iconClasses=""
                                                                btnClasses="rounded-0"
                                                                handleClick={(e) => {
                                                                    e.preventDefault();
                                                                    //setShowMinMsgs(false);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* <Col className="ps-0" xs="auto">
                                        <Row className="flex-column justify-content-end g-1">
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                    label="Members..."
                                                    iconSize={24}
                                                    iconName={"person-add-outline"}
                                                    iconClasses=""
                                                    btnClasses=""
                                                    handleClick={(e) => {
                                                        void (0);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                    label="Start a video chat..."
                                                    iconSize={24}
                                                    iconName={"videocam-outline"}
                                                    iconClasses=""
                                                    btnClasses=""
                                                    handleClick={(e) => {
                                                        void (0);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                    label="Back to recent messages..."
                                                    iconSize={24}
                                                    iconName="ellipsis-vertical-outline"
                                                    iconClasses=""
                                                    btnClasses=""
                                                    handleClick={(e) => {
                                                        e.preventDefault();
                                                        alert("This feature is under development ");
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> */}
                                </Row>

                            </>
                        </div >
                    </CollectionColumnNoHeader>
                </CollectionRow>


                {/* OLD CHAT SCREEN */}
                <Row className="justify-content-between gx-3">
                    <Col lg>
                        {/* <CollectionRow className={"align-items-stretch"}>
                            <CollectionColumnNoHeader
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                className={"position-relative"}
                            >
                                <div
                                    className={(_showFullWidth ? `position-fixed start-0 top-0 end-0 bottom-0 py-1 px-2 px-sm-4 py-sm-3 ${isMobileOnly ? "isMobileOnly " : ""}${isDark ? "bg-dark" : "bg-light"}` : "position-relative")}
                                    style={{
                                        zIndex: _showFullWidth ? 1035 : 1,
                                    }}
                                >
                                    <>
                                        {_showChatAlert && (
                                            <div
                                                className={(isDark ? "text-light" : "text-dark") + " d-flex flex-column align-items-center justify-content-center position-absolute top-0 end-0 bottom-0 start-0 rounded-1"}
                                                style={{
                                                    backgroundColor: isDark ? "rgba(0, 0, 0, .6)" : "rgba(255, 255, 255, .6)",
                                                    zIndex: 5,
                                                    margin: -15,
                                                }}
                                            >
                                                <div
                                                    className={(isDark || true ? "bg-md-dark text-light" : "bg-light text-dark") + " rounded-1 text-center p-4"}
                                                    style={{ maxWidth: 320 }}
                                                >
                                                    <h4 className="d-flex align-items-center justify-content-center mb-2"><span className="text-primary mb-0 me-2 fs-2" style={{ height: "1em" }}><ion-icon name="chatbubbles-outline"></ion-icon></span> Message Authorization</h4>
                                                    <Row className="d-inline-flex align-items-center justify-content-start mx-auto pb-4 pt-2 gx-2">
                                                        <Col className="" xs="auto">
                                                            <img
                                                                src={"https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20220904_005637-small.jpg"}
                                                                className="img-fluid rounded-circle shadow"
                                                                alt={"E-K-Having-Fun"}
                                                                style={{
                                                                    height: 44,
                                                                    width: 44
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col
                                                            className="d-flex flex-column align-items-start justify-content-start"
                                                            xs="auto"
                                                        >
                                                            <h5
                                                                className="mb-0"
                                                                style={{
                                                                    height: "1.2em",
                                                                    //fontSize: ".90rem",
                                                                }}
                                                            >
                                                                E-K-Having-Fun
                                                            </h5>
                                                            <div style={{ height: "1.3em", fontSize: ".80rem", }}>Couple (M/F)</div>
                                                        </Col>
                                                    </Row>
                                                    <p className="text-start mb-4" style={{ fontSize: ".90rem" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam diam diam, euismod vitae sem ut, blandit maximus tellus.</p>
                                                    <Row className="flex-column gy-3">
                                                        <Col>
                                                            <div className="d-grid">
                                                                <BtnStackedLink
                                                                    bsVariant={"primary"}
                                                                    bsSize={"xs"}
                                                                    alignLeft={true}
                                                                    btnClasses="rounded-pill shadow-sm px-3"
                                                                    label={<><span className="text-light" style={{ fontWeight: 700 }}>VIEW</span> Message</>}
                                                                    sublabel={!isMobile ? "Answer this chat request..." : null}
                                                                    iconSize={!isMobile ? 28 : 16}
                                                                    iconName="checkmark-circle-outline"
                                                                    useLink={false}
                                                                    handleClick={() => {
                                                                        _setShowChatAlert(false);
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <h6 className="text-center mb-0 mt-2">Options</h6>
                                                        </Col>
                                                        <Col>
                                                            <div className="d-grid">
                                                                <BtnStackedLink
                                                                    bsVariant={isDark || true ? "light" : "dark"}
                                                                    bsSize={"xs"}
                                                                    alignLeft={true}
                                                                    btnClasses="rounded-pill shadow-sm px-3"
                                                                    label={<><span className="text-primary" style={{ fontWeight: 700 }}>Decline</span> Message</>}
                                                                    sublabel={!isMobile ? "Sender will not know" : null}
                                                                    iconSize={!isMobile ? 28 : 16}
                                                                    iconName="remove-circle-outline"
                                                                    useLink={false}
                                                                    href="/"
                                                                    target=""
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <div className="d-grid">
                                                                <BtnStackedLink
                                                                    bsVariant={"danger"}
                                                                    bsSize={"xs"}
                                                                    alignLeft={true}
                                                                    btnClasses="rounded-pill shadow-sm px-3"
                                                                    label={<>Block Sender</>}
                                                                    sublabel={!isMobile ? "You can undo this at any time" : null}
                                                                    iconSize={!isMobile ? 28 : 16}
                                                                    iconName="ban-outline"
                                                                    useLink={false}
                                                                    href="/"
                                                                    target=""
                                                                />
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </div>
                                            </div>
                                        )}
                                        <Row className="align-items-center justify-content-between pt-2 pb-2 mb-2">
                                            <Col xs="auto" lg={"auto"}>
                                                <h4 className="m-0">Connect Messages</h4>
                                            </Col>
                                            <Col className="order-2 order-sm-1 mt-2 mt-sm-0" xs="10" lg="9">
                                                <Form.Group className="m-0">
                                                    <Form.Control
                                                        size="sm"
                                                        placeholder="Search messages..."
                                                        type="text"
                                                        autoCapitalize="off"
                                                        className={(isDark ? "bg-md-dark border-dark text-light" : "") + " d-grid link-field rounded-1 form-control text-start"}
                                                        value={pageSearchTerm}
                                                        onChange={(e) => {
                                                            setPageSearchTerm(e.target.value);
                                                        }}
                                                    />
                                                </Form.Group>

                                            </Col>
                                            <Col className="order-1 order-sm-2 d-flex align-items-center justify-content-end" xs="auto">
                                                <span className={(_showFullWidth ? "d-inline " : "d-none ") + "me-2"} style={{ fontSize: ".9rem" }}>
                                                    Exit
                                                </span>
                                                <BtnIcon
                                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                    label={_showFullWidth ? "Contract window..." : "Expand window..."}
                                                    iconSize={20}
                                                    iconName={_showFullWidth ? "contract-outline" : "expand-outline"}
                                                    iconClasses=""
                                                    btnClasses=""
                                                    handleClick={(e) => {
                                                        _setShowFullWidth(!_showFullWidth);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        {!isMobileOnly ? (

                                            <Row
                                                className="py-2 gx-3 align-items-stretch justify-content-between"
                                                style={{
                                                    height: _showFullWidth ? "85vh" : "75vh",
                                                    //fontSize: ".9rem",
                                                }}
                                            >
                                                <Col xs="auto">

                                                    <NavLeft
                                                        isDark={isDark}
                                                    />

                                                </Col>
                                                <Col sm="4" lg={_showFullWidth ? "3" : "3"} className={"d-flex flex-column"}>
                                                    <div
                                                        className="pt-1 pb-1 px-1"
                                                        style={{ fontSize: ".9rem", }}
                                                    >

                                                        <NavTopRecentChats
                                                            isDark={isDark}
                                                        />

                                                    </div>
                                                    <div className="position-relative" style={{ height: "100%", fontSize: ".9rem" }}>
                                                        <RecentChatList
                                                            isDark={isDark}
                                                            showMinMsgs={showMinMsgs}
                                                            setShowMinMsgs={setShowMinMsgs}
                                                            isExpanded={!isMobileOnly}
                                                            updateScrollPos={updateScrollPos}
                                                            setShowChatAlert={_setShowChatAlert}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="d-flex flex-column">
                                                    <div className="pt-1 pb-2 px-2">
                                                        <NavTopMsgList
                                                            isDark={isDark}
                                                        />
                                                    </div>
                                                    <div className="position-relative" style={{ height: "100%" }}>
                                                        <div
                                                            style={{
                                                                fontSize: ".9rem"
                                                            }}
                                                            onClick={(e) => {
                                                                _showChatAlert(true);
                                                            }}
                                                        >
                                                            <MsgList
                                                                isDark={isDark}
                                                                updateScrollPos={updateScrollPos}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="text-center pt-2 pb-1 ps-2 pe-0">
                                                        <Row className="g-1 p-0">
                                                            <Col>
                                                                <Form.Control
                                                                    placeholder="what's on your mind?..."
                                                                    className={
                                                                        (isDark ? "bg-md-dark border-0 " : "") + "text-light m-0"
                                                                    }
                                                                    size=""
                                                                />
                                                            </Col>
                                                            <Col xs="auto">
                                                                <BtnIcon
                                                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                                    label="Post your message..."
                                                                    iconSize={26}
                                                                    iconName="arrow-forward-outline"
                                                                    iconClasses=""
                                                                    btnClasses="rounded-0"
                                                                    handleClick={(e) => {
                                                                        e.preventDefault();
                                                                        setShowMinMsgs(false);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>

                                        ) : (
                                            <>
                                                <Row
                                                    className="py-2 gx-3 align-items-stretch justify-content-between"
                                                    style={{
                                                        height: _showFullWidth ? "80vh" : "55vh",
                                                        fontSize: ".9rem",
                                                    }}
                                                >
                                                    <Col className="d-flex flex-column">
                                                        <div className="pt-1 pb-2 px-0">
                                                            {showMinMsgs ? (
                                                                <>
                                                                    <Row className="justify-content-between align-items-center gx-0">
                                                                        <Col className="ps-0" xs="auto">
                                                                            <Row className="justify-content-between align-items-center gx-0">
                                                                                <Col xs="auto">
                                                                                    <BtnIcon
                                                                                        bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                                                        label="Back to recent messages..."
                                                                                        iconSize={22}
                                                                                        iconName="arrow-back-outline"
                                                                                        iconClasses=""
                                                                                        btnClasses=""
                                                                                        handleClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            setShowMinMsgs(false);
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                                <Col xs="auto">
                                                                                    <div className="d-flex align-items-center justify-content-start m-0">
                                                                                        <div className="me-1" style={{ lineHeight: "1rem" }}>
                                                                                            <ion-icon style={{ fontSize: 30, }} name="person-circle-outline"></ion-icon>
                                                                                        </div>
                                                                                        <div style={{ lineHeight: "1rem" }}>
                                                                                            <div style={{ fontSize: "1rem" }}>one.bad.apple</div>
                                                                                            <div className={isDark ? "text-light-opaque" : "text-dark"} style={{ fontSize: ".70rem" }}>Last active: 8:03 PM, 08/28/22</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col className="pe-0" xs="auto">
                                                                            <BtnIcon
                                                                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                                                label="Back to recent messages..."
                                                                                iconSize={22}
                                                                                iconName="ellipsis-vertical-outline"
                                                                                iconClasses=""
                                                                                btnClasses=""
                                                                                handleClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    alert("This feature is under development ");
                                                                                }}
                                                                            />
                                                                        </Col>

                                                                    </Row>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Row className="justify-content-between align-items-center gx-0">
                                                                        <Col xs="auto">
                                                                            <BtnIcon
                                                                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                                                label="View your link contacts..."
                                                                                iconSize={20}
                                                                                iconName="list-outline"
                                                                                iconClasses=""
                                                                                btnClasses=""
                                                                                handleClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    alert("This feature is under development ");
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            <BtnIcon
                                                                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                                                label="Mark all as read..."
                                                                                iconSize={20}
                                                                                iconName="checkmark-circle-outline"
                                                                                iconClasses=""
                                                                                btnClasses=""
                                                                                handleClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    alert("This feature is under development ");
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="position-relative" style={{ height: "100%" }}>

                                                            {showMinMsgs ? (
                                                                <>
                                                                    <MsgList
                                                                        isDark={isDark}
                                                                        updateScrollPos={updateScrollPos}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <RecentChatList
                                                                        isDark={isDark}
                                                                        showMinMsgs={showMinMsgs}
                                                                        setShowMinMsgs={setShowMinMsgs}
                                                                        isExpanded={!isMobileOnly}
                                                                        updateScrollPos={updateScrollPos}
                                                                    />
                                                                </>
                                                            )}

                                                        </div>
                                                        {showMinMsgs && (
                                                            <div className="text-center pt-1 ps-2">
                                                                <Row className="gx-0 p-0">
                                                                    <Col>
                                                                        <Form.Control
                                                                            placeholder="what's on your mind?..."
                                                                            className={
                                                                                (isDark ? "bg-md-dark border-0 " : "") + "text-light m-0"
                                                                            }
                                                                            size=""
                                                                        />
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <BtnIcon
                                                                            bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                                            label="Post your message..."
                                                                            iconSize={22}
                                                                            iconName="arrow-forward-outline"
                                                                            iconClasses=""
                                                                            btnClasses="rounded-0"
                                                                            handleClick={(e) => {
                                                                                e.preventDefault();
                                                                                setShowMinMsgs(false);
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </>
                                </div >
                            </CollectionColumnNoHeader>
                        </CollectionRow> */}

                        {_showAds && (
                            <div className="pb-3">
                                <AdSelectorHub
                                    isDark={isDark}
                                    isMobileOnly={isMobileOnly}
                                    isTablet={isTablet}
                                    useAlt={false}
                                />
                            </div>

                        )}

                        <CollectionRow className={"pt-2"}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<span className="text-primary">Online Now!</span>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".65em", }}>{<>({_activeMembers.length})</>}</small></>}
                                iconName={"sync-outline"}
                                label={"See all members in the connect app..."}
                                appName="events"
                            >
                                <div className={(isDark ? "text-light" : "text-dark") + " d-flex flex-column"}>
                                    {_showSliders ? (
                                        <SliderInsideScrollRow showScrollIcons={false}>
                                            {/* SCROLL VIEW */}
                                            <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                                <Col className="mb-2" xs="6" sm="4" lg="2">

                                                    <MemberCard
                                                        isDark={isDark}
                                                        blur={_blur}
                                                        status={"online"}
                                                        linkStatus={"friends"}
                                                        data={{}}
                                                        handleClick={() => {

                                                            void (0);
                                                        }}
                                                    />

                                                </Col>
                                                <Col className="mb-2" xs="6" sm="4" lg="2">

                                                    <MemberCard
                                                        isDark={isDark}
                                                        blur={_blur}
                                                        status={"online"}
                                                        linkStatus={"friends"}
                                                        data={{}}
                                                        handleClick={() => {

                                                            void (0);
                                                        }}
                                                    />

                                                </Col>
                                                <Col className="mb-2" xs="6" sm="4" lg="2">

                                                    <MemberCard
                                                        isDark={isDark}
                                                        blur={_blur}
                                                        status={"online"}
                                                        linkStatus={"friends"}
                                                        data={{}}
                                                        handleClick={() => {

                                                            void (0);
                                                        }}
                                                    />

                                                </Col>
                                                <Col className="mb-2" xs="6" sm="4" lg="2">

                                                    <MemberCard
                                                        isDark={isDark}
                                                        blur={_blur}
                                                        status={"online"}
                                                        linkStatus={"friends"}
                                                        data={{}}
                                                        handleClick={() => {

                                                            void (0);
                                                        }}
                                                    />

                                                </Col>
                                                <Col className="mb-2" xs="6" sm="4" lg="2">

                                                    <MemberCard
                                                        isDark={isDark}
                                                        blur={_blur}
                                                        status={"online"}
                                                        linkStatus={"friends"}
                                                        data={{}}
                                                        handleClick={() => {

                                                            void (0);
                                                        }}
                                                    />

                                                </Col>
                                                <Col className="mb-2" xs="6" sm="4" lg="2">

                                                    <MemberCard
                                                        isDark={isDark}
                                                        blur={_blur}
                                                        status={"online"}
                                                        linkStatus={"friends"}
                                                        data={{}}
                                                        handleClick={() => {

                                                            void (0);
                                                        }}
                                                    />

                                                </Col>
                                            </Row>
                                        </SliderInsideScrollRow>
                                    ) : (

                                        <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                            {/* GRID VIEW */}
                                            <Col className="mb-2" xs="6" sm="4" lg="2">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="2">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="2">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="2">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="2">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="2">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </CollectionColumn>
                        </CollectionRow>

                        {_showAds && (
                            <div className="pb-3">
                                <AdSelectorHub
                                    isDark={isDark}
                                    isMobileOnly={isMobileOnly}
                                    isTablet={isTablet}
                                    useAlt={false}
                                />
                            </div>
                        )}

                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<span>Your Groups</span>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{"(12)"}</small></>}
                                iconName={"images-outline"}
                                label={"See all member updates in the link app..."}
                                appName="events"
                            >
                                {_showSliders ? (
                                    <SliderInsideScrollRow showScrollIcons={false}>
                                        {/* SCROLL VIEW */}
                                        <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                            <Col className="mb-1" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                                <GroupCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"member"}
                                                    data={{}}
                                                    handleClick={() => {
                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-1" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                                <GroupCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"member"}
                                                    data={{}}
                                                    handleClick={() => {
                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-1" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                                <GroupCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"member"}
                                                    data={{}}
                                                    handleClick={() => {
                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-1" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                                <GroupCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"member"}
                                                    data={{}}
                                                    handleClick={() => {
                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-1" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                                <GroupCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"member"}
                                                    data={{}}
                                                    handleClick={() => {
                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-1" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                                <GroupCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"member"}
                                                    data={{}}
                                                    handleClick={() => {
                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                        </Row>
                                    </SliderInsideScrollRow>
                                ) : (

                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                        {/* GRID VIEW */}
                                        <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                            <GroupCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"member"}
                                                data={{}}
                                                handleClick={() => {
                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                            <GroupCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"member"}
                                                data={{}}
                                                handleClick={() => {
                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                            <GroupCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"member"}
                                                data={{}}
                                                handleClick={() => {
                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                            <GroupCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"member"}
                                                data={{}}
                                                handleClick={() => {
                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                            <GroupCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"member"}
                                                data={{}}
                                                handleClick={() => {
                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                            <GroupCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"member"}
                                                data={{}}
                                                handleClick={() => {
                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                    </Row>
                                )}
                            </CollectionColumn>
                        </CollectionRow>
                    </Col>
                    {/* {!isMobile && (
                        <Col lg="auto">
                            <CollectionRow className={"align-items-stretch"}>
                                <CollectionColumnNoFrame
                                    xs="auto"
                                    sm={null}
                                    md={null}
                                    lg={null}
                                    xl={null}
                                    isDark={isDark}
                                    className={""}
                                >
                                    <AdSelectorSide
                                        isDark={isDark}
                                        stack={"3small"}
                                    />
                                </CollectionColumnNoFrame>
                            </CollectionRow>
                        </Col>
                    )} */}

                </Row>

            </ConnectScreenFrameWithProfile>

            <MainModal
                showNav={true}
                isDark={isDark}
                removeNaveSpacing={false}
                modalColor={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} shadow`}
                bsModalSize=""
                dialogClassName={(`${_mainModalMaxW && _mainModalMaxW !== "" ? ` ${_mainModalMaxW}` : ""}`)}
                contentClassName={`rounded-0${isDark ? " border border-dark" : " border border-secondary"}`}
                showModal={_showMainModal}
                closeModal={() => {
                    _setShowMainModal(false);
                    _setMainModalContent(<></>);
                    _setMainModalMaxW("");
                }}
            >
                {_showMainModal && (
                    <>
                        {_mainModalContent}
                    </>
                )}

            </MainModal>

            {_showLightbox && (
                <LightboxMain
                    lightBoxContent={_lightBoxContent}
                    user={user}
                    isDark={isDark}
                    showComments={true}
                    updateScrollPos={updateScrollPos}
                    setShowLightbox={_setShowLightbox}
                    blur={_blur}
                />
            )}
        </>
    );

};

export default ConnectPreview;