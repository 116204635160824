import React, { useEffect, useRef, useState } from "react";
import {
    //isDesktop,
    isMobileOnly,
    isMobile,
    isTablet,
} from "react-device-detect";
import Cookies from 'universal-cookie';
import gtag from 'ga-gtag';
import Config from "../data/Config";
import {
    Row, Col, Form,
} from "react-bootstrap";


import MainModal from "../components/shared/MainModal";
import CollectionRow from "../components/collections/CollectionRow";
import CollectionHeaderOnly from "../components/collections/CollectionHeaderOnly";
import CollectionColumn from "../components/collections/CollectionColumn";
import CollectionColumnNoHeader from "../components/collections/CollectionColumnNoHeader";
import SliderInsideScrollRow from "../components/collections/SliderInsideScrollRow";
import MemberCard from "../components/cards/MemberCard";
import ConnectScreenFrameWithProfile from "../components/shared/ConnectScreenFrameWithProfile";
import AdSelectorCategories from "../components/ads/AdSelectorCategories";
import AdSelectorSide from "../components/ads/AdSelectorSide";
import PageSortFilter from "../components/shared/PageSortFilter";

const ConnectMembers = ({
    user,
    isDark,
    setShowProfileModal,
    setShowSettingsModal,
    showNotificationsMenu,
    setShowNotificationsMenu,
    setShowProfileSelector
}) => {

    const [_showMainModal, _setShowMainModal] = useState(false);
    const [_mainModalContent, _setMainModalContent] = useState(<></>);
    const [_mainModalMaxW, _setMainModalMaxW] = useState("");
    const [_showSliders, _setShowSliders] = useState(true);
    const [_blur, _setBlur] = useState(true);
    const [_showAds, _setShowAds] = useState(true);
    const [pageSearchTerm, setPageSearchTerm] = useState("");
    
    const _memberPhotoVideoPostData = Config.memberPhotoVideoPosts; //memberClips

    const _gtagSent = useRef(false);
    const _cookies = useRef(new Cookies());
    const _cookieOptions = {
        maxAge: 3153600000,
        sameSite: true,
        path: window.location.origin.replace("www.", "")
    }

    useEffect(() => {

        console.log(pageSearchTerm);

    }, [pageSearchTerm]);

    useEffect(() => {

        const sectionViewCookie = _cookies.current.get("sectionView", { path: window.location.origin.replace("www.", "") });

        if (sectionViewCookie === "slider") {

            !_showSliders && _setShowSliders(true);

        } else if (sectionViewCookie === "grid") {

            _showSliders && _setShowSliders(false);

        }

    }, [_showSliders, _cookies]);

    const setSliders = () => {

        if (_showSliders) {

            _cookies.current.set("sectionView", "grid", { _cookieOptions });
            _setShowSliders(false);

        } else {

            _cookies.current.set("sectionView", "slider", { _cookieOptions });
            _setShowSliders(true);
        }

    };

    useEffect(() => {
        if (!_gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Connect Members',
                page_title: 'Connect Members',
            });

            _gtagSent.current = true;
        }
    }, [_gtagSent]);

    return (
        <>
            <ConnectScreenFrameWithProfile
                user={user}
                isDark={isDark}
                showCarousel={_showAds}
                setShowProfileModal={setShowProfileModal}
                setShowSettingsModal={setShowSettingsModal}
                showNotificationsMenu={showNotificationsMenu}
                setShowNotificationsMenu={setShowNotificationsMenu}
                setShowProfileSelector={setShowProfileSelector}
            >
                <div className="mb-3 ms-1">
                    <CollectionHeaderOnly
                        appName="connect.members"
                        tagline={<>Find connect app members...</>}
                        isDark={isDark}
                        showAds={_showAds}
                        setShowAds={_setShowAds}
                        showScrollBtn={true}
                        showSliders={_showSliders}
                        setShowSliders={setSliders}
                        showBlurBtn={true}
                        blur={_blur}
                        setBlur={_setBlur}
                        iconName="checkmark-circle-outline"
                        appUrl={"/link"}
                    />
                </div>

                <Row className="justify-content-between flex-nowrap gx-3">
                    <Col lg>
                        {/* SEARCH/CREATE */}
                        <CollectionRow className={"align-items-center"}>
                            <CollectionColumnNoHeader
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                            >
                                <div
                                    className="position-relative"
                                    style={{
                                        //cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        void (0);
                                    }}
                                >
                                    <Form.Control
                                        size="lg"
                                        placeholder="Search by member name, comments, etc..."
                                        type="text"
                                        autoCapitalize="off"
                                        className={(isDark ? "bg-md-dark border-dark text-light" : "") + " link-field rounded-1 form-control text-start mt-2 mb-2"}
                                        value={pageSearchTerm}
                                        onChange={(e) => {
                                            setPageSearchTerm(e.target.value);
                                        }}
                                    />

                                </div>

                            </CollectionColumnNoHeader>
                            {/* <CollectionColumnNoFrame
                                    xs="12"
                                    sm={null}
                                    md={null}
                                    lg={"4"}
                                    xl={null}
                                    isDark={isDark}
                                    heading={<>Create Clip</>}
                                    smallHeading={null}
                                    label={"See all clips in the link app..."}
                                >
                                    <div className="d-grid">
                                        <BtnStackedLink
                                            bsVariant={isDark ? "outline-light" : "outline-dark"}
                                            bsSize={""}
                                            btnClasses="rounded-1 border-secondary shadow-sm"
                                            label={"Create/Upload"}
                                            sublabel={!isMobile ? "Get started making your own..." : null}
                                            iconSize={!isMobile ? 62 : 32}
                                            iconName="add-outline"
                                            useLink={true}
                                            href="/"
                                            target=""
                                        />
                                    </div>

                                </CollectionColumnNoFrame> */}
                        </CollectionRow>
                        {/* SORT */}
                        <PageSortFilter
                            isDark={isDark}
                        />
                        {/* ROWS */}
                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<span className="text-primary">Your Friends</span>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                                iconName={"images-outline"}
                                label={"See all member updates in the link app..."}
                                appName="events"
                            >
                                {_showSliders ? (
                                    <SliderInsideScrollRow showScrollIcons={false}>
                                        {/* SCROLL VIEW */}
                                        <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                        </Row>
                                    </SliderInsideScrollRow>
                                ) : (

                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                        {/* GRID VIEW */}
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                    </Row>
                                )}
                            </CollectionColumn>
                        </CollectionRow>

                        {_showAds && (
                            <div className="pb-3">
                                <AdSelectorCategories
                                    isDark={isDark}
                                    isMobileOnly={isMobileOnly}
                                    isTablet={isTablet}
                                    useAlt={false}
                                />
                            </div>

                        )}

                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<span>New!</span>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                                iconName={"images-outline"}
                                label={"See all member updates in the link app..."}
                                appName="events"
                            >
                                {_showSliders ? (
                                    <SliderInsideScrollRow showScrollIcons={false}>
                                        {/* SCROLL VIEW */}
                                        <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                        </Row>
                                    </SliderInsideScrollRow>
                                ) : (

                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                        {/* GRID VIEW */}
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                    </Row>
                                )}
                            </CollectionColumn>
                        </CollectionRow>

                        {_showAds && (
                            <div className="pb-3">
                                <AdSelectorCategories
                                    isDark={isDark}
                                    isMobileOnly={isMobileOnly}
                                    isTablet={isTablet}
                                    useAlt={false}
                                />
                            </div>

                        )}

                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<span>Most Active</span>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                                iconName={"images-outline"}
                                label={"See all member updates in the link app..."}
                                appName="events"
                            >
                                {_showSliders ? (
                                    <SliderInsideScrollRow showScrollIcons={false}>
                                        {/* SCROLL VIEW */}
                                        <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                        </Row>
                                    </SliderInsideScrollRow>
                                ) : (

                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                        {/* GRID VIEW */}
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                    </Row>
                                )}
                            </CollectionColumn>
                        </CollectionRow>

                        {_showAds && (
                            <div className="pb-3">
                                <AdSelectorCategories
                                    isDark={isDark}
                                    isMobileOnly={isMobileOnly}
                                    isTablet={isTablet}
                                    useAlt={false}
                                />
                            </div>

                        )}

                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<span>Online Now</span>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                                iconName={"images-outline"}
                                label={"See all member updates in the link app..."}
                                appName="events"
                            >
                                {_showSliders ? (
                                    <SliderInsideScrollRow showScrollIcons={false}>
                                        {/* SCROLL VIEW */}
                                        <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                            <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                                <MemberCard
                                                    isDark={isDark}
                                                    blur={_blur}
                                                    status={"online"}
                                                    linkStatus={"friends"}
                                                    data={{}}
                                                    handleClick={() => {

                                                        void (0);
                                                    }}
                                                />

                                            </Col>
                                        </Row>
                                    </SliderInsideScrollRow>
                                ) : (

                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                        {/* GRID VIEW */}
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg="20perc">

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                    </Row>
                                )}
                            </CollectionColumn>
                        </CollectionRow>

                    </Col>
                    {!isMobile && (
                        <Col className="" lg="auto">
                            <AdSelectorSide
                                isDark={isDark}
                            />
                        </Col>
                    )}
                </Row>
            </ConnectScreenFrameWithProfile>

            <MainModal
                showNav={true}
                isDark={isDark}
                //removeNaveSpacing={_removeNavSpacing}
                modalColor={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} shadow`}
                bsModalSize=""
                dialogClassName={(`${_mainModalMaxW && _mainModalMaxW !== "" ? ` ${_mainModalMaxW}` : ""}`)}
                contentClassName={`rounded-0${isDark ? " border border-dark" : " border border-secondary"}`}
                showModal={_showMainModal}
                closeModal={() => {
                    _setShowMainModal(false);
                    _setMainModalContent(<></>);
                    _setMainModalMaxW("");
                }}
            >
                {_showMainModal && (
                    <>
                        {_mainModalContent}
                    </>
                )}

            </MainModal>
        </>
    );

};

export default ConnectMembers;