import React from "react";
//import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import AppNameInline from "./AppNameInline";
import { NavLink } from "react-router-dom";


const AppNameStacked = ({
    name,
    headingSize,
    tagLine,
    tagLineSize,
    iconName,
    iconSizeEm,
    showCopyright,
    isMobileOnly,
    showLink

}) => {

    return (
        <>
            <Row className={(isMobileOnly ? "align-items-start" : "align-items-start") + " gx-2 gx-sm-3"} style={{ lineHeight: "1.2em", }}>
                {iconName && (
                    <Col className="d-flex align-items-center" xs="auto">
                        <div style={{ height: iconSizeEm }}>
                            <ion-icon style={{ fontSize: iconSizeEm }} name={iconName}></ion-icon>
                        </div>
                    </Col>
                )}

                <Col xs sm className="d-flex flex-column justify-content-center align-items-start">
                    <h3 style={{ lineHeight: "1.0em", fontWeight: 400 }} className={(headingSize ? headingSize + " " : "") + "mb-1 p-0"}>
                        <AppNameInline name={name} showCopyright={showCopyright} />
                    </h3>
                    <div style={{ fontSize: tagLineSize ? tagLineSize : "auto" }}>{tagLine}</div>
                    {showLink &&(
                        <NavLink to="/" className="pt-1">Learn More</NavLink>
                    )}
                </Col>
            </Row>
        </>
    );

};

export default AppNameStacked;