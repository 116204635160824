import React, { useRef, } from "react";
import {
    isMobile,
    isMobileOnly,
    //isTablet
} from "react-device-detect";
import {
    Row, Col,
} from "react-bootstrap";
import BtnStackedLink from "../buttons/BtnStackedLink";


const ChatPlayerAlertMngr = ({
    isDark,
    toggleChat,
    chatAlertType,
    setShowChatAlert,
    setChatAlertType,
    setShowChatList,
    profileName,
    profileRelationship,
    profileImage,
    playCallingAnimation,
    setShowVideoPlayer,
    setUseVideo,
    setVideoAspect9by16,

}) => {

    const _callingSound = useRef();

    const audioOff = () => {

        _callingSound.current && _callingSound.current.pause();

    };

    return (
        <>
            <div
                className={(isDark || true ? "text-light " : "text-dark ") + (playCallingAnimation ? "call-animation " : "bg-md-dark ") + " text-center rounded-1 p-4"}
                style={{
                    width: "85vw",
                    maxWidth: isMobileOnly ? 260 : 320
                }}
                onClick={() => {
                    audioOff();
                }}
            >
                {chatAlertType === "sendNewReq" && (
                    <>
                        <h4 className="d-flex align-items-center justify-content-center mb-0"><span className="text-primary me-2 mb-0 fs-3" style={{ height: "1em", lineHeight: "1em" }}><ion-icon name="arrow-redo-sharp"></ion-icon></span> Start A Live Call</h4>

                        <Row className="d-inline-flex align-items-center justify-content-start mb-4 mt-3 gx-2">
                            <Col className="" xs="auto">
                                <img
                                    src={profileImage}
                                    className="img-fluid rounded-circle shadow"
                                    alt={profileName}
                                    style={{
                                        height: 44,
                                        width: 44
                                    }}
                                />
                            </Col>
                            <Col
                                className="d-flex flex-column align-items-start justify-content-start"
                                xs="auto"
                            >
                                <h5
                                    className="mb-0"
                                    style={{
                                        height: "1.2em",
                                        //fontSize: ".90rem",
                                    }}
                                >
                                    {profileName}
                                </h5>
                                <div style={{ height: "1.3em", fontSize: ".80rem", }}>{profileRelationship}</div>
                            </Col>
                        </Row>
                        <Row className="flex-column gy-3">
                            <Col xs="auto">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={"primary"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill shadow-sm px-3"
                                        label={<><span className={isDark ? "text-light" : "text-dark"} style={{ fontWeight: 800 }}>VIDEO</span> Call</>}
                                        sublabel={!isMobile ? "Send video chat request..." : null}
                                        iconSize={!isMobile ? 28 : 16}
                                        iconName="videocam-outline"
                                        useLink={false}
                                        handleClick={() => {

                                            setShowVideoPlayer(true);
                                            setUseVideo(true);
                                            setChatAlertType("");
                                            setShowChatAlert(false);
                                            setVideoAspect9by16(false);

                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs="auto">
                                <h6 className="mt-2 mb-0">Options</h6>
                            </Col>
                            <Col xs="auto">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={"light"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill shadow-sm px-3"
                                        label={<><span className="text-primary" style={{ fontWeight: 800 }}>AUDIO</span> Call</>}
                                        sublabel={!isMobile ? "Send audio chat request" : null}
                                        iconSize={!isMobile ? 28 : 16}
                                        iconName="mic-outline"
                                        useLink={false}
                                        handleClick={() => {
                                            setShowVideoPlayer(true);
                                            setUseVideo(false);
                                            setChatAlertType("");
                                            setShowChatAlert(false);
                                            setVideoAspect9by16(false);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={"light"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill shadow-sm px-3"
                                        label={<>Cancel</>}
                                        sublabel={!isMobile ? "End This Request..." : null}
                                        iconSize={!isMobile ? 28 : 16}
                                        iconName="remove-circle-outline"
                                        useLink={false}
                                        handleClick={() => {
                                            //setShowChatList(true);
                                            setVideoAspect9by16(false);
                                            setChatAlertType("");
                                            setShowChatAlert(false);
                                        }}
                                    />
                                </div>
                            </Col>

                        </Row>
                    </>
                )}
                {chatAlertType === "incoming" && (
                    <>
                        <audio ref={_callingSound} id="ringer" autoPlay loop playsInline >
                            <source src="https://storage.googleapis.com/onebadappleprototype.appspot.com/sounds/mixkit-happy-bells-notification-937.mp3" type="audio/mp3" />
                        </audio>
                        <h4 className="d-flex align-items-center justify-content-center mb-0"><span className="text-primary me-2 mb-0 fs-3" style={{ height: "1em", lineHeight: "1em" }}><ion-icon name="videocam-outline"></ion-icon></span> Incoming Live Video Call</h4>

                        <Row className="d-inline-flex align-items-center justify-content-start mb-4 mt-3 gx-2">
                            <Col className="" xs="auto">
                                <img
                                    src={profileImage}
                                    className="img-fluid rounded-circle shadow"
                                    alt={profileName}
                                    style={{
                                        height: 44,
                                        width: 44
                                    }}
                                />
                            </Col>
                            <Col
                                className="d-flex flex-column align-items-start justify-content-start"
                                xs="auto"
                            >
                                <h5
                                    className="mb-0"
                                    style={{
                                        height: "1.2em",
                                        //fontSize: ".90rem",
                                    }}
                                >
                                    {profileName}
                                </h5>
                                <div style={{ height: "1.3em", fontSize: ".80rem", }}>{profileRelationship}</div>
                            </Col>
                        </Row>

                        <Row className="flex-column gy-3">
                            <Col xs="auto">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={"primary"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill shadow-sm px-3"
                                        label={<><span className={isDark ? "text-light" : "text-dark"} style={{ fontWeight: 800 }}>JOIN</span> Now!</>}
                                        sublabel={!isMobile ? "Answer this video chat request..." : null}
                                        iconSize={!isMobile ? 28 : 16}
                                        iconName="checkmark-circle-outline"
                                        useLink={false}
                                        handleClick={() => {
                                            setShowVideoPlayer(true);
                                            setChatAlertType("");
                                            setShowChatAlert(false);

                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs="auto">
                                <h6 className="mt-2 mb-0">Options</h6>
                            </Col>
                            <Col xs="auto">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={"light"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill shadow-sm px-3"
                                        label={<><span className="text-primary" style={{ fontWeight: 800 }}>MESSAGE</span> Caller</>}
                                        sublabel={!isMobile ? "Decline and send a message" : null}
                                        iconSize={!isMobile ? 28 : 16}
                                        iconName="chatbubbles-outline"
                                        useLink={false}
                                        handleClick={() => {

                                            toggleChat();
                                            setChatAlertType("");
                                            setShowChatAlert(false);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={"light"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill shadow-sm px-3"
                                        label={<>Decline</>}
                                        sublabel={!isMobile ? "Call back later..." : null}
                                        iconSize={!isMobile ? 28 : 16}
                                        iconName="remove-circle-outline"
                                        useLink={false}
                                        handleClick={() => {
                                            setChatAlertType("");
                                            setShowChatAlert(false);
                                        }}
                                    />
                                </div>
                            </Col>

                        </Row>
                    </>
                )}
            </div>
        </>
    );

};

export default ChatPlayerAlertMngr;