
import React from "react";
import { Carousel, /*Badge, Row, Col,*/ } from "react-bootstrap";

//import BtnIcon from "../buttons/BtnIcon";


const ConnectImageStreamPostCard = ({
    isDark,
    //isMobileOnly,
    blur,
    user,
    status,
    data,
    handleClick,
    i,

}) => {

    const getMediaType = (type) => {

        let icon = "";

        switch (type) {

            case "image":
                icon = "image-outline";
                break;

            case "video":
                icon = "videocam-outline";
                break;

            case "reel":
                icon = "film-outline";
                break;

            default:
                icon = "square-outline";

        }

        return icon;
    }

    // const getStatusColor = () => {

    //     let color = "";

    //     switch (status) {

    //         case "online":
    //             color = "primary";
    //             break;
    //         case "recentlyOnline":
    //             color = "yellow";
    //             break;
    //         case "offline":
    //             color = "danger";
    //             break;

    //         case null:
    //         case undefined:
    //         case "unknown":
    //         default:
    //             color = "light";
    //             break;
    //     }

    //     return color;
    // };

    // const getStatusText = () => {

    //     let text = "";

    //     switch (status) {

    //         case "online":
    //             text = "Online Now...";
    //             break;
    //         case "recentlyOnline":
    //             text = "Recently Online...";
    //             break;
    //         case "offline":
    //             text = "Offline...";
    //             break;

    //         case null:
    //         case undefined:
    //         case "unknown":
    //         default:
    //             text = "Status Unknown...";
    //             break;
    //     }

    //     return text;
    // };

    // const getStatusIcon = () => {

    //     let name = "";

    //     switch (status) {

    //         case "online":
    //             name = "checkmark-circle-sharp";
    //             break;
    //         case "recentlyOnline":
    //             name = "time-sharp";
    //             break;
    //         case "offline":
    //             name = "ban-sharp";
    //             break;

    //         case null:
    //         case undefined:
    //         case "unknown":
    //         default:
    //             name = "remove-circle-sharp";
    //             break;
    //     }

    //     return name;
    // };

    return (
        <>
            {/* POST CARD */}
            <div className={`${isDark ? "bg-md2-dark text-light border-light-gray" : "bg-white text-dark border-light-dark"} border-top border-2 pt-2 media-post`}>
                {/* <div
                    className="position-relative d-flex flex-row align-items-center justify-content-start px-0 pb-3"
                >
                    <div className="me-2">
                        <img
                            src={user.photoURL}
                            className="img-fluid rounded-1 shadow"
                            alt={
                                `Profile for ${user.email}`
                            }
                            style={{
                                width: 28,
                            }}
                        />
                    </div>
                    <div>
                        <div style={{ height: "1.3em", fontSize: ".90rem", }}>One.Bad.Apple</div>
                        <div style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</div>
                    </div>
                </div> */}

                {/* <div className="mb-2" style={{ height: "1.3em", fontSize: ".90rem", }}>One.Bad.Apple</div> */}

                {/* IMG CONTAINER */}
                <div
                    className="position-relative shadow-sm"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleClick({
                            imgs: data.full,
                        });
                    }}
                >
                    {/* <div
                        className="d-flex align-items-center position-absolute top-0 end-0 me-1 mt-1"
                        style={{
                            zIndex: 5,
                        }}
                    >
                        <Badge
                            bg={"dark"}
                            text={getStatusColor()}
                            title={getStatusText()}
                            className="rounded-circle shadow-sm"
                            style={{
                                fontSize: ".85rem",
                                paddingTop: 3,
                                paddingBottom: 2,
                                paddingLeft: 5,
                                paddingRight: 5,
                            }}
                        >
                            <ion-icon name={getStatusIcon()}></ion-icon>
                        </Badge>
                    </div> */}
                    <Carousel
                        fade
                        controls={false}
                        indicators={false}
                        interval={3500 + (i * 75)}
                    >
                        {data && data.thumbs.map((item, i) => {
                            return (
                                <Carousel.Item key={i}>
                                    <div
                                        className={`${blur ? "img-blur" : ""} rounded-1 d-flex align-items-end justify-content-end pe-0 pb-0`}
                                        style={{
                                            aspectRatio: "1 / 1",
                                            backgroundImage: `url(${item.img})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}
                                    >
                                        {/* <div
                                            className="d-flex align-items-center position-absolute bottom-0 start-0 ms-1 mb-1"
                                        >
                                            <Badge
                                                bg={"dark"}
                                                text={"light"}
                                                className="rounded-pill shadow-sm"
                                                style={{
                                                    fontSize: ".75rem",
                                                }}
                                            >
                                                {(i + 1) + (data.thumbs.length > 1 ? `/${data.thumbs.length}` : "")}
                                            </Badge>
                                        </div> */}
                                        {/* TYPE ICON */}
                                        <div
                                            className="d-flex align-items-center position-absolute bottom-0 end-0 rounded-1 bg-dark text-light pb-2 pt-2 px-2 me-1 mb-1"
                                            style={{
                                                fontSize: "1rem",
                                                height: "1.4em"
                                            }}
                                        >
                                            <ion-icon name={getMediaType(item.type)}></ion-icon>
                                        </div>
                                        {/* END TYPE ICON */}
                                    </div>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                </div>
                {/* END IMG CONTAINER */}
            </div>
        </>
    );

}

export default ConnectImageStreamPostCard;