import React from "react";

const Article = ({
    article
}) => {

    return (
        <>
            <h1>{article.heading}</h1>
            <p>
                <small className="text-xs">{article.date}</small>
            </p>
            {article.body}
            {article.showSignature ? article.signature : ""}
        </>
    );

};

export default Article;