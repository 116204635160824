
import React from "react";
import { Row, Col, } from "react-bootstrap";

import BtnStdLink from "../buttons/BtnStdLink";
import BtnIcon from "../buttons/BtnIcon";


const GroupCard = ({
    isDark,
    blur,
    isMobile,
    status,
    data,
    handleClick,

}) => {


    return (
        <>
            <div
                className={(isDark ? "border-light-gray" : "border-light-dark") + " group-card border-bottom border-3 pb-3 mb-1"}
                style={{
                    cursor: "pointer"
                }}
                onClick={() => {
                    handleClick();
                }}
            >
                <div
                    className={(blur ? "img-blur " : "") + "d-flex align-items-center justify-content-center bg-dark rounded-1 px-3 py-2 mb-2"}
                    style={{ height: 80 }}
                >
                    <a
                        href={"/"}
                        onClick={(e) => {
                            e.preventDefault();
                            void (0);
                        }}
                    >
                        <img
                            src={"https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-Web-Adress-White-Logo.png"}
                            className={"img-fluid shadow-sm"}
                            style={{
                                maxHeight: 40
                            }}
                            alt={"Alt goes here..."}
                        />
                    </a>
                </div>
                <div className="px-1 pb-1">
                    <h5 className="text-start m-0 mb-2" >
                        One.Bad.Apple party group
                    </h5>
                    <div className="mb-2" style={{ fontSize: ".85rem" }}>
                        {"Short description goes here... and it should only be about two lines long."}
                    </div>
                    {/* <h6 className="mb-1" style={{ fontSize: ".85rem", fontWeight: 400 }}>
                        {"Created by: The.Apple"}
                    </h6>*/}
                    <h6 className="mb-1" style={{ fontSize: ".85rem", fontWeight: 400 }}>
                        {"Created: 1 year ago"}
                    </h6>
                    <h6 className="mb-0" style={{ fontSize: ".85rem", fontWeight: 400 }}>
                        {"104 Members - 12 Friends"}
                    </h6> 
                </div>
                <Row className="align-items-end justify-content-between pt-3 px-1 mt-auto">
                    <Col xs="auto">
                        <BtnStdLink
                            bsVariant={(status && status === "member" ? (isDark ? "secondary" : "outline-dark") : "primary")}
                            bsSize={"xs"}
                            btnClasses={"rounded-1 shadow-sm"}
                            label={status && status === "member" ? "Leave" : "Join"}
                            iconSize={20}
                            iconName={null}
                            useLink={true}
                            href="/"
                            target=""
                        />
                    </Col>
                    <Col xs="auto">
                        <div
                            className={(status === "member" ? "bg-primary text-dark " : "bg-dark text-light ") + "d-flex align-items-center rounded-1"}
                            style={{
                                padding: "2px 5px"
                            }}
                        >
                            {status === "member" ? (
                                <>
                                    <span style={{ fontWeight: 400, fontSize: ".80rem", textTransform: "capitalize" }}>{status}</span>
                                </>
                            ) : (
                                <>
                                    <span style={{ fontWeight: 400, fontSize: ".80rem", textTransform: "capitalize" }}>{"Open Group"}</span>
                                </>
                            )}


                        </div>
                    </Col>
                </Row>
                <Row className="gx-1 mt-2">
                    <Col xs="auto">
                        <BtnIcon
                            bsVariant={(isDark ? "icon-light" : "icon-dark")}
                            label={"Create a group post..."}
                            iconSize={20}
                            iconName="create-outline"
                            iconClasses=""
                            btnClasses=""
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development ");
                            }}
                        />
                    </Col>
                    <Col xs="auto">
                        <BtnIcon
                            bsVariant={(isDark ? "icon-light" : "icon-dark")}
                            label={"Start a connect chat..."}
                            iconSize={20}
                            iconName="chatbubble-ellipses-outline"
                            iconClasses=""
                            btnClasses=""
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development ");
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default GroupCard;