import React from "react";
//import PropTypes from 'prop-types';


const OBAName = ({
    fontWeight
}) => {

    return (
        <>
            <span style={{ fontWeight: fontWeight ? fontWeight :"" }}><span style={{ color: 'rgba(255,150,0,1)',}}>one</span>&#183;bad&#183;<span style={{ color: 'rgba(255,150,0,1)' }}>apple</span></span>
        </>
    );


};

export default OBAName;