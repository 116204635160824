import React, { useState, } from "react";
import { Row, Col, Container, } from "react-bootstrap";
import {
    isDesktop, 
    //isMobileOnly,
    //isMobile,
    //isTablet
} from "react-device-detect";
import { Link } from "react-router-dom";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import BtnIcon from "../../buttons/BtnIcon";
import BtnStdLink from "../../buttons/BtnStdLink";

const EventModalContent = ({
    isDark,
}) => {

    const [_showCalendar] = useState(false);

    const _localizer = momentLocalizer(moment) // or globalizeLocalizer

    return (
        <>
            <div className="position-relative">
                <Row className="position-absolute bottom-0 end-0 justify-content-end gx-2 me-2 mb-2">
                    {isDesktop ? (
                        <>
                            <Col xs="auto">
                                <BtnStdLink
                                    bsVariant={"light"}
                                    bsSize={""}
                                    btnClasses="rounded-pill border border-1 border-dark shadow-sm ps-3 pe-3"
                                    label="Save Date"
                                    title="Save to your calendar..."
                                    iconSize={20}
                                    iconName="calendar-outline"
                                    href="/"
                                    target=""
                                />
                            </Col>
                            <Col xs="auto">
                                <BtnStdLink
                                    bsVariant={"light"}
                                    bsSize={""}
                                    btnClasses="rounded-pill border border-1 border-dark shadow-sm ps-3 pe-3"
                                    label="Tickets"
                                    title="Reserve your spot now..."
                                    iconSize={20}
                                    iconName="ticket-outline"
                                    href="/"
                                    target=""
                                />
                            </Col>
                            <Col xs="auto">
                                <BtnStdLink
                                    bsVariant={"dark"}
                                    bsSize={""}
                                    btnClasses="rounded-pill border border-2 border-primary shadow-sm ps-3 pe-3"
                                    label="Event Page"
                                    title="Complete event info..."
                                    iconSize={20}
                                    iconName="arrow-redo-sharp"
                                    href="/"
                                    target=""
                                />
                            </Col>
                        </>
                    ) : (

                        <>

                            <Col xs="auto">
                                <BtnIcon
                                    bsVariant={"light"}
                                    iconSize={20}
                                    iconName="calendar-outline"
                                    iconClasses=""
                                    btnClasses={"shadow-sm border border-1 border-dark"}
                                    handleClick={(e) => {

                                        e.stopPropagation();
                                        alert("This feature is currently under development.");

                                    }}
                                />
                            </Col>
                            <Col xs="auto">
                                <BtnIcon
                                    bsVariant={"light"}
                                    iconSize={20}
                                    iconName="ticket-outline"
                                    iconClasses=""
                                    btnClasses={"shadow-sm border border-1 border-dark"}
                                    handleClick={(e) => {

                                        e.stopPropagation();
                                        alert("This feature is currently under development.");

                                    }}
                                />
                            </Col>
                            <Col xs="auto">
                                <BtnIcon
                                    bsVariant={"dark"}
                                    iconSize={20}
                                    iconName="arrow-redo-sharp"
                                    iconClasses=""
                                    btnClasses={"shadow-sm border border-2 border-primary"}
                                    handleClick={(e) => {

                                        e.stopPropagation();
                                        alert("This feature is currently under development.");

                                    }}
                                />
                            </Col>
                        </>
                    )}
                </Row>
                <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/header-imgs/colette_header_compressed.jpg" className="img-fluid shadow" alt="Link Partner banner" />
            </div>
            <Container fluid className="py-1 py-lg-4 px-1 px-lg-4">
                <Row>
                    <Col className="d-flex justify-content-center mt-2 mt-lg-0" sm="auto">
                        <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/pantiless.jpg" className="img-fluid shadow-sm rounded-1" alt="Event banner" />
                    </Col>
                    <Col className="d-flex flex-column">
                        <h2 className="mb-1 mt-3 mt-sm-0" style={{ fontWeight: 300 }}>Colette Houston</h2>
                        <h4 className="mb-2" style={{ fontWeight: 300 }}>Pantiless Fridays</h4>
                        <h5 className="mb-2" style={{ fontWeight: 300 }}>Select Fridays - 9PM - 4AM</h5>
                        <p className="fs-6 mb-2">
                            That's right...take them off! Attention couples: If you come to colette wearing a skirt or dress and you aren't wearing any panties, you get $20 off of the door fee. So, get out here, and take them off... we won't tell!
                        </p>
                        <Link className="" to="/">
                            learn more...
                        </Link>
                    </Col>
                </Row>

                {/* <Row className="justify-content-between py-4 mt-auto">
                    <Col xs="auto">
                        
                    </Col>
                    <Col xs="auto">
                        <Row className="align-items-center">
                            
                            <Col xs="auto">
                                <BtnIcon
                                    bsVariant={isDark ? "icon-light" : "icon-dark"}
                                    label="direction..."
                                    iconSize={isMobileOnly ? 22 : 24}
                                    iconName="locate-outline"
                                    iconClasses=""
                                    btnClasses={""} //showCalendar ? "active" : ""
                                    handleClick={(e) => {
                                        alert("This feature is underdevelopment");

                                        //setShowCalendar(!showCalendar);
                                    }}
                                />
                            </Col>
                            <Col xs="auto">
                                <BtnIcon
                                    bsVariant={isDark ? "icon-light" : "icon-dark"}
                                    label="view full page..."
                                    iconSize={isMobileOnly ? 22 : 24}
                                    iconName="open-outline"
                                    iconClasses=""
                                    btnClasses={""}
                                    handleClick={(e) => {
                                        alert("This feature is underdevelopment");
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row> */}

                {(_showCalendar) && (
                    <>
                        <h4 className="mt-2 mt-lg-4 mb-1" style={{ fontWeight: 300 }}>Event Calendar</h4>
                        <div className="text-center pb-3 pt-1">
                            <div className="bg-light text-dark rounded-1 p-1" style={{ height: 300 }}>
                                <Calendar
                                    localizer={_localizer}
                                    events={[]}
                                    startAccessor="start"
                                    endAccessor="end"
                                />
                            </div>
                        </div>
                    </>
                )}
                <Row className="gx-2 mt-4">
                    <Col xs="auto">
                        <div className="text-primary fs-2" style={{ height: "1em", lineHeight: "1em" }}><ion-icon name="warning-outline"></ion-icon></div>
                    </Col>
                    <Col>
                        <p className="" style={{ lineHeight: "1.35em", fontSize: ".80rem" }}>
                            Before attending any of the colette locations, you must submit an online application form. If you have been to any of our clubs in the past 3 years you do not need to reapply. If you haven't already done so, please click below and submit it. The application is short and only takes a couple of minutes. Once your membership is accepted, you will be able to purchase tickets in advance. Thank you.
                            <span className="d-block text-center pt-2">
                                <Link to="/" className="d-inline-flex align-items-center">
                                    <ion-icon name="create-outline"></ion-icon> <span className="ms-1">Membership Application</span>
                                </Link>
                            </span>
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );

};

export default EventModalContent;