import React from "react";
import { Container, } from 'react-bootstrap';

const LinkConnectFollow = ({
    children,
}) => {

    return (
        <>
            <div
                className={
                    `${(true) ? "bg-white text-dark border-bottom border-md-dark" : ""} d-flex align-items-center shadow nav-offset pt-3`
                }
                style={{
                    zIndex: 30,
                    fontWeight: 300
                }}
            >
                <Container
                    fluid
                    style={{
                        backgroundImage: "url('https://storage.googleapis.com/onebadappleprototype.appspot.com/temp_imgs/abstract-orange-smooth-wave-lines_1940_Gray.jpg')", backgroundRepeat: "no-repeat",
                        backgroundPositionX: "center",
                        backgroundPositionY: "center",
                        backgroundSize: ""
                    }}
                >
                    <div
                        className="text-center pt-4 pb-4"
                        style={{ textShadow: "2px 2px 4px rgba(0,0,0,.1)" }}
                    >
                        <h1 className="mb-4" style={{ fontWeight: 300, fontSize: "3.4rem", lineHeight: "1em" }}>Link. Connect. Follow.</h1>
                        <h1 className="mb-3 mb-sm-0" style={{ fontWeight: 300, fontSize: "3.4rem", lineHeight: ".8em" }}>Choose.</h1>
                        <div className="mx-auto mb-0" style={{ maxWidth: 700, marginTop: -15 }}>
                            <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo_1470_shadow.png" className="img-fluid" alt="Logo for one.bad.apple" />
                        </div>
                        <h1 style={{ fontSize: "2.5rem", fontWeight: 300, }}>Go Ahead.<br className="d-block d-sm-none" /> Choose Your Adventure.</h1>
                        <p className="text-center mb-3 mt-0" style={{ fontWeight: 300, fontSize: "1.75rem", }}>
                            The social app for those looking for lifestyle and clothing optional adventures.

                        </p>
                    </div>
                    {children && (
                        <div className={`"bg-gray-light text-dark border-light-dark"} d-flex align-items-center justify-content-center border-top border-1 py-2`}>
                            {children}
                        </div>
                    )}
                </Container>
            </div>
        </>
    );

};

export default LinkConnectFollow;