import React, { useEffect, useRef } from "react";
import Config from "../data/Config";
import gtag from 'ga-gtag';
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import BannerTopDefault from "../banners/BannerTopDefault";
import AppNameInlineIcon from "../components/text/AppNameInlineIcon";
import OBAName from "../components/text/OBAName";

const Screen404 = () => {

    const gtagSent = useRef(false);

    useEffect(() => {

        if (!gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: '404 Error',
                page_title: '404 Error',
            });

            gtagSent.current = true;

        }


    }, [gtagSent]);

    return (
        <>
            <BannerTopDefault />
            <div className="text-start bg-md-dark text-light py-5">
                <Container fluid className="container-1200">

                    <h1 className="d-flex justify-content-center align-items-center mb-2">
                        <AppNameInlineIcon
                            name={"error"}
                            fontWeight={null}
                            showCopyright={false}
                            iconName={"warning-outline"}
                            iconSize="1.25em"
                        />
                    </h1>
                    <h2 className="text-center mb-3"><code>{"404 - " + window.location + " - page not found!"}</code></h2>
                    <p>
                        It looks like the location you were searching for was not found on this app. Please check the URL for mistakes and try again, or <Link to="/">visit our website here</Link>. If you think there has been a mistake, please contact support at <a href="mailto:info@onebadapplenow.com">info@onebadapplenow.com</a>
                    </p>
                    <p>
                        Thank you!<br /><OBAName fontWeight={500} /> Team
                    </p>
                </Container>

            </div>
        </>
    );

};

export default Screen404;

