import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Config from "../data/Config";
import ProfileScreenFrame from "../components/shared/ProfileScreenFrame";
import LightboxMain from "../components/lightbox/LightboxMain";
import { Row, Col, Button, } from "react-bootstrap";
import {
    //isDesktop, 
    isMobileOnly,
    isMobile,
    isTablet,
} from "react-device-detect";
//import Cookies from 'universal-cookie';
import gtag from 'ga-gtag';
//import Config from "../data/Config";

import BtnIcon from "../components/buttons/BtnIcon";
import BtnStd from "../components/buttons/BtnStd";
import AppNameInlineIcon from "../components/text/AppNameInlineIcon";
import AdSelectorLink from "../components/ads/AdSelectorLink";

import CollectionHeaderOnly from "../components/collections/CollectionHeaderOnly";
import CollectionNoHeader from "../components/collections/CollectionNoHeader";
import CollectionRow from "../components/collections/CollectionRow";
import CollectionColumn from "../components/collections/CollectionColumn";
import CollectionColumnNoHeader from "../components/collections/CollectionColumnNoHeader";
import ScrollAreaVertical from "../components/shared/ScrollAreaVertical";

import ActiveMemberCardSm from "../components/cards/ActiveMemberCardSm";
import ConnectImageStreamPostCard from "../components/cards/ConnectImageStreamPostCard";

const ProfilePreview = ({
    user,
    isAuthenticated,
    isDark,
    toggleChat,
    toggleLive,
    setShowFlirtModal,
}) => {

    const [_showAds, _setShowAds] = useState(true);
    const [_blur, _setBlur] = useState(false);
    const [_showLightbox, _setShowLightbox] = useState(false);
    const [_lightBoxContent, _setLightBoxContent] = useState(null);
    const [_showFullWidth, _setShowFullWidth] = useState(false);

    const _activeMembers = Config.memberStatusList; //Active Members

    const _connectPhotoStream = Config.connectPhotoStream;
    
    const _gtagSent = useRef(false);
    // const _cookies = useRef(new Cookies());
    // const _cookieOptions = {
    //     maxAge: 3153600000,
    //     sameSite: true,
    //     path: window.location.origin.replace("www.", "")
    // }

    useEffect(() => {

        if (_showLightbox || _showFullWidth) {
            document.body.classList.add("lightbox-open");
        } else {
            document.body.classList.remove("lightbox-open");
        }

    }, [_showLightbox, _showFullWidth]);

    useEffect(() => {
        if (!_gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Profile Activity',
                page_title: 'Profile',
            });

            _gtagSent.current = true;
        }
    }, [_gtagSent]);

    const showLightBoxWindow = (data) => {

        _setLightBoxContent(data.imgs);
        _setShowLightbox(true);
    }

    const updateScrollPos = (target, scrollTo, type) => {

        target.scroll({ top: scrollTo, behavior: type });
    }

    const getUploadType = (type) => {

        let icon = "";

        switch (type) {

            case "image":
                icon = "image-outline";
                break;

            case "video":
                icon = "videocam-outline";
                break;

            case "reel":
                icon = "film-outline";
                break;

            default:
                icon = "square-outline";

        }

        return icon;
    }

    const getImageOverlay = (item) => {

        return (
            <>
                {(item.type === "video" || item.type === "reel") && (
                    <div
                        className="d-flex align-items-center justify-content-center position-absolute top-0 end-0 bottom-0 start-0 text-light"
                        style={{
                            fontSize: "3rem",
                            zIndex: 5,
                            pointerEvents: "none"
                        }}
                    >
                        <ion-icon name={"play-outline"}></ion-icon>
                    </div>
                )}

                {item.type === "reel" && (
                    <>
                        <div
                            className="d-flex align-items-center position-absolute top-0 start-0 rounded-1 bg-dark text-light pb-2 pt-2 px-2 ms-1 mt-1"
                            style={{
                                fontSize: "1.1rem",
                                height: "1.4em"
                            }}
                        >
                            <ion-icon name={"film-outline"}></ion-icon> <span className="ms-1" style={{ fontWeight: 400, fontSize: ".80rem", }}>Clip</span>
                        </div>
                        <div
                            className="d-flex align-items-center position-absolute bottom-0 start-0 rounded-1 bg-dark text-light pb-2 pt-2 px-1 ms-1 mb-1"
                            style={{
                                fontSize: "1.1rem",
                                height: "1.4em"
                            }}
                        >
                            <ion-icon name={"caret-forward-sharp"}></ion-icon> <span className="ms-0" style={{ fontWeight: 400, fontSize: ".80rem", }}>12M</span>
                        </div>
                        <div
                            className="d-flex align-items-center position-absolute bottom-0 end-0 rounded-1 bg-dark text-light pb-2 pt-2 px-1 me-1 mb-1"
                            style={{
                                fontSize: "1.1rem",
                                height: "1.4em"
                            }}
                        >
                            <ion-icon name="eye-sharp"></ion-icon> <span className="ms-1" style={{ fontWeight: 400, fontSize: ".80rem", }}>2.4K</span>

                        </div>
                    </>
                )}
                {item.type !== "reel" && (
                    <div
                        className="d-flex align-items-center position-absolute bottom-0 end-0 rounded-1 bg-dark text-light pb-2 pt-2 px-2 me-1 mb-1"
                        style={{
                            fontSize: "1rem",
                            height: "1.4em"
                        }}
                    >
                        <ion-icon name={getUploadType(item.type)}></ion-icon> <span className="ms-1" style={{ textTransform: "capitalize", fontWeight: 400, fontSize: ".70rem" }}>{item.type}</span>
                    </div>
                )}

            </>
        );

    };

    const UploadGridColumn = ({
        items,
        cols,
        autoOrder
    }) => {

        const len = items.length;

        return (

            <>
                <Col className={"d-flex flex-column"} xs={cols === 1 ? (items[0].type === "reel" ? "6" : "12") : "6"}>

                    {items.map((item, i) => {

                        if (i > 2) {

                            return (null);

                        } else {

                            return (
                                <div
                                    key={i}
                                    className="pb-1"
                                    style={{ height: (len < 3 ? 100 / len : 100 / 3) + "%", }}
                                >
                                    <div
                                        key={i}
                                        className={`${_blur ? "img-blur" : ""} position-relative rounded-1`}
                                        style={{
                                            //aspectRatio: "9 / 16",
                                            height: "100%",
                                            width: "100%",
                                            backgroundImage: `url(${item.img})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat"
                                        }}
                                    >
                                        {len > 4 && i === 2 && (
                                            <div
                                                className="position-absolute start-0 top-0 bottom-0 end-0 d-flex align-items-center justify-content-center rounded-1 text-light"
                                                style={{
                                                    fontSize: "2rem",
                                                    backgroundColor: "rgba(0, 0, 0, .35)",
                                                }}
                                            >
                                                <ion-icon name={"add-outline"}></ion-icon> {len - 3}
                                            </div>
                                        )}
                                        {/* TYPE ICON */}
                                        {getImageOverlay(item)}
                                    </div>
                                </div>
                            );
                        }
                    })}
                </Col>
            </>
        );

    };

    const UploadGrid1 = ({
        items
    }) => {

        return (
            <UploadGridColumn
                items={items}
                cols={items.length > 1 ? 2 : 1}
                autoOrder={false}
            />
        );
    };

    const UploadGrid1x1 = ({
        items
    }) => {

        return (
            <>
                <UploadGridColumn
                    items={items.slice(0, 1)}
                    cols={items.length > 1 ? 2 : 1}
                    autoOrder={false}
                />
                <UploadGridColumn
                    items={items.slice(1, 2)}
                    cols={items.length > 1 ? 2 : 1}
                    autoOrder={false}
                />
            </>
        );
    };

    const UploadGrid1x2 = ({
        items
    }) => {

        return (
            <>
                <UploadGridColumn
                    items={items.slice(0, 1)}
                    cols={items.length > 1 ? 2 : 1}
                    autoOrder={true}
                />
                <UploadGridColumn
                    items={items.slice(1, items.length)}
                    cols={items.length > 1 ? 2 : 1}
                    autoOrder={false}
                />
            </>
        );
    };

    const UploadGrid1x3 = ({
        items
    }) => {

        return (
            <>
                <UploadGridColumn
                    items={items.slice(0, 1)}
                    cols={items.length > 1 ? 2 : 1}
                    autoOrder={true}
                />
                <UploadGridColumn
                    items={items.slice(1, items.length)}
                    cols={items.length > 1 ? 2 : 1}
                    autoOrder={false}
                />
            </>
        );
    };

    const UploadGrid2x3 = ({
        items
    }) => {

        return (
            <>
                <UploadGridColumn
                    items={items.slice(0, 2)}
                    cols={items.length > 1 ? 2 : 1}
                    autoOrder={false}
                />
                <UploadGridColumn
                    items={items.slice(2, items.length)}
                    cols={items.length > 1 ? 2 : 1}
                    autoOrder={false}
                />

            </>
        );
    };

    const FormatUploadGrid = ({
        assets,
        handleClick
    }) => {

        const items = assets.thumbs;

        return (
            <>
                {items && items.length === 0 && (null)}

                {items && items.length > 0 && (
                    <Row
                        className={"align-items-stretch justify-content-center rounded-1 gx-1 gy-1"}
                        style={{
                            cursor: "pointer",
                            width: "100%",
                            aspectRatio: "4 / 3",
                            // height: "100vh",
                            // maxHeight: isMobileOnly ? 260 : 500,
                            overflow: "hidden"
                        }}
                        onClick={() => {
                            handleClick({
                                imgs: assets.full
                            });
                        }}
                    >

                        {items.length === 1 && (

                            <UploadGrid1
                                items={items}
                            />
                        )}

                        {items.length === 2 && (
                            <UploadGrid1x1
                                items={items}
                            />
                        )}

                        {items.length === 3 && (
                            <UploadGrid1x2
                                items={items}
                            />
                        )}

                        {items.length === 4 && (
                            <UploadGrid1x3
                                items={items}
                            />
                        )}

                        {items.length >= 5 && (
                            <UploadGrid2x3
                                items={items}
                            />
                        )}
                    </Row>
                )}
            </>
        );
    };

    const MemberPost = ({
        mediaData,
        profileData,
        commentData
    }) => {

        return (
            <>
                {/* PROFILE */}
                <div className="position-relative my-2">
                    <div
                        className="d-flex align-items-center position-absolute top-0 end-0 text-end"
                        style={{
                            zIndex: 5,
                        }}
                    >
                        <BtnIcon
                            bsVariant={isDark ? "icon-light" : "icon-dark"}
                            iconSize={16}
                            iconName="ellipsis-horizontal-outline"
                            iconClasses=""
                            btnClasses={""}
                            handleClick={(e) => {

                                alert("This feature is under development");
                            }}
                        />
                    </div>
                    <div
                        className="position-absolute end-0 bottom-0 text-primary text-end"
                        style={{ fontSize: "1rem", }}
                    >
                        <span title="You said they are hot!" role="img" aria-label="wink" className="emoji-fire"></span>
                    </div>
                    <Row
                        className="flex-nowrap align-items-center px-0 pb-2 gx-1"
                    >
                        <Col className="me-2" xs="auto">
                            <img
                                src={profileData && profileData.profileImg !== "" ? profileData.profileImg : user.photoURL}
                                className="img-fluid rounded-1 shadow"
                                alt={
                                    `Profile for ${user.email}`
                                }
                                style={{
                                    width: 52,
                                }}
                            />
                        </Col>
                        <Col
                            xs="7"
                        >
                            <div
                                style={{
                                    height: "1.2em",
                                    fontSize: "1rem",
                                    fontWeight: 400,
                                }}
                            >
                                {profileData && profileData.name !== "" ? profileData.name : "One.Bad.Apple"}
                            </div>
                            <div style={{ height: "1.3em", fontSize: ".80rem", }}>{profileData && profileData.memberType !== "" ? profileData.memberType : "Couple"}</div>
                            <div style={{ height: "1.2em", fontSize: ".75rem", }}>Sat, 12/12/2022, 12:04 PM</div>
                        </Col>
                    </Row>
                </div>
                {/* MAIN POST MESSAGE */}
                <div className="mb-2">
                    <h5 className="mb-1" style={{ fontWeight: 500 }}>Wow!</h5>
                    <p className="mb-0" style={{ fontSize: ".95rem" }}>
                        NEW COMPONENT: A message about this image would go here... So, if you need to think of what to type, we'lll be waiting for you!
                    </p>
                </div>
                {/* MEDIA */}
                {mediaData && (
                    <FormatUploadGrid
                        handleClick={showLightBoxWindow}
                        assets={mediaData}
                    />
                )}

                {/* REACTIONS AND COMMENTS */}
                <div
                    className="pt-2 px-0"
                >
                    <Row className="align-items-center justify-content-between gx-1 my-1" style={{ fontSize: "1rem" }}>
                        <Col className="d-flex align-items-center" xs="auto">
                            <div className="d-flex align-items-center justify-content-start" style={{ fontSize: "1.25rem" }}>
                                <div className="text-danger me-0" style={{ fontSize: "1.15rem", height: "1em", lineHeight: "1em" }}>
                                    {/* <ion-icon name="heart-sharp"></ion-icon> */}
                                    <span title="Reactions" role="img" aria-label="heart" className="emoji-heart-fire"></span>
                                </div>
                                <span style={{ fontSize: ".75rem", height: "1em", lineHeight: "1em" }}>56</span>
                            </div>
                        </Col>
                        <Col className={(isDark ? "text-light " : "text-dark ") + "d-flex align-items-center justify-content-end ms-1"} xs="auto">
                            <div style={{ fontSize: "1.25rem", height: "1em", lineHeight: "1em" }} className="me-1"><ion-icon name="chatbubble-ellipses-outline"></ion-icon></div>
                            <div style={{ fontSize: ".75rem", height: "1em", lineHeight: "1em" }}>24</div>
                        </Col>
                    </Row>
                </div>
                <div
                    className=" pt-3"
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        void (0);
                    }}
                >
                    <div
                        className={(isDark ? "bg-md-dark border-dark text-light" : "") + " link-field rounded-1 form-control text-start"}
                        onClick={(e) => {
                            e.stopPropagation();

                            void (0);
                        }}
                    >
                        Comment...
                    </div>
                </div>
            </>
        );

    };

    return (
        <>
            <ProfileScreenFrame user={user} isDark={isDark} showCarousel={true} blur={_blur} handleClick={showLightBoxWindow} toggleChat={toggleChat}
toggleLive={toggleLive} setShowFlirtModal={setShowFlirtModal}>
                <CollectionHeaderOnly
                    appName="profile.activity"
                    tagline={<>Recent posts, uploads, friends and updates</>}
                    isDark={isDark}
                    showAds={_showAds}
                    setShowAds={_setShowAds}
                    showScrollBtn={false}
                    showBlurBtn={true}
                    blur={_blur}
                    setBlur={_setBlur}
                    iconName="create-outline"
                    iconSize={null}
                    appUrl={"/profile"}
                />
                <Row className="flex-nowrap justify-content-between align-items-stretch gx-3">
                    {!isMobile && (
                        <Col lg="4">
                            <div
                                style={{
                                    // position: "sticky",
                                    // top: 59,
                                    // zIndex: 10,
                                }}
                            >
                                <CollectionNoHeader
                                    appName="tools"
                                    tagline={null}
                                    isDark={isDark}
                                    showAds={null}
                                    setShowAds={null}
                                    showScrollBtn={false}
                                    // showSliders={false}
                                    // setShowSliders={setSliders}
                                    showBlurBtn={true}
                                    blur={_blur}
                                    setBlur={_setBlur}
                                >

                                    <CollectionRow className={""}>
                                        <CollectionColumn
                                            xs="12"
                                            sm={null}
                                            md={null}
                                            lg={null}
                                            xl={null}
                                            isDark={isDark}
                                            heading={<>Snapshot</>}
                                            smallHeading={null}
                                            label={"See profile about..."}
                                            appName="events"
                                            appOpenUrl={"/profile/about"}
                                        >

                                            <Row className="ps-3 mb-4 g-1" style={{ fontSize: ".9rem" }}>
                                                <Col xs="5">
                                                    Lives In:
                                                </Col>
                                                <Col xs="7">
                                                    Houston, TX Area
                                                </Col>
                                                <Col xs="5">
                                                    Current Status:
                                                </Col>
                                                <Col xs="7">
                                                    Married
                                                </Col>
                                                <Col xs="5">
                                                    Joined:
                                                </Col>
                                                <Col xs="7">
                                                    08/23/22
                                                </Col>
                                                <Col xs="5">
                                                    Interests:
                                                </Col>
                                                <Col xs="7">
                                                    Nudists, Exhibitionists, Voyeurists, Lifestyle
                                                </Col>
                                                <Col xs="5">
                                                    Years in Lifestyle:
                                                </Col>
                                                <Col xs="7">
                                                    5 Yrs, 2 Mo
                                                </Col>
                                                <Col xs="5">
                                                    Play Preferences:
                                                </Col>
                                                <Col xs="7">
                                                    Single Women, Single Men, Couples
                                                </Col>
                                                <Col xs="5">
                                                    Play Types:
                                                </Col>
                                                <Col xs="7">
                                                    Soft, Full, Individual
                                                </Col>
                                            </Row>
                                            <p className="mb-0" style={{ fontSize: "1rem" }}><Link to="/profile/about">Learn more...</Link></p>
                                        </CollectionColumn>
                                    </CollectionRow>

                                    <CollectionRow className={""}>
                                        <CollectionColumn
                                            xs="12"
                                            sm={null}
                                            md={null}
                                            lg={null}
                                            xl={null}
                                            isDark={isDark}
                                            heading={<>Uploads</>}
                                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>({_connectPhotoStream.length})</small></>}
                                            label={"See all uploads..."}
                                            appName="events"
                                            appOpenUrl={"/profile/uploads"}
                                        >
                                            <div className={(isDark ? "text-light" : "text-dark") + " d-flex flex-column"} style={{ height: 350 }}>
                                                <ScrollAreaVertical>
                                                    <Row className="g-2">
                                                        {_connectPhotoStream && _connectPhotoStream.map((item, i) => {

                                                            return (
                                                                <Col key={i} className="mb-2" xs="4" lg="4">

                                                                    <ConnectImageStreamPostCard
                                                                        isDark={isDark}
                                                                        blur={_blur}
                                                                        user={user}
                                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                                        data={item}
                                                                        handleClick={showLightBoxWindow}
                                                                        i={i}
                                                                    />

                                                                </Col>
                                                            );
                                                        })}
                                                    </Row>
                                                </ScrollAreaVertical>
                                            </div>
                                        </CollectionColumn>
                                    </CollectionRow>

                                    <CollectionRow className={""}>
                                        <CollectionColumn
                                            xs="12"
                                            sm={null}
                                            md={null}
                                            lg={null}
                                            xl={null}
                                            isDark={isDark}
                                            heading={<>Friends</>}
                                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>({_activeMembers.length} - 2 Shared)</small></>}
                                            label={"See all members..."}
                                            appName="events"
                                            appOpenUrl={"/profile/friends"}
                                        >
                                            <div className={(isDark ? "text-light" : "text-dark") + " d-flex flex-column"} style={{ height: 350 }}>
                                                <ScrollAreaVertical>
                                                    <Row className="gx-2">
                                                        {_activeMembers && _activeMembers.map((item, i) => {

                                                            return (
                                                                <Col key={i} className="mb-2" xs="4" lg="4">

                                                                    <ActiveMemberCardSm
                                                                        // iconName="chatbubble-ellipses-outline"
                                                                        // iconSize={18}
                                                                        // bsIconVariant="icon-light"
                                                                        blur={_blur}
                                                                        data={item}
                                                                        handleClick={showLightBoxWindow}
                                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                                    />

                                                                </Col>
                                                            );
                                                        })}
                                                    </Row>
                                                </ScrollAreaVertical>
                                            </div>
                                        </CollectionColumn>
                                    </CollectionRow>

                                </CollectionNoHeader>
                            </div>

                        </Col>
                    )}
                    <Col className="" lg>
                        <CollectionNoHeader
                            appName="tools"
                            isDark={isDark}
                            showAds={null}
                            setShowAds={null}
                            showScrollBtn={false}
                            //showSliders={false}
                            //setShowSliders={setSliders}
                            showBlurBtn={true}
                            blur={_blur}
                            setBlur={_setBlur}
                        >
                            <Row
                                className={(_showFullWidth ? `justify-content-center position-fixed start-0 top-0 end-0 bottom-0 py-1 px-2 px-sm-4 py-sm-3 nav-offset ${isMobileOnly ? "isMobileOnly " : ""}${isDark ? "bg-dark" : "bg-light"}` : "position-relative")}
                                style={{
                                    zIndex: _showFullWidth ? 50 : 1,
                                    overflowY: "auto"
                                }}
                            >
                                <Col xs lg={_showFullWidth ? "5" : "12"}>
                                    {/* POST FIELD */}
                                    <CollectionRow className={""}>
                                        <CollectionColumnNoHeader
                                            xs="12"
                                            sm={null}
                                            md={null}
                                            lg={null}
                                            xl={null}
                                            isDark={isDark}
                                            className={""}
                                        >
                                            <Row className="align-items-center justify-content-between mt-2">
                                                <Col xs="auto">
                                                    <BtnStd
                                                        bsVariant={isDark ? "dark" : "light"}
                                                        bsSize={"sm"}
                                                        btnClasses="rounded-pill border-0 shadow-sm py-1 pe-3"
                                                        label={"Newest"}
                                                        labelSize={null}
                                                        iconSize={14}
                                                        iconName="chevron-down-outline"
                                                        handleClick={(e) => {
                                                            e.preventDefault();
                                                            alert("This feature is under development");
                                                        }}
                                                    />
                                                </Col>
                                                <Col className="d-flex flex-nowrap align-items-center" xs="auto">
                                                    <span className={(_showFullWidth ? "d-inline " : "d-none ") + "me-2"} style={{ fontSize: ".9rem" }}>
                                                        Exit
                                                    </span>
                                                    <BtnIcon
                                                        bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                        label={_showFullWidth ? "Contract window..." : "Expand window..."}
                                                        iconSize={20}
                                                        iconName={_showFullWidth ? "contract-outline" : "expand-outline"}
                                                        iconClasses=""
                                                        btnClasses=""
                                                        handleClick={(e) => {
                                                            _setShowFullWidth(!_showFullWidth);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <div className="d-grid">
                                                <Button
                                                    variant={isDark ? "bg-md-dark text-light" : "bg-light text-dark"}
                                                    className=""
                                                    style={{
                                                        border: 0,
                                                        padding: 0,
                                                    }}
                                                    onClick={() => {
                                                        alert("This feature under development.")
                                                    }}
                                                >
                                                    <Row className="align-items-center gx-2 py-3">
                                                        {/* <Col xs="auto">
                                                            <img
                                                                src={user.photoURL}
                                                                className="img-fluid rounded-1 shadow-sm"
                                                                alt={
                                                                    `Profile for ${user.email}`
                                                                }
                                                                style={{
                                                                    width: 48,
                                                                }}
                                                            />
                                                        </Col> */}
                                                        <Col>
                                                            <div
                                                                className={"link-field rounded-1 form-control text-start"}
                                                            >
                                                                + Message One.Bad.Apple...
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    <Row className="align-items-center my-1" style={{ fontSize: ".9rem", fontWeight: 400 }}>
                                                        <Col xs="auto">
                                                            <span style={{ fontWeight: 400 }}>
                                                                Add:
                                                            </span>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <AppNameInlineIcon
                                                                name={"Pics/Vids"}
                                                                fontWeight={null}
                                                                showCopyright={false}
                                                                iconName={"images-outline"}
                                                                iconSize="1.2em"
                                                            />

                                                        </Col>
                                                        <Col xs="auto">
                                                            <AppNameInlineIcon
                                                                name={"Clip"}
                                                                fontWeight={null}
                                                                showCopyright={false}
                                                                iconName={"film-outline"}
                                                                iconSize="1.2em"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Button>
                                            </div>
                                        </CollectionColumnNoHeader>
                                    </CollectionRow>

                                    {/* SAMPLE POSTS FEED */}
                                    <CollectionRow className={""}>
                                        <CollectionColumnNoHeader
                                            xs="12"
                                            sm={null}
                                            md={null}
                                            lg={null}
                                            xl={null}
                                            className=""
                                            isDark={isDark}
                                        >
                                            <MemberPost
                                                mediaData={
                                                    {
                                                        thumbs: [
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg", type: "image" },
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223032-small.jpg", type: "image" },
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223201-small.jpg", type: "image" },
                                                        ],
                                                        full: [
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg", type: "image" },
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223032-large.jpg", type: "image" },
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223201-large.jpg", type: "image" },
                                                        ]

                                                    }
                                                }
                                                profileData={null}
                                                commentData={null}
                                            />
                                        </CollectionColumnNoHeader>
                                    </CollectionRow>

                                    <CollectionRow className={""}>
                                        <CollectionColumnNoHeader
                                            xs="12"
                                            sm={null}
                                            md={null}
                                            lg={null}
                                            xl={null}
                                            className=""
                                            isDark={isDark}
                                        >
                                            <MemberPost
                                                mediaData={
                                                    {
                                                        thumbs: [
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_5649-small.jpg", type: "image" },
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20200101_003556-small.jpg", type: "image" },
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20220904_005637-small.jpg", type: "image" },
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_1407-small.JPEG", type: "image" },
                                                        ],
                                                        full: [
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg", type: "image" },
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20200101_003556-large.jpg", type: "image" },
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20220904_005637-large.jpg", type: "image" },
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_1407-large.JPEG", type: "image" },
                                                        ]

                                                    }
                                                }
                                                profileData={null}
                                                commentData={null}
                                            />

                                        </CollectionColumnNoHeader>
                                    </CollectionRow>

                                    {_showAds && (
                                        <div className="pb-3">
                                            <AdSelectorLink
                                                isDark={isDark}
                                                isMobileOnly={isMobileOnly}
                                                isTablet={isTablet}
                                                useAlt={false}
                                            />
                                        </div>

                                    )}

                                    <CollectionRow className={""}>
                                        <CollectionColumnNoHeader
                                            xs="12"
                                            sm={null}
                                            md={null}
                                            lg={null}
                                            xl={null}
                                            className=""
                                            isDark={isDark}
                                        >

                                            <MemberPost
                                                mediaData={{
                                                    thumbs: [
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture4-small.PNG", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20210627_132639-small.jpg", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/video-thumbs/20210627_132655_Edit-thumb.jpg", type: "video" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture-small.PNG", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture3-small.PNG", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture14-small.PNG", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture15-small.PNG", type: "image" },

                                                    ],
                                                    full: [
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture4-large.PNG", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20210627_132639-large.jpg", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/videos/K_G-Bobs-Swallow-1080.mp4", type: "video" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture-large.PNG", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture3-large.PNG", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture14-large.PNG", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture15-large.PNG", type: "image" },
                                                    ]

                                                }}
                                                profileData={null}
                                                commentData={null}
                                            />
                                        </CollectionColumnNoHeader>
                                    </CollectionRow>

                                    <CollectionRow className={""}>
                                        <CollectionColumnNoHeader
                                            xs="12"
                                            sm={null}
                                            md={null}
                                            lg={null}
                                            xl={null}
                                            className=""
                                            isDark={isDark}
                                        >
                                            <MemberPost
                                                mediaData={{
                                                    thumbs: [
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/284972914_566171688408801_5982695052161447819_n-small.jpg", type: "image" },
                                                    ],
                                                    full: [
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/284972914_566171688408801_5982695052161447819_n-large.jpg", type: "image" },
                                                    ]
                                                }}
                                                profileData={null}
                                                commentData={null}
                                            />
                                        </CollectionColumnNoHeader>
                                    </CollectionRow>

                                    {_showAds && (
                                        <div className="pb-3">
                                            <AdSelectorLink
                                                isDark={isDark}
                                                isMobileOnly={isMobileOnly}
                                                isTablet={isTablet}
                                                useAlt={false}
                                            />
                                        </div>

                                    )}

                                    <CollectionRow className={""}>
                                        <CollectionColumnNoHeader
                                            xs="12"
                                            sm={null}
                                            md={null}
                                            lg={null}
                                            xl={null}
                                            className=""
                                            isDark={isDark}
                                        >
                                            <MemberPost
                                                mediaData={{
                                                    thumbs: [
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/315330316_469096865093976_5633322688535297539_n-small.jpg", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/315313052_611243747447764_7570547157215204194_n-small.jpg", type: "image" }
                                                    ],
                                                    full: [
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/315330316_469096865093976_5633322688535297539_n-large.jpg", type: "image" },
                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/315313052_611243747447764_7570547157215204194_n-large.jpg", type: "image" }
                                                    ]
                                                }}
                                                profileData={null}
                                                commentData={null}
                                            />
                                        </CollectionColumnNoHeader>
                                    </CollectionRow>

                                    <CollectionRow className={""}>
                                        <CollectionColumnNoHeader
                                            xs="12"
                                            sm={null}
                                            md={null}
                                            lg={null}
                                            xl={null}
                                            className=""
                                            isDark={isDark}
                                        >

                                            <MemberPost
                                                mediaData={
                                                    {
                                                        thumbs: [
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/LakeTravisHawt.jpg", type: "reel" },
                                                        ],
                                                        full: [
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/LakeTravisHawt.mp4", type: "reel" },
                                                        ]
                                                    }
                                                }
                                                profileData={null}
                                                commentData={null}
                                            />

                                        </CollectionColumnNoHeader>
                                    </CollectionRow>

                                    {_showAds && (
                                        <div className="pb-3">
                                            <AdSelectorLink
                                                isDark={isDark}
                                                isMobileOnly={isMobileOnly}
                                                isTablet={isTablet}
                                                useAlt={false}
                                            />
                                        </div>

                                    )}

                                    <CollectionRow className={""}>
                                        <CollectionColumnNoHeader
                                            xs="12"
                                            sm={null}
                                            md={null}
                                            lg={null}
                                            xl={null}
                                            className=""
                                            isDark={isDark}
                                        >
                                            <MemberPost
                                                mediaData={
                                                    {
                                                        thumbs: [
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/SunnyDazeClip.jpg", type: "reel" },
                                                        ],
                                                        full: [
                                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/SunnyDazeClip.mp4", type: "reel" },
                                                        ]
                                                    }
                                                }
                                                profileData={null}
                                                commentData={null}
                                            />

                                        </CollectionColumnNoHeader>
                                    </CollectionRow>
                                    {/* END SAMPLE POSTS FEED */}
                                </Col>
                            </Row>
                        </CollectionNoHeader>

                    </Col>
                </Row>
            </ProfileScreenFrame>

            {_showLightbox && (
                <LightboxMain
                    lightBoxContent={_lightBoxContent}
                    user={user}
                    isDark={isDark}
                    showComments={true}
                    updateScrollPos={updateScrollPos}
                    setShowLightbox={_setShowLightbox}
                    blur={_blur}
                />
            )}
        </>
    );

};

export default ProfilePreview;