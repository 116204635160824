import React, { useState, useEffect, useRef } from "react";
//import { NavLink } from "react-router-dom";
import Config from "../data/Config";
import ProfileScreenFrame from "../components/shared/ProfileScreenFrame";
import LightboxMain from "../components/lightbox/LightboxMain";
import { Row, Col, Form, } from "react-bootstrap";
import {
    //isDesktop, 
    isMobileOnly,
    isMobile,
    isTablet,
} from "react-device-detect";
//import Cookies from 'universal-cookie';
import gtag from 'ga-gtag';

import PageSortFilter from "../components/shared/PageSortFilter";
import MediaPostCard from "../components/cards/MediaPostCard";
import AdSelectorCategories from "../components/ads/AdSelectorCategories";

import CollectionHeaderOnly from "../components/collections/CollectionHeaderOnly";
import CollectionRow from "../components/collections/CollectionRow";
import CollectionColumn from "../components/collections/CollectionColumn";
import CollectionColumnNoHeader from "../components/collections/CollectionColumnNoHeader";
import SliderInsideScrollRow from "../components/collections/SliderInsideScrollRow";

const ProfileUploads = ({
    user,
    isAuthenticated,
    isDark,
    toggleChat,
    toggleLive,
    setShowFlirtModal
}) => {

    const [_showAds, _setShowAds] = useState(true);
    const [_blur, _setBlur] = useState(false);
    const [_showLightbox, _setShowLightbox] = useState(false);
    const [_lightBoxContent, _setLightBoxContent] = useState(null);
    const [pageSearchTerm, setPageSearchTerm] = useState("");
    const [_showSliders, _setShowSliders] = useState(true);

    const _memberPhotoVideoPostData = Config.memberPhotoVideoPosts; //memberClips

    const _gtagSent = useRef(false);
    // const _cookies = useRef(new Cookies());
    // const _cookieOptions = {
    //     maxAge: 3153600000,
    //     sameSite: true,
    //     path: window.location.origin.replace("www.", "")
    // }

    useEffect(() => {

        if (_showLightbox) {
            document.body.classList.add("lightbox-open");
        } else {
            document.body.classList.remove("lightbox-open");
        }

    }, [_showLightbox]);

    useEffect(() => {
        if (!_gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Profile Uploads',
                page_title: 'Profile',
            });

            _gtagSent.current = true;
        }
    }, [_gtagSent]);

    const showLightBoxWindow = (data) => {

        _setLightBoxContent(data.imgs);
        _setShowLightbox(true);
    }

    const updateScrollPos = (target, scrollTo, type) => {

        target.scroll({ top: scrollTo, behavior: type });
    }

    return (
        <>
            <ProfileScreenFrame user={user} isDark={isDark} showCarousel={true} blur={_blur} handleClick={showLightBoxWindow} toggleChat={toggleChat}
toggleLive={toggleLive} setShowFlirtModal={setShowFlirtModal}>
                <CollectionHeaderOnly
                    appName="profile.uploads"
                    tagline={<>Image and video uploads</>}
                    isDark={isDark}
                    showAds={_showAds}
                    setShowAds={_setShowAds}
                    showAdsBtn={true}
                    showScrollBtn={true}
                    showSliders={_showSliders}
                    setShowSliders={_setShowSliders}
                    showBlurBtn={true}
                    blur={_blur}
                    setBlur={_setBlur}
                    iconName="images-outline"
                    iconSize={null}
                    appUrl={"/profile"}
                />
                
                <div className="mt-3">
                    {/* SEARCH/CREATE */}
                    <CollectionRow className={"align-items-center"}>
                        <CollectionColumnNoHeader
                            xs="12"
                            sm={null}
                            md={null}
                            lg={null}
                            xl={null}
                            isDark={isDark}
                        >
                            <div
                                className="position-relative"
                                style={{
                                    //cursor: "pointer",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    void (0);
                                }}
                            >
                                <Form.Control
                                    size="lg"
                                    placeholder="Search by member name, comments, etc..."
                                    type="text"
                                    autoCapitalize="off"
                                    className={(isDark ? "bg-md-dark border-dark text-light" : "") + " link-field rounded-1 form-control text-start mt-2 mb-2"}
                                    value={pageSearchTerm}
                                    onChange={(e) => {
                                        setPageSearchTerm(e.target.value);
                                    }}
                                />

                            </div>

                        </CollectionColumnNoHeader>
                    </CollectionRow>
                    {/* SORT */}
                    <PageSortFilter
                        isDark={isDark}
                    />
                    {/* ROWS */}
                    <CollectionRow className={""}>
                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={null}
                            xl={null}
                            isDark={isDark}
                            heading={<span className="text-primary">New</span>}
                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                            iconName={"images-outline"}
                            label={"See all member updates in the link app..."}
                            appName="events"
                        >
                            {_showSliders ? (
                                <SliderInsideScrollRow showScrollIcons={false} isDark={isDark}>
                                    {/* SCROLL VIEW */}
                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                        {_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-1" xs="6" sm="4" lg="20perc">
                                                    <MediaPostCard
                                                        i={i}
                                                        isDark={isDark}
                                                        isMobile={isMobile}
                                                        blur={_blur}
                                                        setBlur={_setBlur}
                                                        user={user}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </SliderInsideScrollRow>
                            ) : (

                                <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                    {/* GRID VIEW */}
                                    {!_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                        return (
                                            <Col key={i} className="mb-4" xs="6" sm="4" lg="20perc">
                                                <MediaPostCard
                                                    i={i}
                                                    isDark={isDark}
                                                    isMobile={isMobile}
                                                    blur={_blur}
                                                    setBlur={_setBlur}
                                                    user={user}
                                                    data={item}
                                                    handleClick={showLightBoxWindow}
                                                    status={i < 8 ? "online" : "recentlyOnline"}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )}
                        </CollectionColumn>
                    </CollectionRow>

                    {_showAds && (
                        <div className="pb-3">
                            <AdSelectorCategories
                                isDark={isDark}
                                isMobileOnly={isMobileOnly}
                                isTablet={isTablet}
                                useAlt={false}
                            />
                        </div>

                    )}

                    <CollectionRow className={""}>
                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={null}
                            xl={null}
                            isDark={isDark}
                            heading={<span className="">Members Only</span>}
                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                            iconName={"images-outline"}
                            label={"See all member updates in the link app..."}
                            appName="events"
                        >
                            {_showSliders ? (
                                <SliderInsideScrollRow showScrollIcons={false} isDark={isDark}>
                                    {/* SCROLL VIEW */}
                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                        {_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-1" xs="6" sm="4" lg="20perc">
                                                    <MediaPostCard
                                                        i={i}
                                                        isDark={isDark}
                                                        isMobile={isMobile}
                                                        blur={_blur}
                                                        setBlur={_setBlur}
                                                        user={user}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </SliderInsideScrollRow>
                            ) : (

                                <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                    {/* GRID VIEW */}
                                    {!_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                        return (
                                            <Col key={i} className="mb-4" xs="6" sm="4" lg="20perc">
                                                <MediaPostCard
                                                    i={i}
                                                    isDark={isDark}
                                                    isMobile={isMobile}
                                                    blur={_blur}
                                                    setBlur={_setBlur}
                                                    user={user}
                                                    data={item}
                                                    handleClick={showLightBoxWindow}
                                                    status={i < 8 ? "online" : "recentlyOnline"}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )}
                        </CollectionColumn>
                    </CollectionRow>

                    {_showAds && (
                        <div className="pb-3">
                            <AdSelectorCategories
                                isDark={isDark}
                                isMobileOnly={isMobileOnly}
                                isTablet={isTablet}
                                useAlt={false}
                            />
                        </div>

                    )}

                    <CollectionRow className={""}>
                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={null}
                            xl={null}
                            isDark={isDark}
                            heading={<span className="">Friends Only</span>}
                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                            iconName={"images-outline"}
                            label={"See all member updates in the link app..."}
                            appName="events"
                        >
                            {_showSliders ? (
                                <SliderInsideScrollRow showScrollIcons={false} isDark={isDark}>
                                    {/* SCROLL VIEW */}
                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                        {_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-1" xs="6" sm="4" lg="20perc">
                                                    <MediaPostCard
                                                        i={i}
                                                        isDark={isDark}
                                                        isMobile={isMobile}
                                                        blur={_blur}
                                                        setBlur={_setBlur}
                                                        user={user}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </SliderInsideScrollRow>
                            ) : (

                                <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                    {/* GRID VIEW */}
                                    {!_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                        return (
                                            <Col key={i} className="mb-4" xs="6" sm="4" lg="20perc">
                                                <MediaPostCard
                                                    i={i}
                                                    isDark={isDark}
                                                    isMobile={isMobile}
                                                    blur={_blur}
                                                    setBlur={_setBlur}
                                                    user={user}
                                                    data={item}
                                                    handleClick={showLightBoxWindow}
                                                    status={i < 8 ? "online" : "recentlyOnline"}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )}
                        </CollectionColumn>
                    </CollectionRow>

                    {_showAds && (
                        <div className="pb-3">
                            <AdSelectorCategories
                                isDark={isDark}
                                isMobileOnly={isMobileOnly}
                                isTablet={isTablet}
                                useAlt={false}
                            />
                        </div>

                    )}

                    <CollectionRow className={""}>
                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={null}
                            xl={null}
                            isDark={isDark}
                            heading={<span className="">Profile/Cover</span>}
                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                            iconName={"images-outline"}
                            label={"See all member updates in the link app..."}
                            appName="events"
                        >
                            {_showSliders ? (
                                <SliderInsideScrollRow showScrollIcons={false} isDark={isDark}>
                                    {/* SCROLL VIEW */}
                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                        {_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-1" xs="6" sm="4" lg="20perc">
                                                    <MediaPostCard
                                                        i={i}
                                                        isDark={isDark}
                                                        isMobile={isMobile}
                                                        blur={_blur}
                                                        setBlur={_setBlur}
                                                        user={user}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </SliderInsideScrollRow>
                            ) : (

                                <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                    {/* GRID VIEW */}
                                    {!_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                        return (
                                            <Col key={i} className="mb-4" xs="6" sm="4" lg="20perc">
                                                <MediaPostCard
                                                    i={i}
                                                    isDark={isDark}
                                                    isMobile={isMobile}
                                                    blur={_blur}
                                                    setBlur={_setBlur}
                                                    user={user}
                                                    data={item}
                                                    handleClick={showLightBoxWindow}
                                                    status={i < 8 ? "online" : "recentlyOnline"}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )}
                        </CollectionColumn>
                    </CollectionRow>

                </div>

            </ProfileScreenFrame>

            {_showLightbox && (
                <LightboxMain
                    lightBoxContent={_lightBoxContent}
                    user={user}
                    isDark={isDark}
                    showComments={true}
                    updateScrollPos={updateScrollPos}
                    setShowLightbox={_setShowLightbox}
                    blur={_blur}
                />
            )}
        </>
    );

};

export default ProfileUploads;