import React from "react";
import { Spinner, } from 'react-bootstrap';
import OBAName from "../text/OBAName";
import AppNameInline from "../text/AppNameInline";


const LoadingOverlayAbsolute = ({
    msgText,
    msgTextBsColor,
    spinnerType,
    spinnerBsVariant,
    spinnerBsSize,
    fontSize,
    appName,
    showAppNameCopyright,
    showAppLogo,
    showOBALogo,
    isDark,

}) => {

    return (
        <>
            <div
                className="position-absolute start-0 end-0 top-0 bottom-0 d-flex flex-column align-items-center justify-content-center"
                style={{
                    zIndex: 20000,
                    backgroundColor: isDark ? "rgba(0,0,0,.7)" : "rgba(255,255,255,.9)",
                }}
            >
                <div className="d-flex flex-column align-items-center justify-content-center">{/*bg-dark rounded-pill px-5 py-2*/}
                    {(showOBALogo || showAppLogo) && (
                        <h3 className={(isDark ? "text-light" : "text-dark") + " text-center m-0 mb-2"}>
                            {showOBALogo && (
                                <>
                                    <OBAName />{" "}
                                </>
                            )}
                            {showAppLogo && (
                                <AppNameInline name={appName} showCopyright={showAppNameCopyright} />
                            )}
                        </h3>
                    )}
                    <div className={`${msgTextBsColor ? msgTextBsColor + " " : "text-light "}text-center m-0 d-flex align-items-center`}>
                        <Spinner
                            animation={spinnerType}
                            size={spinnerBsSize}
                            variant={spinnerBsVariant}
                            className="me-2"
                        />
                        <h6 className={(isDark ? "text-light" : "text-dark") + " m-0"} style={{ fontSize: fontSize && fontSize !== "" ? fontSize : "auto" }}>{msgText}</h6>
                    </div>

                </div>
            </div>
        </>
    );
};

export default LoadingOverlayAbsolute;