import React from "react";
//import PropTypes from 'prop-types';
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import { NavLink } from "react-router-dom";
import AppNameInlineIcon from "../../text/AppNameInlineIcon";

const MobileNotAuthMenu = ({
    showMobileNotAuthMenu,
    setShowMobileNotAuthMenu
}) => {

    return (
        <>
            <div
                className={(isMobileOnly ? "isMobileOnly " : "") + "app-menu position-fixed start-0 bottom-0 top-0 d-flex flex-column nav-offset pb-1 bp-sm-3 bg-light-dark text-light shadow" + (showMobileNotAuthMenu ? " show-menu" : "")}
                style={{
                    fontSize: ".85rem",
                    zIndex: 100,
                    //left: 15,
                    //marginTop: -1,
                    minWidth: 220
                }}
            >
                <div className="d-grid">
                    <NavLink
                        end
                        className="d-block px-4 px-lg-3 py-2 py-lg-1 mb-1"
                        to="/"
                        onClick={() => {
                            setShowMobileNotAuthMenu(false);
                        }}
                    >
                        <AppNameInlineIcon
                            name={"home"}
                            fontWeight={null}
                            showCopyright={false}
                            iconName={"information-circle-outline"}
                            iconSize="1.6rem"

                        />
                    </NavLink>
                    <NavLink
                        end
                        className="d-block px-4 px-lg-3 py-2 py-lg-1 mb-1"
                        to="/news"
                        onClick={() => {
                            setShowMobileNotAuthMenu(false);
                        }}
                    >
                        <AppNameInlineIcon
                            name={"news"}
                            fontWeight={null}
                            showCopyright={false}
                            iconName={"newspaper-outline"}
                            iconSize="1.6rem"
                        />
                    </NavLink>
                    <NavLink
                        end
                        className="d-block px-4 px-lg-3 py-2 py-lg-1 mb-1"
                        to="/contact"
                        onClick={() => {
                            setShowMobileNotAuthMenu(false);
                        }}
                    >
                        <AppNameInlineIcon
                            name={"contact"}
                            fontWeight={null}
                            showCopyright={false}
                            iconName={"mail-outline"}
                            iconSize="1.6rem"
                        />
                    </NavLink>
                    <NavLink
                        end
                        className="d-block px-4 px-lg-3 py-2 py-lg-1 mb-1 opacity-0"
                        to="/promotions/free-rtic/signup"
                        onClick={() => {
                            setShowMobileNotAuthMenu(false);
                        }}
                    >
                        <AppNameInlineIcon
                            name={"RTIC"}
                            fontWeight={null}
                            showCopyright={false}
                            iconName={"mail-outline"}
                            iconSize="1.6rem"
                        />
                    </NavLink>
                    
                </div>
            </div>
        </>
    );

};

// MobileNotAuthMenu.propTypes = {

// }

// MobileNotAuthMenu.defaultProps = {

// };

export default MobileNotAuthMenu;