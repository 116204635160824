
import React from "react";
import { Carousel, Row, Col, Badge } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";

//import BtnIcon from "../buttons/BtnIcon";


const MediaPostCard = ({
    isDark,
    blur,
    user,
    status,
    data,
    handleClick,
    i,

}) => {

    const getMediaType = (type) => {

        let icon = "";

        switch (type) {

            case "image":
                icon = "image-outline";
                break;

            case "video":
                icon = "videocam-outline";
                break;

            case "reel":
                icon = "film-outline";
                break;

            default:
                icon = "square-outline";

        }

        return icon;
    }

    const getStatusColor = () => {

        let color = "";

        switch (status) {

            case "online":
                color = "primary";
                break;
            case "recentlyOnline":
                color = "yellow";
                break;
            case "offline":
                color = "danger";
                break;

            case null:
            case undefined:
            case "unknown":
            default:
                color = "light";
                break;
        }

        return color;
    };

    const getStatusText = () => {

        let text = "";

        switch (status) {

            case "online":
                text = "Online Now...";
                break;
            case "recentlyOnline":
                text = "Recently Online...";
                break;
            case "offline":
                text = "Offline...";
                break;

            case null:
            case undefined:
            case "unknown":
            default:
                text = "Status Unknown...";
                break;
        }

        return text;
    };

    const getStatusIcon = () => {

        let name = "";

        switch (status) {

            case "online":
                name = "checkmark-circle-sharp";
                break;
            case "recentlyOnline":
                name = "time-sharp";
                break;
            case "offline":
                name = "ban-sharp";
                break;

            case null:
            case undefined:
            case "unknown":
            default:
                name = "remove-circle-sharp";
                break;
        }

        return name;
    };

    return (
        <>
            {/* POST CARD */}
            <div className={`${isDark ? "bg-md2-dark text-light border-light-gray" : "bg-white text-dark border-light-dark"} border-top border-3 pt-2 media-post`}>
                <Row
                    className="position-relative flex-nowrap align-items-center px-0 pb-3 gx-1"
                >
                    <div
                        className="position-absolute end-0 bottom-0 text-primary text-end fs-6 pb-1"
                        style={{ cursor: "pointer", marginBottom: 2 }}
                    >
                        {i % 2 === 0 && i % 3 !== 0 && i % 5 !== 0 && i % 7 !== 0 && (
                            <span title="You are linked" role="img" aria-label="linked" className="emoji-link"></span>
                        )}
                        {i % 3 === 0 && i % 5 !== 0 && i % 7 !== 0 && (
                            <span title="You waved!" role="img" aria-label="hand wave" className="emoji-wave"></span>
                        )}
                        {i % 5 === 0 && i % 7 !== 0 && (
                            <span title="You said they are hot!" role="img" aria-label="wink" className="emoji-fire"></span>
                        )}
                        {i % 7 === 0 && (
                            <span title="You winked!" role="img" aria-label="wink" className="emoji-wink"></span>
                        )}
                    </div>
                    {!isMobileOnly && (
                        <Col className="me-2" xs="auto">
                            <img
                                src={user.photoURL}
                                className="img-fluid rounded-1 shadow"
                                alt={
                                    `Profile for ${user.email}`
                                }
                                style={{
                                    width: 44,
                                }}
                            />
                        </Col>
                    )}
                    <Col
                        sm="10"
                    >
                        <div
                            style={{
                                height: "1.2em",
                                fontSize: ".90rem",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                maxWidth: "96%",
                            }}
                        >
                            One.Bad.Apple
                        </div>
                        <div style={{ height: "1.3em", fontSize: ".70rem", }}>Couple</div>
                        <div style={{ height: "1.2em", fontSize: ".65rem", }}>Sat, 12/12/2022</div>
                    </Col>

                </Row>

                {/* IMG CONTAINER */}
                <div
                    className="position-relative"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleClick({
                            imgs: data.full,
                        });
                    }}
                >
                    <div
                        className="d-flex align-items-center position-absolute top-0 end-0 me-1 mt-1"
                        style={{
                            zIndex: 5,
                        }}
                    >
                        <Badge
                            bg={"dark"}
                            text={getStatusColor()}
                            title={getStatusText()}
                            className="rounded-circle shadow-sm"
                            style={{
                                fontSize: ".85rem",
                                paddingTop: 3,
                                paddingBottom: 2,
                                paddingLeft: 5,
                                paddingRight: 5,
                            }}
                        >
                            <ion-icon name={getStatusIcon()}></ion-icon>
                        </Badge>
                    </div>
                    <Carousel
                        fade
                        controls={false}
                        indicators={false}
                        interval={3500 + (i * 75)}
                    >
                        {data && data.thumbs.map((item, i) => {
                            return (
                                <Carousel.Item key={i}>
                                    <div
                                        className={`${blur ? "img-blur" : ""} rounded-1 shadow-sm d-flex align-items-end justify-content-end pe-0 pb-0`}
                                        style={{
                                            aspectRatio: "1 / 1",
                                            backgroundImage: `url(${item.img})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}
                                    >
                                        {item.type === "video" && (
                                            <div
                                                className="d-flex align-items-center justify-content-center position-absolute top-0 end-0 bottom-0 start-0 text-light"
                                                style={{
                                                    fontSize: "3rem",
                                                    zIndex: 5,
                                                    pointerEvents: "none"
                                                }}
                                            >
                                                <ion-icon name={"play-outline"}></ion-icon>
                                            </div>
                                        )}
                                        <div
                                            className="d-flex align-items-center position-absolute bottom-0 start-0 ms-1 mb-1"
                                        >
                                            <Badge
                                                bg={"dark"}
                                                text={"light"}
                                                className="rounded-pill shadow-sm"
                                                style={{
                                                    fontSize: ".75rem",
                                                }}
                                            >
                                                {(i + 1) + (data.thumbs.length > 1 ? `/${data.thumbs.length}` : "")}
                                            </Badge>
                                        </div>
                                        {/* TYPE ICON */}
                                        <div
                                            className="d-flex align-items-center position-absolute bottom-0 end-0 rounded-1 bg-dark text-light pb-2 pt-2 px-2 me-1 mb-1"
                                            style={{
                                                fontSize: "1.25rem",
                                                height: "1.4em"
                                            }}
                                        >
                                            <ion-icon name={getMediaType(item.type)}></ion-icon>
                                        </div>
                                        {/* END TYPE ICON */}
                                    </div>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                </div>
                {/* END IMG CONTAINER */}
                <div
                    className="pt-2 px-0"
                >
                    <Row className="align-items-center justify-content-start gx-1">
                        <Col className="d-flex align-items-center" xs="auto">
                            <div className="d-flex align-items-center justify-content-start" style={{ fontSize: "1.25rem" }}>
                                <div className="text-danger me-0" style={{ fontSize: "1.15rem", height: "1em", lineHeight: "1em" }}>
                                    {/* <ion-icon name="heart-sharp"></ion-icon> */}
                                    <span title="Reactions" role="img" aria-label="heart" className="emoji-heart-fire"></span>
                                </div>
                                <span style={{ fontSize: ".75rem", height: "1em", lineHeight: "1em" }}>56</span>
                            </div>
                        </Col>
                        <Col className={(isDark ? "text-light " : "text-dark ") + "d-flex align-items-center justify-content-end ms-1"} xs="auto">
                            <div style={{ fontSize: "1.25rem", height: "1em", lineHeight: "1em" }} className="me-1"><ion-icon name="chatbubble-ellipses-outline"></ion-icon></div>
                            <div style={{ fontSize: ".75rem", height: "1em", lineHeight: "1em" }}>24</div>
                        </Col>
                        {/* <Col className="d-flex align-items-center ms-auto" xs="auto">
                            <div className="d-flex align-items-center justify-content-start text-primary" style={{ fontSize: "1.25rem" }}>
                                <div style={{ fontSize: "1.25rem", height: "1em", lineHeight: "1em" }} className="me-1"><ion-icon name="call-sharp"></ion-icon></div>
                                
                            </div>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-end text-primary ms-1" xs="auto">
                            <div style={{ fontSize: "1.25rem", height: "1em", lineHeight: "1em" }} className="me-1"><ion-icon name="chatbubbles-sharp"></ion-icon></div>
                            
                        </Col> */}
                    </Row>
                </div>
            </div>
        </>
    );

}

export default MediaPostCard;