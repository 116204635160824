import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
    //isDesktop, 
    isMobileOnly,
    //isMobile,
    //isTablet,
} from "react-device-detect";

import ProfileBtnsHorizontal from "../profile/ProfileBtnsHorizontal";
//import CarouselFullWidth from "../../banners/CarouselFullWidth";
import ProfileScreenHeaderNav from "../profile/ProfileScreenHeaderNav";
import ShortcutMenu from "../menus/shortcutMenu/ShortcutMenu";
import BtnStackedLink from "../buttons/BtnStackedLink";
//import AppNameInline from "../text/AppNameInline";
import BtnIcon from "../buttons/BtnIcon";

const ProfileScreenFrame = ({
    children,
    isDark,
    blur,
    user,
    showCarousel,
    handleClick,
    toggleChat,
    setShowFlirtModal,

}) => {

    const [_showShortcutMenu, _setShowShortcutMenu] = useState(false);

    return (
        <>
            <div className={`${isDark ? "bg-md-dark" : "bg-gray-light"} pb-5`}>

                {showCarousel && (
                    <>
                        <Container
                            fluid
                            className={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} ${showCarousel ? "" : "pt-4"} container- shadow-sm px-0 px-sm-0`}
                        >
                            <div
                                className={`${blur ? "img-blur " : ""}position-relative rounded-0 shadow`}
                                style={{
                                    aspectRatio: isMobileOnly ? "21 / 9" : "21 / 5",
                                    //width: "100%",
                                    backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223201-large.jpg)",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    cursor: "pointer"
                                }}
                                onClick={() => {

                                    handleClick({
                                        imgs: [
                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223201-large.jpg", type: "image" },
                                        ]
                                    })
                                }}
                            >
                                <div
                                    className="position-absolute end-0 bottom-0 mb-3 me-3"
                                >
                                    {isMobileOnly ? (
                                        <Row className="gx-3">
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={"light"}
                                                    iconSize={20}
                                                    iconName="checkmark-circle-outline"
                                                    iconClasses=""
                                                    btnClasses={"shadow-sm"}
                                                    handleClick={(e) => {

                                                        e.stopPropagation();
                                                        alert("This feature is currently under development.");

                                                    }}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={"light"}
                                                    iconSize={20}
                                                    iconName="videocam-outline"
                                                    iconClasses=""
                                                    btnClasses={"shadow-sm"}
                                                    handleClick={(e) => {

                                                        e.stopPropagation();
                                                        alert("This feature is currently under development.");

                                                    }}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={"dark"}
                                                    iconSize={20}
                                                    iconName="chatbubbles-outline"
                                                    iconClasses=""
                                                    btnClasses={"shadow-sm border border-2 border-primary"}
                                                    handleClick={(e) => {

                                                        e.stopPropagation();
                                                        toggleChat(false);

                                                    }}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={"dark"}
                                                    iconSize={20}
                                                    iconName="heart-outline"
                                                    iconClasses=""
                                                    btnClasses={"shadow-sm border border-2 border-primary"}
                                                    handleClick={(e) => {

                                                        e.stopPropagation();
                                                        setShowFlirtModal(true);

                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row className="gx-3">
                                            <Col xs="auto">
                                                <BtnStackedLink
                                                    bsVariant={"light"}
                                                    bsSize={"xs"}
                                                    btnClasses="rounded-pill shadow ps-3 pe-3"
                                                    label={<>Friends</>}
                                                    sublabel={"Manage your friendship"}
                                                    iconSize={26}
                                                    iconName="checkmark-circle-outline"
                                                    useLink={false}
                                                    href="/live"
                                                    target=""
                                                    handleClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        alert("This feature is currently under development.");
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnStackedLink
                                                    bsVariant={"dark"}
                                                    bsSize={"xs"}
                                                    btnClasses="rounded-pill border border-2 border-primary shadow ps-3 pe-3"
                                                    label={<>Live</>}
                                                    sublabel={"Start an audio/video call!"}
                                                    iconSize={26}
                                                    iconName="videocam-outline"
                                                    useLink={false}
                                                    href="/live"
                                                    target=""
                                                    handleClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        alert("This feature is currently under development.");
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnStackedLink
                                                    bsVariant={"dark"}
                                                    bsSize={"xs"}
                                                    btnClasses="rounded-pill border border-2 border-primary shadow ps-3 pe-3"
                                                    label={<>Connect</>}
                                                    sublabel={"Send them a message!"}
                                                    iconSize={26}
                                                    iconName="chatbubbles-outline"
                                                    useLink={false}
                                                    handleClick={(e) => {
                                                        e.stopPropagation();
                                                        toggleChat(false);
                                                    }}
                                                />
                                            </Col>

                                            <Col xs="auto">
                                                <BtnStackedLink
                                                    bsVariant={"dark"}
                                                    bsSize={"xs"}
                                                    btnClasses="rounded-pill border border-2 border-primary shadow ps-3 pe-3"
                                                    label={<>Flirt</>}
                                                    sublabel={"Show youre interest!"}
                                                    iconSize={26}
                                                    iconName="heart-outline"
                                                    useLink={false}
                                                    handleClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowFlirtModal(true);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    )}

                                </div>
                            </div>
                        </Container>
                    </>
                )}

                <Container
                    fluid
                    className={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} ${showCarousel ? "" : "pt-4"} container-1200 shadow-sm pb-5 px-3 px-sm-4`}
                >
                    {/* PROFILE */}
                    <ProfileScreenHeaderNav
                        showShortcutMenu={_showShortcutMenu}
                        setShowShortcutMenu={_setShowShortcutMenu}
                        showCarousel={showCarousel}
                        user={user}
                        isDark={isDark}
                    >
                        <ProfileBtnsHorizontal
                            isDark={isDark}
                            isMobileOnly={isMobileOnly}
                        />
                    </ProfileScreenHeaderNav>
                    {children}
                </Container>
            </div>

            <ShortcutMenu
                showShortcutMenu={_showShortcutMenu}
                setShowShortcutMenu={_setShowShortcutMenu}
                user={user}
                isDark={isDark}
            />
        </>
    );

};

export default ProfileScreenFrame;