import React from "react";
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { NavLink, } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

const BtnIconBadgeCountLink = ({
  bsVariant,
  isDark,
  iconSize,
  iconName,
  iconClasses,
  label,
  visibleTextSize,
  visibleText,
  btnClasses,
  badgeCount,
  badgeBG,
  badgeText,
  badgeClasses,
  ribbon,
  url,
  handleClick,
}) => {

  let defaultClasses = "position-relative d-flex align-items-center justify-content-center btn";
  //defaultClasses += (bsVariant && bsVariant !== "") ? " btn-" + bsVariant : "";
  defaultClasses += (btnClasses && btnClasses !== "") ? ` ${btnClasses}` : "";

  return (
    <>
      <NavLink
        preventScrollReset={false}
        to={url}
        title={label}
        className={({ isActive }) => (isActive ? "activeNow btn-primary border-1 " : `btn-${bsVariant} `) + defaultClasses}
        style={{
          borderRadius: iconSize,
          fontSize: iconSize,
          width: `calc(${iconSize}px + ${isMobileOnly ? "30px" : "36px)"}`,//18
          height: `calc(${iconSize}px + ${isMobileOnly ? "10px" : "12px)"})`,//18
        }}
        onClick={handleClick}
      >
        <span
          className={iconClasses ? iconClasses : ""}
          style={{
            lineHeight: "1em",
            height: "1em",
          }}
        >
          <ion-icon name={iconName}></ion-icon>
        </span>
        {badgeCount && badgeCount !== 0 && (
          <div className="position-absolute bottom-0" style={{ zIndex: 1, right: -12, marginBottom: -13 }}>
            <Badge
              className={`${badgeClasses && badgeClasses !== "" ? `${badgeClasses} ` : ""} ${isDark ? "border-dark" : "border-light"} rounded-pill border shadow-sm`}
              style={{ fontSize: ".75rem", pointerEvents: "none" }}
              bg={badgeBG}
              text={badgeText}
            >
              {badgeCount}
            </Badge>
          </div>
        )}

        {(ribbon && ribbon !== "") && (
          <div className="position-absolute shadow" style={{ zIndex: 1, top: 4, right: -15 }}>
            <div
              className={`${isDark ? "bg-dark text-light " : "bg-dark text-light "}rounded-1 border border-1 border-primary py-0 px-1`}
              style={{
                fontSize: ".75rem",
                fontWeight: 400,
              }}
            >
              {ribbon}
            </div>
          </div>
        )}

      </NavLink>
      {visibleText && visibleText !== "" && (
        <div
          className={`${isDark ? "text-light" : "text-dark"} text-label text-center pb-1 mt-1`}
          style={{
            fontSize: visibleTextSize && visibleTextSize !== "" ? visibleTextSize : ".7rem",
            lineHeight: "1.2em",
            fontWeight: 400,
            //width: "100%",
            marginTop: 0,
          }}
        >
          {visibleText}
        </div>
      )}
    </>
  );

};

BtnIconBadgeCountLink.propTypes = {
  bsVariant: PropTypes.string,
  iconSize: PropTypes.any,
  iconName: PropTypes.string,
  iconClasses: PropTypes.string,
  btnClasses: PropTypes.string,
  handleClick: PropTypes.func,
}

BtnIconBadgeCountLink.defaultProps = {
  bsVariant: "icon-light",
  iconSize: 24,
  iconName: "ellipse-outline",
  iconClasses: "",
  btnClasses: "",
  handleClick: null,
};

export default BtnIconBadgeCountLink;

