import React from "react";
import { Row } from "react-bootstrap";


const CollectionRow = ({
    children,
    className
}) => {

    return (
        <>
            <Row className={(className && className !== "" ? className : "")}>
                {children}
            </Row>
        </>
    )

};

export default CollectionRow;