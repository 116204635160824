import React from "react";
import { Row, Col, } from "react-bootstrap";
//import BtnIcon from "../buttons/BtnIcon";

const ImageNameLG = ({
    user,
    isDark,
    isMobileOnly,
    showShortcutMenu,
    setShowShortcutMenu

}) => {

    return (
        <Row className="align-items-end gx-3">
            <Col xs="5" sm="3" lg="6" className="rounded-1">
                <img
                    src={user.photoURL}
                    className="img-fluid rounded-circle shadow"
                    alt={
                        "Profile picture for " + user.email
                    }
                    style={{
                        pointerEvents: "auto",
                        border: "2px solid rgba(0,0,0,.8)"
                    }}
                />
            </Col>
            <Col
                xs="7"
                sm="9"
                lg="6"
                style={{
                    //height: isMobileOnly ? "auto" : 110,
                    pointerEvents: "auto",
                }}
            >
                <h4 className="m-1">One.Bad.Apple</h4>
                <h6 className="m-1">Couple (M/F)</h6>
                <h6 className="m-1">Houston, TX Area</h6>
                <h6 className="m-1">Status: Online</h6>

            </Col>
        </Row>
    );
}

export default ImageNameLG;