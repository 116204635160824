import React from "react";
//import PropTypes from 'prop-types';
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    //isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
//import { Row, Col, Form, /*Badge*/ } from 'react-bootstrap';
import BtnIcon from "../../buttons/BtnIcon";


const NavLeft = ({
    isDark,
}) => {

    return (
        <>
            <div className="position-relative ps-1 mb-1">
                <div className="position-absolute" style={{ zIndex: 5, bottom: 10, right: 1 }}>
                    <a href="/" className="text-primary" style={{ fontSize: 16 }} title="Unread"><ion-icon name="ellipse-sharp"></ion-icon></a>
                </div>
                <BtnIcon
                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                    label="Chat messages..."
                    visibleText="Chats"
                    iconSize={28}
                    iconName="chatbubble-outline"
                    iconClasses=""
                    btnClasses="rounded-1 mb-1 active"
                    handleClick={(e) => {
                        e.preventDefault();
                        alert("This feature is under development ");
                    }}
                />
            </div>
            <div className="position-relative ps-1 mb-1">
                <div className="position-absolute" style={{ zIndex: 5, bottom: 8, right: 1 }}>
                    {/* <a href="/" className="text-primary" style={{ fontSize: 16 }} title="Unread"><ion-icon name="ellipse-sharp"></ion-icon></a> */}
                </div>
                <BtnIcon
                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                    label="Group messages..."
                    visibleText="Groups"
                    iconSize={28}
                    iconName="chatbubbles-outline"
                    iconClasses=""
                    btnClasses="rounded-1 mb-1"
                    handleClick={(e) => {
                        e.preventDefault();
                        alert("This feature is under development ");
                    }}
                />
            </div>
            {/* <div className="position-relative ps-1 mb-1">
                <div className="position-absolute" style={{ zIndex: 5, bottom: 8, right: 1 }}>
                    <a href="/" className="text-primary" style={{ fontSize: 16 }} title="Unread"><ion-icon name="ellipse-sharp"></ion-icon></a>
                </div>
                <BtnIcon
                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                    label="Live video messages..."
                    visibleText="Live"
                    iconSize={28}
                    iconName="videocam-outline"
                    iconClasses=""
                    btnClasses="rounded-1 mb-1"
                    handleClick={(e) => {
                        e.preventDefault();
                        alert("This feature is under development ");
                    }}
                />
            </div> */}
        </>
    );

};

export default NavLeft;