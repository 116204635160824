import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const BtnIconLink = ({
    bsVariant,
    iconSize,
    iconName,
    iconClasses,
    href,
    target,
    handleClick,
    label,
}) => {

    const defaultClasses = "d-flex align-items-center justify-content-center";

    return (
        <>
            <Link
                className={"btn btn-" + (bsVariant) + " " + defaultClasses}
                to={href}
                title={label}
                style={{
                    borderRadius: iconSize,
                    fontSize: iconSize,
                    width: `calc(${iconSize}px + 16px)`,
                    height: `calc(${iconSize}px + 16px)`,
                    pointerEvents: "auto"
                }}
                target={target}
                onClick={handleClick}
            >
                <span
                    className={iconClasses ? iconClasses : ""}
                    style={{
                        lineHeight: "1em",
                        height: "1em",
                    }}
                >
                    <ion-icon name={iconName}></ion-icon>
                </span>
            </Link>
            {/* <Button
                type="button"
                as="a"
                title={label}
                variant={bsVariant}
                className={defaultClasses}
                style={{
                    borderRadius: iconSize,
                    fontSize: iconSize,
                    width: "calc(" + iconSize + "px + 12px",
                    height: "calc(" + iconSize + "px + 12px",
                }}
                href={href && href !== "" ? href : "/"}
                target={target}
            >
                

            </Button> */}
        </>
    );

};

BtnIconLink.propTypes = {
    bsVariant: PropTypes.string,
    iconSize: PropTypes.any,
    iconName: PropTypes.string,
    iconClasses: PropTypes.string,
    href: PropTypes.string,
}

BtnIconLink.defaultProps = {
    bsVariant: "icon-light",
    iconSize: 24,
    iconName: "ellipse-outline",
    iconClasses: "",
};

export default BtnIconLink;

