import React from "react";
import { Row, Col, } from "react-bootstrap";
import BtnIconBadgeCountLink from "../buttons/BtnIconBadgeCountLink";

const AppBtnsHorizontal = ({

    isDark,
    isMobileOnly,

}) => {

    return (
        <>
            <Row className="align-items-start justify-content-around gy-3 gx-1 g-lg-0 gx-lg-2 mt-2 mt-lg-0" style={{ fontSize: "1rem", }}>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="Main hub..."
                            visibleText="hub"
                            visibleTextSize={".85rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="globe-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/hub/preview"}
                            ribbon={null}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="link"
                            visibleTextSize={".85rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="share-social-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/link"}
                            ribbon={null}
                        />
                    </div>                    
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="connect"
                            visibleTextSize={".85rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="chatbubbles-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/connect"}
                            ribbon={null}
                        />
                    </div>                    
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="live"
                            visibleTextSize={".85rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="videocam-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/live"}
                            ribbon={null}
                        />
                    </div>                    
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "outline-secondary" : "outline-secondary")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="follow"
                            visibleTextSize={".85rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="person-circle-outline"
                            iconClasses=""
                            btnClasses={"shadow-sm rounded-pill text-secondary mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/follow"}
                            ribbon={"2023"}
                        />
                    </div>                    
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "outline-secondary" : "outline-secondary")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="follow.me"
                            visibleTextSize={".85rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="people-circle-outline"
                            iconClasses=""
                            btnClasses="shadow-sm rounded-pill text-secondary mb-1"
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/follow-me"}
                            ribbon={"2023"}
                        />
                    </div>                    
                </Col>
                {/* <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="link partners"
                            visibleTextSize={".85rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="sync-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/follow-me"}
                        />
                    </div>                    
                </Col> */}
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "outline-secondary" : "outline-secondary")}
                            isDark={isDark}
                            label="partners..."
                            visibleText="partner"
                            visibleTextSize={".85rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="git-compare-outline"
                            iconClasses=""
                            btnClasses="shadow-sm rounded-pill text-secondary mb-1"
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/partners"}
                            ribbon={"SOON"}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="shop"
                            visibleTextSize={".85rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="cart-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/shop"}
                            ribbon={null}
                        />
                    </div>                    
                </Col>
                
            </Row>
        </>
    );
};

export default AppBtnsHorizontal;