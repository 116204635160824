import React, { useState } from "react";
import { Container, } from "react-bootstrap";
import {
    //isDesktop, 
    isMobileOnly,
    //isMobile,
    //isTablet,
} from "react-device-detect";

import LinkBtnsHorizontal from "../profile/LinkBtnsHorizontal";
import CarouselFullWidth from "../../banners/CarouselFullWidth";
import StandardHeaderNav from "../profile/StandardHeaderNav";
import ShortcutMenu from "../menus/shortcutMenu/ShortcutMenu";

const LinkScreenFrameWithProfile = ({
    children,
    isDark,
    user,
    showCarousel,
    setShowProfileModal,
    setShowNotificationsMenu,
    showNotificationsMenu,
    setShowSettingsModal,
    setShowProfileSelector
}) => {

    const [_showShortcutMenu, _setShowShortcutMenu] = useState(false);

    return (
        <>
            <div className={`${isDark ? "bg-md-dark" : "bg-gray-light"} pb-5`}>

                {showCarousel && (
                    <>
                        <CarouselFullWidth
                            interval={10000}
                        />
                    </>
                )}

                <Container
                    fluid
                    className={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} ${showCarousel ? "" : "pt-4"} container-1400 shadow-sm pb-5 px-3 px-sm-4`}
                >
                    {/* PROFILE */}
                    <StandardHeaderNav
                        showShortcutMenu={_showShortcutMenu}
                        setShowShortcutMenu={_setShowShortcutMenu}
                        showCarousel={showCarousel}
                        user={user}
                        isDark={isDark}
                        setShowProfileModal={setShowProfileModal}
                        setShowNotificationsMenu={setShowNotificationsMenu}
                        showNotificationsMenu={showNotificationsMenu}
                        setShowSettingsModal={setShowSettingsModal}
                        setShowProfileSelector={setShowProfileSelector}
                    >
                        <LinkBtnsHorizontal
                            isDark={isDark}
                            isMobileOnly={isMobileOnly}
                        />
                    </StandardHeaderNav>
                    {children}
                </Container>
            </div>

            <ShortcutMenu
                showShortcutMenu={_showShortcutMenu}
                setShowShortcutMenu={_setShowShortcutMenu}
                user={user}
                isDark={isDark}
            />
        </>
    );

};

export default LinkScreenFrameWithProfile;