import React from "react";
//import PropTypes from 'prop-types';
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    //isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import { Row, Col, Button, Badge, Form } from 'react-bootstrap';

const UserMenu = ({
    showUserMenu,
    isAuthenticated,
    isDark,
    setIsDark,
    setShowUserMenu,
    setShowCreateAcct,
    setShowLogin,
    setShowLogout,
    checkMenus,
    setShowSettingsModal,
    setShowProfileSelector,
    user,
}) => {

    let menuTimer = null;

    return (
        <>
            <div
                className={"user-menu position-fixed bg-light-dark text-light shadow" + (showUserMenu ? " show-menu" : "")}
                style={{
                    fontSize: ".85rem",
                    zIndex: 100,
                    right: 15,
                    marginTop: -1,
                    minWidth: 220
                }}
                onMouseLeave={() => {

                    menuTimer = setTimeout(() => {

                        setShowUserMenu(false);

                    }, 500);

                }}
                onMouseEnter={() => {

                    clearTimeout(menuTimer);

                    if (!showUserMenu) setShowUserMenu(true);

                }}
                onClick={() => {

                    clearTimeout(menuTimer);

                    setShowUserMenu(false);

                }}
            >
                {isAuthenticated && (
                    <div className="d-grid mb-1">
                        <small className="text-light-gray pt-2 ps-3 d-block" style={{ fontSize: ".85rem", }}>Account</small>
                        {user && (
                            <div className="ps-4 pe-3 py-1">{user.email}</div>
                        )}
                        
                        <Button
                            variant="menu-std"
                            className="btn px-4 pt-2 text-light border-bottom border-md-dark"
                            onClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development");
                            }}
                        >
                            <Row className="align-items-center g-2">
                                <Col xs="auto">
                                    <div style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: 26 }} name="shield-checkmark-outline"></ion-icon></div>

                                </Col>
                                <Col className="text-start position-relative">
                                    My Account
                                    <Badge className="position-absolute rounded-pill shadow-sm border border-light text-dark" style={{ top: 5, right: 0, fontSize: ".55rem", zIndex: 1 }} bg="primary">1</Badge>
                                </Col>
                            </Row>
                        </Button>
                        <Button
                            variant="menu-std"
                            className="btn px-4 py-2 text-light border-bottom border-md-dark"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowProfileSelector(true);
                            }}
                        >
                            <Row className="align-items-center g-2">
                                <Col className="" xs="auto">
                                    <div style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: 26 }} name="information-circle-outline"></ion-icon></div>
                                </Col>
                                <Col className="text-start">
                                    My Profiles
                                </Col>
                            </Row>
                        </Button>
                        <Button
                            variant="menu-std"
                            className="btn px-4 py-2 text-light border-bottom border-md-dark"
                            onClick={() => {

                                setShowSettingsModal(true);

                            }}
                        >
                            <Row className="align-items-center g-2">
                                <Col className="" xs="auto">
                                    <div style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: 26 }} name={"cog-outline"}></ion-icon></div>
                                </Col>
                                <Col className="text-start">
                                    {"App Settings"}
                                </Col>
                            </Row>
                        </Button>
                        <Button
                            variant="menu-std"
                            className="btn px-4 py-2 text-light border-bottom border-md-dark"
                            onClick={() => {

                                alert("This feature is under development.");

                            }}
                        >
                            <Row className="align-items-center g-2">
                                <Col className="" xs="auto">
                                    <div style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: 26 }} name={"reorder-four-outline"}></ion-icon></div>
                                </Col>
                                <Col className="text-start">
                                    {"Shortcuts"}
                                </Col>
                            </Row>
                        </Button>
                        <small className="text-light-gray pt-2 ps-3 ps-3 d-block" style={{ fontSize: ".85rem", }}>Temp Button</small>                        
                        <div className="btn-menu-std d-flex justify-content-start px-4 py-2 text-light border-bottom border-md-dark">                            
                            <Row className="align-items-center g-2">
                                <Col className="" xs="auto">
                                    <div style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: 26 }} name={"invert-mode-outline"}></ion-icon></div>
                                </Col>
                                <Col className="text-start">
                                    <Form.Check
                                        type="switch"
                                        label="dark"
                                        className=""
                                        style={{ height: "1.4rem", fontSize: 16 }}
                                        checked={isDark ? true : false}
                                        reverse
                                        onChange={(e) => {

                                            //e.stopPropagation();
                                            setIsDark(!isDark);

                                        }}
                                        onClick={(e) => {

                                            //e.stopPropagation();

                                        }}
                                    />
                                </Col>
                            </Row>
                            
                        </div>
                        {/* <Button
                            variant="menu-std"
                            className="btn px-4 py-2 text-light border-bottom border-md-dark"
                            onClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development");
                            }}
                        >
                            <Row className="align-items-center g-2">
                                <Col className="" xs="auto">
                                    <div style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: 26 }} name="globe-outline"></ion-icon></div>
                                </Col>
                                <Col className="text-start">
                                    my hub
                                </Col>
                            </Row>
                        </Button> */}
                    </div>
                )}
                <div className="d-grid mb-1">
                    <small className="text-light-gray pt-2 ps-3 ps-3 d-block" style={{ fontSize: ".85rem", }}>Tools</small>
                    {!isAuthenticated && (
                        <Button
                            variant="menu-std"
                            className="btn px-4 py-2 text-light border-bottom border-md-dark"
                            onClick={() => {

                                setShowCreateAcct(true);

                                setShowUserMenu(false);

                            }}
                        >
                            <Row className="align-items-center g-2">
                                <Col className="" xs="auto">
                                    <div style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: 26 }} name={"create-outline"}></ion-icon></div>
                                </Col>
                                <Col className="text-start">
                                    {"Create Account"}
                                </Col>
                            </Row>
                        </Button>
                    )}
                    <Button
                        variant="menu-std"
                        className="btn px-4 py-2 text-light border-bottom border-md-dark"
                        onClick={() => {

                            if (!isAuthenticated) {
                                setShowLogin(true);
                            } else {
                                setShowLogout(true);
                                checkMenus();
                            }

                            setShowUserMenu(false);

                        }}
                    >
                        <Row className="align-items-center g-2">
                            <Col className="" xs="auto">
                                <div style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: 26 }} name={!isAuthenticated ? "log-in-outline" : "log-out-outline"}></ion-icon></div>
                            </Col>
                            <Col className="text-start">
                                {!isAuthenticated ? "Log In" : "Log Out"}
                            </Col>
                        </Row>
                    </Button>
                    <Button
                        variant="menu-std"
                        className="btn px-4 py-2 text-light border-bottom border-md-dark"
                        onClick={(e) => {
                            e.preventDefault();
                            alert("This feature is under development");
                        }}
                    >
                        <Row className="align-items-center g-2">
                            <Col className="" xs="auto">
                                <div style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: 26 }} name="help-circle-outline"></ion-icon></div>
                            </Col>
                            <Col className="text-start">
                                Support &amp; FAQ
                            </Col>
                        </Row>
                    </Button>
                </div>
            </div>
        </>
    );

};

// UserMenu.propTypes = {

// }

// UserMenu.defaultProps = {

// };

export default UserMenu;