import React, { useEffect, useRef } from "react";
import Config from "../data/Config";
import gtag from 'ga-gtag';
import BannerTopDefault from "../banners/BannerTopDefault";
import NewsContent from "../content/news/NewsContent";

const News = ({
    isAuthenticated,
}) => {

    const gtagSent = useRef(false);

    useEffect(() => {

        if (!gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'News',
                page_title: 'News',
            });

            gtagSent.current = true;

        }

    }, [gtagSent]);

    return (
        <>
            <BannerTopDefault />
            <NewsContent
                isAuthenticated={isAuthenticated}
            />
        </>
    );

};

export default News;