import React, { useState, } from "react";
//import PropTypes from 'prop-types';
import {
    //isDesktop,
    //isMobile,
    isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import { Row, Col, Form, } from 'react-bootstrap';
import BtnIcon from "../buttons/BtnIcon";
import NavTopMain from "./NavTopMain";
import NavLeft from "./fullChat/NavLeft";
import RecentChatList from "./RecentChatList";
import NavTopRecentChats from "./fullChat/NavTopRecentChats";
import MsgList from "./MsgList";
import NavTopMsgList from "./fullChat/NavTopMsgList";
import ChatProfileHorizontal from "../profile/ChatProfileHorizontal";

const ChatWindow = ({
    showChatWindow,
    toggleChat,
    toggleLive,
}) => {

    const [isDark, setIsDark] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showMinMsgs, setShowMinMsgs] = useState(true);

    const updateScrollPos = (target, scrollTo, type) => {

        target.scroll({ top: scrollTo, behavior: type });
    }

    const expandWindow = (e) => {

        e.preventDefault();

        if (!isMobileOnly) setIsExpanded(!isExpanded);

    };

    return (
        <div
            className={
                (isDark ? "bg-md2-dark text-light border-light-gray " : "bg-light text-dark  ") +
                "border border-1 rounded-1 shadow connect-window position-fixed pt-2 mb-4 px-1 shadow" +
                (showChatWindow ? " show-menu" : " ")
            }
            style={{
                fontSize: ".9rem",
                zIndex: 1036,
                right: 15,
                //left: isExpanded ? 300 : "auto",
                bottom: 0,
                width: isExpanded ? (isTablet ? "100vw" : "60vw") : 380,
                minWidth: 300,
                maxWidth: "calc(100vw - 30px)",
                height: "100vh",
                maxHeight: isExpanded ? (isTablet ? "85vh" : "80vh") : isMobileOnly ? "70vh" : "65vh"
            }}
        >
            <>
                {/* NAV TOP */}
                <NavTopMain
                    expandWindow={expandWindow}
                    isExpanded={isExpanded}
                    isDark={isDark}
                    setIsDark={setIsDark}
                    toggleChat={toggleChat}
                    toggleLive={toggleLive}
                />

                {isExpanded ? (
                    <Row
                        className="mt-4 pt-4 pb-2 g-2 justify-content-between position-absolute bottom-0 top-0 end-0 start-0"
                        style={{ zIndex: -1 }}
                    >
                        <Col xs="auto" className={(isDark ? "border-md-light " : "border-md-dark ") + "border-end"}>

                            {/* CONNECT LEFT NAV */}
                            <NavLeft
                                isDark={isDark}
                            />

                        </Col>
                        <Col sm="4" lg="4" className={(isDark ? "border-md-light " : "border-md-dark ") + "d-flex flex-column border-end"}>
                            <div className="pt-1 pb-1 px-1">

                                {/* ACTIVE CHAT NAV TOP */}
                                <NavTopRecentChats
                                    isDark={isDark}
                                />

                            </div>
                            <div className="position-relative" style={{ height: "100%" }}>
                                {/* ACTIVE CHAT LIST */}
                                <RecentChatList
                                    isDark={isDark}
                                    showMinMsgs={showMinMsgs}
                                    setShowMinMsgs={setShowMinMsgs}
                                    isExpanded={isExpanded}
                                    updateScrollPos={updateScrollPos}
                                />
                            </div>
                        </Col>
                        <Col className="d-flex flex-column">
                            <div className="pt-1 pb-2 px-2">
                                {/* CONNECT MSG NAV */}
                                <NavTopMsgList
                                    isDark={isDark}
                                />
                            </div>
                            <div className="position-relative" style={{ height: "100%" }}>
                                {/* CONNECT MSG LIST */}
                                <MsgList
                                    isDark={isDark}
                                    updateScrollPos={updateScrollPos}
                                />
                            </div>
                            <div className="text-center pt-2 pb-1 ps-2 pe-3">
                                {/* CONNECT MSG INPUT */}
                                <Row className="g-1 p-0">
                                    <Col>
                                        <Form.Control
                                            placeholder="what's on your mind?..."
                                            className={
                                                (isDark ? "bg-md-dark border-0 " : "") + "text-light m-0"
                                            }
                                            size=""
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <BtnIcon
                                            bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                            label="Post your message..."
                                            iconSize={22}
                                            iconName="checkbox-outline"
                                            iconClasses=""
                                            btnClasses="rounded-0"
                                            handleClick={(e) => {
                                                e.preventDefault();
                                                setShowMinMsgs(false);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row
                            className="mt-4 pt-4 pb-2 g-2 justify-content-between position-absolute bottom-0 top-0 end-0 start-0"
                            style={{
                                zIndex: -1
                            }}
                        >
                            <Col className="d-flex flex-column">
                                <div className="pt-1 pb-1 px-2">
                                    {/* NAV HERE... */}
                                    {showMinMsgs ? (
                                        <>
                                            <Row className="justify-content-between align-items-center g-1">
                                                <Col xs="auto">
                                                    <Row className="justify-content-between align-items-center g-1 pb-1">
                                                        <Col xs="auto">
                                                            <BtnIcon
                                                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                                label="Back to recent messages..."
                                                                iconSize={22}
                                                                iconName="arrow-back-outline"
                                                                iconClasses=""
                                                                btnClasses=""
                                                                handleClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowMinMsgs(false);
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xs="auto">
                                                                <ChatProfileHorizontal
                                                                    isDark={isDark}
                                                                //showLightBoxWindow={showLightBoxWindow}
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs="auto">
                                                    <BtnIcon
                                                        bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                        label="Back to recent messages..."
                                                        iconSize={22}
                                                        iconName="ellipsis-vertical-outline"
                                                        iconClasses=""
                                                        btnClasses=""
                                                        handleClick={(e) => {
                                                            e.preventDefault();
                                                            alert("This feature is under development ");
                                                        }}
                                                    />
                                                </Col>

                                            </Row>
                                        </>
                                    ) : (
                                        <>
                                            <Row className="justify-content-between align-items-center g-1">
                                                <Col xs="auto">
                                                    <BtnIcon
                                                        bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                        label="View your link contacts..."
                                                        iconSize={20}
                                                        iconName="list-outline"
                                                        iconClasses=""
                                                        btnClasses=""
                                                        handleClick={(e) => {
                                                            e.preventDefault();
                                                            alert("This feature is under development ");
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs="auto">
                                                    <BtnIcon
                                                        bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                        label="Mark all as read..."
                                                        iconSize={20}
                                                        iconName="checkmark-circle-outline"
                                                        iconClasses=""
                                                        btnClasses=""
                                                        handleClick={(e) => {
                                                            e.preventDefault();
                                                            alert("This feature is under development ");
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </div>
                                <div className="position-relative" style={{ height: "100%" }}>

                                    {showMinMsgs ? (
                                        <>
                                            {/* MSG LIST HERE... */}
                                            <MsgList
                                                isDark={isDark}
                                                updateScrollPos={updateScrollPos}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {/* CHAT LIST HERE... */}
                                            <RecentChatList
                                                isDark={isDark}
                                                showMinMsgs={showMinMsgs}
                                                setShowMinMsgs={setShowMinMsgs}
                                                isExpanded={isExpanded}
                                                updateScrollPos={updateScrollPos}
                                            />
                                        </>
                                    )}

                                </div>
                                {showMinMsgs && (
                                    <div className="text-center pt-1 px-2">
                                        {/* CONNECT MSG INPUT */}
                                        <Row className="g-1 p-0">
                                            <Col>
                                                <Form.Control
                                                    placeholder="what's on your mind?..."
                                                    className={
                                                        (isDark ? "bg-md-dark border-0 " : "") + "text-light m-0"
                                                    }
                                                    size=""
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                    label="Post your message..."
                                                    iconSize={22}
                                                    iconName="checkbox-outline"
                                                    iconClasses=""
                                                    btnClasses="rounded-0"
                                                    handleClick={(e) => {
                                                        e.preventDefault();
                                                        setShowMinMsgs(false);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </>
        </div >
    );
};

// ChatWindow.propTypes = {

// }

// ChatWindow.defaultProps = {

// };

export default ChatWindow;