import React, { useEffect, useState, useRef, } from "react";
import Cookies from 'universal-cookie';
import base64 from 'base-64';
import gtag from 'ga-gtag';
import axios from "axios";
import { Routes, Route, useNavigate, Link, } from "react-router-dom";
import { ToastContainer, Toast, Row, Col, Badge, Button } from 'react-bootstrap';
import {
  isDesktop,
  isMobileOnly,
  isMobile, //tablet and handheld
  //isTablet,  //tablet only
  //useMobileOrientation
} from 'react-device-detect';
import { initializeApp, } from "firebase/app";
import {
  getAuth,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
  updateProfile,
} from "firebase/auth";

import Config from "./data/Config";
import LoadingOverlayFixed from "./components/shared/LoadingOverlayFixed";
import BtnIcon from "./components/buttons/BtnIcon";
import BtnStd from "./components/buttons/BtnStd";
import MainLayout from "./components/shared/MainLayout";
import MainModal from "./components/shared/MainModal";
import ConnectWindow from "./components/connect/ConnectWindow";
import AppNameInline from "./components/text/AppNameInline";
import AppNameInlineIcon from "./components/text/AppNameInlineIcon";
import BtnStackedLink from "./components/buttons/BtnStackedLink";

import NotificationsMenu from "./components/menus/notifications/NotificationsMenu";
import Login from "./components/shared/login";
import Logout from "./components/shared/logout";
import CreateAcct from "./components/shared/CreateAcct";
import AppSetings from "./components/menus/settings/AppSetings";
//import ErrorOverlayAbsolute from "./components/shared/ErrorOverlayAbsolute";

import Error404 from "./screens/Error404";

import Home from "./screens/Home";
import News from "./screens/News";
import Contact from "./screens/Contact";


// PROMO SUCCESS
import PromotionSuccess from "./screens/promotions/PromotionSuccess";

//RTIC Promo
import RTICPromotionForm from "./screens/promotions/rtic/RTICPromotionForm";

import HubIntro from "./screens/HubIntro";
import ShopIntro from "./screens/ShopIntro";
import LinkIntro from "./screens/LinkIntro";
import ConnectIntro from "./screens/ConnectIntro";
import ConnectLiveIntro from "./screens/ConnectLiveIntro";
import FollowIntro from "./screens/FollowIntro";
import FollowMeIntro from "./screens/FollowMeIntro";
import LinkPartnersIntro from "./screens/LinkPartnersIntro";

//QR CODE SCREENS
import Admin from "./screens/codes/Admin";
import Friends from "./screens/codes/Friends";
import Invest from "./screens/codes/Invest";
import Member from "./screens/codes/Member";
import Now from "./screens/codes/Now";

import HubPreview from "./screens/HubPreview";

import LinkPreview from "./screens/LinkPreview";
import LinkClips from "./screens/LinkClips";
import LinkMedia from "./screens/LinkMedia";
import LinkMembers from "./screens/LinkMembers";
import LinkGroups from "./screens/LinkGroups";
import LinkEvents from "./screens/LinkEvents";
import LinkCalendar from "./screens/LinkCalendar";

import ConnectPreview from "./screens/ConnectPreview";
import ConnectMembers from "./screens/ConnectMembers";
import ConnectGroups from "./screens/ConnectGroups";
import ConnectMedia from "./screens/ConnectMedia";

import ProfilePreview from "./screens/ProfilePreview";
import ProfileAbout from "./screens/ProfileAbout";
import ProfileUploads from "./screens/ProfileUploads";
import ProfileClips from "./screens/ProfileClips";
import ProfileFriends from "./screens/ProfileFriends";
import ProfileGroups from "./screens/ProfileGroups";
import ProfileModal from "./screens/ProfileModal";
import BtnStdLink from "./components/buttons/BtnStdLink";
import ConnectLivePreview from "./screens/ConnectLivePreview";
import LiveWindow from "./components/live/LiveWindow";

const App = () => {

  //STATE OBJECT
  const [_visitor, _setVisitor] = useState({});
  const [_isLoading, _setIsLoading] = useState(true);
  const [_isAuthenticated, _setIsAuthenticated] = useState(null);
  const [_isDark, _setIsDark] = useState(false);
  const [_showToast, _setShowToast] = useState(false);
  const [_toastMsg, _setToastMsg] = useState("");
  const [_showConnectBtn, _setShowConnectBtn] = useState(true);
  const [_showConnectWindow, _setShowConnectWindow] = useState(false);
  const [_showLiveWindow, _setShowLiveWindow,] = useState(false);
  const [_showCreateAcct, _setShowCreateAcct] = useState(false);
  const [_showLogin, _setShowLogin] = useState(false);
  const [_showLogout, _setShowLogout] = useState(false);
  const [_showHistory, _setShowHistory] = useState(false);
  const [_initFirebase, _setInitFirebase] = useState(false);
  const [_showProfileModal, _setShowProfileModal] = useState(false);
  const [_showProfileSelector, _setShowProfileSelector] = useState(false);
  const [_showFlirtModal, _setShowFlirtModal] = useState(false);
  const [_showNotificationsMenu, _setShowNotificationsMenu] = useState(false);
  const [_showSettingsModal, _setShowSettingsModal] = useState(false);

  //_showProfileSelector
  //APP CONFIGS
  const appVersionHistory = Config.appVersionHistory;
  const appVersion = (appVersionHistory[0]);
  const inviteCodes = Config.inviteCodes;
  const imgPath = Config.imgPath;

  //FOR ROUTE CALLS IN CODE
  const navigate = useNavigate();

  //CHECK FOR SCANNED CODE IN URL
  const navSearch = useRef(window.location.search);
  const navParams = useRef(new URLSearchParams(navSearch.current));
  const navCode = useRef(navParams.current.get("code"));

  //FIREBASE AUTH
  const firebaseConfig = useRef(null);
  const firebaseApp = useRef(null);
  const firebaseAuth = useRef(null);
  const firebaseUser = useRef(null);
  const visitorSet = useRef(false);

  const cookies = useRef(new Cookies());
  const cookieOptions = {
    maxAge: 86400,
    sameSite: true,
    path: window.origin.replace("www.", "")
  }

  useEffect(() => {

    if (isDesktop) {
      document.body.classList.add("isDesktop");
    } else {
      document.body.classList.remove("isDesktop");
    }
  });

  useEffect(() => {

    const themeCookie = cookies.current.get("theme");

    if (themeCookie === "light") {

      _isDark && _setIsDark(false);

    } else if (themeCookie === "dark") {

      !_isDark && _setIsDark(true);

    } else if (!themeCookie) {

      !_isDark && _setIsDark(true);
    }

  }, [_isDark, cookies]);

  const setDark = () => {

    if (_isDark) {

      _setIsDark(false);
      cookies.current.set("theme", "light", cookieOptions.current);

    } else {

      _setIsDark(true);
      cookies.current.set("theme", "dark", cookieOptions.current);
    }

  };

  useEffect(() => {

    if (navCode.current) _setShowCreateAcct(true);

  }, [navSearch, navParams, navCode, _showCreateAcct]);

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {

        let data = response.data;
        _setVisitor(data);

      })
      .catch((error) => {
        console.log(error);
      });
  };

  const gaSetVisitor = () => {

    gtag('set', {
      'country': _visitor.country,
      'ip_address': _visitor.ip
    });

  };
  //Generic effect
  useEffect(() => {

    if (!visitorSet.current) {
      getGeoInfo();

      visitorSet.current = true;
    }

    if (_visitor !== {}) gaSetVisitor();

  });

  const checkCodes = (codeEntered) => {

    let urlCode = navCode.current && base64.decode(navCode.current);

    for (let i = 0; i < inviteCodes.length; i++) {

      if (codeEntered === inviteCodes[i] || urlCode === inviteCodes[i]) {

        return true;
      }

    }

    return false;

  }

  //Account effect
  useEffect(() => {

    if (!_initFirebase && !firebaseConfig.current && !firebaseApp.current && !firebaseAuth.current && !firebaseUser.current) {

      firebaseConfig.current = {
        apiKey: "AIzaSyCpfnlOf7p3jPyXHG6tge0VnZ7dK2FDJ7k",
        authDomain: "onebadappleprototype.firebaseapp.com",
        projectId: "onebadappleprototype",
        storageBucket: "onebadappleprototype.appspot.com",
        messagingSenderId: "804986515589",
        appId: "1:804986515589:web:1d91bf5b63f2671196f9ec",
        measurementId: "G-BZKH3R6H99"
      };

      firebaseApp.current = initializeApp(firebaseConfig.current);

      firebaseAuth.current = getAuth(firebaseApp.current);

      if (window.location.host.includes("localhost")) connectAuthEmulator(firebaseAuth.current, "http://localhost:9099");

      if (!_initFirebase) _setInitFirebase(true);

      onAuthStateChanged(firebaseAuth.current, (user) => {

        firebaseUser.current = user;

        const loc = window.location.pathname;

        if (user) {

          setTimeout(() => {
            _setIsAuthenticated(true);
            _setIsLoading(false);
          }, 500);

          if (loc === "/") navigate("/hub", { replace: true });

          setPersistence(firebaseAuth.current, browserSessionPersistence)
            .then(() => {

              // setEmail("");
              // setPassword("");

            })
            .catch((error) => {

              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(`[Error] Code: ${errorCode} Msg: ${errorMessage}`);

            });

        } else {

          setTimeout(() => {
            _setIsAuthenticated(false);
            _setIsLoading(false);
          }, 1500);

          if (loc === "/") {
            navigate("/", { replace: true });
          }


        }
      });

    }

  }, [_initFirebase, firebaseConfig, firebaseApp, firebaseAuth, _isAuthenticated, navigate]);

  const validateEmailPassword = () => {

    return true;

  };

  const createAccount = (email, password, name, inviteCodeEntered) => {

    if (!validateEmailPassword()) {

      return;

    }

    if (navCode.current) {

      inviteCodeEntered = base64.decode(navCode.current);
      navCode.current = null;

    }

    createUserWithEmailAndPassword(firebaseAuth.current, email, password, name, inviteCodeEntered)
      .then((userCredential) => {

        const user = userCredential.user
        showToastMsg(`${user.email} logged in`);

        gtag('event', 'sign_up', {
          method: 'Email/Password [web]',
          invite_code: `[${inviteCodeEntered}]`
        });

        gtag('event', 'login', {
          method: `email/password [web]`
        });

        _setShowCreateAcct(false);

        updateProfile(user, {

          displayName: `${name} [${inviteCodeEntered}]`,
          photoURL: `${imgPath}logos/icon_on_black.png`,

        }).then(() => {

          //

        }).catch((error) => {

          const errorCode = error.code;
          const errorMessage = error.message;

          console.log(`[Error] Code: ${errorCode} Msg: ${errorMessage}`);
        });

      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log(`[Error] Code: ${errorCode} Msg: ${errorMessage}`);
      });

  }

  const acctSignIn = (email, password) => {

    if (!validateEmailPassword()) {

      return;

    }

    signInWithEmailAndPassword(firebaseAuth.current, email, password)
      .then((userCredential) => {

        const user = userCredential.user
        gtag('event', 'login', {
          method: 'email/password [web]',
        });

        showToastMsg(`${user.email} logged in`);

        navigate("/hub", { replace: true });

      })
      .catch((error) => {

        const errorCode = error.code;
        const errorMessage = error.message;

        console.log(`[Error] Code: ${errorCode} Msg: ${errorMessage}`);
      });

  };

  const acctSignOut = () => {

    signOut(firebaseAuth.current).then(() => {

      gtag('event', 'logout');

      showToastMsg("You have logged out");

      _setIsAuthenticated(false);

      firebaseUser.current = null;

      navigate("/", { replace: true });

    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      console.log(`[Error] Code: ${errorCode} Msg: ${errorMessage}`);
    });

  };
  //Toast effect
  useEffect(() => {

    if (!_showToast) {

      _setToastMsg("");

    }

  }, [_showToast]);

  const showToastMsg = (msg) => {

    _setToastMsg(msg);

    _setShowToast(true);

  };

  const hideToastMsg = () => {

    _setToastMsg("");
    _setShowToast(false);

  };

  const toggleChat = (closeWindow) => {

    _setShowConnectBtn(closeWindow === true ? true : !_showConnectBtn);
    _setShowConnectWindow(closeWindow === true ? false : !_showConnectWindow);

  };

  const toggleLive = (closeWindow) => {

    _setShowConnectBtn(closeWindow === true ? true : !_showConnectBtn);
    _setShowLiveWindow(closeWindow === true ? false : !_showLiveWindow);

  };

  return (
    <>
      {_isLoading ? (
        <LoadingOverlayFixed
          msgText="initializing settings..."
          msgTextBsColor="text-light"
          spinnerType="grow"
          spinnerBsVariant="light"
          spinnerBsSize="sm"
          fontSize=""
          appName="home"
          showAppNameCopyright={false}
          showAppLogo={false}
          showOBALogo={true}
        />
      ) : (
        <>
          <MainLayout
            isAuthenticated={_isAuthenticated}
            appVersion={appVersion}
            isDark={_isDark}
            setIsDark={setDark}
            setShowHistory={_setShowHistory}
            setShowLogout={_setShowLogout}
            setShowLogin={_setShowLogin}
            setShowCreateAcct={_setShowCreateAcct}
            setShowProfileModal={_setShowProfileModal}
            toggleChat={toggleChat}
            toggleLive={toggleLive}
            user={firebaseUser.current}
            setShowNotificationsMenu={_setShowNotificationsMenu}
            showNotificationsMenu={_showNotificationsMenu}
            setShowSettingsModal={_setShowSettingsModal}
            setShowProfileSelector={_setShowProfileSelector}
          >

            {/* VISITOR INTRO SCREENS */}
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    {_isAuthenticated ? (
                      <HubIntro isAuthenticated={_isAuthenticated} />
                    ) : (
                      <Home />
                    )}

                  </>
                }
              />
              <Route path="/home" element={<Home />} />
              <Route path="/news" element={<News />} />
              <Route path="/contact" element={<Contact isAuthenticated={_isAuthenticated} />} />

              <Route path="/promotions/success" element={<PromotionSuccess />} />

              <Route path="/promotions/free-rtic/signup" element={<RTICPromotionForm />} />

              <Route path="/code/admin" element={<Admin />} />
              <Route path="/code/friends" element={<Friends />} />
              <Route path="/code/invest" element={<Invest />} />
              <Route path="/code/member" element={<Member />} />
              <Route path="/code/now" element={<Now />} />

              {/* APP INTRO SCREENS */}
              <Route path="/hub" element={<HubIntro isAuthenticated={_isAuthenticated} />} />
              <Route path="/shop" element={<ShopIntro isAuthenticated={_isAuthenticated} />} />
              <Route path="/link" element={<LinkIntro isAuthenticated={_isAuthenticated} />} />
              <Route path="/connect" element={<ConnectIntro isAuthenticated={_isAuthenticated} />} />
              <Route path="/live" element={<ConnectLiveIntro isAuthenticated={_isAuthenticated} />} />
              <Route path="/follow" element={<FollowIntro isAuthenticated={_isAuthenticated} />} />
              <Route path="/me" element={<FollowMeIntro isAuthenticated={_isAuthenticated} />} />
              <Route path="/partners" element={<LinkPartnersIntro isAuthenticated={_isAuthenticated} />} />

              {/* HUB PREVIEW SCREEN */}
              <Route path="/hub/preview" element={
                <HubPreview
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                />
              }
              />

              <Route path="/link/preview" element={
                <LinkPreview
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                />
              }
              />
              <Route path="/link/calendar" element={
                <LinkCalendar
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                />
              }
              />
              <Route path="/link/events" element={
                <LinkEvents
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                />
              }
              />
              <Route path="/link/clips" element={
                <LinkClips
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                />
              }
              />
              <Route path="/link/pics-vids" element={
                <LinkMedia
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                />
              }
              />
              <Route path="/link/members" element={
                <LinkMembers
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                />
              }
              />
              <Route path="/link/groups" element={
                <LinkGroups
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                />
              }
              />

              <Route path="/connect/preview" element={
                <ConnectPreview
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                  setShowFlirtModal={_setShowFlirtModal}
                  toggleLive={toggleLive}
                  showConnectWindow={_showConnectWindow}
                />
              }
              />

              <Route path="/connect/members" element={
                <ConnectMembers
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                />
              }
              />

              <Route path="/connect/groups" element={
                <ConnectGroups
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                />
              }
              />

              <Route path="/connect/media" element={
                <ConnectMedia
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                />
              }
              />

              <Route path="/live/preview" element={
                <ConnectLivePreview
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  setShowNotificationsMenu={_setShowNotificationsMenu}
                  showNotificationsMenu={_showNotificationsMenu}
                  setShowSettingsModal={_setShowSettingsModal}
                  setShowProfileSelector={_setShowProfileSelector}
                  toggleChat={toggleChat}
                  toggleLive={toggleLive}
                  showConnectWindow={_showConnectWindow}
                  setShowFlirtModal={_setShowFlirtModal}
                />
              }
              />

              <Route path="/profile/activity" element={
                <ProfilePreview
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  toggleChat={toggleChat}
                  toggleLive={toggleLive}
                  setShowFlirtModal={_setShowFlirtModal}
                />
              }
              />

              <Route path="/profile/about" element={
                <ProfileAbout
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  toggleChat={toggleChat}
                  toggleLive={toggleLive}
                  setShowFlirtModal={_setShowFlirtModal}
                />
              }
              />

              <Route path="/profile/clips" element={
                <ProfileClips
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  toggleChat={toggleChat}
                  toggleLive={toggleLive}
                  setShowFlirtModal={_setShowFlirtModal}
                />
              }
              />

              <Route path="/profile/uploads" element={
                <ProfileUploads
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  toggleChat={toggleChat}
                  toggleLive={toggleLive}
                  setShowFlirtModal={_setShowFlirtModal}
                />
              }
              />

              <Route path="/profile/friends" element={
                <ProfileFriends
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  toggleChat={toggleChat}
                  toggleLive={toggleLive}
                  setShowFlirtModal={_setShowFlirtModal}
                />
              }
              />

              <Route path="/profile/groups" element={
                <ProfileGroups
                  user={firebaseUser.current}
                  isAuthenticated={_isAuthenticated}
                  isDark={_isDark}
                  setShowProfileModal={_setShowProfileModal}
                  toggleChat={toggleChat}
                  toggleLive={toggleLive}

                  setShowFlirtModal={_setShowFlirtModal}
                />
              }
              />

              <Route path="*" element={<Error404 />} />

            </Routes>
          </MainLayout>
        </>

      )}

      <NotificationsMenu
        showNotificationsMenu={_showNotificationsMenu}
        setShowNotificationsMenu={_setShowNotificationsMenu}
        user={firebaseUser.current}
      />

      {_showSettingsModal && (
        <MainModal
          showNav={true}
          isDark={_isDark}
          removeNaveSpacing={false}
          bsModalSize=""
          dialogClassName="container-600"
          contentClassName={"rounded-0"}
          modalColor={`${_isDark ? "bg-md-dark border border-dark text-light" : "bg-white border border-secondary text-dark"}`}
          showModal={true}
          closeModal={() => {
            _setShowSettingsModal(false);
          }}
          showLoading={true}
          loadingMsg="Loading settings..."
        >
          <AppSetings
            isDark={_isDark}
            isMobileOnly={isMobileOnly}
            setShowSettingsModal={_setShowSettingsModal}
            showToastMsg={showToastMsg}

          />
        </MainModal>
      )}

      {_showFlirtModal && (
        <MainModal
          showNav={true}
          isDark={_isDark}
          removeNaveSpacing={false}
          modalColor={`${_isDark ? "bg-md-dark border border-dark text-light" : "bg-white border border-secondary text-dark"}`}
          bsModalSize=""
          dialogClassName="container-400"
          contentClassName={`rounded-0`}
          showModal={true}
          closeModal={() => {
            _setShowFlirtModal(false);
            showToastMsg(<><strong>Flirt:</strong> Sent!</>);
          }}
          showLoading={false}
        >
          <div className="mx-4">
            <h5 className="text-start mb-2">Flirt</h5>
            <p className="mb-2" style={{ fontSize: ".9rem" }}>
              Show your interest and get notified of the response.
            </p>
            <Row className="gy-2 mb-3">
              <Col lg="12">
                <div className="d-grid">
                  <BtnStackedLink
                    bsVariant={_isDark ? "dark" : "light"}
                    bsSize={"sm"}
                    alignLeft={true}
                    btnClasses="rounded-pill px-3 py-2"
                    label={<>Say Hi!</>}
                    sublabel={"Let them know you were here"}
                    iconSize={24}
                    useEmoji={true}
                    iconName="emoji-wave"
                    useLink={false}
                    handleClick={(e) => {
                      _setShowFlirtModal(false);
                      showToastMsg(<><strong>Flirt:</strong> Sent!</>);
                    }}
                  />
                </div>
              </Col>
              <Col lg="12">
                <div className="d-grid">
                  <BtnStackedLink
                    bsVariant={_isDark ? "dark" : "light"}
                    bsSize={"sm"}
                    alignLeft={true}
                    btnClasses="rounded-pill px-3 py-2"
                    label={<>Defintely Interested</>}
                    sublabel={"Show them you are interested"}
                    iconSize={24}
                    useEmoji={true}
                    iconName="emoji-wink"
                    useLink={false}
                    handleClick={(e) => {
                      _setShowFlirtModal(false);
                      showToastMsg(<><strong>Flirt:</strong> Sent!</>);
                    }}
                  />
                </div>
              </Col>
              <Col lg="12">
                <div className="d-grid">
                  <BtnStackedLink
                    bsVariant={_isDark ? "dark" : "light"}
                    bsSize={"sm"}
                    alignLeft={true}
                    btnClasses="rounded-pill px-3 py-2"
                    label={<>On Fire!</>}
                    sublabel={"Tell them they are hot"}
                    iconSize={24}
                    useEmoji={true}
                    iconName="emoji-fire"
                    useLink={false}
                    handleClick={(e) => {
                      _setShowFlirtModal(false);
                      showToastMsg(<><strong>Flirt:</strong> Sent!</>);
                    }}
                  />
                </div>
              </Col>

              <Col lg="12">
                <div className="d-grid">
                  <BtnStackedLink
                    bsVariant={_isDark ? "dark" : "light"}
                    bsSize={"sm"}
                    alignLeft={true}
                    btnClasses="rounded-pill px-3 py-2"
                    label={<>Unlock</>}
                    sublabel={"Unlock your private uploads"}
                    iconSize={24}
                    useEmoji={true}
                    iconName="emoji-unlock"
                    useLink={false}
                    handleClick={(e) => {
                      _setShowFlirtModal(false);
                      showToastMsg(<><strong>Flirt:</strong> Sent!</>);
                    }}
                  />
                </div>
              </Col>
              <Col lg="12">
                <h5 className="text-start my-2">Request</h5>
              </Col>
              <Col lg="12">
                <div className="d-grid">
                  <BtnStackedLink
                    bsVariant={_isDark ? "dark" : "light"}
                    bsSize={"sm"}
                    alignLeft={true}
                    btnClasses="rounded-pill border border-2 border-primary px-3 py-2"
                    label={<>Add Friend</>}
                    sublabel={"Request a friend link"}
                    iconSize={24}
                    useEmoji={false}
                    iconName="add-circle-outline"
                    useLink={false}
                    handleClick={(e) => {
                      _setShowFlirtModal(false);
                      showToastMsg(<><strong>Friend Request:</strong> Sent!</>);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </MainModal>
      )}

      {_showProfileModal && (
        <MainModal
          showNav={true}
          isDark={_isDark}
          removeNaveSpacing={true}
          modalColor={`${_isDark ? "bg-dark text-light" : "bg-light text-dark"} shadow`}
          bsModalSize=""
          dialogClassName="container-700"
          contentClassName={`rounded-0${_isDark ? " border border-dark" : " border border-secondary"}`}
          showModal={true}
          closeModal={() => {
            _setShowProfileModal(false);
            //showToastMsg(<><strong>Profile:</strong> Updated!</>);
          }}
          showLoading={true}
          loadingMsg="Loading profile preview..."
        >
          <ProfileModal
            setShowProfileModal={_setShowProfileModal}
            user={firebaseUser.current}
            isAuthenticated={_isAuthenticated}
            isDark={_isDark}
          />
        </MainModal>
      )}

      {_showProfileSelector && (
        <MainModal
          showNav={true}
          isDark={_isDark}
          removeNaveSpacing={false}
          modalColor={`${_isDark ? "bg-dark text-light" : "bg-light text-dark"} shadow`}
          bsModalSize=""
          dialogClassName="container-800"
          contentClassName={`pb-5 rounded-0${_isDark ? " border border-dark" : " border border-secondary"}`}
          showModal={true}
          closeModal={() => {
            _setShowProfileSelector(false);
            _setShowFlirtModal(false);
            showToastMsg(<><strong>Profile:</strong> Updated!</>);
          }}
          showLoading={true}
          loadingMsg="Loading profile selector..."
        >
          <section className="d-flex align-items-center justify-content-center" style={{ minHeight: 260 }}>
            <div className="w-100">
              <h4 className="text-center mb-3">Profile Selector</h4>
              <div className="text-center pb-3">
                <BtnStdLink
                  bsVariant={_isDark ? "outline-light" : "outline-dark"}
                  bsSize={"sm"}
                  btnClasses="rounded-pill shadow-sm pe-3"
                  iconClasses=""
                  label={<>Add A Profile</>}
                  iconSize={20}
                  iconName="add-circle-outline"
                  useLink={true}
                  href="/signup/freetrial"
                  target=""
                />
              </div>
              <Row className="align-items-center justify-content-center gx-5">
                <Col className="text-center mb-3 mb-sm-0" xs="12" sm="auto">
                  <h6 className="mb-1 text-muted">Primary</h6>
                  <Link
                    to="/profile/activity"
                  >
                    <img
                      src={firebaseUser.current ? firebaseUser.current.photoURL : ""}
                      className="img-fluid rounded-circle shadow border border-2 border-primary"
                      alt={
                        "Profile picture for " + (firebaseUser.current ? firebaseUser.current.email : "")
                      }
                      style={{
                        pointerEvents: "auto",
                        cursor: "pointer",
                        border: "2px solid rgba(0,0,0,.8)",
                        width: 180,
                      }}
                    />
                  </Link>
                  <h4
                    className="mb-1 mt-2"
                  >
                    One.Bad.Apple
                  </h4>
                  <Row className="align-items-center justify-content-center pt-1 gx-2 gx-lg-3">
                    <Col xs="auto">
                      <Button
                        type="button"
                        title={"Profile edit"}
                        variant={_isDark ? "primary" : "primary"}
                        className="d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0"
                        style={{
                          fontSize: isMobileOnly ? 22 : 28,
                          pointerEvents: "none",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/profile/activity/#edit");
                        }}
                      >
                        <span
                          className={""}
                          style={{
                            lineHeight: "1em",
                            height: "1em",
                          }}
                        >
                          <ion-icon name={"checkmark-circle-outline"}></ion-icon>
                        </span>
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button
                        type="button"
                        title={"Profile edit"}
                        variant={_isDark ? "icon-light" : "icon-dark"}
                        className="d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0"
                        style={{
                          fontSize: isMobileOnly ? 22 : 28,
                          pointerEvents: "auto"
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/profile/activity/#edit");
                        }}
                      >
                        <span
                          className={""}
                          style={{
                            lineHeight: "1em",
                            height: "1em",
                          }}
                        >
                          <ion-icon name={"create-outline"}></ion-icon>
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col className="text-center" lg="auto">
                  {/* <h6 className="mb-1 text-muted">Him</h6> */}
                  <Link
                    to="/profile/activity"
                  >
                    <img
                      src={firebaseUser.current ? firebaseUser.current.photoURL : ""}
                      className={"img-fluid rounded-circle shadow border border-2" + (_isDark ? " border-light" : " border-dark")}
                      alt={
                        "Profile picture for " + (firebaseUser.current ? firebaseUser.current.email : "")
                      }
                      style={{
                        pointerEvents: "auto",
                        cursor: "pointer",
                        border: "2px solid rgba(0,0,0,.8)",
                        width: 140
                      }}
                    />
                  </Link>
                  <h4
                    className="mb-1 mt-2"
                  >
                    The.Dude
                  </h4>
                  <Row className="align-items-center justify-content-center pt-1 gx-2 gx-lg-3">
                    <Col xs="auto">
                      <Button
                        type="button"
                        title={"Profile edit"}
                        variant={_isDark ? "icon-light" : "icon-dark"}
                        className="d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0"
                        style={{
                          fontSize: isMobileOnly ? 22 : 28,
                          pointerEvents: "auto",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/profile/activity/#edit");
                        }}
                      >
                        <span
                          className={""}
                          style={{
                            lineHeight: "1em",
                            height: "1em",
                          }}
                        >
                          <ion-icon name={"checkmark-circle-outline"}></ion-icon>
                        </span>
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button
                        type="button"
                        title={"Profile edit"}
                        variant={_isDark ? "icon-light" : "icon-dark"}
                        className="d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0"
                        style={{
                          fontSize: isMobileOnly ? 22 : 28,
                          pointerEvents: "auto"
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/profile/activity/#edit");
                        }}
                      >
                        <span
                          className={""}
                          style={{
                            lineHeight: "1em",
                            height: "1em",
                          }}
                        >
                          <ion-icon name={"create-outline"}></ion-icon>
                        </span>
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button
                        type="button"
                        title={"Profile edit"}
                        variant={_isDark ? "icon-light" : "icon-dark"}
                        className="d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0"
                        style={{
                          fontSize: isMobileOnly ? 22 : 28,
                          pointerEvents: "auto"
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/profile/activity/#edit");
                        }}
                      >
                        <span
                          className={""}
                          style={{
                            lineHeight: "1em",
                            height: "1em",
                          }}
                        >
                          <ion-icon name={"remove-circle-outline"}></ion-icon>
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Col className="text-center" lg="auto">
                  {/* <h6 className="mb-1 text-muted">Her</h6> */}
                  <Link
                    to="/profile/activity"
                  >
                    <img
                      src={firebaseUser.current ? firebaseUser.current.photoURL : ""}
                      className={"img-fluid rounded-circle shadow border border-2" + (_isDark ? " border-light" : " border-dark")}
                      alt={
                        "Profile picture for " + (firebaseUser.current ? firebaseUser.current.email : "")
                      }
                      style={{
                        pointerEvents: "auto",
                        cursor: "pointer",
                        border: "2px solid rgba(0,0,0,.8)",
                        width: 140
                      }}
                    />
                  </Link>
                  <h4
                    className="mb-1 mt-2"
                  >
                    The.Hottie
                  </h4>
                  <Row className="align-items-center justify-content-center pt-1 gx-2 gx-lg-3">
                    <Col xs="auto">
                      <Button
                        type="button"
                        title={"Profile edit"}
                        variant={_isDark ? "icon-light" : "icon-dark"}
                        className="d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0"
                        style={{
                          fontSize: isMobileOnly ? 22 : 28,
                          pointerEvents: "auto",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/profile/activity/#edit");
                        }}
                      >
                        <span
                          className={""}
                          style={{
                            lineHeight: "1em",
                            height: "1em",
                          }}
                        >
                          <ion-icon name={"checkmark-circle-outline"}></ion-icon>
                        </span>
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button
                        type="button"
                        title={"Profile edit"}
                        variant={_isDark ? "icon-light" : "icon-dark"}
                        className="d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0"
                        style={{
                          fontSize: isMobileOnly ? 22 : 28,
                          pointerEvents: "auto"
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/profile/activity/#edit");
                        }}
                      >
                        <span
                          className={""}
                          style={{
                            lineHeight: "1em",
                            height: "1em",
                          }}
                        >
                          <ion-icon name={"create-outline"}></ion-icon>
                        </span>
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button
                        type="button"
                        title={"Profile edit"}
                        variant={_isDark ? "icon-light" : "icon-dark"}
                        className="d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0"
                        style={{
                          fontSize: isMobileOnly ? 22 : 28,
                          pointerEvents: "auto"
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/profile/activity/#edit");
                        }}
                      >
                        <span
                          className={""}
                          style={{
                            lineHeight: "1em",
                            height: "1em",
                          }}
                        >
                          <ion-icon name={"remove-circle-outline"}></ion-icon>
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="text-end px-4 pt-4">
                <BtnStdLink
                  bsVariant={_isDark ? "light" : "dark"}
                  bsSize={"sm"}
                  btnClasses="rounded-pill shadow-sm pe-3 me-2"
                  iconClasses=""
                  label={<>Cancel</>}
                  iconSize={20}
                  iconName="remove-circle-outline"
                  useLink={false}
                  handleClick={() => {
                    _setShowProfileSelector(false);

                  }}
                />
                <BtnStdLink
                  bsVariant="dark"
                  bsSize={"sm"}
                  btnClasses="rounded-pill border border-2 border-primary text-light shadow-sm pe-3"
                  iconClasses=""
                  label={<><span className="text-primary" style={{ fontWeight: 600 }}>Save</span></>}
                  iconSize={20}
                  iconName="arrow-redo-sharp"
                  useLink={false}
                  handleClick={() => {

                    showToastMsg(<><strong>Profile:</strong> Updated!</>);
                    _setShowProfileSelector(false);

                  }}
                />
              </div>
            </div>
          </section>
        </MainModal>
      )}

      {_showCreateAcct && (
        <CreateAcct
          createAccount={createAccount}
          setShowCreateAcct={_setShowCreateAcct}
          checkCodes={checkCodes}
        />
      )}

      {_showLogin && (
        <Login
          setShowLogin={_setShowLogin}
          handleSubmit={acctSignIn}
        />
      )}

      {_showLogout && (
        <>
          <Logout
            setShowLogout={_setShowLogout}
            acctSignOut={acctSignOut}
          />
        </>
      )}

      {(_isAuthenticated && _showConnectBtn) && (
        <div
          className="connect-btn position-fixed bottom-0 d-flex align-items-center mb-4"
          style={{
            borderRadius: 30,
            right: 15,
            zIndex: 10
          }}>
          <Row className="">
            <Col className="position-relative">
              <BtnStd
                bsVariant="dark"
                bsSize={!isMobileOnly ? "" : ""}
                btnClasses="rounded-pill pe-3 pe-sm-3 ps-3 ps-sm-3 border border-light bg-md-dark text-light shadow"
                label={null}
                iconSize={isMobile ? 22 : 26}
                iconName="videocam-outline"
                handleClick={() => {

                  _setShowConnectBtn(false);
                  _setShowLiveWindow(true);
                  //alert("This feature is under development.")
                }}
              />
              <Badge className="position-absolute rounded-pill shadow-sm border border-light text-dark" style={{ top: 0, right: 2, fontSize: ".65rem" }} bg="primary">1</Badge>
            </Col>
            <Col className="position-relative">
              <BtnStd
                bsVariant="dark"
                bsSize={!isMobileOnly ? "" : ""}
                btnClasses="rounded-pill pe-3 pe-sm-3 ps-3 ps-sm-3 border border-light bg-md-dark text-light shadow"
                label={isDesktop ? <AppNameInline name="connect" fontWeight={400} /> : null}
                iconSize={isMobile ? 22 : 26}
                iconName="chatbubbles-outline"
                handleClick={() => {
                  toggleChat();
                }}
              />
              <Badge className="position-absolute rounded-pill shadow-sm border border-light text-dark" style={{ top: 0, right: 2, fontSize: ".65rem" }} bg="primary">2</Badge>
            </Col>
          </Row>

        </div>
      )}

      {(_isAuthenticated && _showConnectWindow) && (

        <ConnectWindow
          showConnectWindow={_showConnectWindow}
          toggleChat={toggleChat}
          toggleLive={toggleLive}
        />
      )}

      {(_isAuthenticated && _showLiveWindow) && (

        <LiveWindow
          isDark={_isDark}
          showLiveWindow={_showLiveWindow}
          toggleLive={toggleLive}
        />
      )}

      {(_showHistory) && (
        <>
          <MainModal
            showNav={true}
            isDark={_isDark}
            removeNaveSpacing={false}
            modalColor={`${_isDark ? "bg-dark text-light" : "bg-light text-dark"} shadow`}
            bsModalSize=""
            dialogClassName="container-800"
            contentClassName={`rounded-0${_isDark ? " border border-dark" : " border border-secondary"}`}
            showModal={true}
            closeModal={() => {
              _setShowHistory(false);
            }}
          >
            <div className="px-lg-3 pb-lg-3" style={{ fontSize: 14 }}>
              <h2 className="d-flex justify-content-center mb-3">
                <AppNameInlineIcon iconName="code-working-outline" iconSize={"1.8rem"} name={(appVersion.phase.toLowerCase()) + "." + appVersion.num} showCopyright={false} />
              </h2>
              <Row>
                <Col>
                  <ul className="list-unstyled">
                    {appVersionHistory.slice([0], [19]).map((item, i) => {

                      return (
                        <li key={i} className={`${i === 0 ? _isDark ? "bg-secondary text-light" : "bg-secondary text-light" : ""} border-bottom border-md-dark rounded-1 p-2`}>{item.phase} - {item.num} - {item.description}</li>
                      );

                    })}
                  </ul>
                </Col>
              </Row>
            </div>
          </MainModal>
        </>
      )}

      <ToastContainer
        position={"top-end"}
        className="system-toast position-fixed me-3"
        style={{
          pointerEvents: "none",
          zIndex: 1,
          marginTop: 80,
          width: "100wh",
          maxWidth: 280,
        }}
      >
        <Toast
          onClose={() => {
            hideToastMsg();
          }}
          show={_showToast}
          className={"bg-light-dark text-light rounded-1"}
          delay={7000}
          autohide={true}
          style={{
            fontWeight: 400
          }}
        >
          <div
            className="d-flex align-items-center justify-content-start border-bottom border-light-dark ps-1 pe-1 pt-2 fs-6"
            style={{ fontWeight: 400 }}
          >
            {/* <ion-icon name="information-outline"></ion-icon> */}
            <span className="ms-1 me-auto">System Message</span>
            {/* <small className="pe-1">Now</small> */}
            <BtnIcon
              bsVariant={"icon-light"}
              iconSize={14}
              iconName="remove-circle-outline"
              iconClasses=""
              handleClick={() => {
                hideToastMsg();
              }}
            />
          </div>
          <Toast.Body className="pb-1 py-1 ps-2 pe-2 rounded-1">
            <Row className="justify-content-between align-items-center gx-1">
              <Col>
                {_toastMsg}
              </Col>
            </Row>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default App;
