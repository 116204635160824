import React from "react";
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

const BtnStdLink = ({
    bsVariant,
    bsSize,
    btnClasses,
    label,
    title,
    iconSize,
    iconName,
    iconClasses,
    useLink,
    href,
    target,
    handleClick
}) => {

    return (
        <>
            {useLink === true ? (
                <>
                    <Link className={
                        // eslint-disable-next-line
                        `btn-${bsSize} btn-${bsVariant} ${btnClasses} btn`
                    }
                        to={href}
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            {iconName && iconName !== "" && (
                                <div
                                    className={`${iconClasses && iconClasses !== "" ? iconClasses : ""} pe-1`}
                                    style={{
                                        lineHeight: "1em",
                                        fontSize: iconSize
                                    }}
                                >
                                    <ion-icon name={iconName}></ion-icon>
                                </div>
                            )}
                            <div style={{ fontWeight: 400 }}>{label}</div>
                        </div>
                    </Link>
                </>
            ) : (
                <>
                    <Button                        
                        variant={bsVariant}
                        size={bsSize}
                        className={btnClasses}
                        title={title}
                        style={{
                            pointerEvents: "auto",
                        }}
                        onClick={handleClick}
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            {iconName && iconName !== "" && (
                                <div
                                    className={`${iconClasses && iconClasses !== "" ? iconClasses : ""} pe-1`}
                                    style={{
                                        lineHeight: "1em",
                                        height: "1em",
                                        fontSize: iconSize
                                    }}
                                >
                                    <ion-icon name={iconName}></ion-icon>
                                </div>
                            )}
                            <div>{label}</div>
                        </div>

                    </Button>
                </>
            )}

        </>
    );

};

BtnStdLink.propTypes = {
    bsVariant: PropTypes.string,
    bsSize: PropTypes.string,
    btnClasses: PropTypes.string,
    label: PropTypes.any,
    iconSize: PropTypes.number,
    iconName: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
}

BtnStdLink.defaultProps = {
    bsVariant: "primary",
    bsSize: "",
    btnClasses: "",
    label: "Add Label",
    iconSize: 20,
    iconName: "add-circle-outline",
    href: "",
    target: "",
};

export default BtnStdLink;

