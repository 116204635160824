import React, { useEffect, useState } from "react";
//import PropTypes from 'prop-types';
import { Container, Row, Col, Form, InputGroup, } from 'react-bootstrap';
import { isDesktop } from "react-device-detect";
import FadeIn from 'react-fade-in';
import ScrollAreaVertical from "../../shared/ScrollAreaVertical";
import BtnIcon from "../../buttons/BtnIcon";
import AppNameInlineIcon from "../../text/AppNameInlineIcon";

const SearchMenu = ({
    showSearchMenu,
    setShowSearchMenu,
}) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [showResults, setShowResults] = useState(true);

    useEffect(() => {


        if (!showSearchMenu) {

            if (showResults) setShowResults(false);

            setSearchTerm("");

            return;
        }

        searchTerm.length > 0 && console.log(searchTerm);

        if (searchTerm.length > 2) {

            if (!showResults) setShowResults(true);

        } else {

            if (showResults) setShowResults(false);
        }

    }, [searchTerm, showSearchMenu, showResults]);

    return (
        <>
            <div
                className={`search-menu position-fixed start-0 end-0 bg-light-dark text-light shadow${showSearchMenu ? " show-menu" : ""}`}
                style={{
                    fontSize: ".85rem",
                    zIndex: 90,
                    marginTop: -1,
                    minWidth: 0
                }}
            >
                <Container fluid className="px-1 px-lg-3">
                    <Row className="justify-content-between align-items-center py-2 g-2 g-sm-3">
                        <Col xs="auto" className="d-none d-sm-block">
                            <h5 className="h2 mb-0 text-start">
                                <AppNameInlineIcon name="search" fontWeight={400} showCopyright={false} iconName="search-outline" iconsSize="1.5rem" />
                            </h5>
                        </Col>
                        <Col xs lg="6" className="position-relative">
                            {searchTerm.length > 0 && (
                                <div className="position-absolute end-0 mt-1 me-3" style={{ zIndex: 10 }}>
                                    <BtnIcon
                                        bsVariant={"icon-dark"}
                                        label="clear search..."
                                        iconSize={18}
                                        iconName="close-outline"
                                        iconClasses=""
                                        btnClasses={""}
                                        handleClick={(e) => {
                                            e.preventDefault();
                                            setSearchTerm("");
                                        }}
                                    />
                                </div>
                            )}
                            <InputGroup className="position-relative mb-0">
                                <InputGroup.Text className="bg-light text-dark" id="basic-addon1"><ion-icon name="search-outline"></ion-icon></InputGroup.Text>
                                <Form.Control
                                    placeholder="enter your search..."
                                    type="text"
                                    autoCapitalize="off"
                                    className=""
                                    value={searchTerm}
                                    onChange={(e) => { setSearchTerm(e.target.value) }}
                                />
                            </InputGroup>
                            {showResults && (
                                <div
                                    className="search-menu position-absolute start-0 end-0 shadow bg-light text-dark pt-2 pe-1 me-2"
                                    style={{
                                        top: 46,
                                        height: "100vh",
                                        maxHeight: isDesktop ? "40vh" : "55vh"
                                    }}>
                                    <ScrollAreaVertical>
                                        <FadeIn>
                                            <div>
                                                <div className="text-dark pb-1 ps-2 me-1" style={{ fontSize: "1.2rem" }}>
                                                    <AppNameInlineIcon name="members" fontWeight={500} showCopyright={false} iconName="sync-outline" iconSize="1.4rem" />
                                                </div>
                                                <div className="ps-4 pe-2 pb-2">
                                                    <a href="/" className="d-block px-2 py-1 rounded-1">
                                                        Member results here...
                                                    </a>
                                                    <a href="/" className="d-block px-2 py-1 rounded-1">
                                                        Member results here...
                                                    </a>
                                                    <a href="/" className="d-block px-2 py-1 rounded-1">
                                                        Member results here...
                                                    </a>
                                                    <a href="/" className="d-block px-2 py-1 rounded-1">
                                                        Member results here...
                                                    </a>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-dark pb-1 ps-2 me-1" style={{ fontSize: "1.2rem" }}>
                                                    <AppNameInlineIcon name="posts" fontWeight={500} showCopyright={false} iconName="image-outline" iconSize="1.4rem" />
                                                </div>
                                                <div className="ps-4 pe-2 pb-2">
                                                    <a href="/" className="d-block px-2 py-1 rounded-1">
                                                        Post results here...
                                                    </a>
                                                    <a href="/" className="d-block px-2 py-1 rounded-1">
                                                        Post results here...
                                                    </a>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-dark pb-1 ps-2 me-1" style={{ fontSize: "1.2rem" }}>
                                                    <AppNameInlineIcon name="clips" fontWeight={500} showCopyright={false} iconName="recording-outline" iconSize="1.4rem" />
                                                </div>
                                                <div className="ps-4 pe-2 pb-2">
                                                    <a href="/" className="d-block px-2 py-1 rounded-1">
                                                        Clip results here...
                                                    </a>
                                                    <a href="/" className="d-block px-2 py-1 rounded-1">
                                                        Clip results here...
                                                    </a>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-dark pb-1 ps-2 me-1" style={{ fontSize: "1.2rem" }}>
                                                    <AppNameInlineIcon name="support" fontWeight={500} showCopyright={false} iconName="help-circle-outline" iconSize="1.4rem" />
                                                </div>
                                                <div className="ps-4 pe-2 pb-2">
                                                    <a href="/" className="d-block px-2 py-1 rounded-1">
                                                        Site results here...
                                                    </a>
                                                    <a href="/" className="d-block px-2 py-1 rounded-1">
                                                        Site results here...
                                                    </a>
                                                </div>
                                            </div>

                                        </FadeIn>
                                    </ScrollAreaVertical>
                                </div>
                            )}
                        </Col>
                        <Col xs="auto">
                            <BtnIcon
                                bsVariant={"icon-light"}
                                iconSize={20}
                                iconName="arrow-up-outline"
                                iconClasses=""
                                btnClasses={""}
                                handleClick={(e) => {
                                    setShowSearchMenu(false);
                                }}
                            />
                        </Col>
                    </Row>

                </Container>

            </div>
        </>
    );

};

// SearchMenu.propTypes = {

// }

// SearchMenu.defaultProps = {

// };

export default SearchMenu;