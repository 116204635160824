import React, {useEffect, useRef} from "react";
//import PropTypes from 'prop-types';
import { Row, Col, /*Badge*/ } from 'react-bootstrap';
import gtag from 'ga-gtag';
import Config from "../../data/Config";
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
//import Config from "../../data/Config";
import BtnIcon from "../buttons/BtnIcon";
import BtnStd from "../buttons/BtnStd";
//import BtnRectIcon from "../buttons/BtnRectIcon";
//import FadeIn from 'react-fade-in';
import AppNameInline from "../text/AppNameInline";
import CartList from "./CartList";

const CartWindow = ({
    showCartWindow,
    setShowCartWindow,
}) => {

    const _gtagSent = useRef(false);

    useEffect(() => {
        if (!_gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Cart Preview',
                page_title: 'Cart',
            });

            _gtagSent.current = true;
        }
    }, [_gtagSent]);

    return (
        <>
            <div
                className={(isMobileOnly ? "isMobileOnly " : "") + "nav-offset cart-window position-fixed end-0 top-0 bottom-0 bg-white text-dark shadow" + (showCartWindow ? " show-menu" : "showMenu")}
                style={{
                    zIndex: 50,
                    //right: 0,
                    width: 150,
                    //maxWidth: "calc(100vw - 30px)",
                    //height: "100%",
                    //minHeight: "70vh",
                    //maxHeight: isMobileOnly ? "calc(100vh - 80px)" : "calc(100vh - 53px)"
                }}

            >
                <>
                    <div className="d-flex flex-column" style={{ height: "100%" }}>
                        <div className="bg-light-dark border-bottom border-md-dark text-light pt-1 pb-1">
                            <Row className="justify-content-between align-items-center g-1 ps-3 pe-2">
                                <Col xs="auto">
                                    <h4 className="d-flex align-items-center justify-content-start m-0">
                                        <AppNameInline name="cart" fontWeight={400} showCopyright={false} />
                                    </h4>
                                </Col>
                                <Col xs="auto">
                                    <BtnIcon
                                        bsVariant={"icon-light"}
                                        label="Minimize cart window..."
                                        iconSize={20}
                                        iconName="arrow-forward-outline"
                                        iconClasses=""
                                        btnClasses=""
                                        handleClick={() => {
                                            setShowCartWindow(false);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="bg-light-dark text-light text-center mt-auto pt-1 pb-3 px-2">
                            <div style={{ fontSize: ".85em", fontWeight: 400, lineHeight:"1.2em "}}>Subtotal:<br />$114.96</div>
                            <div className="d-grid pt-2 px-1">
                                <BtnStd
                                    bsVariant="primary"
                                    bsSize={"sm"}
                                    btnClasses="rounded-pill text-dark shadow py-1 justify-content-center"
                                    label={<>Go to cart</>}
                                    iconSize={18}
                                    iconName={"cart-outline"}
                                    handleClick={(e) => {
                                        e.preventDefault();

                                        alert("This feature is under development.");
                                    }}
                                />
                            </div>

                        </div>
                        <CartList
                            showCartWindow={showCartWindow}
                        />

                    </div>
                </>
            </div>
        </>
    );

};

// CartWindow.propTypes = {

// }

// CartWindow.defaultProps = {

// };

export default CartWindow;