import React, { useRef, useEffect } from "react";
//import PropTypes from 'prop-types';
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    //isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import { Row, Col, } from 'react-bootstrap';
import FadeIn from "react-fade-in/lib/FadeIn";

const RecentChatList = ({
    isDark,
    showMinMsgs,
    setShowMinMsgs,
    isExpanded,
    updateScrollPos,
    setShowChatAlert,
    
}) => {

    const scrollArea = useRef(0);

    const shortMsg = "Lorem ipsum dolor sit amet";
    const mediumMsg = "Quisque aliquam ac lectus fringilla consectetur. Quisque vel laoreet sapien. Proin est purus, consectetur sit amet ex in, facilisis rhoncus metus.";
    const longMsg = "Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur augue lectus, pellentesque ac tortor ut, dictum tempor nunc. Proin non lorem ac odio consectetur sollicitudin in mollis nisl. Cras mattis a dui id tincidunt. Donec facilisis sed massa ac lobortis. Nulla in blandit erat. Donec placerat interdum sem ut varius.";

    useEffect(() => {

        updateScrollPos(scrollArea.current, 0, "auto");

    });

    const listItems = () => {

        let list = [];

        for (let i = 0; i < 15; i++) {

            list.push(
                <a
                    key={i}
                    href={"/"}
                    onClick={(e) => {
                        e.preventDefault();
                        if (!showMinMsgs && !isExpanded) {

                            setShowMinMsgs(true);

                        } else {

                            if (setShowChatAlert) {

                                setShowChatAlert(true);

                            } else {

                                alert("This feature is under development ");
                            }

                        }
                    }}
                    className={(isDark ? "text-light " : "text-dark  ") + ((i < 1 && isExpanded) ? "active " : "") + "app-menu-item rounded-2 position-relative d-block py-1 px-1 mb-1"}
                >
                    <div className="position-absolute end-0 top-0 me-1 mt-1">
                        <div className={i < 2 ? "text-primary" : "text-secondary"} title="Unread"><ion-icon name="ellipse-sharp"></ion-icon></div>
                    </div>
                    <Row className="align-items-start g-1 p-1">
                        <Col xs="auto">
                            <ion-icon style={{ fontSize: 30 }} name="person-circle-outline"></ion-icon>
                        </Col>
                        <Col xs="10">
                            <div className="d-inline-block" style={{ fontSize: ".9em" }}>One.Bad.Apple <br className="d-lg-none" />
                                <span className="text-light-gray">8:03 PM, 08/28/22</span>
                            </div>
                            <div title={i % 3 === 0 ? longMsg : i % 2 === 0 ? shortMsg : mediumMsg} className="d-inline-block" style={{ width: "100%", height: "1.3em", whiteSpace: "nowrap", overflow: "clip", textOverflow: "ellipsis", }}>{i % 3 === 0 ? longMsg : i % 2 === 0 ? shortMsg : mediumMsg} </div>
                        </Col>
                    </Row>
                </a>
            );
        }

        return list;

    };

    return (
        <div
            ref={scrollArea}
            className="scroll-div-vertical position-absolute top-0 bottom-0 start-0 end-0 me-1 py-1 pe-2"
            style={{
                overflowX: "hidden",
                overflowY: "auto",
            }}
            onScroll={(e) => {
                e.stopPropagation();
            }}
        >
            <FadeIn delay={25} duration={200}>
                {listItems()}
            </FadeIn>
        </div>
    );

};

export default RecentChatList;