import React, { useState } from "react";
import { Container, } from "react-bootstrap";
import {
    //isDesktop, 
    isMobileOnly,
    //isMobile,
    //isTablet,
} from "react-device-detect";

import LiveBtnsHorizontal from "../profile/LiveBtnsHorizontal";
import CarouselFullWidth from "../../banners/CarouselFullWidth";
import StandardHeaderNav from "../profile/StandardHeaderNav";
import ShortcutMenu from "../menus/shortcutMenu/ShortcutMenu";

const LiveScreenFrameWithProfile = ({
    children,
    isDark,
    user,
    showCarousel,
    showFullWidth,
    setShowProfileModal,
    setShowSettingsModal,
    showNotificationsMenu,
    setShowNotificationsMenu,
    setShowProfileSelector
}) => {

    const [_showShortcutMenu, _setShowShortcutMenu] = useState(false);

    return (
        <>
            <div className={`${isDark ? "bg-md-dark" : "bg-gray-light"} pb-5`}>

                {showCarousel && (
                    <>
                        <CarouselFullWidth
                            interval={10000}
                        />
                    </>
                )}

                <Container
                    fluid
                    className={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} ${showCarousel ? "" : "pt-4"} ${showFullWidth ? "container-1400" : "container-1400"}  shadow-sm pb-5 px-3 px-sm-4`}
                >
                    <div className={(showFullWidth ? "px-4 mx-auto " : "") + "container-1400"}>
                        {/* PROFILE */}
                        <StandardHeaderNav
                            showShortcutMenu={_showShortcutMenu}
                            setShowShortcutMenu={_setShowShortcutMenu}
                            showCarousel={showCarousel}
                            user={user}
                            isDark={isDark}
                            setShowProfileModal={setShowProfileModal}
                            setShowSettingsModal={setShowSettingsModal}
                            showNotificationsMenu={showNotificationsMenu}
                            setShowNotificationsMenu={setShowNotificationsMenu}
                            setShowProfileSelector={setShowProfileSelector}
                        >
                            <LiveBtnsHorizontal
                                isDark={isDark}
                                isMobileOnly={isMobileOnly}
                                setShowSettingsModal={setShowSettingsModal}
                            />
                        </StandardHeaderNav>
                    </div>

                    {children}
                </Container>
            </div>

            <ShortcutMenu
                showShortcutMenu={_showShortcutMenu}
                setShowShortcutMenu={_setShowShortcutMenu}
                user={user}
                isDark={isDark}
            />
        </>
    );

};

export default LiveScreenFrameWithProfile;