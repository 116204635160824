
import React from "react";
import { Row, Col, Badge } from "react-bootstrap";

const ReelPostCard = ({
    isDark,
    //isMobileOnly,
    blur,
    //user,
    status,
    data,
    handleClick,
    //i,

}) => {

    const getStatusColor = () => {

        let color = "";

        switch (status) {

            case "online":
                color = "primary";
                break;
            case "recentlyOnline":
                color = "yellow";
                break;
            case "offline":
                color = "danger";
                break;

            case null:
            case undefined:
            case "unknown":
            default:
                color = "light";
                break;
        }

        return color;
    };

    const getStatusText = () => {

        let text = "";

        switch (status) {

            case "online":
                text = "Online Now...";
                break;
            case "recentlyOnline":
                text = "Recently Online...";
                break;
            case "offline":
                text = "Offline...";
                break;

            case null:
            case undefined:
            case "unknown":
            default:
                text = "Status Unknown...";
                break;
        }

        return text;
    };

    const getStatusIcon = () => {

        let name = "";

        switch (status) {

            case "online":
                name = "checkmark-circle-sharp";
                break;
            case "recentlyOnline":
                name = "time-sharp";
                break;
            case "offline":
                name = "ban-sharp";
                break;

            case null:
            case undefined:
            case "unknown":
            default:
                name = "remove-circle-sharp";
                break;
        }

        return name;
    };

    return (
        <>
            {/* POST CARD */}
            <div className={`${isDark ? "border-light-gray" : "border-light-dark"} reel-post border-top border-3`}>
                <div
                    className={`d-flex justify-content-start pb-1 pt-1`}
                >
                    {data.postedBy !== null ? (
                        <div
                            style={{
                                fontSize: ".75rem",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                maxWidth: "96%",
                            }}
                            title={data.postedBy}
                        >
                            {data.postedBy}
                        </div>
                    ) : (
                        <div className="text-muted" style={{ fontSize: ".75rem", }}>Placeholder</div>
                    )}
                </div>
                <div className={`${isDark ? "bg-md2-dark text-light" : "bg-white text-dark"}`}>

                    {/* IMG CONTAINER */}
                    <div
                        className={`${isDark ? "bg-dark" : "bg-light"} position-relative rounded-1 shadow-sm`}

                    >
                        {data && data.thumbs.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className={`${blur && item.img !== null ? "img-blur" : ""} rounded-1 d-flex align-items-end justify-content-end`}
                                    style={{
                                        aspectRatio: "9 / 16",
                                        backgroundImage: `url(${item.img})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        if (item.img === null) return;
                                        handleClick({
                                            imgs: data.full
                                        });
                                    }}
                                >
                                    {item.img !== null ? (
                                        <>
                                            <div
                                                className="d-flex align-items-center position-absolute top-0 end-0 me-1 mt-1"
                                                style={{
                                                    zIndex: 5,
                                                }}
                                            >
                                                <Badge
                                                    bg={"dark"}
                                                    text={getStatusColor()}
                                                    title={getStatusText()}
                                                    className="rounded-circle shadow-sm"
                                                    style={{
                                                        fontSize: ".85rem",
                                                        paddingTop: 3,
                                                        paddingBottom: 2,
                                                        paddingLeft: 5,
                                                        paddingRight: 5,
                                                    }}
                                                >
                                                    <ion-icon name={getStatusIcon()}></ion-icon>
                                                </Badge>
                                            </div>
                                            <div
                                                className="d-flex align-items-center position-absolute bottom-0 start-0 rounded-1 bg-dark text-light pb-2 pt-2 px-1 ms-1 mb-1"
                                                style={{
                                                    //borderTopLeftRadius: "3px",
                                                    fontSize: ".80rem",
                                                    height: "1.4em"
                                                }}
                                            >
                                                <ion-icon name={"caret-forward-sharp"}></ion-icon> <span className="ms-0" style={{ fontWeight: 400, fontSize: ".70rem", }}>12M</span>
                                            </div>
                                            <div
                                                className="d-flex align-items-center position-absolute bottom-0 end-0 rounded-1 bg-dark text-light pb-2 pt-2 px-1 me-1 mb-1"
                                                style={{
                                                    //borderTopLeftRadius: "3px",
                                                    fontSize: ".80rem",
                                                    height: "1.4em"
                                                }}
                                            >
                                                <ion-icon name="eye-sharp"></ion-icon> <span className="ms-1" style={{ fontWeight: 400, fontSize: ".70rem", }}>2.4K</span>
                                                
                                            </div>
                                            <div
                                                className="d-flex align-items-center justify-content-center position-absolute top-0 end-0 bottom-0 start-0 rounded-1 text-light"
                                                style={{
                                                    fontSize: "2rem",
                                                    zIndex: 5,
                                                    pointerEvents: "none"
                                                }}
                                            >
                                                <ion-icon name={"play-outline"}></ion-icon>
                                            </div>
                                        </>
                                    ) : (
                                        <div
                                            className="d-flex flex-column align-items-center justify-content-center position-absolute top-0 end-0 bottom-0 start-0 rounded-1 text-muted"
                                            style={{
                                                fontSize: "2rem",
                                                zIndex: 5,
                                                pointerEvents: "none",
                                                cursor: "default"
                                            }}
                                        >
                                            <ion-icon name={"film-outline"}></ion-icon>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    {/* END IMG CONTAINER */}
                </div>
                {data.thumbs[0].img && (
                    <div
                        className="pt-2 px-0"
                    >
                        <Row className="align-items-center justify-content-start gx-1">
                            <Col className="d-flex align-items-center" xs="auto">
                                <div className="d-flex align-items-center justify-content-start" style={{ fontSize: "1.25rem" }}>
                                    <div className="text-danger me-0" style={{ fontSize: "1.15rem", height: "1em", lineHeight: "1em" }}>
                                        {/* <ion-icon name="heart-sharp"></ion-icon> */}
                                        <span title="Reactions" role="img" aria-label="heart" className="emoji-fire"></span>
                                    </div>
                                    <span style={{ fontSize: ".75rem", height: "1em", lineHeight: "1em" }}>56</span>
                                </div>
                            </Col>
                            <Col className={(isDark ? "text-light " : "text-dark ") + "d-flex align-items-center justify-content-end ms-1"} xs="auto">
                                <div style={{ fontSize: "1.25rem", height: "1em", lineHeight: "1em" }} className="me-1"><ion-icon name="chatbubble-ellipses-outline"></ion-icon></div>
                                <div style={{ fontSize: ".75rem", height: "1em", lineHeight: "1em" }}>24</div>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>

        </>
    );

}

export default ReelPostCard;