import React from "react";
import { Container, } from "react-bootstrap";
// import {
//     //isDesktop, 
//     isMobileOnly,
//     //isMobile,
//     //isTablet,
// } from "react-device-detect";

// import AppBtnsHorizontal from "../profile/AppBtnsHorizontal";
// import CarouselFullWidth from "../../banners/CarouselFullWidth";
// import StandardHeaderNav from "../profile/StandardHeaderNav";
// import ShortcutMenu from "../menus/shortcutMenu/ShortcutMenu";

const PromoFrame = ({
    children,
}) => {

    return (
        <>
            <div className={`bg-md-dark pb-5`}>

                <Container
                    fluid
                    //className={"bg-light text-dark container-1200 shadow-sm pb-5 px-3 px-sm-4"}
                    className={"bg-light text-dark container-1200 shadow-sm pb-5 px-3 px-sm-5"}
                >
                    {children}
                </Container>
            </div>
        </>
    );

};

export default PromoFrame;