import React from "react";
import {
    Row, Col,
} from "react-bootstrap";
import BtnStd from "../buttons/BtnStd";

const PageSortFilter = ({
    isDark,
}) => {


    return (
        <>
            <Row className="align-items-center justify-content-start flex-nowrap gx-2 mb-2">
                <Col xs="auto">                    
                    <BtnStd
                        bsVariant={isDark ? "dark" : "light"}
                        bsSize={"sm"}
                        btnClasses="rounded-pill border-0 shadow-sm py-1 pe-3"
                        label={"Uploads"}
                        labelSize={null}
                        iconSize={14}
                        iconName="chevron-down-outline"
                        handleClick={(e) => {
                            e.preventDefault();
                            alert("This feature is under development");
                        }}
                    />
                </Col>
                <Col xs="auto">                    
                    <BtnStd
                        bsVariant={isDark ? "dark" : "light"}
                        bsSize={"sm"}
                        btnClasses="rounded-pill border-0 shadow-sm py-1 pe-3"
                        label={"Grouped"}
                        labelSize={null}
                        iconSize={14}
                        iconName="chevron-down-outline"
                        handleClick={(e) => {
                            e.preventDefault();
                            alert("This feature is under development");
                        }}
                    />
                </Col>

                <Col xs="auto" className="ms-auto">
                    <BtnStd
                        bsVariant={isDark ? "dark" : "light"}
                        bsSize={"sm"}
                        btnClasses="rounded-pill border-0 shadow-sm py-1 pe-3"
                        label={"Newest"}
                        labelSize={null}
                        iconSize={14}
                        iconName="chevron-down-outline"
                        handleClick={(e) => {
                            e.preventDefault();
                            alert("This feature is under development");
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PageSortFilter;