import React from "react";
//import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { isMobileOnly } from "react-device-detect";
import FadeIn from "react-fade-in/lib/FadeIn";
import BtnStdLink from "../components/buttons/BtnStdLink";
import OBAName from "../components/text/OBAName";
//import AppNameInlineIcon from "../components/text/AppNameInlineIcon";
import AppNameInline from "../components/text/AppNameInline";

const HomeVisitorContent = ({
    isAuthenticated
}) => {

    return (
        <>
            {/* <section className="px-5 pb-3 text-center" style={{ textShadow: "2px 2px 4px rgba(0,0,0,.1)" }}>
                <Container fluid className="container-1200">
                    <FadeIn>
                        <h1 className="d-flex justify-content-center align-items-center">
                            <AppNameInlineIcon name="info" showCopyright={false} iconName="information-circle-outline" iconsSize="1.25em" />
                        </h1>
                    </FadeIn>
                </Container>
            </section> */}
            <section className="bg-md-dark text-light py-4 py-lg-5">
                <Container fluid className="container-1200">
                    <FadeIn>
                        <h1 className="mt-0">
                            <OBAName /> <AppNameInline name="info" showCopyright={false} />
                        </h1>
                        <p>
                            <OBAName fontWeight={500} /> is a multi-app hub concept that will feature <strong>adults only</strong> products and merchandise from the shop app, social engagement from the link app, entertainment in the link.follow app, all presented in a more couples &amp; lifestyle friendly environment. You can learn more by reading the section below. We will be updating with new feature offerings when available. Our site is currently under development. So, check back soon for more news and updates.
                        </p>
                        <h3 className="pt-3 pb-3">
                            Interested in more info about <OBAName fontWeight={500} />?
                        </h3>
                        <p>
                            <OBAName fontWeight={500} /> is actively engaged in the discovery and model development phase. The one.bad.apple Team is currently connecting with investors, content developers, and general users to understand what type of products, features, offerings and entertainment interests them. If you have interested in investing, partnering, becoming a vendor, or just want more info about our business, contact us using the link below. We will get back to you as soon as possible.
                        </p>
                        <p>
                            Thank you for visiting,<br />
                            <OBAName fontWeight={500} /> Team
                        </p>
                    </FadeIn>
                </Container>
                <div className="mt-3 text-center d-flex justify-content-center">
                    <BtnStdLink
                        bsVariant="primary"
                        bsSize={isMobileOnly ? "" : "lg"}
                        btnClasses="rounded-pill shadow-sm pe-3"
                        label="Contact Us Here!"
                        iconSize={24}
                        iconName="mail-outline"
                        href="mailto:info@onebadapplenow.com"
                        target=""
                    />
                </div>
            </section>
            <section className="bg-light text-dark py-4 py-5">
                <Container fluid className="container-1200">
                    <Row className="align-items-start mb-3">
                        <Col className="pb-3" xs="12" sm="4" lg="5">
                            <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-top-White.png" className="img-fluid" alt="Logo for One Bad Apple" />

                        </Col>
                        <Col className="" xs="12" sm="8" lg="7">
                            <h2 className="mt-2 mb-4">Web, iOS &amp; Android Apps to include:</h2>
                            <ul>

                            </ul>
                            <h3 className="mb-2">Adult Store - <small className="text-sm">Adult Apparel, Merchandise, Lick'er Wagons<sup className="text-xs">&copy;</sup>, Games + More...</small></h3>
                            <ul className="mb-3">
                                <li>
                                    one.bad.apple Branded
                                </li>
                                <li>
                                    Partner/Seller Stores
                                </li>
                            </ul>
                            <h3 className="mb-2">Adult Social App - <small className="text-sm">Free &amp; Subscription</small></h3>
                            <ul className="mb-3">
                                <li>
                                    Private Individual Profiles
                                </li>
                                <li>
                                    Private Couples Profiles
                                </li>
                                <li>
                                    Private Business Profiles
                                </li>
                                <li>
                                    Private Messaging/Chat
                                </li>
                                <li>
                                    Video Messaging/Chat
                                </li>
                                <li>
                                    Events
                                    <ul className="mb-3">
                                        <li>
                                            Public Events
                                        </li>
                                        <li>
                                            Private Individual Events
                                        </li>
                                        <li>
                                            Private Business Events
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <h3 className="mb-2">Private Adult Follow Profile Pages</h3>
                            <ul className="mb-3">
                                <li>
                                    Free to join <small className="text-muted" style={{ fontSize: ".85rem", }}>* limited * pricing/structure TBD </small>
                                </li>
                                <li>
                                    Subscription <small className="text-muted" style={{ fontSize: ".85rem", }}>* unlimited * pricing/structure TBD </small>
                                </li>
                                <li>
                                    In-app content purchases <small className="text-muted" style={{ fontSize: ".85rem", }}>* pricing/structure TBD</small>
                                </li>
                            </ul>
                            <h3 className="mb-2">Single account management</h3>
                            <ul className="mb-3">
                                <li>
                                    All billing info for purchases, subscriptions, and in-app purchases in one place!
                                </li>
                                <li>
                                    Manage purchases, subscriptions, and personal profile information!
                                </li>
                            </ul>

                            <h3 className="mb-2">More to be announced soon...</h3>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );

};

// HomeVisitorContent.propTypes = {

// }

// HomeVisitorContent.defaultProps = {

// };

export default HomeVisitorContent;