import React from "react";
//import Config from "../../data/Config";
import { Card, Col, Row, } from "react-bootstrap";

//import BtnStd from "../../buttons/BtnStd";

const EventCardImage300x250 = ({
    //eventId,
    isDark,
    imgURL,
    vendor,
    imgAltTitle,
    //showBtns,
    handleClick
}) => {

    //const imgPath = Config.imgPath;
    
    return (
        <>
            <Card
                bg={isDark ? "md2-dark" : "white"}
                text={isDark ? "light" : "dark"}
                border={"0"}
                className="rounded-0"
                style={{ height: "auto", cursor: "pointer" }}

            >
                <div className="position-relative">
                    <a href={"/"} onClick={(e) => { e.preventDefault(); handleClick(true) }}>
                        <Card.Img variant="top" src={imgURL} className="rounded-1 shadow-sm" alt={imgAltTitle} />
                    </a>
                    <Row className="flex-column pt-2 pb-1">
                        <Col>
                            <div className="pb-1" style={{ fontSize: ".90rem", lineHeight: "1em" }}>{vendor}</div>
                        </Col>
                        <Col>
                            <div style={{ fontSize: ".80rem", lineHeight: "1em" }}>
                                Sat, 12/12/22
                            </div>                            
                        </Col>
                    </Row>                    
                </div>                
            </Card>
        </>
    );

};

export default EventCardImage300x250;