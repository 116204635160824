import React from "react";
import { Row, Col, } from "react-bootstrap";
import BtnIconBadgeCountLink from "../buttons/BtnIconBadgeCountLink";

const LinkBtnsHorizontal = ({

    isDark,
    isMobileOnly,

}) => {

    return (
        <>
            <Row className="align-items-start justify-content-around gy-3 gx-1 g-lg-0 gx-lg-2 mt-3 mt-lg-0" style={{ fontSize: "1rem", }}>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="activity"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="share-social-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/link/preview"}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="calendar"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="calendar-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/link/calendar"}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="events"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="calendar-number-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/link/events"}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="clips"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="film-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/link/clips"}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="uploads"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="images-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/link/pics-vids"}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="members"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="checkmark-circle-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/link/members"}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="groups"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="sync-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/link/groups"}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="link updates..."
                            visibleText="partners"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="git-compare-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/link/pages"}
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is currently under development.");
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default LinkBtnsHorizontal;