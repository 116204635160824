
import React from "react";
//import PropTypes from 'prop-types';
import {
    //isDesktop, isMobileOnly,
    //isMobile,
    //isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import { Row, Col, } from 'react-bootstrap';
import BtnIcon from "../buttons/BtnIcon";
import AppNameInlineIcon from "../text/AppNameInlineIcon";

const NavTopMain = ({
    expandWindow,
    isExpanded,
    isDark,
    setIsDark,
    toggleChat,
}) => {

    return (
        <Row
            className={
                (isDark ? "border-md-light " : "border-md-dark ") +
                "justify-content-between align-items-center g-1 mx-2 mb-2 pb-2 border-bottom"
            }
        >
            <Col xs="auto">
                <Row className="align-items-center g-1">
                    <Col xs="auto">
                        <h5 className="h2 d-flex align-items-center m-0">
                            <AppNameInlineIcon name="connect" fontWeight={400} showCopyright={false} iconName="chatbubbles-outline" iconSize="1.4rem" />
                        </h5>
                    </Col>
                    <Col xs="auto">
                        <BtnIcon
                            bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                            label="Open the link.connect app..."
                            iconSize={22}
                            iconName="open-outline"
                            iconClasses="text-primary"
                            btnClasses=""
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development ");
                            }}
                        />
                    </Col>
                </Row>

            </Col>
            {/* {isExpanded && (
                <Col xs="5">
                    <Form.Control
                        placeholder="Search messages..."
                        className={
                            (isDark ? "bg-md-dark border-0 " : "") + "text-light m-0"
                        }
                        size="sm"
                    />
                </Col>
            )} */}
            <Col xs="auto">
                <Row className="g-1 align-items-center">
                    {/* <Col xs="auto">
                        <Form.Check
                            type="switch"
                            id="connect-dark-switch"
                            label="Light"
                            className="m-0 me-1"
                            style={{ minHeight: "1em" }}
                            checked={!isDark ? true : false}
                            reverse
                            onChange={(e) => {

                                setIsDark(!isDark);

                            }}
                        />
                    </Col> */}
                    {(!isMobileOnly) && (
                        <Col xs="auto">
                            <BtnIcon
                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                label={isExpanded ? "Minimize window.." : "Expand window..."}
                                iconSize={22}
                                iconName={isExpanded ? "contract-outline" : "expand-outline"}
                                iconClasses=""
                                btnClasses=""
                                handleClick={(e) => {
                                    expandWindow(e);
                                }}
                            />
                        </Col>
                    )}
                    <Col xs="auto">
                        <BtnIcon
                            bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                            label="Close link.connect window..."
                            iconSize={22}
                            iconName="arrow-down-outline"
                            iconClasses=""
                            btnClasses=""
                            handleClick={() => {
                                toggleChat();
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );

};

export default NavTopMain;