import React from "react";
import { Row, Col, Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
//import BtnStdLink from "../buttons/BtnStdLink";
//import BtnIcon from "../buttons/BtnIcon";
//import BtnIconLink from "../buttons/BtnIconLink";

//import BtnStackedLink from "../buttons/BtnStackedLink";

const ImageNameLG = ({
    user,
    isDark,
    isMobileOnly,
    showShortcutMenu,
    setShowShortcutMenu,
    setShowProfileModal,
    setShowNotificationsMenu,
    showNotificationsMenu,
    setShowSettingsModal,
    setShowProfileSelector,
}) => {

    //const [_showSettingsModal, _setShowSettingsModal] = useState(false);
    //const [_activeSettingSection, _setActiveSettingSection] = useState("theme");

    //const navigate = useNavigate();

    return (
        <>
            <Row className="align-items-end gx-3">
                <Col xs="5" sm="3" lg="6" className="position-relative rounded-1">
                    <Link
                        to="/profile/activity"
                    >
                        <img
                            src={user.photoURL}
                            className="img-fluid rounded-circle shadow"
                            alt={
                                "Profile picture for " + user.email
                            }
                            style={{
                                pointerEvents: "auto",
                                cursor: "pointer",
                                border: "2px solid rgba(0,0,0,.8)"
                            }}
                        />
                    </Link>

                </Col>
                <Col
                    xs="7"
                    sm="9"
                    lg="6"
                    style={{
                        //height: isMobileOnly ? "auto" : 110,
                        pointerEvents: "auto",
                    }}
                >
                    <h4
                        style={{
                            cursor: "pointer",
                        }}
                        className="mb-1"
                        onClick={() => {
                            setShowProfileModal(true);
                        }}
                    >
                        One.Bad.Apple
                    </h4>
                    <h6 className="mb-1">Your Status: Online</h6>
                    <h6 className="mb-1">Visible To: Members</h6>
                    <Row className="align-items-center justify-content-start pt-1 gx-2 gx-lg-2">
                        <Col xs="auto">
                            <Button
                                type="button"
                                title={"Profile edit"}
                                variant={isDark ? "icon-light" : "icon-dark"}
                                className="d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0"
                                style={{
                                    fontSize: isMobileOnly ? 22 : 28,
                                    pointerEvents: "auto"
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    
                                    setShowProfileSelector(true);
                                }}
                            >
                                <span
                                    className={""}
                                    style={{
                                        lineHeight: "1em",
                                        height: "1em",
                                    }}
                                >
                                    <ion-icon name={"information-circle-outline"}></ion-icon>
                                </span>
                            </Button>
                        </Col>
                        <Col xs="auto">
                            <Button
                                type="button"
                                title={"Settings menu"}
                                variant={isDark ? "icon-light" : "icon-dark"}
                                className="d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0"
                                style={{
                                    fontSize: isMobileOnly ? 22 : 28,
                                    pointerEvents: "auto"
                                }}
                                onClick={() => {
                                    setShowSettingsModal(true);
                                }}
                            >
                                <span
                                    className={""}
                                    style={{
                                        lineHeight: "1em",
                                        height: "1em",
                                    }}
                                >
                                    <ion-icon name={"cog-outline"}></ion-icon>
                                </span>
                            </Button>
                        </Col>
                        <Col xs="auto">
                            <Button
                                type="button"
                                title={"Shortcut menu"}
                                variant={isDark ? "icon-light" : "icon-dark"}
                                className={`${showShortcutMenu ? "active " : ""}d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0`}
                                style={{
                                    fontSize: isMobileOnly ? 22 : 28,
                                    pointerEvents: "auto"
                                }}
                                onClick={() => {
                                    setShowShortcutMenu(!showShortcutMenu);
                                }}
                            >
                                <span
                                    className={""}
                                    style={{
                                        lineHeight: "1em",
                                        height: "1em",
                                    }}
                                >
                                    <ion-icon name={"reorder-four-outline"}></ion-icon>
                                </span>
                            </Button>
                        </Col>
                        <Col xs="auto">
                            <Button
                                type="button"
                                title={"Notifications..."}
                                variant={isDark ? "icon-light" : "icon-dark"}
                                className={`${showNotificationsMenu ? "active " : ""}d-flex align-items-center justify-content-center position-relative rounded-circle border-0 p-0`}
                                style={{
                                    fontSize: isMobileOnly ? 22 : 28,
                                    pointerEvents: "auto"
                                }}
                                onClick={() => {
                                    setShowNotificationsMenu(!showNotificationsMenu);
                                }}
                            >
                                <div className="position-absolute bottom-0" style={{ zIndex: 1, right: -12, marginBottom: -13 }}>
                                    <Badge
                                        className={`${isDark ? "border-dark" : "border-light"} rounded-pill border shadow-sm`}
                                        style={{ fontSize: ".75rem", pointerEvents: "none" }}
                                        bg={"primary"}
                                        text={isDark ? "dark" : "light"}
                                    >
                                        6
                                    </Badge>
                                </div>
                                <span
                                    className={""}
                                    style={{
                                        lineHeight: "1em",
                                        height: "1em",
                                    }}
                                >
                                    <ion-icon name={"notifications-outline"}></ion-icon>
                                </span>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            
        </>
    );
}

export default ImageNameLG;