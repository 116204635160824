import React, { useRef, useEffect, } from "react";
import {
    //isDesktop,
    //isMobileOnly,
    //isMobile,
    //isTablet,
} from "react-device-detect";
import { Container, /*Row, Col, Form,*/ } from "react-bootstrap";
import OBAName from "../../components/text/OBAName";
import Config from "../../data/Config";

import PromoFrame from "../../components/shared/PromoFrame";

// import Collection from "../../components/collections/Collection";
// import CollectionRow from "../../components/collections/CollectionRow";
// import CollectionColumn from "../../components/collections/CollectionColumn";
//import SliderInsideScrollRow from "../../components/collections/SliderInsideScrollRow";

import gtag from 'ga-gtag';
//import BtnStackedLink from "../../components/buttons/BtnStackedLink";
//import AppNameInline from "../../components/text/AppNameInline";
// import { Link } from "react-router-dom";

const PromotionSuccess = ({
    isAuthenticated,
    isDark,
}) => {

    // const [selectedProduct, setSelectedProduct] = useState(null);
    // const [emailField, setEmailField] = useState("");
    // const [passwordField, setPasswordField] = useState("");
    // const [emailOptIn, setEmailOptIn] = useState(true);
    // const [referedField, setReferedField] = useState(0);

    const gtagSent = useRef(false);

    //const _shopProducts = Config.shopProducts; //Shop data

    useEffect(() => {

        // console.log("Selected product: " + selectedProduct);
        // console.log("Email: " + emailField);
        // console.log("Password: " + passwordField);
        // console.log("Email Optin: " + emailOptIn);
        // console.log("Refered: " + referedField);

        // return () => {
        //     setSelectedProduct(null);
        //     setEmailField("");
        //     setPasswordField("");
        //     setEmailOptIn(true);
        //     setReferedField("0");
        // }

    });

    // const submitForm = () => {

    //     const formData = {
    //         product: selectedProduct,
    //         email: emailField,
    //         password: passwordField,
    //         optin: emailOptIn,
    //         referer: referedField
    //     }

    //     console.log(formData);
    // };

    useEffect(() => {

        if (!gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Email Account Sign Up/Cup Promo',
                page_title: 'Email Account Sign Up/Cup Promo',
            });

            gtagSent.current = true;

        }

    }, [gtagSent]);

    return (
        <>
            <div
                className={
                    `${(true) ? "bg-white text-dark" : ""} d-flex align-items-center shadow nav-offset pt-3 position-relative`
                }
                style={{
                    zIndex: 30,
                    fontWeight: 300,
                }}
            >
                <Container
                    fluid
                    style={{
                        backgroundImage: "url('https://storage.googleapis.com/onebadappleprototype.appspot.com/temp_imgs/abstract-orange-smooth-wave-lines_1940_Gray.jpg')", backgroundRepeat: "no-repeat",
                        backgroundPositionX: "center",
                        backgroundPositionY: "center",
                        backgroundSize: ""
                    }}
                >

                    <div
                        className="text-center pt-4 pb-4"
                        style={{ textShadow: "2px 2px 4px rgba(0,0,0,.1)" }}
                    >
                        <h1 className="mb-4" style={{ fontWeight: 300, fontSize: "3.2rem", lineHeight: "1em" }}>Thank you for supporting <OBAName fontWeight="500" />!</h1>
                        {/* <h1 className="mb-3 mb-sm-0" style={{ fontWeight: 300, fontSize: "3.4rem", lineHeight: ".8em" }}>Choose.</h1> */}
                        <div className="mx-auto mb-0" style={{ maxWidth: 700, marginTop: -15 }}>
                            <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo_1470_shadow.png" className="img-fluid" title="Logo for one.bad.apple" alt="Logo for one.bad.apple" />
                        </div>
                        {/*<h1 className="" style={{ fontSize: "2.0rem", fontWeight: 400, }}>
                            <OBAWebName />
                        </h1>
                         <h2 style={{ fontSize: "2rem", fontWeight: 400, }}>
                            Coming soon!
                        </h2> */}
                        <h1 className="mb-4" style={{ fontWeight: 300, fontSize: "3.2rem", lineHeight: "1em" }}>Success!</h1>
                    </div>
                </Container>
            </div>
            <PromoFrame>
                <h1 className="text-center pt-5" style={{ fontWeight: 300, fontSize: "2.50rem", lineHeight: "1em" }}>Thank You</h1>
                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ornare tellus eget ante condimentum accumsan. Orci varius natoque penatibus et magnis.</p>

                {/* <h2 className="mb-2 pt-5" style={{ fontWeight: 300, fontSize: "2.0rem", lineHeight: "1em" }}>
                    1. Choose your <span className="text-primary" style={{ fontWeight: 400 }}>FREE</span> RTIC<small style={{ fontSize: ".85rem" }}>&reg;</small> drinkware by <OBAName fontWeight="500" />
                </h2>
                <small className="" style={{ fontSize: "1rem" }}>
                    * Promotion limited to <span>one free item per email address</span>. If you are interested in purchasing <OBAName fontWeight="500" /> items, <a href="/">visit our online store here</a>.
                </small> */}
                

            </PromoFrame>

        </>
    );

};

export default PromotionSuccess;