import React from "react";
import {
    Row, Col, Badge
} from "react-bootstrap";
import {
    //isDesktop,
    //isMobile,
    //isMobileOnly,
    //isTablet
} from "react-device-detect";
//import BtnIconLink from "../buttons/BtnIconLink";
//import BtnStackedLink from "../buttons/BtnStackedLink";


const ChatProfileHorizontal = ({
    blur,
    isDark,
    showLightBoxWindow,
}) => {

    return (
        <>
            <Row className="gx-3 mb-3 align-items-center justify-content-start">
                <Col xs="auto">
                    <div
                        className={`${blur ? "img-blur" : ""} position-relative rounded-circle shadow `}
                        style={{
                            //height: isMobileOnly ? "calc(100vw - 80px)" : 190,
                            aspectRatio: "1 / 1",
                            backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg)",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            cursor: "pointer",
                            width: "100vw",
                            maxWidth: 48,
                        }}
                        onClick={() => {
                            showLightBoxWindow({
                                imgs: [
                                    { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg", type: "image" },
                                ]
                            });
                        }}
                    >
                        <div
                            className="d-flex align-items-center position-absolute top-0 end-0"
                            style={{
                                zIndex: 5,
                            }}
                        >
                            <Badge
                                bg={"dark"}
                                text={"primary"}
                                title={"Online Now..."}
                                className="rounded-circle shadow-sm"
                                style={{
                                    fontSize: ".85rem",
                                    paddingTop: 3,
                                    paddingBottom: 2,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                }}
                            >
                                <ion-icon name={"checkmark-circle-sharp"}></ion-icon>
                            </Badge>
                        </div>
                    </div>
                </Col>
                <Col xs="auto">
                    <div className="text-left">
                        <h5 className="mb-1">E-K-Having-Fun</h5>
                        <h6 className="mb-1">Couple (M/F)</h6>
                        {/* <h6 className="mb-1">Status: Online</h6> */}
                    </div>
                </Col>
            </Row>            
        </>
    );

};

export default ChatProfileHorizontal;