import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, } from 'react-bootstrap';
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import BtnIcon from "../../buttons/BtnIcon";
//import AppNameInline from "../../text/AppNameInline";
import ScrollAreaVertical from "../../shared/ScrollAreaVertical";

const ShortcutMenu = ({
    showShortcutMenu,
    setShowShortcutMenu,
    user,

}) => {

    let menuTimer = null;

    return (
        <>
            <aside
                className={`${isMobileOnly ? "isMobileOnly " : ""}shortcut-menu position-fixed start-0 bottom-0 top-0 d-flex flex-column nav-offset pb-1 bp-sm-3 bg-light-dark text-light shadow ${showShortcutMenu ? "show-menu" : ""}`}
                style={{

                    zIndex: 100,
                    width: "100vw",
                    maxWidth: 380
                }}
                onClick={() => {

                    clearTimeout(menuTimer);

                    setShowShortcutMenu(false);

                }}
                onMouseEnter={() => {

                    clearTimeout(menuTimer);

                    setShowShortcutMenu(true);

                }}
                onMouseLeave={() => {

                    menuTimer = setTimeout(() => {
                        setShowShortcutMenu(false);
                    }, 500);

                }}
            >
                <div className="border-bottom border-md-dark pt-1 pb-1">
                    <Row className="justify-content-between align-items-center g-1 ps-3 pe-3">
                        <Col xs="auto">
                            <h4 className="d-flex align-items-center justify-content-start m-0">
                                Shortcuts
                            </h4>
                        </Col>
                        <Col xs="auto">
                            <BtnIcon
                                bsVariant={"icon-light"}
                                label="Minimize cart window..."
                                iconSize={20}
                                iconName="arrow-back-outline"
                                iconClasses=""
                                btnClasses=""
                                handleClick={() => {
                                    setShowShortcutMenu(false);
                                }}
                            />
                        </Col>

                    </Row>
                </div>
                <div className="d-flex flex-column p-3" style={{ height: "100%", fontSize: ".85rem", }}>
                    <ScrollAreaVertical>
                        <div className="ms-1 me-2">
                            <h5>Your Events</h5>
                            <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                <Col xs="auto">
                                    <img
                                        src={user.photoURL}
                                        className="rounded-1 shadow me-1"
                                        alt={
                                            `Profile for ${user.email}`
                                        }
                                        style={{
                                            width: 40,
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Link
                                        to={"/"}
                                        className="link-list-item rounded-1 text-light"
                                    >
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".85rem",
                                            }}
                                        >
                                            One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                        </div>
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".80rem",
                                            }}
                                        >
                                            HOUSE PARTY! Come join us for my wife's birthday!
                                        </div>
                                    </Link>
                                </Col>
                                
                            </Row>
                            <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                <Col xs="auto">
                                    <img
                                        src={user.photoURL}
                                        className="rounded-1 shadow me-1"
                                        alt={
                                            `Profile for ${user.email}`
                                        }
                                        style={{
                                            width: 40,
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Link
                                        to={"/"}
                                        className="link-list-item rounded-1 text-light text-light"
                                    >
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".85rem",
                                            }}
                                        >
                                            One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                        </div>
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".80rem",
                                            }}
                                        >
                                            HOUSE PARTY! Come join us for my wife's birthday!
                                        </div>
                                    </Link>
                                </Col>
                                
                            </Row>
                        </div>

                        <div className="ms-1 me-2">
                            <h5>Your Groups</h5>
                            <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                <Col xs="auto">
                                    <img
                                        src={user.photoURL}
                                        className="rounded-1 shadow me-1"
                                        alt={
                                            `Profile for ${user.email}`
                                        }
                                        style={{
                                            width: 40,
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Link
                                        to={"/"}
                                        className="link-list-item rounded-1 text-light text-light"
                                    >
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".85rem",
                                            }}
                                        >
                                            One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                        </div>
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".80rem",
                                            }}
                                        >
                                            HOUSE PARTY! Come join us for my wife's birthday!
                                        </div>
                                    </Link>
                                </Col>
                                
                            </Row>
                            <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                <Col xs="auto">
                                    <img
                                        src={user.photoURL}
                                        className="rounded-1 shadow me-1"
                                        alt={
                                            `Profile for ${user.email}`
                                        }
                                        style={{
                                            width: 40,
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Link
                                        to={"/"}
                                        className="link-list-item rounded-1 text-light text-light"
                                    >
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".85rem",
                                            }}
                                        >
                                            One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                        </div>
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".80rem",
                                            }}
                                        >
                                            HOUSE PARTY! Come join us for my wife's birthday!
                                        </div>
                                    </Link>
                                </Col>
                                
                            </Row>
                        </div>
                        <div className="ms-1 me-2">
                            <h5>Your Partner Pages</h5>
                            <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                <Col xs="auto">
                                    <img
                                        src={user.photoURL}
                                        className="rounded-1 shadow me-1"
                                        alt={
                                            `Profile for ${user.email}`
                                        }
                                        style={{
                                            width: 40,
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Link
                                        to={"/"}
                                        className="link-list-item rounded-1 text-light text-light"
                                    >
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".85rem",
                                            }}
                                        >
                                            One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                        </div>
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".80rem",
                                            }}
                                        >
                                            HOUSE PARTY! Come join us for my wife's birthday!
                                        </div>
                                    </Link>
                                </Col>
                                
                            </Row>
                            <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                <Col xs="auto">
                                    <img
                                        src={user.photoURL}
                                        className="rounded-1 shadow me-1"
                                        alt={
                                            `Profile for ${user.email}`
                                        }
                                        style={{
                                            width: 40,
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Link
                                        to={"/"}
                                        className="link-list-item rounded-1 text-light text-light"
                                    >
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".85rem",
                                            }}
                                        >
                                            One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                        </div>
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".80rem",
                                            }}
                                        >
                                            HOUSE PARTY! Come join us for my wife's birthday!
                                        </div>
                                    </Link>
                                </Col>
                                
                            </Row>
                        </div>
                        <div className="ms-1 me-2">
                            <h5>Your Shop</h5>
                            <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                <Col xs="auto">
                                    <img
                                        src={user.photoURL}
                                        className="rounded-1 shadow me-1"
                                        alt={
                                            `Profile for ${user.email}`
                                        }
                                        style={{
                                            width: 40,
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Link
                                        to={"/"}
                                        className="link-list-item rounded-1 text-light text-light"
                                    >
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".85rem",
                                            }}
                                        >
                                            One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                        </div>
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".80rem",
                                            }}
                                        >
                                            HOUSE PARTY! Come join us for my wife's birthday!
                                        </div>
                                    </Link>
                                </Col>

                            </Row>
                            <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                <Col xs="auto">
                                    <img
                                        src={user.photoURL}
                                        className="rounded-1 shadow me-1"
                                        alt={
                                            `Profile for ${user.email}`
                                        }
                                        style={{
                                            width: 40,
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Link
                                        to={"/"}
                                        className="link-list-item rounded-1 text-light text-light"
                                    >
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".85rem",
                                            }}
                                        >
                                            One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                        </div>
                                        <div
                                            style={{
                                                lineHeight: "1.2em",
                                                fontSize: ".80rem",
                                            }}
                                        >
                                            HOUSE PARTY! Come join us for my wife's birthday!
                                        </div>
                                    </Link>
                                </Col>

                            </Row>
                        </div>
                    </ScrollAreaVertical>
                </div>
            </aside>
        </>
    );

};

export default ShortcutMenu;