import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, } from 'react-bootstrap';
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import BtnIcon from "../../buttons/BtnIcon";
//import AppNameInline from "../../text/AppNameInline";
import ScrollAreaVertical from "../../shared/ScrollAreaVertical";

const NotificationsMenu = ({
    showNotificationsMenu,
    setShowNotificationsMenu,
    user,

}) => {

    const menu = useRef("menu");

    let menuTimer = null;

    useEffect(() => {

        const myMenu = menu.current;

        if (menu.current && showNotificationsMenu) {
            setTimeout(() => {
                myMenu.classList.add("show-menu");
            }, 250)
        } else {
            myMenu.classList.remove("show-menu");
        }

    })

    return (
        <>
            <aside
                ref={menu}
                className={`${isMobileOnly ? "isMobileOnly " : ""}notifications-menu nav-offset position-fixed end-0 bottom-0 top-0 d-flex flex-column pe-1 pb-1 bp-sm-3 bg-light-dark text-light shadow ${showNotificationsMenu ? "" : ""}`}
                style={{

                    zIndex: 100,
                    width: "100vw",
                    maxWidth: 380
                }}
                onClick={() => {

                    clearTimeout(menuTimer);

                    setShowNotificationsMenu(false);

                }}
                onMouseEnter={() => {

                    clearTimeout(menuTimer);

                    setShowNotificationsMenu(true);

                }}
                onMouseLeave={() => {

                    menuTimer = setTimeout(() => {
                        setShowNotificationsMenu(false);
                    }, 500);

                }}
            >
                <div className="border-bottom border-md-dark pt-1 pb-1">
                    <Row className="justify-content-end align-items-center gx-1">
                        <Col className="me-auto ms-3" xs="auto">
                            <h4 className="d-flex align-items-center justify-content-start m-0">
                                Notifications
                            </h4>
                        </Col>
                        <Col xs="auto">
                            <BtnIcon
                                bsVariant={"icon-light"}
                                iconSize={16}
                                iconName="ellipsis-horizontal-outline"
                                iconClasses=""
                                label={"Mark all as read..."}
                                btnClasses={""}
                                handleClick={(e) => {

                                    e.stopPropagation();
                                    alert("This feature is currently under development.");

                                }}
                            />
                        </Col>
                        <Col xs="auto">
                            <BtnIcon
                                bsVariant={"icon-light"}
                                label="Minimize cart window..."
                                iconSize={20}
                                iconName="arrow-forward-outline"
                                iconClasses=""
                                btnClasses=""
                                handleClick={() => {
                                    setShowNotificationsMenu(false);
                                }}
                            />
                        </Col>

                    </Row>
                </div>
                <div className="d-flex flex-column mt-3" style={{ height: "100%", }}>
                    <ScrollAreaVertical>

                        <div className="ms-3 me-2">
                            <h5>New!</h5>
                            <div style={{ fontSize: ".85rem", }}>
                                <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                    <Col xs="auto">
                                        <img
                                            src={user && user.photoURL}
                                            className="rounded-1 shadow me-1"
                                            alt={
                                                `Profile for ${user && user.email}`
                                            }
                                            style={{
                                                width: 40,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to={"/"}
                                            className="link-list-item rounded-1 text-light"
                                        >
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".85rem",
                                                }}
                                            >
                                                One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".80rem",
                                                }}
                                            >
                                                HOUSE PARTY! Come join us for my wife's birthday!
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col className="" xs="auto">
                                        <div className="text-primary" style={{ fontSize: ".85rem", }}>
                                            <ion-icon name="ellipse-sharp"></ion-icon>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                    <Col xs="auto">
                                        <img
                                            src={user && user.photoURL}
                                            className="rounded-1 shadow me-1"
                                            alt={
                                                `Profile for ${user && user.email}`
                                            }
                                            style={{
                                                width: 40,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to={"/"}
                                            className="link-list-item rounded-1 text-light text-light"
                                        >
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".85rem",
                                                }}
                                            >
                                                One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".80rem",
                                                }}
                                            >
                                                HOUSE PARTY! Come join us for my wife's birthday!
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col className="" xs="auto">
                                        <div className="text-primary" style={{ fontSize: ".85rem", }}>
                                            <ion-icon name="ellipse-sharp"></ion-icon>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                    <Col xs="auto">
                                        <img
                                            src={user && user.photoURL}
                                            className="rounded-1 shadow me-1"
                                            alt={
                                                `Profile for ${user && user.email}`
                                            }
                                            style={{
                                                width: 40,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to={"/"}
                                            className="link-list-item rounded-1 text-light text-light"
                                        >
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".85rem",
                                                }}
                                            >
                                                One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".80rem",
                                                }}
                                            >
                                                HOUSE PARTY! Come join us for my wife's birthday!
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col className="" xs="auto">
                                        <div className="text-primary" style={{ fontSize: ".85rem", }}>
                                            <ion-icon name="ellipse-sharp"></ion-icon>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                    <Col xs="auto">
                                        <img
                                            src={user && user.photoURL}
                                            className="rounded-1 shadow me-1"
                                            alt={
                                                `Profile for ${user && user.email}`
                                            }
                                            style={{
                                                width: 40,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to={"/"}
                                            className="link-list-item rounded-1 text-light text-light"
                                        >
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".85rem",
                                                }}
                                            >
                                                One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".80rem",
                                                }}
                                            >
                                                HOUSE PARTY! Come join us for my wife's birthday!
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col className="" xs="auto">
                                        <div className="text-primary" style={{ fontSize: ".85rem", }}>
                                            <ion-icon name="ellipse-sharp"></ion-icon>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                    <Col xs="auto">
                                        <img
                                            src={user && user.photoURL}
                                            className="rounded-1 shadow me-1"
                                            alt={
                                                `Profile for ${user && user.email}`
                                            }
                                            style={{
                                                width: 40,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to={"/"}
                                            className="link-list-item rounded-1 text-light text-light"
                                        >
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".85rem",
                                                }}
                                            >
                                                One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".80rem",
                                                }}
                                            >
                                                HOUSE PARTY! Come join us for my wife's birthday!
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col className="" xs="auto">
                                        <div className="text-primary" style={{ fontSize: ".85rem", }}>
                                            <ion-icon name="ellipse-sharp"></ion-icon>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <h5>Older</h5>
                            <div style={{ fontSize: ".85rem", }}>
                                <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                    <Col xs="auto">
                                        <img
                                            src={user && user.photoURL}
                                            className="rounded-1 shadow me-1"
                                            alt={
                                                `Profile for ${user && user.email}`
                                            }
                                            style={{
                                                width: 40,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to={"/"}
                                            className="link-list-item rounded-1 text-light text-light"
                                        >
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".85rem",
                                                }}
                                            >
                                                One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".80rem",
                                                }}
                                            >
                                                HOUSE PARTY! Come join us for my wife's birthday!
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col className="" xs="auto">
                                        <div className="text-primary" style={{ fontSize: ".85rem", }}>
                                            <ion-icon name="ellipse-sharp"></ion-icon>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                    <Col xs="auto">
                                        <img
                                            src={user && user.photoURL}
                                            className="rounded-1 shadow me-1"
                                            alt={
                                                `Profile for ${user && user.email}`
                                            }
                                            style={{
                                                width: 40,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to={"/"}
                                            className="link-list-item rounded-1 text-light text-light"
                                        >
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".85rem",
                                                }}
                                            >
                                                One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".80rem",
                                                }}
                                            >
                                                HOUSE PARTY! Come join us for my wife's birthday!
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col className="" xs="auto">
                                        <div className="text-secondary" style={{ fontSize: ".85rem", }}>
                                            <ion-icon name="ellipse-sharp"></ion-icon>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                    <Col xs="auto">
                                        <img
                                            src={user && user.photoURL}
                                            className="rounded-1 shadow me-1"
                                            alt={
                                                `Profile for ${user && user.email}`
                                            }
                                            style={{
                                                width: 40,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to={"/"}
                                            className="link-list-item rounded-1 text-light text-light"
                                        >
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".85rem",
                                                }}
                                            >
                                                One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".80rem",
                                                }}
                                            >
                                                HOUSE PARTY! Come join us for my wife's birthday!
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col className="" xs="auto">
                                        <div className="text-secondary" style={{ fontSize: ".85rem", }}>
                                            <ion-icon name="ellipse-sharp"></ion-icon>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                    <Col xs="auto">
                                        <img
                                            src={user && user.photoURL}
                                            className="rounded-1 shadow me-1"
                                            alt={
                                                `Profile for ${user && user.email}`
                                            }
                                            style={{
                                                width: 40,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to={"/"}
                                            className="link-list-item rounded-1 text-light text-light"
                                        >
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".85rem",
                                                }}
                                            >
                                                One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".80rem",
                                                }}
                                            >
                                                HOUSE PARTY! Come join us for my wife's birthday!
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col className="" xs="auto">
                                        <div className="text-secondary" style={{ fontSize: ".85rem", }}>
                                            <ion-icon name="ellipse-sharp"></ion-icon>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="link-list-item align-items-start flex-nowrap justify-content-start gx-1 mb-3">
                                    <Col xs="auto">
                                        <img
                                            src={user && user.photoURL}
                                            className="rounded-1 shadow me-1"
                                            alt={
                                                `Profile for ${user && user.email}`
                                            }
                                            style={{
                                                width: 40,
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to={"/"}
                                            className="link-list-item rounded-1 text-light text-light"
                                        >
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".85rem",
                                                }}
                                            >
                                                One.Bad.Apple - <span style={{ height: "1.2em", fontSize: ".70rem", }}>Sat, 12/12/2022</span>
                                            </div>
                                            <div
                                                style={{
                                                    lineHeight: "1.2em",
                                                    fontSize: ".80rem",
                                                }}
                                            >
                                                HOUSE PARTY! Come join us for my wife's birthday!
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col className="" xs="auto">
                                        <div className="text-secondary" style={{ fontSize: ".85rem", }}>
                                            <ion-icon name="ellipse-sharp"></ion-icon>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </ScrollAreaVertical>
                </div>
            </aside>
        </>
    );

};

export default NotificationsMenu;