import React from "react";
//import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";

const BtnStackedLink = ({
    bsVariant,
    bsSize,
    alignLeft,
    btnClasses,
    label,
    sublabel,
    iconSize,
    useEmoji,
    iconName,
    useLink,
    href,
    target,
    handleClick
}) => {

    return (
        <>
            {useLink === true ? (
                <>
                    <Link className={
                        // eslint-disable-next-line
                        `btn-${bsSize} btn-${bsVariant} ${btnClasses} btn d-inline-block`
                    }
                        to={href}
                        onClick={handleClick}
                    >
                        <div className={"d-flex align-items-center" + (alignLeft ? " justify-content-start" : " justify-content-center")}>
                            <Row className="align-items-center justify-content-center flex-nowrap gx-2">
                                <Col xs="auto">
                                    {iconName && iconName !== "" && (
                                        <div
                                            className=""
                                            style={{
                                                lineHeight: "1em",
                                                height: "1em",
                                                fontSize: iconSize && iconSize > 0 ? iconSize : "1.9em"
                                            }}
                                        >
                                            {useEmoji ? (
                                                <span className={iconName}></span>
                                            ) : (
                                                <ion-icon name={iconName}></ion-icon>
                                            )}

                                        </div>
                                    )}
                                </Col>
                                <Col className="d-flex flex-column align-items-start justify-content-start">
                                    <div className="" style={{ fontWeight: 500, lineHeight: "1.4em", fontSize: "1.3em" }}>{label}</div>
                                    {sublabel && sublabel !== "" && (
                                        <div style={{ fontWeight: 400, lineHeight: "1.1em", fontSize: ".9em" }}>{sublabel}</div>
                                    )}

                                </Col>
                            </Row>
                        </div>
                    </Link>
                </>
            ) : (
                <>
                    <Button
                        variant={bsVariant}
                        size={bsSize}
                        className={btnClasses}
                        style={{
                            pointerEvents: "auto",
                        }}
                        onClick={handleClick}
                    >
                        <div className={"d-flex align-items-center" + (alignLeft ? " justify-content-start" : " justify-content-center")}>
                            <Row className="align-items-center justify-content-center flex-nowrap gx-0">
                                <Col xs="auto">
                                    {iconName && iconName !== "" && (
                                        <div
                                            className="me-2"
                                            style={{
                                                lineHeight: "1em",
                                                height: "1em",
                                                fontSize: iconSize && iconSize > 0 ? iconSize : "1.9em"
                                            }}
                                        >
                                            {useEmoji ? (
                                                <span className={iconName}></span>
                                            ) : (
                                                <ion-icon name={iconName}></ion-icon>
                                            )}
                                        </div>
                                    )}
                                </Col>
                                <Col className="d-flex flex-column align-items-start justify-content-start">
                                    <div className="" style={{ fontWeight: 500, lineHeight: "1.4em", fontSize: "1.2em" }}>{label}</div>
                                    {sublabel && sublabel !== "" && (
                                        <div style={{ fontWeight: 400, lineHeight: "1.1em", fontSize: ".9em" }}>{sublabel}</div>
                                    )}

                                </Col>
                            </Row>
                        </div>

                    </Button>
                </>
            )}

        </>
    );

};

// BtnStdLink.propTypes = {
//     bsVariant: PropTypes.string,
//     bsSize: PropTypes.string,
//     btnClasses: PropTypes.string,
//     label: PropTypes.any,
//     iconSize: PropTypes.number,
//     iconName: PropTypes.string,
//     href: PropTypes.string,
//     target: PropTypes.string,
// }

// BtnStdLink.defaultProps = {
//     bsVariant: "primary",
//     bsSize: "",
//     btnClasses: "",
//     label: "Add Label",
//     iconSize: 20,
//     iconName: "add-circle-outline",
//     href: "",
//     target: "",
// };

export default BtnStackedLink;

