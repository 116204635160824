import React, { useRef, useEffect, useState } from "react";
//import PropTypes from 'prop-types';
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    //isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import { Row, Col, /*Badge*/ } from 'react-bootstrap';
import FadeIn from "react-fade-in/lib/FadeIn";
import BtnIcon from "../buttons/BtnIcon";
import LoadingOverlayContained from "../shared/LoadingOverlayAbsolute";

const MsgList = ({
    isDark,
    updateScrollPos,
}) => {

    const scrollArea = useRef(0);

    const shortMsg = "Lorem ipsum dolor sit amet";
    const mediumMsg = "Quisque aliquam ac lectus fringilla consectetur. Quisque vel laoreet sapien. Proin est purus, consectetur sit amet ex in, facilisis rhoncus metus.";
    const longMsg = "Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur augue lectus, pellentesque ac tortor ut, dictum tempor nunc. Proin non lorem ac odio consectetur sollicitudin in mollis nisl. Cras mattis a dui id tincidunt. Donec facilisis sed massa ac lobortis. Nulla in blandit erat. Donec placerat interdum sem ut varius.";
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        if (isLoading) {

            setTimeout(() => {
                setIsLoading(false);

            }, 1000);

        }

        if (!isLoading) {
            updateScrollPos(scrollArea.current, scrollArea.current.scrollHeight, "auto");

            setTimeout(()=>{
                updateScrollPos(scrollArea.current, scrollArea.current.scrollHeight, "smooth");
            }, 50);
        }

    }, [isLoading, updateScrollPos]);


    const listItems = () => {

        let list = [];

        for (let i = 0; i < 20; i++) {

            const msg = i % 4 === 0 ? longMsg : i % 2 ? shortMsg : mediumMsg;

            const user = (
                <Row key={i} className="d-flex justify-content-start me-auto mb-4 g-1" style={{ maxWidth: "90%" }}>
                    <Col xs="auto">
                        <ion-icon style={{ fontSize: 30 }} name="person-circle-outline"></ion-icon>
                    </Col>
                    <Col className="">
                        <div className="message-bubble position-relative d-inline-block rounded-1 bg-md-dark text-light px-3 py-2">
                            <div className="position-absolute" style={{ right: -36, top: 3 }}>
                                <BtnIcon
                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                    label="Options..."
                                    iconSize={18}
                                    iconName="ellipsis-vertical"
                                    iconClasses="text-muted"
                                    btnClasses=""
                                    handleClick={(e) => {
                                        e.preventDefault();
                                        alert("This feature is under development ");
                                    }}
                                />
                            </div>
                            <div className="pb-1" style={{  }}>One.Bad.Apple: <span style={{ }}>8:03 PM, 08/28/22</span></div>
                            <div>{msg}</div>
                        </div>
                    </Col>
                </Row>
            );

            const self = (
                <Col key={i} xs="12" className="d-flex justify-content-end ms-auto mb-4" style={{ maxWidth: "80%", fontWeight: 400 }}>
                    <div className={`${isDark ? "bg-primary text-light " : "bg-primary text-light "}position-relative message-bubble d-inline-block rounded-1 px-3 py-2`}>
                        <div className="position-absolute" style={{ left: -36, top: 3 }}>
                            <BtnIcon
                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                label="Options..."
                                iconSize={18}
                                iconName="ellipsis-vertical"
                                iconClasses="text-muted"
                                btnClasses=""
                                handleClick={(e) => {
                                    e.preventDefault();
                                    alert("This feature is under development ");
                                }}
                            />
                        </div>
                        <div className="pb-1" style={{  }}>Me: <span style={{ }}>8:03 PM, 08/28/22</span></div>
                        <div>{msg}</div>
                    </div>
                </Col>
            );

            if (i % 3 === 0) {
                list.push(
                    self
                );
            } else {
                list.push(
                    user
                );
            }
        }

        return list;

    };

    return (
        <>
            {isLoading ? (
                <>
                    <LoadingOverlayContained
                        msgText="loading messages..."
                        msgTextBsColor={isDark ? "text-light" : "text-dark"}
                        spinnerType="grow"
                        spinnerBsVariant={isDark ? "primary" : "primary"}
                        spinnerBsSize="sm"
                        fontSize=".9rem"
                        appName="hub"
                        showAppNameCopyright={false}
                        showAppLogo={false}
                        showOBALogo={true}
                        isDark={isDark}
                    />
                </>
            ) : (
                <div
                    ref={scrollArea}
                    className="scroll-div-vertical position-absolute top-0 bottom-0 start-0 end-0 me-1 py-0 px-2"
                    style={{
                        overflowX: "hidden",
                        overflowY: "auto",
                    }}
                    onScroll={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Row className="mt-3">
                        <FadeIn delay={25} duration={200}>
                            {listItems()}
                        </FadeIn>
                    </Row>
                </div>
            )}

        </>
    );

};

export default MsgList;