import React from "react";
import { NavLink } from "react-router-dom";
import { Badge, Row, Col } from 'react-bootstrap';
import Config from "../../../data/Config";
//import PropTypes from 'prop-types';
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import FadeIn from 'react-fade-in';
import BtnIcon from "../../buttons/BtnIcon";
import AppNameStacked from "../../text/AppNameStacked";
import AppNameInline from "../../text/AppNameInline";
//import ScrollAreaVertical from "../../shared/ScrollAreaVertical";

const AppMenu = ({
    showAppMenu,
    setShowAppMenu,
    isAuthenticated,

}) => {

    const menuData = Config.AppList;

    const MenuItem = ({
        url,
        children,
    }) => {

        return (

            <NavLink
                /*end*/
                to={url}
                className="app-menu-item position-relative d-block px-3 py-2 py-sm-3 border-bottom border-md-dark"
            >
                {children}
            </NavLink>
        )

    }

    let menuTimer = null;

    return (
        <>
            <div
                className={(isMobileOnly ? "isMobileOnly " : "") + "app-menu position-fixed start-0 bottom-0 top-0 d-flex flex-column nav-offset pb-1 bp-sm-3 bg-light-dark text-light shadow" + (showAppMenu ? " show-menu" : "")}
                style={{

                    zIndex: 100,
                    width: 300
                }}
                onClick={() => {

                    clearTimeout(menuTimer);

                    setShowAppMenu(false);

                }}
                onMouseEnter={() => {

                    clearTimeout(menuTimer);

                    setShowAppMenu(true);

                }}
                onMouseLeave={() => {

                    menuTimer = setTimeout(() => {
                        setShowAppMenu(false);
                    }, 500);

                }}
            >
                <div className="border-bottom border-md-dark pt-1 pb-1">
                    <Row className="justify-content-between align-items-center g-1 ps-3 pe-2">
                        <Col xs="auto">
                            <h4 className="d-flex align-items-center justify-content-start m-0">
                                <AppNameInline name="apps" fontWeight={400} showCopyright={false} />
                            </h4>
                        </Col>
                        <Col xs="auto">
                            <BtnIcon
                                bsVariant={"icon-light"}
                                label="Minimize cart window..."
                                iconSize={20}
                                iconName="arrow-back-outline"
                                iconClasses=""
                                btnClasses=""
                                handleClick={() => {
                                    setShowAppMenu(false);
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                <FadeIn style={{ textShadow: "2px 2px 4px rgba(0,0,0,.1)" }}>
                    {menuData.map((item, i) => {

                        return (
                            <MenuItem
                                key={i}
                                url={item.url}
                                active={i === 0 ? "active" : ""}
                            >
                                {/* {item.selected && (

                                    <>
                                        {(item.badgeCount && item.badgeCount > 0) && (
                                            <Badge className="position-absolute rounded-pill shadow-sm border border-light text-dark end-0 top-0 me-2 mt-2" style={{ fontSize: ".70rem", zIndex: 1 }} bg="primary">{item.badgeCount}</Badge>
                                        )}
                                    </>

                                )} */}

                                {!item.selected && (

                                    <Badge className="position-absolute rounded-1 shadow-sm border border-1 border-primary end-0 top-0 me-2 mt-3" style={{ fontSize: ".75rem", fontWeight: 300, zIndex: 1 }} text="light" bg="dark">2023</Badge>

                                )}
                                <span className={!item.selected ? "text-light-gray" : ""}>
                                    <AppNameStacked name={item.heading} tagLine={item.text} tagLineSize={".85rem"} iconName={item.iconName} iconSizeEm={isMobileOnly ? "2.25rem" : "2.75rem"} headingSize="h4" />
                                </span>

                            </MenuItem>
                        )
                    })}
                </FadeIn>
                {/* <ScrollAreaVertical>
                    
                </ScrollAreaVertical> */}
            </div>
        </>
    );

};

export default AppMenu;