import React from "react";
//import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import {
    isDesktop,
    //isMobile,
    //isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import Config from "../data/Config";
import AppNameStacked from "../components/text/AppNameStacked";
import AppNameInline from "../components/text/AppNameInline";
import OBAName from "../components/text/OBAName";

const AppsListInlineContent = () => {

    const iconSize = isDesktop ? "3.4em" : "2.25em";
    const listData = Config.AppList;

    const ListItem = ({
        item
    }) => {

        return (
            <AppNameStacked
                name={item.heading}
                headingSize={isMobileOnly ? "h3" : "h1"}
                isMobileOnly={isMobileOnly}
                tagLine={item.text}
                iconName={item.iconName}
                iconSizeEm={iconSize}
                showCopyright={true}
                showLink={true}
            />
        )
    };

    return (
        <>
            <h1 className="mb-5">
                <OBAName fontWeight={400} /> <AppNameInline name="apps" showCopyright={true} /> <br className="d-block d-sm-none" /><small className="text-muted" style={{ fontSize: ".85rem" }}>*names and logos subject to change</small>
            </h1>
            <Row className="app-feature justify-content-start mt-2 mb-4 gx-3 gx-sm-3" style={{ textShadow: "2px 2px 4px rgba(0,0,0,.1)" }}>
                {listData.map((item, i) => {
                    return (
                        <Col key={i} xs="6" sm="4" lg="3" className="mb-5">
                            <ListItem
                                item={item}
                            />
                        </Col>
                    );
                })}
                <Col xs="12" lg="6">
                    <Row className={(isDesktop ? "align-items-center" : "align-items-start") + " gx-2 gx-sm-3"} style={{ lineHeight: "1.2em", }}>
                        <Col className="d-flex align-items-center" xs="auto">
                            <div style={{ lineHeight: "1em", height: iconSize }}><ion-icon style={{ fontSize: iconSize }} name="apps-outline"></ion-icon></div>
                        </Col>
                        <Col className="d-flex flex-column justify-content-center align-items-start">
                            <h1 className="m-0 p-0">
                                <OBAName fontWeight={400} /> <AppNameInline name="apps" showCopyright={true} fontWeight={400} /> <br className="d-block d-sm-none" />coming soon...
                            </h1>
                            <span>check back often for updates!</span>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    );

};

// AppsListInlineContent.propTypes = {

// }

// AppsListInlineContent.defaultProps = {

// };

export default AppsListInlineContent;