import React from "react";
import { Row, Col, } from "react-bootstrap";
import {
    //isDesktop, 
    isMobileOnly,
    isMobile,
    //isTablet,
} from "react-device-detect";

import ImageNameProfileLG from "./ImageNameProfileLG";

const ProfileModalHeaderNav = ({
    children,
    showShortcutMenu,
    setShowShortcutMenu,
    user,
    isDark,
    showCarousel,

}) => {

    return (
        <>
            <header className="pb-0 pb-lg-2">
                <>
                    <div
                        className={`${isDark ? "text-light" : "text-dark"} position-relative mb-2 mb-lg-2 pb-0`}
                        style={{
                            marginTop: showCarousel ? (isMobile ? -30 : -65) : 0,
                            zIndex: 5,
                            pointerEvents: "none"
                        }}
                    >
                        <Row className="align-items-end justify-content-between">
                            <Col xs="12" lg="7">
                                {/* PROFILE INFO */}
                                <ImageNameProfileLG
                                    user={user}
                                    isDark={isDark}
                                    isMobileOnly={isMobileOnly}
                                    setShowShortcutMenu={setShowShortcutMenu}
                                    showShortcutMenu={showShortcutMenu}
                                />
                            </Col>
                            <Col
                                className="text-center text-sm-end align-self-center pt-3 mt-lg-5"
                                xs="12"
                                lg
                                style={{
                                    //height: isMobileOnly ? "auto" : 115,
                                    pointerEvents: "auto"
                                }}
                            >
                                {children}
                            </Col>
                        </Row>
                    </div>
                </>
            </header>
        </>
    );

};

export default ProfileModalHeaderNav;