import React from "react";
import { Button, Container, } from 'react-bootstrap';
import FadeIn from "react-fade-in/lib/FadeIn";
//import OBAName from "../text/OBAName";
//import AppNameInline from "../text/AppNameInline";


const ErrorOverlayFixed = ({
    bgBsVariant,
    borderBsVariant,
    headerIconBsVariant,
    headerMsg,
    bodyMsg,
    customButtons,
    isDark,
    showError,
    setShowError

}) => {

    return (

        showError && (

            <div
                className="position-fixed start-0 end-0 top-0 bottom-0 d-flex align-items-center justify-content-center mx-2 mx-lg-0"
                style={{
                    zIndex: 90000,
                    backgroundColor: "rgba(0, 0, 0, .6)",
                }}
            >
                <FadeIn className="w-100">
                    <div
                        className={`${borderBsVariant ? `border-${borderBsVariant}` : "border-primary"} rounded-0 border-top border-bottom border-3 bg-dark pt-2 pb-2 w-100`}
                    >
                        <Container className="container-1000 text-light">
                            <h4 className="d-flex align-items-center justify-content-center mb-2">
                                <div
                                    className={`${headerIconBsVariant ? `text-${headerIconBsVariant}` : "text-primary"} me-2`}
                                    style={{
                                        fontSize: "1.4em",
                                        lineHeight: "1em",
                                        height: "1em"
                                    }}
                                >
                                    <ion-icon name="warning-outline"></ion-icon>
                                </div>
                                <div style={{ height: "1.1em" }}>{headerMsg}</div>
                            </h4>
                            <p className="text-center mb-2 fs-6">
                                {bodyMsg}
                            </p>

                            <div className="text-center">
                                {!customButtons && (
                                    <Button
                                        variant="icon-light"
                                        className="rounded-pill px-3"
                                        onClick={() => {
                                            setShowError(false);
                                        }}
                                    >
                                        OK
                                    </Button>
                                )}
                            </div>
                        </Container>

                    </div>
                </FadeIn>

            </div>
        )

    );
};

export default ErrorOverlayFixed;