import React from "react";
//import PropTypes from 'prop-types';
import Config from "../../data/Config";
import Article from "./Article";
import { Container, Row, Col } from 'react-bootstrap';
import {
    isDesktop
} from "react-device-detect";
import FadeIn from "react-fade-in/lib/FadeIn";
//import BtnStdLink from "../buttons/BtnStdLink";
import AppNameInlineIcon from "../../components/text/AppNameInlineIcon";
import AppNameInline from "../../components/text/AppNameInline";
import OBAName from "../../components/text/OBAName";

const sectionColors = [
    "bg-white border-bottom border-md-dark",
    "bg-md-dark text-light",
    //"bg-dark text-light",
]

const NewsContent = () => {

    const articles = Config.newsArticles;

    const NewsSection = ({
        children,
        color,
    }) => {

        return (
            <section className={(sectionColors[color]) + " py-4 py-lg-5"}>
                {children}
            </section>
        );
    }

    return (
        <>
            <section className="px-5 pb-3 text-center" style={{ textShadow: "2px 2px 4px rgba(0,0,0,.1)" }}>
                <Container fluid className="container-1200">
                    <FadeIn>
                        <h1 className="d-flex justify-content-center align-items-center">
                            <AppNameInlineIcon name="news" showCopyright={false} iconName="newspaper-outline" iconsSize="1.25em" />
                        </h1>
                    </FadeIn>
                </Container>
            </section>

            <section className="bg-dark text-light py-4 py-lg-5">
                <Container fluid className="container-1200">
                    <FadeIn>
                        <h1><OBAName /> <AppNameInline name="news" showCopyright={false} /></h1>
                        <p>
                            Find all the <OBAName fontWeight={400} /> updates, news, feature releases + all our new apps to come... right here. Check back often for more updates. This are moving fast around here.
                        </p>
                    </FadeIn>
                </Container>
            </section>
            {articles.map((article, i) => {
                return (
                    <NewsSection
                        key={i}
                        color={i % sectionColors.length}
                    >
                        <Container fluid className="position-relative container-1200">
                            {i === 0 && (

                                <div
                                    className="position-absolute bg-light-dark border border-light rounded-pill shadow text-light ms-2 px-5 py-1"
                                    style={{ left: 0, top: isDesktop ? -20 : 10 }}
                                >

                                    <AppNameInline name={<>news.latest</>} fontWeight={400} showCopyright={false} />

                                </div>
                            )}
                            <FadeIn>
                                <Row className="g-5">
                                    {article.showImg && article.imgPos === "left" && (
                                        <Col sm="4">

                                            <img src={i % sectionColors.length === 0 ? "https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-top-White.png" : "https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-top-Black.png"} alt="Article visual" className="img-fluid" />

                                        </Col>
                                    )}
                                    <Col>
                                        <article className="">

                                            <Article
                                                key={i}
                                                article={article}
                                            />

                                        </article>

                                    </Col>
                                    {article.showImg && article.imgPos === "right" && (
                                        <Col sm="4">

                                            <img src={i % sectionColors.length === 0 ? "https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-top-White.png" : "https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-top-Black.png"} alt="Article visual" className="img-fluid" />

                                        </Col>
                                    )}
                                </Row>
                            </FadeIn>
                        </Container>
                    </NewsSection>
                );
            })}

        </>
    );

};

// NewsContent.propTypes = {

// }

// NewsContent.defaultProps = {

// };

export default NewsContent;