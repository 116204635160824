import React, { useState, useEffect, useRef } from "react";
//import { NavLink } from "react-router-dom";
import Config from "../data/Config";
import ProfileModalFrame from "../components/shared/ProfileModalFrame";
import LightboxMain from "../components/lightbox/LightboxMain";
import { Row, Col, } from "react-bootstrap";
import {
    isDesktop,
    //isMobileOnly,
    //isMobile,
    //isTablet,
} from "react-device-detect";
//import Cookies from 'universal-cookie';
import gtag from 'ga-gtag';
//import Config from "../data/Config";

//import BtnStackedLink from "../components/buttons/BtnStackedLink";
import CollectionRow from "../components/collections/CollectionRow";
import CollectionColumnNoHeader from "../components/collections/CollectionColumnNoHeader";
import SliderInsideScrollRow from "../components/collections/SliderInsideScrollRow";
import ConnectImageStreamPostCard from "../components/cards/ConnectImageStreamPostCard";

const ProfileModal = ({
    user,
    isAuthenticated,
    isDark,
    setShowProfileModal,
    toggleChat,
    toggleLive,
}) => {

    const [_showLightbox, _setShowLightbox] = useState(false);
    const [_lightBoxContent, _setLightBoxContent] = useState(null);

    const _connectPhotoStream = Config.connectPhotoStream;

    const _gtagSent = useRef(false);
    // const _cookies = useRef(new Cookies());
    // const _cookieOptions = {
    //     maxAge: 3153600000,
    //     sameSite: true,
    //     path: window.location.origin.replace("www.", "")
    // }

    useEffect(() => {

        if (_showLightbox) {
            document.body.classList.add("lightbox-open");
        } else {
            document.body.classList.remove("lightbox-open");
        }

    }, [_showLightbox]);

    useEffect(() => {
        if (!_gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Profile About',
                page_title: 'Profile',
            });

            _gtagSent.current = true;
        }
    }, [_gtagSent]);

    const showLightBoxWindow = (data) => {

        _setLightBoxContent(data.imgs);
        _setShowLightbox(true);
    }

    const updateScrollPos = (target, scrollTo, type) => {

        target.scroll({ top: scrollTo, behavior: type });
    }

    return (
        <>
            <ProfileModalFrame user={user} isDark={isDark} showCarousel={true} blur={false} showLightBoxWindow={showLightBoxWindow} setShowProfileModal={setShowProfileModal} toggleChat={toggleChat} toggleLive={toggleLive}>
                <CollectionRow className={"align-items-stretch mt-3"}>
                    <CollectionColumnNoHeader
                        xs="12"
                        sm={null}
                        md={null}
                        lg={null}
                        xl={null}
                        isDark={isDark}
                        className={""}
                    >
                        <div className="pt-2 px-sm-2">
                            <h4>Recent Uploads - <small>Profiles &amp; Covers</small> <small>({_connectPhotoStream.length})</small></h4>
                            <div className="mb-4">
                                {isDesktop ? (
                                    <Row className="flex-nowrap align-items-center justify-content-start gx-2">
                                        {_connectPhotoStream && _connectPhotoStream.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-0" xs="3" sm="4" lg="2">
                                                    <ConnectImageStreamPostCard
                                                        isDark={isDark}
                                                        blur={false}
                                                        user={user}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        i={i}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                ) : (
                                    <SliderInsideScrollRow>
                                        <Row className="flex-nowrap align-items-center justify-content-start">
                                            {_connectPhotoStream && _connectPhotoStream.map((item, i) => {
                                                return (
                                                    <Col key={i} className="mb-2" xs="4" sm="3" lg="2">
                                                        <ConnectImageStreamPostCard
                                                            isDark={isDark}
                                                            blur={false}
                                                            user={user}
                                                            status={i < 8 ? "online" : "recentlyOnline"}
                                                            data={item}
                                                            handleClick={showLightBoxWindow}
                                                            i={i}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </SliderInsideScrollRow>
                                )}
                            </div>
                            {/* <h4>Snapshot</h4>
                            <Row className="ps-3 mb-4 g-1" style={{ fontSize: ".85rem" }}>
                            <Col xs="3">
                                    Current Area:
                                </Col>
                                <Col xs="9">
                                    Houston, TX Area
                                </Col>
                                <Col xs="3">
                                    Current Status:
                                </Col>
                                <Col xs="9">
                                    Married
                                </Col>
                                <Col xs="3">
                                    Joined:
                                </Col>
                                <Col xs="9">
                                    08/23/22
                                </Col>
                                <Col xs="3">
                                    Interests:
                                </Col>
                                <Col xs="9">
                                    Nudists, Exhibitionists, Voyeurists, Lifestyle
                                </Col>
                                <Col xs="3">
                                    Years in Lifestyle:
                                </Col>
                                <Col xs="9">
                                    5 Yrs, 2 Mo
                                </Col>
                                <Col xs="3">
                                    Play Preferences:
                                </Col>
                                <Col xs="9">
                                    Single Women, Single Men, Couples
                                </Col>
                                <Col xs="3">
                                    Play Types:
                                </Col>
                                <Col xs="9">
                                    Soft, Full, Individual
                                </Col>
                            </Row> */}

                            {/* <h4>Summary</h4>
                            <p className="ps-3 mb-4" style={{ fontSize: ".85rem" }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut bibendum diam vel lectus vestibulum, vitae viverra erat tempor. Suspendisse sit amet tincidunt lacus, ut rutrum velit. Phasellus non dapibus tellus. Orci varius natoque penatibus et magnis dis parturient montes...
                            </p>

                            <div className="text-center mb-4">
                                <BtnStackedLink
                                bsVariant={isDark ? "dark" : "light"}
                                bsSize={"xs"}
                                btnClasses="rounded-pill shadow-sm border border-2 border-primary pe-3"
                                label={<><span className="text-primary">EXPLORE</span> Full Profile</>}
                                sublabel={"Info, posts, uploads, more"}
                                iconSize={26}
                                iconName="arrow-redo-sharp"
                                useLink={true}
                                href="/profile/activity"
                                target=""
                                handleClick={(e) => {

                                    e.stopPropagation();
                                    setShowProfileModal(false);
                                }}
                            />
                            </div> */}
                        </div>

                    </CollectionColumnNoHeader>
                </CollectionRow>

            </ProfileModalFrame>

            {_showLightbox && (
                <LightboxMain
                    lightBoxContent={_lightBoxContent}
                    user={user}
                    isDark={isDark}
                    showComments={true}
                    updateScrollPos={updateScrollPos}
                    setShowLightbox={_setShowLightbox}
                    blur={false}
                />
            )}
        </>
    );

};

export default ProfileModal;