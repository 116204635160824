import React, { useEffect, useRef, useState } from "react";
import {
    isDesktop,
    isMobileOnly,
    isMobile,
    isTablet,
} from "react-device-detect";
import Cookies from 'universal-cookie';
import gtag from 'ga-gtag';
import Config from "../data/Config";
import { Row, Col, Badge } from "react-bootstrap";


import BtnStackedLink from "../components/buttons/BtnStackedLink";
import MainModal from "../components/shared/MainModal";
import Collection from "../components/collections/Collection";
import CollectionRow from "../components/collections/CollectionRow";
import CollectionColumn from "../components/collections/CollectionColumn";
import ScrollAreaVertical from "../components/shared/ScrollAreaVertical";
import SliderInsideScrollRow from "../components/collections/SliderInsideScrollRow";
import EventCardImage300x250 from "../components/cards/EventCardImage300x250";
import EventModalContent from "../components/link/events/EventModalContent";
import MediaPostCard from "../components/cards/MediaPostCard";
import ReelPostCard from "../components/cards/ReelPostCard";
import AdSelectorHub from "../components/ads/AdSelectorHub";
import MsgList from "../components/connect/MsgList";
import LightboxMain from "../components/lightbox/LightboxMain";
import ScrollAreaHorizontal from "../components/shared/ScrollAreaHorizontal";
import MemberListItemSm from "../components/cards/ActiveMemberCardSm";
//import CalendarPreviewCard from "../components/cards/CalendarPreviewCard";
//import ConnectImageStreamPostCard from "../components/cards/ConnectImageStreamPostCard";
//import LiveVideoStreamPostCard from "../components/cards/LiveVideoStreamPostCard";

import HubScreenFrameWithProfile from "../components/shared/HubScreenFrameWithProfile";

const HubPreview = ({
    user,
    isDark,
    setShowProfileModal,
    setShowNotificationsMenu,
    showNotificationsMenu,
    setShowSettingsModal,
    setShowProfileSelector,
}) => {

    const [_showMainModal, _setShowMainModal] = useState(false);
    const [_mainModalContent, _setMainModalContent] = useState(<></>);
    const [_removeNavSpacing, _setRemoveNavSpacing] = useState(false);
    const [_mainModalMaxW, _setMainModalMaxW] = useState("");
    const [_showSliders, _setShowSliders] = useState(true);
    const [_blur, _setBlur] = useState(true);
    const [_showAds, _setShowAds] = useState(true);
    const [_showLightbox, _setShowLightbox] = useState(false);
    const [_lightBoxContent, _setLightBoxContent] = useState(null);

    const _memberPhotoVideoPostData = Config.memberPhotoVideoPosts; //memberPosts
    const _memberClips = Config.memberClips; //memberClips
    const _eventBanners = Config.eventData; //eventBanners
    const _shopProducts = Config.shopProducts; //Shop data
    const _activeMembers = Config.memberStatusList; //Active Members
    //const _calendarPreviewData = Config.calendarEvents; //Calendar Events
    //const _connectPhotoStream = Config.connectPhotoStream; //Connect Photo Stream
    //const _liveVideoStream = Config.liveVideoStream; //Live Video Stream


    //const _imgPath = Config.imgPath;

    const _gtagSent = useRef(false);
    const _cookies = useRef(new Cookies());
    const _cookieOptions = {
        maxAge: 3153600000,
        sameSite: true,
        path: window.origin.replace("www.", "")
    }

    useEffect(() => {

        if (_showLightbox) {
            document.body.classList.add("lightbox-open");
        } else {
            document.body.classList.remove("lightbox-open");
        }
    });

    useEffect(() => {

        const sectionViewCookie = _cookies.current.get("sectionView", { path: window.origin.replace("www.", "") });

        if (sectionViewCookie === "slider") {

            !_showSliders && _setShowSliders(true);

        } else if (sectionViewCookie === "grid") {

            _showSliders && _setShowSliders(false);

        }

    }, [_showSliders, _cookies]);

    const setSliders = () => {

        if (_showSliders) {

            _cookies.current.set("sectionView", "grid", { _cookieOptions });
            _setShowSliders(false);

        } else {

            _cookies.current.set("sectionView", "slider", { _cookieOptions });
            _setShowSliders(true);
        }

    };

    useEffect(() => {
        if (!_gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Hub Preview',
                page_title: 'Hub',
            });

            _gtagSent.current = true;
        }
    }, [_gtagSent]);

    const updateScrollPos = (target, scrollTo, type) => {

        target.scroll({ top: scrollTo, behavior: type });
    }

    const renderEventModal = () => {

        _setMainModalMaxW("container-1000");
        _setRemoveNavSpacing(true);
        _setMainModalContent(
            <EventModalContent
                isDark={isDark}
            />
        );
        _setShowMainModal(true);
    };

    const showLightBoxWindow = (data) => {

        _setLightBoxContent(data.imgs);
        _setShowLightbox(true);
    }

    return (
        <>
            <HubScreenFrameWithProfile
                user={user}
                isDark={isDark}
                showCarousel={_showAds}
                setShowProfileModal={setShowProfileModal}
                setShowNotificationsMenu={setShowNotificationsMenu}
                showNotificationsMenu={showNotificationsMenu}
                setShowSettingsModal={setShowSettingsModal}
                setShowProfileSelector={setShowProfileSelector}
            >
                {/* LINK */}
                <Collection
                    appName="link"
                    tagline={<>Link to other couples and singles</>}
                    isDark={isDark}
                    showAds={_showAds}
                    setShowAds={_setShowAds}
                    showScrollBtn={true}
                    showSliders={_showSliders}
                    setShowSliders={setSliders}
                    showBlurBtn={true}
                    blur={_blur}
                    setBlur={_setBlur}
                    iconName="share-social-outline"
                    appUrl={"/link"}
                >

                    <CollectionRow className={""}>
                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={null}
                            xl={null}
                            isDark={isDark}
                            heading={<>Latest Events</>}
                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>({_eventBanners.length})</small></>}
                            iconName={"calendar-number-outline"}
                            label={"See all events in the link app..."}
                            appName="events"
                        >
                            {_showSliders ? (
                                <SliderInsideScrollRow isDark={isDark} showScrollIcons={isDesktop}>
                                    <Row className={`${isMobileOnly && "gx-2 "}justify-content-start flex-nowrap`} style={{ fontSize: ".70em" }}>
                                        {_showSliders && _eventBanners.map((item, i) => {

                                            return (
                                                <Col key={i} xs="6" sm="3" md={null} lg="20perc" xl={null} className="text-start mb-1">
                                                    <EventCardImage300x250
                                                        //eventId={"Event_ID"}
                                                        isDark={isDark}
                                                        imgURL={item.img}
                                                        vendor={item.vendor}
                                                        imgAltTitle={"Logo for one.bad.apple"}
                                                        //showBtns={false}
                                                        handleClick={renderEventModal}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </SliderInsideScrollRow>
                            ) : (
                                <Row className="justify-content-start flex-wrap" style={{ fontSize: ".70em" }}>
                                    {!_showSliders && _eventBanners.map((item, i) => {

                                        return (
                                            <Col key={i} xs="6" sm="6" md={null} lg="20perc" xl={null} className="text-start mb-4">
                                                <EventCardImage300x250
                                                    //eventId={"Event_ID"}
                                                    isDark={isDark}
                                                    imgURL={item.img}
                                                    vendor={item.vendor}
                                                    imgAltTitle={"Logo for one.bad.apple"}
                                                    //showBtns={false}
                                                    handleClick={renderEventModal}
                                                />
                                            </Col>
                                        )

                                    })}
                                </Row>
                            )}

                        </CollectionColumn>
                    </CollectionRow>

                    <CollectionRow className={""}>
                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={null}
                            xl={null}
                            isDark={isDark}
                            heading={<>Member Pics/Vids</>}
                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{`(${_memberPhotoVideoPostData.length})`}</small></>}
                            iconName={"images-outline"}
                            label={"See all member updates in the link app..."}
                            appName="events"
                        >
                            {_showSliders ? (
                                <SliderInsideScrollRow isDark={isDark} showScrollIcons={isDesktop}>
                                    {/* SCROLL VIEW */}
                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                        {_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-1" xs="6" sm="4" lg="20perc">
                                                    <MediaPostCard
                                                        i={i}
                                                        isDark={isDark}
                                                        isMobile={isMobile}
                                                        blur={_blur}
                                                        setBlur={_setBlur}
                                                        user={user}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </SliderInsideScrollRow>
                            ) : (

                                <Row className="justify-content-start flex-wrap">
                                    {/* GRID VIEW */}
                                    {!_showSliders && _memberPhotoVideoPostData && _memberPhotoVideoPostData.map((item, i) => {
                                        return (
                                            <Col key={i} className="mb-4" xs="6" sm="4" lg="20perc">
                                                <MediaPostCard
                                                    i={i}
                                                    isDark={isDark}
                                                    isMobile={isMobile}
                                                    blur={_blur}
                                                    setBlur={_setBlur}
                                                    user={user}
                                                    data={item}
                                                    handleClick={showLightBoxWindow}
                                                    status={i < 8 ? "online" : "recentlyOnline"}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )}
                        </CollectionColumn>
                    </CollectionRow>

                    <CollectionRow className={""}>
                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={null}
                            xl={null}
                            isDark={isDark}
                            heading={<>Member Clips</>}
                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>({_memberClips.length})</small></>}
                            iconName={"film-outline"}
                            label={"See all clips in the link app..."}
                            appName="events"
                        >
                            {_showSliders ? (
                                <SliderInsideScrollRow isDark={isDark} showScrollIcons={isDesktop}>
                                    {/* SCROLL VIEW */}
                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>

                                        {_showSliders && _memberClips.map((item, i) => {
                                            return (
                                                <Col key={i} className="mb-1" xs="6" sm="4" lg={"12_5perc"}>
                                                    <ReelPostCard
                                                        //i={i}
                                                        isDark={isDark}
                                                        isMobile={isMobile}
                                                        blur={_blur}
                                                        setBlur={_setBlur}
                                                        user={user}
                                                        data={item}
                                                        handleClick={showLightBoxWindow}
                                                        status={i < 8 ? "online" : "recentlyOnline"}
                                                    />
                                                </Col>
                                            );
                                        })}

                                    </Row>
                                </SliderInsideScrollRow>
                            ) : (
                                <Row className="justify-content-start flex-wrap">
                                    {/* GRID VIEW */}

                                    {!_showSliders && _memberClips.map((item, i) => {
                                        return (
                                            <Col key={i} className="mb-4" xs="6" sm="4" lg={"12_5perc"}>
                                                <ReelPostCard
                                                    //i={i}
                                                    isDark={isDark}
                                                    isMobile={isMobile}
                                                    blur={_blur}
                                                    setBlur={_setBlur}
                                                    user={user}
                                                    data={item}
                                                    handleClick={showLightBoxWindow}
                                                    status={i < 8 ? "online" : "recentlyOnline"}
                                                />
                                            </Col>
                                        );
                                    })}

                                </Row>
                            )}
                        </CollectionColumn>
                    </CollectionRow>

                </Collection>
                {_showAds && (
                    <AdSelectorHub
                        isDark={isDark}
                        isMobileOnly={isMobileOnly}
                        isTablet={isTablet}
                        useAlt={false}
                    />
                )}

                {/* CONNECT */}
                <Collection
                    appName="connect"
                    tagline={<>Connect with real-time messaging, share links, images + more</>}
                    isDark={isDark}
                    showAds={_showAds}
                    setShowAds={_setShowAds}
                    showScrollBtn={true}
                    showSliders={_showSliders}
                    showBlurBtn={true}
                    blur={_blur}
                    setBlur={_setBlur}
                    setShowSliders={setSliders}
                    iconName="chatbubbles-outline"
                    appUrl={"/connect"}
                >
                    <CollectionRow className={""}>
                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={8}
                            xl={null}
                            isDark={isDark}
                            heading={
                                <div className="d-flex align-items-center">
                                    <div style={{ height: "1em", lineHeight: "1em" }}>Latest Chat Message</div>
                                </div>
                            }
                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{null}</small></>}
                            iconName={"chatbubble-ellipses-outline"}
                            label={"See all messages in the connect app..."}
                            appName="events"
                        >
                            <div className="position-relative" style={{ height: 455 }}>
                                <Row
                                    className="position-absolute top-0 end-0 align-items-stretch start-0 gx-2"
                                    style={{
                                        zIndex: 5,
                                        height: "100%"
                                    }}
                                >
                                    <Col sm="4" lg="3" className={`${isDark ? "text-light" : "text-dark"} pb-3`}>
                                        {/* PROFILE PIC */}
                                        <>
                                            <Row>
                                                <Col xs="4" lg="12">
                                                    <div
                                                        className={`${_blur ? "img-blur" : ""} position-relative rounded-1 shadow d-flex align-items-end justify-content-end mb-2`}
                                                        style={{
                                                            //height: isMobileOnly ? "calc(100vw - 80px)" : 190,
                                                            aspectRatio: "1 / 1",
                                                            backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg)",
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center",
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                            showLightBoxWindow({
                                                                imgs: [
                                                                    { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg", type: "image" },
                                                                ]
                                                            });
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex align-items-center position-absolute top-0 end-0 me-1 mt-1"
                                                            style={{
                                                                zIndex: 5,
                                                            }}
                                                        >
                                                            <Badge
                                                                bg={"dark"}
                                                                text={"primary"}
                                                                title={"Online Now..."}
                                                                className="rounded-circle shadow-sm"
                                                                style={{
                                                                    fontSize: ".85rem",
                                                                    paddingTop: 3,
                                                                    paddingBottom: 2,
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                }}
                                                            >
                                                                <ion-icon name={"checkmark-circle-sharp"}></ion-icon>
                                                            </Badge>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs="8" lg="12">
                                                    <div className="mb-0">
                                                        <div style={{ height: "1.4em", fontSize: ".95rem", }}>One.Bad.Apple</div>
                                                        <div style={{ height: "1.6em", fontSize: ".85rem", }}>Couple</div>
                                                        <div style={{ height: "1.4em", fontSize: ".75rem", }}>Status: Online Now</div>
                                                    </div>
                                                </Col>
                                                <Col lg="12">
                                                    <Row className={isMobileOnly && "gx-2"}>
                                                        <Col xs="4" lg="12">
                                                            <div className="d-grid mt-2 mt-lg-3">
                                                                <BtnStackedLink
                                                                    bsVariant={isDark ? "dark" : "dark"}
                                                                    bsSize={"xs"}
                                                                    btnClasses="rounded-pill border border-2 border-primary shadow-sm"
                                                                    label={"Connect"}
                                                                    sublabel={!isMobile ? "Continue your chat" : null}
                                                                    iconSize={!isMobile ? 26 : 16}
                                                                    iconName="chatbubbles-outline"
                                                                    useLink={true}
                                                                    href="/"
                                                                    target=""
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col xs="4" lg="12">
                                                            <div className="d-grid mt-2">
                                                                <BtnStackedLink
                                                                    bsVariant={isDark ? "outline-light" : "outline-dark"}
                                                                    bsSize={"xs"}
                                                                    btnClasses="rounded-pill border-2 shadow-sm"
                                                                    label={"Profile"}
                                                                    sublabel={!isMobile ? "See latest activity" : null}
                                                                    iconSize={!isMobile ? 26 : 16}
                                                                    iconName="checkmark-circle-outline"
                                                                    useLink={true}
                                                                    href="/"
                                                                    target=""
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col xs="4" lg="12">
                                                            <div className="d-grid mt-2">
                                                                <BtnStackedLink
                                                                    bsVariant={isDark ? "outline-light" : "outline-dark"}
                                                                    bsSize={"xs"}
                                                                    btnClasses="rounded-pill border-2 shadow-sm"
                                                                    label={"Flirt"}
                                                                    sublabel={!isMobile ? "Show your interest" : null}
                                                                    iconSize={!isMobile ? 26 : 16}
                                                                    iconName="heart-outline"
                                                                    useLink={true}
                                                                    href="/"
                                                                    target=""
                                                                />
                                                            </div>
                                                        </Col>
                                                        
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </>

                                    </Col>
                                    <Col
                                        className={`${isDark ? "text-light" : "text-dark"} d-flex flex-column pb-3`}
                                        style={{
                                            height: !isMobile ? "100%" : "60%"
                                        }}
                                    >
                                        <div className="position-relative oveflow-hidden" style={{ height: "100%", fontSize: ".85rem" }}>
                                            {/* ACTIVE CHAT LIST */}
                                            <MsgList
                                                isDark={isDark}
                                                updateScrollPos={updateScrollPos}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </CollectionColumn>

                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={4}
                            xl={null}
                            isDark={isDark}
                            heading={<>Active Members</>}
                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".65em", }}>{<>({_activeMembers.length})</>}</small></>}
                            iconName={"sync-outline"}
                            label={"See all members in the connect app..."}
                            appName="events"
                        >
                            <div className={(isDark ? "text-light" : "text-dark") + " d-flex flex-column"} style={{ height: !isMobile ? 450 : "auto" }}>
                                {!isMobile ? (
                                    <ScrollAreaVertical>
                                        <Row className="gx-2">
                                            {_activeMembers && _activeMembers.map((item, i) => {

                                                return (
                                                    <Col key={i} className="mb-2" xs="4" lg="4">

                                                        <MemberListItemSm
                                                            // iconName="chatbubble-ellipses-outline"
                                                            // iconSize={18}
                                                            // bsIconVariant="icon-light"
                                                            blur={_blur}
                                                            data={item}
                                                            handleClick={showLightBoxWindow}
                                                            status={i < 8 ? "online" : "recentlyOnline"}
                                                        />

                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </ScrollAreaVertical>
                                ) : (
                                    _showSliders ? (
                                        <ScrollAreaHorizontal>
                                            <Row className="flex-nowrap gx-2">
                                                {_activeMembers && _activeMembers.map((item, i) => {
                                                    return (
                                                        <Col key={i} className="overflow-hidden mb-2" xs="4" lg="4">

                                                            <MemberListItemSm
                                                                // iconName="chatbubble-ellipses-outline"
                                                                // iconSize={18}
                                                                // bsIconVariant="icon-light"
                                                                blur={_blur}
                                                                data={item}
                                                                handleClick={showLightBoxWindow}
                                                                status={i < 8 ? "online" : "recentlyOnline"}
                                                            />
                                                        </Col>
                                                    );
                                                })}

                                            </Row>
                                        </ScrollAreaHorizontal>
                                    ) : (
                                        <Row className="flex-wrap gx-2">
                                            {_activeMembers && _activeMembers.map((item, i) => {
                                                return (
                                                    <Col key={i} className="overflow-hidden mb-2" xs="4" lg="4">
                                                        <MemberListItemSm
                                                            // iconName="chatbubble-ellipses-outline"
                                                            // iconSize={18}
                                                            // bsIconVariant="icon-light"
                                                            blur={_blur}
                                                            data={item}
                                                            handleClick={showLightBoxWindow}
                                                            status={i < 8 ? "online" : "recentlyOnline"}
                                                        />
                                                    </Col>
                                                );

                                            })}
                                        </Row>
                                    )
                                )}
                                <div className="d-grid mt-2 mt-lg-2">
                                    <BtnStackedLink
                                        bsVariant={isDark ? "outline-light" : "outline-dark"}
                                        bsSize={"xs"}
                                        btnClasses="rounded-pill shadow-sm"
                                        label={"Search All Members"}
                                        sublabel={"Find people to connect with"}
                                        iconSize={28}
                                        iconName="search-outline"
                                        useLink={true}
                                        href="/"
                                        target=""
                                    />
                                </div>
                            </div>
                        </CollectionColumn>
                    </CollectionRow>
                </Collection>

                {_showAds && (
                    <AdSelectorHub
                        isDark={isDark}
                        isMobileOnly={isMobileOnly}
                        isTablet={isTablet}
                    />
                )}

                {/* CONNECT.LIVE */}
                <Collection
                    appName="connect.live"
                    tagline={<>Connect with live video chats or leave video messages + more</>}
                    isDark={isDark}
                    showAds={_showAds}
                    setShowAds={_setShowAds}
                    showScrollBtn={true}
                    showSliders={_showSliders}
                    setShowSliders={setSliders}
                    showBlurBtn={true}
                    blur={_blur}
                    setBlur={_setBlur}
                    iconName="videocam-outline"
                    appUrl={"/connect-live"}
                >
                    <CollectionRow className={""}>
                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={8}
                            xl={null}
                            isDark={isDark}
                            heading={
                                <div className="d-flex align-items-center">
                                    <div style={{ height: "1em", lineHeight: "1em" }}>Latest Video Message</div>
                                </div>
                            }
                            smallHeading={null}
                            iconName={"recording-outline"}
                            label={"See all messages in the connect.live app..."}
                            appName="events"
                        >
                            <div className="position-relative" style={{ height: 455 }}>
                                <Row
                                    className="align-items-stretch justify-content-between pb-3 gx-3 gx-sm-2"
                                    style={{
                                        zIndex: 5,
                                        height: "100%"
                                    }}
                                >
                                    <Col sm="4" lg="3" className={`${isDark ? "text-light" : "text-dark"} pb-3 pb-sm-0`}>
                                        {/* PROFILE PIC */}
                                        <>
                                            <Row>
                                                <Col xs="4" lg="12">
                                                    <div
                                                        className={`${_blur ? "img-blur" : ""} position-relative rounded-1 shadow d-flex align-items-end justify-content-end mb-2`}
                                                        style={{
                                                            //height: isMobileOnly ? "calc(100vw - 80px)" : 190,
                                                            aspectRatio: "1 / 1",
                                                            backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20220904_005637-small.jpg)",
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center",
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                            showLightBoxWindow({
                                                                imgs: [
                                                                    { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20220904_005637-large.jpg", type: "image" },
                                                                ]
                                                            });
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex align-items-center position-absolute top-0 end-0 me-1 mt-1"
                                                            style={{
                                                                zIndex: 5,
                                                            }}
                                                        >
                                                            <Badge
                                                                bg={"dark"}
                                                                text={"primary"}
                                                                title={"Online Now..."}
                                                                className="rounded-circle shadow-sm"
                                                                style={{
                                                                    fontSize: ".85rem",
                                                                    paddingTop: 3,
                                                                    paddingBottom: 2,
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                }}
                                                            >
                                                                <ion-icon name={"checkmark-circle-sharp"}></ion-icon>
                                                            </Badge>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs="8" lg="12">
                                                    <div className="mb-0">
                                                        <div style={{ height: "1.4em", fontSize: ".95rem", }}>One.Bad.Apple</div>
                                                        <div style={{ height: "1.6em", fontSize: ".85rem", }}>Couple</div>
                                                        <div style={{ height: "1.4em", fontSize: ".75rem", }}>Status: Online Now</div>
                                                    </div>
                                                </Col>
                                                <Col lg="12">
                                                    <Row className={isMobileOnly && "gx-2"}>
                                                        <Col xs="4" lg="12">
                                                            <div className="d-grid mt-2 mt-lg-3">
                                                                <BtnStackedLink
                                                                    bsVariant={isDark ? "dark" : "dark"}
                                                                    bsSize={"xs"}
                                                                    btnClasses="rounded-pill border border-2 border-primary shadow-sm"
                                                                    label={"Live"}
                                                                    sublabel={!isMobile ? "Return they're call" : null}
                                                                    iconSize={!isMobile ? 26 : 16}
                                                                    iconName="videocam-outline"
                                                                    useLink={true}
                                                                    href="/"
                                                                    target=""
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col xs="4" lg="12">
                                                            <div className="d-grid mt-2">
                                                                <BtnStackedLink
                                                                    bsVariant={isDark ? "outline-light" : "outline-dark"}
                                                                    bsSize={"xs"}
                                                                    btnClasses="rounded-pill border-2 shadow-sm"
                                                                    label={"Profile"}
                                                                    sublabel={!isMobile ? "See latest activity" : null}
                                                                    iconSize={!isMobile ? 26 : 16}
                                                                    iconName="checkmark-circle-outline"
                                                                    useLink={true}
                                                                    href="/"
                                                                    target=""
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col xs="4" lg="12">
                                                            <div className="d-grid mt-2">
                                                                <BtnStackedLink
                                                                    bsVariant={isDark ? "outline-light" : "outline-dark"}
                                                                    bsSize={"xs"}
                                                                    btnClasses="rounded-pill border-2 shadow-sm"
                                                                    label={"Flirt"}
                                                                    sublabel={!isMobile ? "Show your interest" : null}
                                                                    iconSize={!isMobile ? 26 : 16}
                                                                    iconName="heart-outline"
                                                                    useLink={true}
                                                                    href="/"
                                                                    target=""
                                                                />
                                                            </div>
                                                        </Col>
                                                        
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </>
                                    </Col>
                                    <Col className={`${isDark ? "text-light" : "text-dark"} ps-3 overflow-hidden`}>
                                        <div
                                            className={`${_blur ? "img-blur" : ""} position-relative rounded-1 shadow pe-0 pb-0`}
                                            style={{
                                                height: "100%",
                                                aspectRatio: !isMobile ? "auto" : "16 / 9",
                                                backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg)",
                                                backgroundSize: "cover",
                                                backgroundPosition: "center"
                                            }}
                                        >
                                            <div
                                                className="d-flex align-items-center justify-content-center position-absolute top-0 end-0 bottom-0 start-0 rounded-1 text-light"
                                                style={{
                                                    fontSize: "3rem",
                                                    zIndex: 5,
                                                    pointerEvents: "none"
                                                }}
                                            >
                                                <ion-icon name={"play-outline"}></ion-icon>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </CollectionColumn>

                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={4}
                            xl={null}
                            isDark={isDark}
                            heading={<>Active Members</>}
                            smallHeading={<> <small className="ps-1" style={{ fontSize: ".65em", }}>{<>({_activeMembers.length})</>}</small></>}
                            iconName={"sync-outline"}
                            label={"See all members in the connect.live app..."}
                            appName="events"
                        >
                            <div className={(isDark ? "text-light" : "text-dark") + " d-flex flex-column"} style={{ height: !isMobile ? 450 : "auto" }}>
                                {!isMobile ? (
                                    <ScrollAreaVertical>
                                        <Row className="gx-2">
                                            {_activeMembers && _activeMembers.map((item, i) => {
                                                return (
                                                    <Col key={i} className="mb-2" xs="4" lg="4">

                                                        <MemberListItemSm
                                                            iconName="chatbubble-ellipses-outline"
                                                            iconSize={18}
                                                            bsIconVariant="icon-light"
                                                            blur={_blur}
                                                            data={item}
                                                            handleClick={showLightBoxWindow}
                                                            status={i < 8 ? "online" : "recentlyOnline"}
                                                        />

                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </ScrollAreaVertical>
                                ) : (

                                    _showSliders ? (
                                        <ScrollAreaHorizontal>
                                            <Row className="flex-nowrap gx-2">
                                                {_activeMembers && _activeMembers.map((item, i) => {

                                                    return (
                                                        <Col key={i} className="overflow-hidden mb-2" xs="4" lg="4">
                                                            <MemberListItemSm
                                                                iconName="chatbubble-ellipses-outline"
                                                                iconSize={18}
                                                                bsIconVariant="icon-light"
                                                                blur={_blur}
                                                                data={item}
                                                                handleClick={showLightBoxWindow}
                                                                status={i < 8 ? "online" : "recentlyOnline"}
                                                            />
                                                        </Col>
                                                    );

                                                })}
                                            </Row>
                                        </ScrollAreaHorizontal>
                                    ) : (
                                        <Row className="flex-wrap gx-2">
                                            {_activeMembers && _activeMembers.map((item, i) => {

                                                return (
                                                    <Col key={i} className="overflow-hidden mb-2" xs="4" lg="4">
                                                        <MemberListItemSm
                                                            iconName="chatbubble-ellipses-outline"
                                                            iconSize={18}
                                                            bsIconVariant="icon-light"
                                                            blur={_blur}
                                                            data={item}
                                                            handleClick={showLightBoxWindow}
                                                            status={i < 8 ? "online" : "recentlyOnline"}
                                                        />
                                                    </Col>
                                                );

                                            })}
                                        </Row>
                                    )

                                )}

                                <div className="d-grid mt-2 mt-lg-2">
                                    <BtnStackedLink
                                        bsVariant={isDark ? "outline-light" : "outline-dark"}
                                        bsSize={"xs"}
                                        btnClasses="rounded-pill shadow-sm"
                                        label={"Search All Members"}
                                        sublabel={"Find people to connect with"}
                                        iconSize={28}
                                        iconName="search-outline"
                                        useLink={true}
                                        href="/"
                                        target=""
                                    />
                                </div>
                            </div>
                        </CollectionColumn>
                    </CollectionRow>
                </Collection>

                {_showAds && (
                    <AdSelectorHub
                        isDark={isDark}
                        isMobileOnly={isMobileOnly}
                        isTablet={isTablet}
                        useAlt={true}
                    />
                )}

                {/* SHOP */}
                <Collection
                    appName="shop"
                    tagline={<>Shop quality branded/partner products and merchandise</>}
                    isDark={isDark}
                    showAds={_showAds}
                    setShowAds={_setShowAds}
                    showScrollBtn={true}
                    showSliders={_showSliders}
                    setShowSliders={setSliders}
                    showBlurBtn={true}
                    blur={_blur}
                    setBlur={_setBlur}
                    iconName="cart-outline"
                    appUrl={"/shop"}
                >
                    <CollectionRow className={""}>

                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg="8"
                            xl={null}
                            isDark={isDark}
                            heading={"New!"}
                            smallHeading={null}
                            label={"See all new items in the shop app..."}
                            appName="shop"
                        >
                            {_showSliders ? (

                                <SliderInsideScrollRow isDark={isDark} showScrollIcons={isDesktop}>
                                    <Row className={`${!isMobile ? "" : "gx-2 "}justify-content-start flex-nowrap`} style={{ fontSize: ".70em" }}>

                                        {_shopProducts && _shopProducts.slice(0, 8).map((item, i) => {

                                            return (

                                                <Col key={i} xs="6" sm="3" lg="3" className={(!_showSliders ? "mb-3 " : "mb-0 ") + "text-center text-dark"}>
                                                    <div className="bg-white rounded-1">
                                                        <a href={"/"} onClick={(e) => { e.preventDefault(); alert("Feature under development.") }}>
                                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                                                <img alt={item.label} title={item.label} src={item.img} className="img-fluid" style={{ maxHeight: 165 }} />
                                                            </div>
                                                        </a>
                                                        <p
                                                            className="text-center py-1 mb-2"
                                                            style={{
                                                                fontSize: ".70rem",
                                                                overflow: "hidden",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                                maxWidth: "96%",
                                                            }}
                                                        >
                                                            {item.label}
                                                        </p>
                                                    </div>
                                                </Col>
                                            );
                                        })}

                                    </Row>
                                </SliderInsideScrollRow>

                            ) : (

                                <Row className={`${!isMobile ? "" : "gx-2 "}justify-content-start flex-wrap`} style={{ fontSize: ".70em" }}>

                                    {_shopProducts && _shopProducts.slice(0, 8).map((item, i) => {

                                        return (

                                            <Col key={i} xs="6" sm="3" lg="3" className={(!_showSliders ? "mb-3 " : "mb-0 ") + "text-center text-dark"}>
                                                <div className="bg-white rounded-1">
                                                    <a href={"/"} onClick={(e) => { e.preventDefault(); alert("Feature under development.") }}>
                                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                                            <img alt={item.label} title={item.label} src={item.img} className="img-fluid" style={{ maxHeight: 165 }} />
                                                        </div>
                                                    </a>
                                                    <p
                                                        className="text-center py-1 mb-2"
                                                        style={{
                                                            fontSize: ".70rem",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis",
                                                            maxWidth: "96%",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        {item.label}
                                                    </p>
                                                </div>

                                            </Col>
                                        );
                                    })}

                                </Row>
                            )}
                        </CollectionColumn>

                        <CollectionColumn
                            xs="12"
                            md={null}
                            lg="4"
                            xl={null}
                            isDark={isDark}
                            heading={<><span className="text-primary">Your Cart</span></>}
                            smallHeading={<><small className="ms-auto mt-auto ps-1" style={{ fontSize: "0.75em" }}>(4 items)</small></>}
                            label="See all cart items in the cart app..."
                            appName="shop"

                        >
                            {_showSliders ? (

                                <SliderInsideScrollRow isDark={isDark} showScrollIcons={isDesktop}>
                                    <Row className={`${!isMobile ? "" : "gx-2 "}justify-content-start flex-nowrap`} style={{ fontSize: ".70em" }}>

                                        {_shopProducts && _shopProducts.slice(0, 4).map((item, i) => {

                                            return (

                                                <Col key={i} xs="6" sm="3" lg="6" className={(!_showSliders ? "mb-3 " : "mb-0 ") + "text-center text-dark"}>
                                                    <div className="bg-white rounded-1">
                                                        <a href={"/"} onClick={(e) => { e.preventDefault(); alert("Feature under development.") }}>
                                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                                                <img alt={item.label} title={item.label} src={item.img} className="img-fluid" style={{ maxHeight: 165 }} />
                                                            </div>
                                                        </a>
                                                        <p
                                                            className="text-center py-1 mb-2"
                                                            style={{
                                                                fontSize: ".70rem",
                                                                overflow: "hidden",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",

                                                            }}
                                                        >
                                                            {item.label}
                                                        </p>
                                                    </div>
                                                </Col>
                                            );
                                        })}

                                    </Row>
                                </SliderInsideScrollRow>

                            ) : (

                                <Row className={`${!isMobile ? "" : "gx-2 "}justify-content-start flex-wrap`} style={{ fontSize: ".70em" }}>

                                    {_shopProducts && _shopProducts.slice(0, 4).map((item, i) => {

                                        return (

                                            <Col key={i} xs="6" sm="3" lg="6" className={(!_showSliders ? "mb-3 " : "mb-0 ") + "text-center text-dark"}>
                                                <div className="bg-white rounded-1">
                                                    <a href={"/"} onClick={(e) => { e.preventDefault(); alert("Feature under development.") }}>
                                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                                            <img alt={item.label} title={item.label} src={item.img} className="img-fluid" style={{ maxHeight: 165 }} />
                                                        </div>
                                                    </a>
                                                    <p
                                                        className="text-center py-1 mb-2"
                                                        style={{
                                                            fontSize: ".70rem",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis",
                                                            maxWidth: "96%",
                                                        }}
                                                    >
                                                        {item.label}
                                                    </p>
                                                </div>

                                            </Col>
                                        );
                                    })}

                                </Row>
                            )}

                        </CollectionColumn>

                    </CollectionRow>

                    <CollectionRow className={""}>

                        <CollectionColumn
                            xs="12"
                            sm={null}
                            md={null}
                            lg={null}
                            xl={null}
                            isDark={isDark}
                            heading="Most Popular"
                            smallHeading={null}
                            label={"See all new items in the shop app..."}
                            appName="shop"
                        >
                            {_showSliders ? (

                                <SliderInsideScrollRow isDark={isDark} showScrollIcons={isDesktop}>
                                    <Row className={`${!isMobile ? "" : "gx-2 "}justify-content-start flex-nowrap`} style={{ fontSize: ".70em" }}>

                                        {_shopProducts && _shopProducts.slice(0, 12).map((item, i) => {

                                            return (

                                                <Col key={i} xs="6" sm="3" lg="2" className={(!_showSliders ? "mb-3 " : "mb-0 ") + "text-center text-dark"}>
                                                    <div className="bg-white rounded-1">
                                                        <a href={"/"} onClick={(e) => { e.preventDefault(); alert("Feature under development.") }}>
                                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                                                <img alt={item.label} title={item.label} src={item.img} className="img-fluid" style={{ maxHeight: 165 }} />
                                                            </div>
                                                        </a>
                                                        <p
                                                            className="text-center py-1 mb-2"
                                                            style={{
                                                                fontSize: ".70rem",
                                                                overflow: "hidden",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                                maxWidth: "96%",
                                                            }}
                                                        >
                                                            {item.label}
                                                        </p>
                                                    </div>
                                                </Col>
                                            );
                                        })}

                                    </Row>
                                </SliderInsideScrollRow>

                            ) : (

                                <Row className={`${!isMobile ? "" : "gx-2 "}justify-content-start flex-wrap`} style={{ fontSize: ".70em" }}>

                                    {_shopProducts && _shopProducts.slice(0, 12).map((item, i) => {

                                        return (

                                            <Col key={i} xs="6" sm="3" lg="2" className={(!_showSliders ? "mb-3 " : "mb-0 ") + "text-center text-dark"}>
                                                <div className="bg-white rounded-1">
                                                    <a href={"/"} onClick={(e) => { e.preventDefault(); alert("Feature under development.") }}>
                                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                                            <img alt={item.label} title={item.label} src={item.img} className="img-fluid" style={{ maxHeight: 165 }} />
                                                        </div>
                                                    </a>
                                                    <p
                                                        className="text-center py-1 mb-2"
                                                        style={{
                                                            fontSize: ".70rem",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis",
                                                            maxWidth: "96%",
                                                        }}
                                                    >
                                                        {item.label}
                                                    </p>
                                                </div>

                                            </Col>
                                        );
                                    })}

                                </Row>
                            )}
                        </CollectionColumn>

                    </CollectionRow>
                </Collection>
            </HubScreenFrameWithProfile>

            {_showMainModal && (
                <MainModal
                    showNav={_showMainModal}
                    isDark={isDark}
                    removeNaveSpacing={_removeNavSpacing}
                    modalColor={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} shadow`}
                    bsModalSize=""
                    dialogClassName={(`${_mainModalMaxW && _mainModalMaxW !== "" ? ` ${_mainModalMaxW}` : ""}`)}
                    contentClassName={`rounded-0${isDark ? " border border-dark" : " border border-secondary"}`}
                    showModal={true}
                    showLoading={true}
                    loadingMsg="Loading event..."
                    closeModal={() => {
                        _setShowMainModal(false);
                        _setMainModalContent(<></>);
                        _setMainModalMaxW("");
                    }}
                >

                    {_mainModalContent}

                </MainModal>
            )}


            {/* MEDIA LIGHTBOX WINDOW */}
            {_showLightbox && (
                <LightboxMain
                    lightBoxContent={_lightBoxContent}
                    user={user}
                    isDark={isDark}
                    showComments={true}
                    updateScrollPos={updateScrollPos}
                    setShowLightbox={_setShowLightbox}
                    blur={_blur}
                />)}
        </>
    );

};

export default HubPreview;