import React, { useEffect, useRef } from "react";
import Config from "../data/Config";
import gtag from 'ga-gtag';
import FadeIn from "react-fade-in/lib/FadeIn";
import LinkConnectFollow from "../banners/LinkConnectFollow";
import LinkIntroContent from "../content/LinkIntroContent";
import AppNameInline from "../components/text/AppNameInline";
//import BtnStdLink from "../components/buttons/BtnStdLink";
import BtnStackedLink from "../components/buttons/BtnStackedLink";

const LinkIntro = ({
    isAuthenticated,
}) => {

    const gtagSent = useRef(false);

    useEffect(() => {

        if (!gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Link Intro',
                page_title: 'Link Intro',
            });

            gtagSent.current = true;

        }

    }, [gtagSent]);

    return (
        <>
            <FadeIn>
                <LinkConnectFollow>
                    
                    <BtnStackedLink
                        bsVariant={"dark"}
                        bsSize={""}
                        btnClasses="rounded-pill border border-2 border-primary shadow-sm pe-3"
                        label={<><AppNameInline name="link" showCopyright={false} /> prototype</>}
                        sublabel={"See the latest preview screens"}
                        iconSize={36}
                        iconName="share-social-outline"
                        useLink={true}
                        href="/link/preview"
                        target=""
                    />
                </LinkConnectFollow>
            </FadeIn>
            <LinkIntroContent
                isAuthenticated={isAuthenticated} 
            />
        </>
    );

};

export default LinkIntro;