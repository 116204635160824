import React from "react";
import Config from "../../data/Config";
import PropTypes from 'prop-types';
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import PageNavTop from "../nav/PageNavTop";
import PageNavBottom from "../nav/PageNavBottom";

const MainLayout = ({
    children,
    isAuthenticated,
    appVersion,
    isDark,
    setIsDark,
    setShowHistory,
    setShowLogout,
    setShowLogin,
    setShowCreateAcct,
    setShowNotificationsMenu,
    showNotificationsMenu,
    setShowSettingsModal,
    setShowProfileSelector,
    toggleChat,
    toggleLive,
    user,
}) => {

    const imgPath = Config.imgPath + "logos/";

    return (
        <>
            <PageNavTop
                logo={`${imgPath}logo-text-Black.png`}
                //logo={"https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-Web-Adress-White-Logo.png"}
                logoAlt="Logo for One Bad Apple"
                logoMaxW={!isMobileOnly ? 200 : 110}
                btnIconBsVariant="icon-light"
                isAuthenticated={isAuthenticated}
                isDark={isDark}
                setIsDark={setIsDark}
                setShowLogin={setShowLogin}
                setShowLogout={setShowLogout}
                setShowCreateAcct={setShowCreateAcct}
                toggleChat={toggleChat}
                toggleLive={toggleLive}
                user={user}
                setShowNotificationsMenu={setShowNotificationsMenu}
                showNotificationsMenu={showNotificationsMenu}
                setShowSettingsModal={setShowSettingsModal}
                setShowProfileSelector={setShowProfileSelector}

            />
            <main className={(isMobileOnly ? "isMobileOnly" : "") + " nav-offset bg-light"} style={{ minHeight: "100vh" }}>
                {children}
            </main>
            <footer className="bg-dark text-light py-3 mb-5">
                <div className="mb-lg-0 pb-1">
                    <PageNavBottom
                        logo={`${imgPath}logo-text-Black.png`}
                        logoAlt="Logo for One Bad Apple"
                        logoMaxW={!isMobileOnly ? 300 : 200}
                        btnIconBsVariant="icon-light"
                        isAuthenticated={isAuthenticated}
                        appVersion={appVersion}
                        setShowHistory={setShowHistory}
                    />
                </div>
            </footer>
        </>
    );

};

MainLayout.propTypes = {
    children: PropTypes.any
}

MainLayout.defaultProps = {
    children: (<h1 className="text-center mt-5">Add Content...</h1>)
};

export default MainLayout;