import React from "react";
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const BtnIcon = ({
  bsVariant,
  iconSize,
  iconName,
  iconClasses,
  label,
  visibleTextSize,
  visibleText,
  btnClasses,
  handleClick,
}) => {

  let defaultClasses = "d-flex align-items-center justify-content-center";
  defaultClasses += (btnClasses && btnClasses !== "") ? " " + btnClasses : "";

  return (
    <>
      <Button
        type="button"
        title={label}
        variant={bsVariant}
        className={defaultClasses}
        style={{
          borderRadius: iconSize,
          fontSize: iconSize,
          width: `calc(${iconSize}px + 16px)`,
          height: `calc(${iconSize}px + 16px)`,
          pointerEvents: "auto"
        }}
        onClick={handleClick}
      >
        <span
          className={iconClasses ? iconClasses : ""}
          style={{
            lineHeight: "1em",
            height: "1em",
          }}
        >
          <ion-icon name={iconName}></ion-icon>
        </span>
      </Button>
      {visibleText && visibleText !== "" && (
        <div className="text-center" style={{ fontSize: visibleTextSize && visibleTextSize !== "" ? visibleTextSize : ".7rem", lineHeight: "1.2em", marginTop: 0, marginLeft: -2 }}>
          {visibleText}
        </div>
      )}
    </>
  );

};

BtnIcon.propTypes = {
  bsVariant: PropTypes.string,
  iconSize: PropTypes.any,
  iconName: PropTypes.string,
  iconClasses: PropTypes.string,
  btnClasses: PropTypes.string,
  handleClick: PropTypes.func,
}

BtnIcon.defaultProps = {
  bsVariant: "icon-light",
  iconSize: 24,
  iconName: "ellipse-outline",
  iconClasses: "",
  btnClasses: "",
  handleClick: null,
};

export default BtnIcon;

