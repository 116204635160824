import React, { useEffect, useRef } from "react";
import Config from "../data/Config";
import gtag from 'ga-gtag';
import FadeIn from "react-fade-in/lib/FadeIn";
import LinkConnectFollow from "../banners/LinkConnectFollow";
import ConnectLiveIntroContent from "../content/ConnectLiveIntroContent";
//import AppNameInlineIcon from "../components/text/AppNameInlineIcon";
import AppNameInline from "../components/text/AppNameInline";
import BtnStackedLink from "../components/buttons/BtnStackedLink";

const ConnectLiveIntro = ({
    isAuthenticated,
}) => {

    const gtagSent = useRef(false);

    useEffect(() => {

        if (!gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Connect.Live Intro',
                page_title: 'Connect.Live Intro',
            });

            gtagSent.current = true;

        }


    }, [gtagSent]);

    return (
        <>
            <FadeIn>
                <LinkConnectFollow>
                    {/* <h2 className={"d-flex justify-content-center align-items-center m-0"}>
                        <AppNameInlineIcon name={"connect.live"} showCopyright={true} iconName={"videocam-outline"} iconsSize="1.25em" />
                    </h2> */}
                    <BtnStackedLink
                        bsVariant={"dark"}
                        bsSize={""}
                        btnClasses="rounded-pill border border-2 border-primary shadow-sm pe-3"
                        label={<><AppNameInline name="live" showCopyright={false} /> prototype</>}
                        sublabel={"See the latest preview screens"}
                        iconSize={36}
                        iconName="videocam-outline"
                        useLink={true}
                        href="/live/preview"
                        target=""
                    />
                </LinkConnectFollow>
            </FadeIn>
            <ConnectLiveIntroContent
                isAuthenticated={isAuthenticated} 
            />
        </>
    );

};

export default ConnectLiveIntro;