import React from "react";
//import { useNavigate } from "react-router-dom";
//import { Row, Col, } from "react-bootstrap";
import {
    //isDesktop, 
    //isMobileOnly,
    //isMobile,
    //isTablet,
} from "react-device-detect";
// import AppNameInlineIcon from "../text/AppNameInlineIcon";
// import AppNameStacked from "../text/AppNameStacked";
// import BtnIcon from "../buttons/BtnIcon";

const CollectionNoHeader = ({
    children,
    // appName,
    // tagline,
    isDark,
    // showAds,
    // setShowAds,
    // showScrollBtn,
    // showSliders,
    // setShowSliders,
    // showBlurBtn,
    // blur,
    // setBlur,
    // iconName,
    // appUrl

}) => {

    //const nav = useNavigate();

    return (
        <>
            <main className={`${isDark ? "text-light" : "text-dark"} pt-3 pb-0`}>
                
                {children}
            </main>
        </>
    );
};

export default CollectionNoHeader;