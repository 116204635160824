import React, { useState, useEffect } from "react";
import Config from "../../data/Config";
import FadeIn from "react-fade-in/lib/FadeIn";
import MainModal from "./MainModal";
//import { Routes, Route, useNavigate } from "react-router-dom";
import { Row, Col, Form } from 'react-bootstrap';
import BtnStd from "../buttons/BtnStd";



const CreateAcct = ({
    setShowCreateAcct,
    createAccount,
    checkCodes
}) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [inviteCodeEntered, setInviteCodeEntered] = useState("");
    const [inviteCodeValidated, setInviteCodeValidated] = useState(false);
    const [ndaAgreed, setNdaAgreed] = useState(false);

    const imgPath = Config.imgPath;

    useEffect(() => {

        if (checkCodes(inviteCodeEntered.trim().toLowerCase())) {

            setTimeout(() => {
                setInviteCodeValidated(true);
            }, 200);
        }

    });

    return (
        <>
            <MainModal
                showNav={true}
                bsModalSize="lg"
                useDark={true}
                modalColor={"bg-dark text-light shadow"}
                dialogClassName="rounded-1"
                contentClassName=""
                showModal={true}
                closeModal={() => {
                    setInviteCodeEntered("");
                    setName("");
                    setInviteCodeValidated(false);
                    setNdaAgreed(false);
                    setShowCreateAcct(false);
                }}
            >
                {!inviteCodeValidated && (
                    <>
                        <div className="px-3 pb-2 pb-lg-3">
                            <Row className="align-items-center justify-content-center g-2 mb-3 py-2">
                                <Col lg="auto" className="d-flex flex-column justify-content-center">
                                    <h3 className="mb-2 text-center">Enter Invite Code</h3>
                                    <p className="text-center">Your invite code will be located in the join invitation email that was sent to you.</p>
                                </Col>
                            </Row>
                            <div className="pb-4">
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        size="lg"
                                        type="text"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        placeholder="*Invitation code required..."
                                        value={inviteCodeEntered}
                                        onChange={(e) => {
                                            setInviteCodeEntered(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </>
                )}
                {inviteCodeValidated && !ndaAgreed && (
                    <div className="px-3">
                        <Row className="align-items-center justify-content-center g-2 mb-3 py-2" style={{ lineHeight: "1em" }}>
                            <Col lg="auto" className="d-flex justify-content-center align-items-center">
                                <h3 className="m-0 p-0 text-center">General Non-Disclosure Agreement</h3>
                            </Col>
                        </Row>
                        <iframe
                            src={`${imgPath}docs/Non-DisclosureAgreement.pdf`}
                            title="Non-Disclosure Agreement"
                            style={{ width: "100%", height: "50vh", border: 0, }}
                        ></iframe>
                        <div className="pt-2">
                            <Form.Group className="mb-0">
                                <Form.Control
                                    size="lg"
                                    type="text"
                                    autoComplete="off"
                                    placeholder="*enter your first and last name..."
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        if (nameError) setNameError(false);
                                    }}
                                />
                                {nameError && (
                                    <FadeIn>
                                        <div class="form-text text-warning d-flex align-items-center py-1" style={{ fontSize: ".85rem" }}>
                                            <div className="me-2" style={{ lineHeight: ".8em", fontSize: "1.25rem" }}><ion-icon name="warning-outline"></ion-icon></div>
                                            <div>First and last name required.</div>
                                        </div>
                                    </FadeIn>
                                )}
                            </Form.Group>
                        </div>
                        <Row className="justify-content-center g-3 my-2">
                            <Col xs="auto">
                                <BtnStd
                                    bsVariant="outline-secondary"
                                    bsSize=""
                                    btnClasses="rounded-pill shadow-sm pe-3"
                                    label="cancel"
                                    iconSize={22}
                                    iconName="close-outline"
                                    handleClick={() => {

                                        setInviteCodeEntered("");
                                        setName("");
                                        setInviteCodeValidated(false);
                                        setNdaAgreed(false);
                                        setShowCreateAcct(false);

                                    }}
                                />
                            </Col>
                            <Col xs="auto">
                                <BtnStd
                                    bsVariant="primary"
                                    bsSize=""
                                    btnClasses="rounded-pill shadow-sm pe-3"
                                    label="agree"
                                    iconSize={22}
                                    iconName="checkbox-outline"
                                    handleClick={(e) => {

                                        if (name.length > 0 && name.trim().includes(" ")) {
                                            setNdaAgreed(true);
                                            setNameError(false);
                                        } else {
                                            setNameError(true);
                                        }

                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
                {ndaAgreed && inviteCodeValidated && (
                    <FadeIn>
                        <div className="px-3 pb-2 pb-lg-3">
                            <Row className="align-items-center justify-content-center g-2 mb-3 py-2" style={{ lineHeight: "1em" }}>
                                <Col lg="auto" className="d-flex justify-content-center align-items-center">
                                    <h3 className="m-0 p-0 text-center">Create Account</h3>
                                </Col>
                            </Row>
                            <div className="pb-4">
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        size="lg"
                                        type="email"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        placeholder="*Email required"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        size="lg"
                                        type="password"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        placeholder="*Password required"
                                        value={password}
                                        onFocus={(e) => {
                                            e.target.type = "text"
                                        }}
                                        onBlur={(e) => {
                                            e.target.type = "password"
                                        }}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <Row className="justify-content-center g-3">
                                <Col xs="auto">
                                    <BtnStd
                                        bsVariant="outline-secondary"
                                        bsSize=""
                                        btnClasses="rounded-pill shadow-sm pe-3"
                                        label="cancel"
                                        iconSize={22}
                                        iconName="close-outline"
                                        handleClick={() => {
                                            setName("");
                                            setEmail("");
                                            setPassword("");
                                            setInviteCodeEntered("");
                                            setInviteCodeValidated(false);
                                            setNdaAgreed(false);
                                            setShowCreateAcct(false);

                                        }}
                                    />
                                </Col>
                                <Col xs="auto">
                                    <BtnStd
                                        bsVariant="primary"
                                        bsSize=""
                                        btnClasses="rounded-pill shadow-sm pe-3"
                                        label="create account"
                                        iconSize={22}
                                        iconName="arrow-forward-outline"
                                        handleClick={() => {

                                            createAccount(email, password, name, inviteCodeEntered);

                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </FadeIn>
                )}
            </MainModal>
        </>
    );

};

export default CreateAcct;