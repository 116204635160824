import React, { useEffect, useState, } from "react";
import { Container, } from 'react-bootstrap';
import AppNameInline from "../components/text/AppNameInline";
import AppsListInlineContent from "./AppsListInlineContent";
import FadeIn from 'react-fade-in';
import OBAName from "../components/text/OBAName";
import LoadingOverlayFixed from "../components/shared/LoadingOverlayFixed";
//import BtnStdLink from "../components/buttons/BtnStdLink";

const HubIntroContent = () => {

    const [isLoading, setIsLoading] = useState(true);
    const fontBold = 400;
    
    useEffect(() => {

        if (isLoading) {

            setTimeout(() => {
                setIsLoading(false);
            }, 1000);

        }

    }, [isLoading]);

    return (
        <>
            {isLoading ? (
                <>
                    <LoadingOverlayFixed
                        msgText="loading..."
                        msgTextBsColor="text-light"
                        spinnerType="grow"
                        spinnerBsVariant="light"
                        spinnerBsSize="sm"
                        fontSize=""
                        appName={null}
                        showAppNameCopyright={false}
                        showAppLogo={false}
                        showOBALogo={true}
                    />
                </>
            ) : (
                <>
                    {/* <section className="px-5 pb-3 text-center" style={{ textShadow: "2px 2px 4px rgba(0,0,0,.1)" }}>
                        <FadeIn className="d-flex flex-column justify-content-center align-items-center">
                            <BtnStdLink
                                bsVariant="dark"
                                bsSize={""}
                                btnClasses="rounded-1 shadow-sm"
                                label={<><AppNameInline name="hub.preview" showCopyright={false} /></>}
                                iconSize={20}
                                iconName="globe-outline"
                                useLink={true}
                                href="/hub/preview"
                                target=""
                            />
                        </FadeIn >
                    </section > */}
                    <section className="bg-md-dark text-light py-4 py-lg-5 d-flex align-items-center">
                        <Container fluid className="container-1200">
                            <FadeIn>
                                <h1 className="mb-3">
                                    <OBAName /> <AppNameInline name="hub" showCopyright={true} />
                                </h1>
                                <p>
                                    This is where you will find all the latest updates for all of your one.bad.apple apps. From recommended purchases, browsing history, and open orders, to cart items you have added from the <AppNameInline name="shop" fontWeight={fontBold} /> app. Recent happenings on your the <AppNameInline name="link" fontWeight={fontBold} /> app profile including: updates to your friends profiles, private messages, friend requests, and photos/videos posted, as well as, access to your <AppNameInline name="link.follow" fontWeight={fontBold} /> page, <AppNameInline name="account" fontWeight={fontBold} /> management app and much more. Check back often to see new offerings.
                                </p>
                                <p>
                                    <AppNameInline name="hub" fontWeight={fontBold} /> is currently under development... look out for much more to come in the near future. In the meantime, check out our navigation features for our latest prototype design updates.
                                </p>
                                
                                <AppsListInlineContent />
                            </FadeIn>
                        </Container>
                    </section>
                </>
            )}
        </>
    );
};

// HubContent.propTypes = {

// }

// HubContent.defaultProps = {

// };

export default HubIntroContent;