import React from "react";
import {
    Row, Col, Badge
} from "react-bootstrap";
import {
    //isDesktop,
    isMobile,
    //isMobileOnly,
    //isTablet
} from "react-device-detect";
import BtnStackedLink from "../buttons/BtnStackedLink";


const ChatProfileVertical = ({
    pageName,
    blur,
    isDark,
    showLightBoxWindow,
    setShowFlirtModal,
    setShowChatAlert,
    setChatAlertType,
    setPlayCallingAnimation,
    setShowProfileModal,
    toggleChat,
    toggleLive,
    showVideoPlayer,
    

}) => {

    return (
        <>
            <Row className="">
                <Col xs="4" lg={"12"}>
                    <div
                        className={`${blur ? "img-blur" : ""} position-relative rounded-circle shadow d-flex align-items-end justify-content-end mb-2`}
                        style={{
                            //height: isMobileOnly ? "calc(100vw - 80px)" : 190,
                            aspectRatio: "1 / 1",
                            backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg)",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            showLightBoxWindow({
                                imgs: [
                                    { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg", type: "image" },
                                ]
                            });
                        }}
                    >
                        <div
                            className="d-flex align-items-center position-absolute top-0 end-0 me-1 mt-1"
                            style={{
                                zIndex: 5,
                            }}
                        >
                            <Badge
                                bg={"dark"}
                                text={"primary"}
                                title={"Online Now..."}
                                className="rounded-circle shadow-sm"
                                style={{
                                    fontSize: ".85rem",
                                    paddingTop: 3,
                                    paddingBottom: 2,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                }}
                            >
                                <ion-icon name={"checkmark-circle-sharp"}></ion-icon>
                            </Badge>
                        </div>
                    </div>
                </Col>
                <Col xs="8" lg="12">
                    <div className="text-center mt-1">
                        <h4 className="mb-1">E-K-Having-Fun</h4>
                        <h6 className="mb-1">Couple (M/F)</h6>
                        <h6 className="mb-1">Status: Online</h6>
                    </div>
                </Col>
                <Col lg="12">
                    {pageName==="live" &&(
                        <Row className={"g-3 mt-2"}>
                            <Col xs="4" lg="12">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={"dark"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses={(showVideoPlayer ? "disabled " : "") + "rounded-pill border border-2 border-primary px-3"}
                                        label={<><span className="text-primary">START</span> Live!</>}
                                        sublabel={!isMobile ? "Start an audio/video call" : null}
                                        iconSize={!isMobile ? 26 : 16}
                                        iconName="arrow-redo-sharp"
                                        useLink={false}
                                        handleClick={() => {

                                            if (showVideoPlayer) return;

                                            setShowChatAlert(true);
                                            setPlayCallingAnimation(false);
                                            setChatAlertType("sendNewReq");
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs="4" lg="12">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={isDark ? "outline-light" : "outline-dark"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill px-3"
                                        label={"Connect"}
                                        sublabel={!isMobile ? "Send them a message" : null}
                                        iconSize={!isMobile ? 26 : 16}
                                        iconName="chatbubbles-outline"
                                        useLink={false}
                                        handleClick={() => {
                                            toggleChat();
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs="4" lg="12">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={isDark ? "outline-light" : "outline-dark"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill px-3"
                                        label={"View Profile"}
                                        sublabel={!isMobile ? "See their latest activity" : null}
                                        iconSize={!isMobile ? 26 : 16}
                                        iconName="information-circle-outline"
                                        useLink={false}
                                        handleClick={() => {
                                            setShowProfileModal(true);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs="4" lg="12">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={isDark ? "outline-light" : "outline-dark"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill px-3"
                                        label={"Flirt"}
                                        sublabel={!isMobile ? "Show your interest" : null}
                                        iconSize={!isMobile ? 26 : 16}
                                        iconName="heart-outline"
                                        useLink={false}
                                        handleClick={() => {
                                            setShowFlirtModal(true);
                                        }}
                                    />
                                </div>
                            </Col>
                            
                        </Row>
                    )}
                    {pageName === "connect" && (
                        <Row className={"g-3 mt-2"}>
                            <Col xs="4" lg="12">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={"dark"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses={(showVideoPlayer ? "disabled " : "") + "rounded-pill border border-2 border-primary px-3"}
                                        label={<><span className="text-primary">START</span> A Chat!</>}
                                        sublabel={!isMobile ? "Send them a message" : null}
                                        iconSize={!isMobile ? 26 : 16}
                                        iconName="arrow-redo-sharp"
                                        useLink={false}
                                        handleClick={() => {

                                            if (showVideoPlayer) return;

                                            setShowChatAlert(true);
                                            setPlayCallingAnimation(false);
                                            setChatAlertType("sendNewReq");
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs="4" lg="12">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={isDark ? "outline-light" : "outline-dark"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill px-3"
                                        label={"Live"}
                                        sublabel={!isMobile ? "Start an audio/video chat" : null}
                                        iconSize={!isMobile ? 26 : 16}
                                        iconName="videocam-outline"
                                        useLink={false}
                                        handleClick={() => {
                                            toggleLive();
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs="4" lg="12">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={isDark ? "outline-light" : "outline-dark"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill px-3"
                                        label={"View Profile"}
                                        sublabel={!isMobile ? "See their latest activity" : null}
                                        iconSize={!isMobile ? 26 : 16}
                                        iconName="information-circle-outline"
                                        useLink={false}
                                        handleClick={() => {
                                            setShowProfileModal(true);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs="4" lg="12">
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={isDark ? "outline-light" : "outline-dark"}
                                        bsSize={"xs"}
                                        alignLeft={true}
                                        btnClasses="rounded-pill px-3"
                                        label={"Flirt"}
                                        sublabel={!isMobile ? "Show your interest" : null}
                                        iconSize={!isMobile ? 26 : 16}
                                        iconName="heart-outline"
                                        useLink={false}
                                        handleClick={() => {
                                            setShowFlirtModal(true);
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    );

};

export default ChatProfileVertical;