import React from "react";
import { Link } from "react-router-dom";

const AdSelectorSide = ({
    isDark,
    stack
}) => {

    return (
        <>
            <div className="position-relative mb-3">
                <Link
                    to="/"
                    onClick={(e) => {
                        e.preventDefault();
                        alert("This feature is under development.");
                    }}
                >
                    <img className="card-img-top rounded-1 shadow-sm" src="https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/pantiless.jpg" alt="Logo for one.bad.apple" />
                </Link>
            </div>
            <div className="position-relative mb-3">
                <Link
                    to="/"
                    onClick={(e) => {
                        e.preventDefault();
                        alert("This feature is under development.");
                    }}
                >
                    <img className="card-img-top rounded-1 shadow-sm" src="https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/collete_houston_halloween.gif" alt="Logo for one.bad.apple" />
                </Link>
            </div>
            {/* {stack && stack === "3small" && (
                <>
                    

                    <div className="position-relative mb-3">
                        <Link
                            to="/"
                            onClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development.");
                            }}
                        >
                            <img className="card-img-top rounded-1 shadow-sm" src="https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/pantiless.jpg" alt="Logo for one.bad.apple" />
                        </Link>
                    </div>
                </>
            )} */}
            <div className={(isDark ? "text-light bg-md-dark border-light-gray " : "text-dark bg-light border-light-dark") + " position-relative d-flex flex-column align-items-center justify-content-center border rounded-1 shadow-sm text-center mb-3"} style={{ width: 300, height: 600 }}>
                <div className="position-absolute end-0 bottom-0 me-1 mb-1" style={{ zIndex: 1, }}>
                    <div className="text-center text-muted" style={{ fontSize: ".80rem" }}>Branded/Partner Ad Space 300x600</div>
                </div>
                <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                    <Link
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            alert("This feature is under development.");
                        }}
                    >
                        <img src={"https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-top-White.png"} className="img-fluid" alt="Logo for one.bad.apple" style={{ maxHeight: 160 }} />
                    </Link>
                </div>
            </div>

        </>
    );

};

export default AdSelectorSide;