import React, { useRef, useEffect, useState } from "react";
import {
    //isDesktop,
    //isMobileOnly,
    isMobile,
    //isTablet,
} from "react-device-detect";
import { Container, Row, Col, Form, } from "react-bootstrap";
import LoadingOverlayFixed from "../../../components/shared/LoadingOverlayFixed";
import OBAName from "../../../components/text/OBAName";
import Config from "../../../data/Config";

import PromoFrame from "../../../components/shared/PromoFrame";

// import Collection from "../../components/collections/Collection";
// import CollectionRow from "../../components/collections/CollectionRow";
// import CollectionColumn from "../../components/collections/CollectionColumn";
//import SliderInsideScrollRow from "../../components/collections/SliderInsideScrollRow";

import gtag from 'ga-gtag';
import BtnStackedLink from "../../../components/buttons/BtnStackedLink";
import { useNavigate } from "react-router-dom";
import FadeIn from "react-fade-in/lib/FadeIn";
//import AppNameInline from "../../components/text/AppNameInline";
// import { Link } from "react-router-dom";

const RTICPromotionForm = ({
    isAuthenticated,
    isDark,
}) => {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [emailField, setEmailField] = useState("");
    const [passwordField, setPasswordField] = useState("");
    const [emailOptIn, setEmailOptIn] = useState(true);
    const [inPerson, setInPerson] = useState(true);
    const [shipToMe, setShipToMe] = useState(false);
    const [nameField, setNameField] = useState("");
    const [addressField, setAddressField] = useState("");
    const [address2Field, setAddress2Field] = useState("");
    const [cityField, setCityField] = useState("");
    const [stateField, setStateField] = useState("");
    const [zipField, setZipField] = useState("");

    const [referedField, setReferedField] = useState(0);
    const [commentField, setCommentField] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const gtagSent = useRef(false);

    const _shopProducts = Config.shopProducts; //Shop data

    //FOR ROUTE CALLS IN CODE
    const navigate = useNavigate();

    // useEffect(() => {

    //     return () => {

    //         setSelectedProduct(null);
    //         setEmailField("");
    //         setPasswordField("");
    //         setEmailOptIn(true);
    //         setReferedField("");
    //     }

    // },[]);

    const cancelForm = () => {

        setSelectedProduct(null);
        setEmailField("");
        setPasswordField("");
        setEmailOptIn(true);
        setReferedField("");
    }

    const submitForm = () => {

        const formData = {
            product: selectedProduct,
            email: emailField,
            password: passwordField,
            optin: emailOptIn,
            inPerson: inPerson,
            shipToMe: shipToMe,
            name: nameField,
            address1: addressField,
            address2: address2Field,
            city: cityField,
            state: stateField,
            zip: zipField,
            referer: referedField,
            comment: commentField
        }

        console.log(formData);
    };

    useEffect(() => {

        if (!gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'RTIC Promotion Form',
                page_title: 'RTIC Promotion Form',
            });

            gtagSent.current = true;

        }

    }, [gtagSent]);

    return (
        <>
            <div
                className={
                    `${(true) ? "bg-white text-dark" : ""} d-flex align-items-center shadow nav-offset position-relative`
                }
                style={{
                    zIndex: 30,
                    fontWeight: 300,
                }}
            >
                <Container
                    fluid
                    style={{
                        backgroundImage: "url('https://storage.googleapis.com/onebadappleprototype.appspot.com/temp_imgs/abstract-orange-smooth-wave-lines_1940_Gray.jpg')", backgroundRepeat: "no-repeat",
                        backgroundPositionX: "center",
                        backgroundPositionY: "center",
                        backgroundSize: ""
                    }}
                >

                    <div
                        className="text-center pt-4 pb-4"
                        style={{ textShadow: "2px 2px 4px rgba(0,0,0,.1)" }}
                    >
                        <h1 className="mb-4" style={{ fontWeight: 300, fontSize: "3.1rem", lineHeight: "1em" }}>Have An Email?</h1>
                        <h1 className="pb-4 mb-0" style={{ fontWeight: 300, fontSize: "3.1rem", lineHeight: ".8em" }}>Get <span className="text-primary" style={{ fontWeight: 300 }}>FREE</span> RTIC<small style={{ fontSize: ".85rem" }}>&reg;</small> Drinkware</h1>
                        <div className="mx-auto mb-0" style={{ maxWidth: 700, marginTop: -15 }}>
                            <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo_1470_shadow.png" className="img-fluid" title="Logo for one.bad.apple" alt="Logo for one.bad.apple" />
                        </div>
                        <h1 style={{ fontSize: "2.5rem", fontWeight: 300, }}>Coming Soon...<br className="d-block d-sm-none" /> Choose Your Adventure.</h1>
                        <p className="mb-3 mt-0" style={{ fontWeight: 300, fontSize: "1.7rem", }}>
                            The premiere, all-new social app for those looking for lifestyle and clothing optional adventures.
                        </p>
                    </div>
                </Container>
            </div>
            <PromoFrame>
                <h1 className="text-center pt-5" style={{ fontWeight: 300, fontSize: "2.50rem", lineHeight: "1em" }}>Claim Your <span className="text-primary" style={{ fontWeight: 400 }}>FREE</span> RTIC<small style={{ fontSize: ".85rem" }}>&reg;</small> Drinkware by <OBAName fontWeight="500" /></h1>
                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ornare tellus eget ante condimentum accumsan. Orci varius natoque penatibus et magnis.</p>

                <h2 className="mb-2 pt-5" style={{ fontWeight: 300, fontSize: "2.0rem", lineHeight: "1em" }}>
                    1. Choose your <span className="text-primary" style={{ fontWeight: 400 }}>FREE</span> RTIC<small style={{ fontSize: ".85rem" }}>&reg;</small> drinkware by <OBAName fontWeight="500" />
                </h2>
                <small className="" style={{ fontSize: "1.1rem" }}>
                    * Promotion limited to <span>one free item per email address</span>. If you are interested in purchasing <OBAName fontWeight="500" /> items, <a href="/">visit our online store here</a>.
                </small>
                {/* PRODUCT */}
                <Row className={"justify-content-center pb-5 pt-5"} style={{ fontSize: ".90em" }}>

                    {_shopProducts && _shopProducts.slice(0, 3).map((item, i) => {

                        return (

                            <Col key={i} xs="11" sm="3" lg="4" className={"position-relative text-center text-dark mb-3"}>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedProduct(i);
                                    }}
                                    style={{
                                        backgroundColor: "transparent",
                                    }}
                                    className={(i === 2 ? "opacity-50 disabled" : "") + (selectedProduct === i ? "border-primary shadow" : "shadow-sm") + " border border-2 bg-white p-3 rounded-1 position-relative w-100"}
                                    disabled={i === 2 ? true : false}

                                >
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <img alt={item.label} title={item.label} src={item.img} className="img-fluid" style={{ maxHeight: 240 }} />
                                    </div>
                                    <p
                                        className="py-2 mx-3 mb-2"
                                        style={{

                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            maxWidth: "96%",
                                        }}
                                    >
                                        {item.label}
                                    </p>
                                    <div className={(selectedProduct === i ? "text-primary" : "text-light-gray") + " position-absolute end-0 top-0 mt-1 me-3 h3"}>
                                        {selectedProduct === i ? (
                                            <ion-icon name="checkbox-sharp"></ion-icon>
                                        ) : (
                                            <ion-icon name="square-outline"></ion-icon>
                                        )}
                                    </div>

                                </button>



                            </Col>
                        );
                    })}

                </Row>

                <h2 className="mb-5" style={{ fontWeight: 300, fontSize: "2.0rem", lineHeight: "1em" }}>
                    2. Enter an email &amp; password
                </h2>
                <Form autoComplete="off">
                    <Row className="align-items-center">
                        <Col lg="4">
                            <p>Email <small className="text-secondary" style={{ fontSize: ".85rem" }}>(*Required)</small></p>
                        </Col>
                        <Col lg="8">
                            <Form.Group className="mb-3">
                                <Form.Control
                                    className="rounded-1"
                                    size="lg"
                                    type="email"
                                    placeholder="name@example.com"
                                    autoComplete="email"
                                    value={emailField}
                                    onChange={(e) => { setEmailField(e.target.value) }}

                                />
                            </Form.Group>

                        </Col>
                        <Col lg="4">
                            <p>Password <small className="text-secondary" style={{ fontSize: ".85rem" }}>(*Required)</small></p>
                        </Col>
                        <Col lg="8">
                            <Form.Group className="mb-3">
                                <Form.Control
                                    className="rounded-1"
                                    size="lg"
                                    type="password"
                                    placeholder="password..."
                                    autoComplete="new-password"
                                    value={passwordField}
                                    onChange={(e) => { setPasswordField(e.target.value) }}
                                />
                            </Form.Group>

                        </Col>
                        <Col lg="4">
                            &nbsp;
                        </Col>
                        <Col lg="8">
                            <Form.Group className="mb-3 fs-6 d-flex justify-content-end text-muted">
                                <Form.Check
                                    type="switch"
                                    checked={emailOptIn}
                                    reverse
                                    //id="custom-switch"
                                    label="Receive Email Updates"
                                    className="bg-muted"
                                    onChange={(e) => { setEmailOptIn(!emailOptIn) }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>

                <h2 className="mb-5 pt-5" style={{ fontWeight: 300, fontSize: "2.0rem", lineHeight: "1em" }}>
                    3. How are you receiving your RTIC<small style={{ fontSize: ".85rem" }}>&reg;</small> Drinkware by <OBAName fontWeight="500" />?
                </h2>
                <Form.Group className="mb-3">
                    <Row className="align-items-start">
                        <Col lg="4">
                            Choose your option
                        </Col>
                        <Col xs="8" className="d-flex align-items-center justify-content-start">
                            <Form.Group className="mb-3 me-4 d-flex justify-content-start">
                                <Form.Check
                                    type="switch"
                                    checked={!shipToMe && inPerson}

                                    //id="custom-switch"
                                    label="In person"
                                    className="bg-muted"
                                    onChange={(e) => {
                                        setInPerson(!inPerson);
                                        setShipToMe(false);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 d-flex justify-content-start">
                                <Form.Check
                                    type="switch"
                                    checked={shipToMe && !inPerson}

                                    //id="custom-switch"
                                    label="Ship it to me"
                                    className="bg-muted"
                                    onChange={(e) => {
                                        setShipToMe(!shipToMe);
                                        setInPerson(false);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="4">
                            Your name <small className="text-secondary" style={{ fontSize: ".85rem" }}>(*Required)</small>
                        </Col>
                        <Col lg="8">
                            <Form.Group className="mb-3">
                                <Form.Control
                                    className="rounded-1"
                                    size="lg"
                                    type="text"
                                    placeholder="Name..."
                                    //autoComplete="email"
                                    value={nameField}
                                    onChange={(e) => { setNameField(e.target.value) }}

                                />
                            </Form.Group>
                        </Col>
                        {shipToMe && (
                            <Col xs="12">
                                <FadeIn>
                                    <Row className="pt-3">                                        
                                        <Col lg="4">
                                            Enter shipping info <small className="text-secondary" style={{ fontSize: ".85rem" }}>(*Required)<br />
                                            (Address used for shipping only)</small>
                                        </Col>
                                        <Col lg="8">
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    className="rounded-1"
                                                    size="lg"
                                                    type="text"
                                                    placeholder="Street Address..."
                                                    //autoComplete="email"
                                                    value={addressField}
                                                    onChange={(e) => { setAddressField(e.target.value) }}

                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="4">
                                            &nbsp;
                                        </Col>
                                        <Col lg="8">
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    className="rounded-1"
                                                    size="lg"
                                                    type="text"
                                                    placeholder="Address 2 (Optional)..."
                                                    //autoComplete="email"
                                                    value={address2Field}
                                                    onChange={(e) => { setAddress2Field(e.target.value) }}

                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="4">
                                            &nbsp;
                                        </Col>
                                        <Col lg="8">
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control
                                                            className="rounded-1"
                                                            size="lg"
                                                            type="text"
                                                            placeholder="City..."
                                                            //autoComplete="email"
                                                            value={cityField}
                                                            onChange={(e) => { setCityField(e.target.value) }}

                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control
                                                            className="rounded-1"
                                                            size="lg"
                                                            type="text"
                                                            placeholder="State..."
                                                            //autoComplete="email"
                                                            value={stateField}
                                                            onChange={(e) => { setStateField(e.target.value) }}

                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control
                                                            className="rounded-1"
                                                            size="lg"
                                                            type="text"
                                                            placeholder="Zip Code..."
                                                            //autoComplete="email"
                                                            value={zipField}
                                                            onChange={(e) => { setZipField(e.target.value) }}

                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </FadeIn>
                            </Col>
                        )}
                    </Row>

                </Form.Group>

                <h2 className="mb-5 pt-5" style={{ fontWeight: 300, fontSize: "2.0rem", lineHeight: "1em" }}>
                    4. Tell us how you heard about <OBAName fontWeight="500" /> <small className="fs-6">(Optional)</small>
                </h2>
                <Form.Group className="mb-3">
                    <Row className="align-items-center">
                        <Col lg="4">
                            <p>Select one</p>
                        </Col>
                        <Col lg="8">
                            <Form.Group className="mb-3">
                                <Form.Select
                                    className="text-muted rounded-1"
                                    size="lg"
                                    aria-label="Default select example"
                                    defaultValue={referedField}
                                    onChange={(e) => { setReferedField(e.target.value) }}
                                >
                                    {/* <option>Optional...</option> */}
                                    <option value="0" disabled hidden>Select your option...</option>
                                    <option value="QR Code on drinkware">QR Code on drinkware</option>
                                    {/* <option value="QR Code on women's tank or tee">QR Code on women's tank or tee</option> */}
                                    <option value="QR Code in an email or printed material">QR Code in an email or printed material</option>
                                    <option value="One.Bad.Apple representative">One.Bad.Apple representative</option>
                                    <option value="Google/internet search">Google/internet search</option>
                                    <option value="Word of mouth">Word of mouth</option>
                                    <option value="Other">Other</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>

                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: '100px' }}
                        value={commentField}
                        onChange={(e) => { setCommentField(e.target.value) }}
                    />
                </Form.Group>

                <Row className="justify-content-between pt-5">

                    <Col xs="auto">
                        <BtnStackedLink
                            bsVariant="outline-dark"
                            bsSize={"sm"}
                            btnClasses="rounded-pill shadow-sm px-3"
                            label={"No, thanks"}
                            sublabel={!isMobile ? "Cancel this order" : null}
                            iconSize={!isMobile ? 24 : 16}
                            iconName="remove-circle-outline"
                            useLink={false}
                            // href="/"
                            // target=""
                            handleClick={() => {
                                cancelForm();
                            }}
                        />
                    </Col>
                    <Col xs="auto">
                        <BtnStackedLink
                            bsVariant="dark"
                            bsSize={"sm"}
                            btnClasses="rounded-pill border border-2 border-primary shadow-sm px-3"
                            label={"Get It Now!"}
                            sublabel={!isMobile ? "Claim your drinkware" : null}
                            iconSize={!isMobile ? 24 : 16}
                            iconName="arrow-redo"
                            useLink={false}
                            // href="/"
                            // target=""
                            handleClick={(e) => {

                                e.preventDefault();

                                setIsLoading(true);

                                setTimeout(() => {

                                    submitForm();

                                    navigate("/promotions/success", { replace: false });

                                }, 1500);


                            }}
                        />
                    </Col>
                </Row>

                <Row className="justify-content-center pt-5">
                    <Col xs="auto">
                        <BtnStackedLink
                            bsVariant="dark"
                            bsSize={"sm"}
                            btnClasses="rounded-pill border border-2 border-primary shadow-sm px-3"
                            label={"Purchase RTIC Drinkware"}
                            sublabel={!isMobile ? "Visit our online store" : null}
                            iconSize={!isMobile ? 24 : 16}
                            iconName="cart-outline"
                            useLink={true}
                            href="/"
                            target=""
                        />
                    </Col>
                </Row>

                {/* <SliderInsideScrollRow isDark={false} showScrollIcons={false}>
                    <Row className={`${!isMobile ? "" : "gx-2 "}mt-5 justify-content-start flex-nowrap`} style={{ fontSize: ".70em" }}>

                        {_shopProducts && _shopProducts.slice(0, 8).map((item, i) => {

                            return (

                                <Col key={i} xs="6" sm="3" lg="3" className={"text-center text-dark"}>
                                    <div className="bg-white rounded-1">
                                        <a href={"/"} onClick={(e) => { e.preventDefault(); alert("Feature under development.") }}>
                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                                <img alt={item.label} title={item.label} src={item.img} className="img-fluid" style={{ maxHeight: 225 }} />
                                            </div>
                                        </a>
                                        <p
                                            className="py-2 mx-3 mb-2"
                                            style={{
                                                
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                maxWidth: "96%",
                                            }}
                                        >
                                            {item.label}
                                        </p>
                                    </div>
                                </Col>
                            );
                        })}

                    </Row>
                </SliderInsideScrollRow> */}

            </PromoFrame>
            {(isLoading) && (
                <LoadingOverlayFixed
                    msgText="Submitting your data..."
                    msgTextBsColor="text-light"
                    spinnerType="grow"
                    spinnerBsVariant="light"
                    spinnerBsSize="sm"
                    fontSize=""
                    appName="promo"
                    showAppNameCopyright={false}
                    showAppLogo={true}
                    showOBALogo={true}
                />
            )}
        </>
    );

};

export default RTICPromotionForm;