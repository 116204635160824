import React, { useState } from "react";
import { useEffect } from "react";
import { Row, Col, Form, Carousel, } from "react-bootstrap";
import {
    //isDesktop, 
    isMobileOnly,
    //isMobile,
    //isTablet,
    //isDesktop
} from "react-device-detect";

import BtnIcon from "../buttons/BtnIcon";
import ConnectMsgList from "../connect/MsgList";
import LightboxVideoPlayer from "../video/LightboxVideoPlayer";

const LightboxMain = ({
    lightBoxContent,
    user,
    isDark,
    showComments,
    updateScrollPos,
    setShowLightbox,
    blur,

}) => {

    const [_isFullScreen, _setIsFullScreen] = useState(false);
    const [_selectedSlide, _setSelectedSlide] = useState(0);
    const [_slideOut, _setSlideOut] = useState(false);

    useEffect(() => {

        setTimeout(() => {

            !_slideOut && _setSlideOut(true);

        }, 200);

    }, [_slideOut]);

    return (
        <>
            <Row
                className={`${_slideOut ? "active " : ""}lightbox-slide position-fixed align-items-stretch justify-content-between start-0 top-0 bottom-0 end-0 ${isDark ? " bg-dark text-light" : " bg-light text-dark"}`}
                style={{
                    zIndex: 1050,
                    fontSize: "1rem"
                }}
            >
                <Col className={"position-relative d-flex flex-column align-items-center justify-content-center bg-black text-light"}>
                    {/* NAVBAR */}
                    <div
                        className={"pt-lg-2 pb-1 px-2 w-100"}
                        style={{
                            zIndex: 2,
                        }}
                    >
                        <Row className="align-items-center justify-content-between gx-1">
                            <Col xs="auto">
                                <BtnIcon
                                    bsVariant={("icon-light")}
                                    iconSize={28}
                                    iconName="arrow-back-outline"
                                    iconClasses=""
                                    handleClick={() => {
                                        setShowLightbox(false);
                                    }}
                                />
                            </Col>
                            <Col xs="auto">
                                <Row className="align-items-center justify-content-center gx-1">
                                    <Col xs="auto">
                                        <BtnIcon
                                            bsVariant={("icon-light")}
                                            label={"zoom out..."}
                                            iconSize={28}
                                            iconName="remove-circle-outline"
                                            iconClasses=""
                                            btnClasses=""
                                            handleClick={() => {
                                                void (0);
                                            }}
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <div style={{ fontSize: ".80em" }}>
                                            Default
                                        </div>
                                    </Col>
                                    <Col xs="auto">
                                        <BtnIcon
                                            bsVariant={("icon-light")}
                                            label={"zoom in..."}
                                            iconSize={28}
                                            iconName="add-circle-outline"
                                            iconClasses=""
                                            handleClick={() => {
                                                void (0);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="auto">
                                <BtnIcon
                                    bsVariant={("icon-light")}
                                    label={"full screen..."}
                                    iconSize={24}
                                    iconName={_isFullScreen ? "contract-outline" : "expand-outline"}
                                    iconClasses=""
                                    handleClick={() => {
                                        _setIsFullScreen(!_isFullScreen);
                                        //void (0);
                                        //toggleFullScreen();
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                    {/* END NAVBAR */}

                    <Carousel
                        fade
                        interval={null}
                        controls={lightBoxContent.length > 1 ? true : false}
                        indicators={lightBoxContent.length > 1 ? true : false}
                        nextIcon={
                            <div
                                style={{
                                    fontSize: "3rem",
                                    height: "1.2em",
                                    width: "1.2em",
                                    pointerEvents: "auto",
                                    cusor: "pointer"
                                }}
                                className="text-light rounded-circle shadow"
                            >
                                <ion-icon name="chevron-forward-circle-outline"></ion-icon>
                            </div>
                        }
                        prevIcon={
                            <div
                                style={{
                                    fontSize: "3rem",
                                    height: "1.2em",
                                    width: "1.2em",
                                    pointerEvents: "auto",
                                    cusor: "pointer"
                                }}
                                className="text-light rounded-circle shadow"
                            >
                                <ion-icon name="chevron-back-circle-outline"></ion-icon>
                            </div>
                        }
                        className="d-flex align-items-stretch"
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                        // onSelect={(selectedIndex, e) => {

                        //     console.log(selectedIndex);

                        // }}
                        // onSlide={() => {

                        //     //pauseVideo();

                        // }}
                        onSlid={(selectedIndex, e) => {

                            _setSelectedSlide(selectedIndex);

                            //resetVideo();

                        }}
                    >
                        {lightBoxContent && lightBoxContent.map((item, i) => {
                            return (
                                <Carousel.Item
                                    key={i}
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    {item.type === "image" && (
                                        <div
                                            className={`${blur ? "img-blur" : ""} my-auto pe-0 pb-0`}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                backgroundImage: `url(${item.img})`,
                                                backgroundSize: "contain",
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat"
                                            }}
                                        >
                                        </div>
                                    )}

                                    {(item.type === "reel" || item.type === "video") && (

                                        <LightboxVideoPlayer
                                            selectedSlide={_selectedSlide}
                                            myIndex={i}
                                            item={item}
                                            blur={blur}
                                        />

                                    )}

                                </Carousel.Item>
                            );
                        })}
                    </Carousel>

                    <div className="px-2 pb-4 pt-1 w-100">
                        <h3 className="mb-1" style={{ fontWeight: 300 }}>Wow!</h3>
                        <p className="mb-0" style={{ fontSize: ".85rem" }}>
                            A message about this image would go here... So, if you need to think of what to type, we'lll be waiting for you!
                        </p>
                    </div>
                </Col>
                {
                    (showComments && !_isFullScreen) && (
                        <Col className="d-flex flex-column" lg="3">
                            {/* PROFILE AREA */}
                            <div
                                className="position-relative d-flex flex-row align-items-center justify-content-start pt-2 px-2 px-lg-0 mb-2"

                            >
                                <div className="me-2">
                                    <img
                                        src={user.photoURL}
                                        className="img-fluid rounded-1 shadow"
                                        alt={
                                            `Profile for ${user.email}`
                                        }
                                        style={{
                                            cursor: "pointer",
                                            width: 60,
                                        }}
                                        onClick={() => {

                                            void (0);
                                        }}
                                    />
                                </div>
                                <div>
                                    <div style={{ height: "1.4em", fontSize: ".95rem", }}>One.Bad.Apple</div>
                                    <div style={{ height: "1.4em", fontSize: ".85rem", }}>Couple</div>
                                    <div style={{ height: "1.4em", fontSize: ".75rem", }}>Sat, Oct 28, 2022</div>
                                </div>
                                <div className="position-absolute end-0 top-0 text-secondary me-1 mt-1 fs-6" title="Verified">
                                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                                </div>
                            </div>

                            {/* REACTIONS */}
                            <Row className="align-items-center justify-content-between pb-1 mb-1">
                                <Col xs="auto">
                                    <Row className="align-items-center justify-content-start ps-2 ps-lg-0 gx-1">
                                        <Col xs="auto">
                                            <BtnIcon
                                                bsVariant={isDark ? "icon-light" : "icon-dark"}
                                                label="post your reaction..."
                                                iconSize={isMobileOnly ? 16 : 20}
                                                iconName="heart-outline"
                                                iconClasses=""
                                                btnClasses={""}
                                                handleClick={(e) => {
                                                    alert("This feature is underdevelopment");
                                                }}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <div style={{ fontSize: "1rem", height: "1em", lineHeight: "1em" }} className="me-1">|</div>
                                        </Col>
                                        <Col xs="auto">
                                            <div className="d-flex align-items-center justify-content-start" style={{ fontSize: "1rem" }}>
                                                <div style={{ fontSize: "1rem", height: "1em", lineHeight: "1em" }} className="text-danger me-0">
                                                    <ion-icon name="heart-sharp"></ion-icon>
                                                </div>
                                                <div style={{ fontSize: "1rem", height: "1em", lineHeight: "1em" }} className="text-warning me-1"><ion-icon name="flame-sharp">
                                                </ion-icon>
                                                </div>
                                                <span>24</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="auto">
                                    <BtnIcon
                                        bsVariant={isDark ? "icon-light" : "icon-dark"}
                                        label="go to my account..."
                                        iconSize={isMobileOnly ? 16 : 20}
                                        iconName="ellipsis-vertical-outline"
                                        iconClasses=""
                                        btnClasses={""}
                                        handleClick={(e) => {
                                            alert("This feature is underdevelopment");
                                        }}
                                    />
                                </Col>
                            </Row>

                            {/* MESSAGES */}
                            <div className="position-relative mb-1" style={{ height: "100%", fontSize: ".9rem" }}>

                                <>
                                    {/* MSG LIST HERE... */}
                                    <ConnectMsgList
                                        isDark={isDark}
                                        updateScrollPos={updateScrollPos}
                                    />
                                </>

                            </div>

                            {/* MESSAGE INPUT */}
                            <Row className="gx-1 py-2">
                                <Col>
                                    <Form.Control
                                        placeholder="what's on your mind?..."
                                        className={
                                            `${isDark ? "bg-md-dark border-0 " : ""}text-light m-0`
                                        }
                                        size=""
                                    />
                                </Col>
                                <Col xs="auto">
                                    <BtnIcon
                                        bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                        label="Post your message..."
                                        iconSize={22}
                                        iconName="arrow-forward-outline"
                                        iconClasses=""
                                        btnClasses="rounded-0"
                                        handleClick={(e) => {
                                            e.preventDefault();
                                            alert("This feature in under development");
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    )
                }

            </Row >
        </>
    );

};

export default LightboxMain;