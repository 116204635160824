import React, { useEffect, useRef, useState } from "react";
import {
    //isDesktop,
    isMobileOnly,
    isMobile,
    //isTablet,
} from "react-device-detect";
import Cookies from 'universal-cookie';
import gtag from 'ga-gtag';
import Config from "../data/Config";
import {
    Row, Col, Form
} from "react-bootstrap";

import BtnStackedLink from "../components/buttons/BtnStackedLink";
import MainModal from "../components/shared/MainModal";
import CollectionRow from "../components/collections/CollectionRow";
import CollectionHeaderOnly from "../components/collections/CollectionHeaderOnly";
import CollectionColumn from "../components/collections/CollectionColumn";
import CollectionColumnNoHeader from "../components/collections/CollectionColumnNoHeader";
import CollectionColumnNoFrame from "../components/collections/CollectionColumnNoFrame";
import SliderInsideScrollRow from "../components/collections/SliderInsideScrollRow";
import EventModalContent from "../components/link/events/EventModalContent";
import EventCardImage300x250 from "../components/cards/EventCardImage300x250";
import AdSelectorSide from "../components/ads/AdSelectorSide";

import LinkScreenFrameWithProfile from "../components/shared/LinkScreenFrameWithProfile";

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

const LinkCalendar = ({
    user,
    isDark,
    setShowProfileModal,
    setShowProfileSelector
}) => {

    const [_showMainModal, _setShowMainModal] = useState(false);
    const [_mainModalContent, _setMainModalContent] = useState(<></>);
    const [_removeNavSpacing, _setRemoveNavSpacing] = useState(false);
    const [_mainModalMaxW, _setMainModalMaxW] = useState("");
    const [_showSliders, _setShowSliders] = useState(true);
    const [_blur, _setBlur] = useState(true);
    const [_showAds, _setShowAds] = useState(true);
    const [pageSearchTerm, setPageSearchTerm] = useState("");

    const _localizer = momentLocalizer(moment) // or globalizeLocalizer

    const _eventBanners = Config.eventData; //eventBanners

    const _gtagSent = useRef(false);
    const _cookies = useRef(new Cookies());
    const _cookieOptions = {
        maxAge: 3153600000,
        sameSite: true,
        path: window.location.origin.replace("www.", "")
    }

    useEffect(() => {

        const sectionViewCookie = _cookies.current.get("sectionView", { path: window.location.origin.replace("www.", "") });

        if (sectionViewCookie === "slider") {

            !_showSliders && _setShowSliders(true);

        } else if (sectionViewCookie === "grid") {

            _showSliders && _setShowSliders(false);

        }

    }, [_showSliders, _cookies]);

    const setSliders = () => {

        if (_showSliders) {

            _cookies.current.set("sectionView", "grid", { _cookieOptions });
            _setShowSliders(false);

        } else {

            _cookies.current.set("sectionView", "slider", { _cookieOptions });
            _setShowSliders(true);
        }

    };

    useEffect(() => {
        if (!_gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Link Events',
                page_title: 'Link',
            });

            _gtagSent.current = true;
        }
    }, [_gtagSent]);

    const renderEventModal = () => {

        _setMainModalMaxW("container-1000");
        _setRemoveNavSpacing(true);
        _setMainModalContent(
            <EventModalContent
                isDark={isDark}
            />
        );
        _setShowMainModal(true);
    };

    return (
        <>

            <LinkScreenFrameWithProfile user={user} isDark={isDark} showCarousel={_showAds} setShowProfileModal={setShowProfileModal} setShowProfileSelector={setShowProfileSelector}>
                <div className="mb-3 ms-1">
                    <CollectionHeaderOnly
                        appName="link.calendar"
                        tagline={<>Link with other couples and singles</>}
                        isDark={isDark}
                        showAds={_showAds}
                        setShowAds={_setShowAds}
                        showSliders={_showSliders}
                        setShowSliders={setSliders}
                        showScrollBtn={false}
                        showBlurBtn={false}
                        blur={_blur}
                        setBlur={_setBlur}
                        iconName="calendar-outline"
                        appUrl={"/link"}
                    />
                </div>

                <Row className="justify-content-between flex-nowrap gx-0">
                    <Col lg="9">

                        {/* SEARCH/CREATE */}
                        <CollectionRow className={"align-items-center"}>
                            <CollectionColumnNoHeader
                                xs="12"
                                sm={null}
                                md={null}
                                lg={"8"}
                                xl={null}
                                isDark={isDark}
                            >
                                <div
                                    className="position-relative"
                                    style={{
                                        //cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        void (0);
                                    }}
                                >
                                    <Form.Control
                                        size="lg"
                                        placeholder="Search by partner, event, screen name, etc..."
                                        type="text"
                                        autoCapitalize="off"
                                        className={(isDark ? "bg-md-dark border-dark text-light" : "") + " link-field rounded-1 form-control text-start mt-2 mb-2"}
                                        value={pageSearchTerm}
                                        onChange={(e) => {
                                            setPageSearchTerm(e.target.value);
                                        }}
                                    />

                                </div>

                            </CollectionColumnNoHeader>

                            <CollectionColumnNoFrame
                                xs="12"
                                sm={null}
                                md={null}
                                lg={"4"}
                                xl={null}
                                isDark={isDark}
                                heading={<>Create Clip</>}
                                smallHeading={null}
                                label={"See all clips in the link app..."}
                            >
                                <div className="d-grid">
                                    <BtnStackedLink
                                        bsVariant={isDark ? "outline-light" : "outline-dark"}
                                        bsSize={"lg"}
                                        btnClasses="rounded-1 border-secondary shadow-sm"
                                        label={"Create an Event"}
                                        sublabel={!isMobile ? "Start making your own..." : null}
                                        iconSize={!isMobile ? 62 : 32}
                                        iconName="add-outline"
                                        useLink={true}
                                        href="/"
                                        target=""
                                    />
                                </div>

                            </CollectionColumnNoFrame>
                        </CollectionRow>

                        {/* ROWS */}
                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<>Your Calendar</>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>({_eventBanners.length})</small></>}
                                iconName={"calendar-number-outline"}
                                label={"See all events in the link app..."}
                                appName="events"
                            >
                                <div className={(isDark ? "bg-light" : "bg-white") + " text-dark rounded-1 p-3"} style={{ height: "60vh" }}>
                                    <Calendar
                                        localizer={_localizer}
                                        events={[]}
                                        startAccessor="start"
                                        endAccessor="end"
                                    />
                                </div>
                            </CollectionColumn>
                        </CollectionRow>

                        <CollectionRow className={""}>
                            <CollectionColumn
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                heading={<>Upcoming Events</>}
                                smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>({_eventBanners.length})</small></>}
                                iconName={"calendar-number-outline"}
                                label={"See all events in the link app..."}
                                appName="events"
                            >
                                {_showSliders ? (
                                    <SliderInsideScrollRow showScrollIcons={false}>
                                        <Row className={`${isMobileOnly && "gx-2 "}justify-content-start flex-nowrap`} style={{ fontSize: ".70em" }}>
                                            {_showSliders && _eventBanners.map((item, i) => {

                                                return (
                                                    <Col key={i} xs="6" sm="6" md={null} lg="3" xl={null} className="text-start mb-1">
                                                        <EventCardImage300x250
                                                            //eventId={"Event_ID"}
                                                            isDark={isDark}
                                                            imgURL={item.img}
                                                            vendor={item.vendor}
                                                            imgAltTitle={"Logo for one.bad.apple"}
                                                            //showBtns={false}
                                                            handleClick={renderEventModal}
                                                            status={i < 8 ? "online" : "recentlyOnline"}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </SliderInsideScrollRow>
                                ) : (
                                    <Row className="justify-content-start flex-wrap" style={{ fontSize: ".70em" }}>
                                        {!_showSliders && _eventBanners.map((item, i) => {

                                            return (
                                                <Col key={i} xs="6" sm="6" md={null} lg="3" xl={null} className="text-start mb-4">
                                                    <EventCardImage300x250
                                                        //eventId={"Event_ID"}
                                                        isDark={isDark}
                                                        imgURL={item.img}
                                                        vendor={item.vendor}
                                                        imgAltTitle={"Logo for one.bad.apple"}
                                                        //showBtns={false}
                                                        handleClick={renderEventModal}
                                                    />
                                                </Col>
                                            )

                                        })}
                                    </Row>
                                )}

                            </CollectionColumn>
                        </CollectionRow>
                    </Col>
                    {!isMobile && (
                        <Col className="" lg="auto" style={{ width: 300 }}>
                            <AdSelectorSide
                                isDark={isDark}
                            />
                        </Col>
                    )}
                </Row>
            </LinkScreenFrameWithProfile>

            <MainModal
                showNav={true}
                isDark={isDark}
                removeNaveSpacing={_removeNavSpacing}
                modalColor={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} shadow`}
                bsModalSize=""
                dialogClassName={(`${_mainModalMaxW && _mainModalMaxW !== "" ? ` ${_mainModalMaxW}` : ""}`)}
                contentClassName={`rounded-0${isDark ? " border border-dark" : " border border-secondary"}`}
                showModal={_showMainModal}
                closeModal={() => {
                    _setShowMainModal(false);
                    _setMainModalContent(<></>);
                    _setMainModalMaxW("");
                }}
            >
                {_showMainModal && (
                    <>
                        {_mainModalContent}
                    </>
                )}

            </MainModal>

            {/* {_showLightbox && (
                <LightboxMain
                    lightBoxContent={_lightBoxContent}
                    user={user}
                    isDark={isDark}
                    showComments={true}
                    updateScrollPos={updateScrollPos}
                    setShowLightbox={_setShowLightbox}
                    blur={_blur}
                />
            )} */}
        </>
    );

};

export default LinkCalendar;