import React from "react";
import Config from "../../data/Config";
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import { Row, Col, Container } from 'react-bootstrap';

import AppNameInlineIcon from "../text/AppNameInlineIcon";
import BtnIconLink from "../buttons/BtnIconLink";
import AppNameInline from "../text/AppNameInline";

const PageNavBottom = ({
    logo,
    logoAlt,
    logoMaxW,
    isAuthenticated,
    appVersion,
    setShowHistory,
}) => {

    const getCurYear = () => {

        let dt = new Date();

        return dt.getFullYear();

    };

    return (
        <>
            <Container fluid className="container-1345 py-3">
                <Row className="nav-bottom-links justify-content-center align-items-center gx-1 pb-3">
                    <Col xs="auto">
                        <NavLink end className="rounded-1 d-block px-2 px-lg-2 py-1 py-lg-1 my-linked" to="/">{isAuthenticated ? "Hub" : "Home"}</NavLink>
                    </Col>
                    {isAuthenticated && (
                        <Col className="text-md-dark" xs="auto">
                            |
                        </Col>
                    )}
                    <Col xs="auto">
                        <NavLink end className="rounded-1 d-block px-2 px-lg-2 py-1 py-lg-1 my-linked" to="/news">News</NavLink>
                    </Col>
                    <Col xs="auto">
                        <NavLink end className="rounded-1 d-block px-2 px-lg-2 py-1 py-lg-1 my-linked" to="/contact">Contact</NavLink>
                    </Col>
                    {isAuthenticated && (
                        <>
                            <Col xs="auto">
                                <NavLink end className="rounded-1 d-block px-2 px-lg-2 py-1 py-lg-1 my-linked" to="/about">About</NavLink>
                            </Col>
                            <Col xs="auto">
                                <NavLink end className="rounded-1 d-block px-2 px-lg-2 py-1 py-lg-1 my-linked" to="/privacy">Privacy</NavLink>
                            </Col>
                            <Col xs="auto">
                                <NavLink end className="rounded-1 d-block px-2 px-lg-2 py-1 py-lg-1 my-linked" to="/terms">Terms</NavLink>
                            </Col>
                            <Col className="text-md-dark" xs="auto">
                                |
                            </Col>
                            <Col xs="auto" className="">
                                <BtnIconLink
                                    bsVariant="icon-light"
                                    iconSize={18}
                                    iconName="logo-facebook"
                                    iconClasses=""
                                    btnClasses=""
                                    label="Follow Our Private Facebook Group!"
                                    href="https://www.facebook.com/one.bad.apple.now"
                                    target="_blank"
                                />
                            </Col> 
                        </>
                    )}
                </Row>
                <Row className="justify-content-center mt-1">
                    <Col className="d-flex flex-column justify-content-center" xs="auto">
                        <div className="d-flex flex-row justify-content-center align-items-end">
                            {/* <img src={Config.imgPath + "logos/logo-text-Black.png"} className="img-fluid d-inline-block me-2" alt="Logo for One Bad Apple" style={{ maxWidth: logoMaxW }} /> */}
                            <img src={Config.imgPath + "logos/logo-Web-Adress-White-Logo.png"} className="img-fluid d-inline-block me-2" alt="Logo for One Bad Apple" style={{ maxWidth: logoMaxW }} />                            
                            <span style={{ fontSize: ".95rem" }}>
                                <AppNameInline name="alpha" showCopyright={false} />
                            </span>
                        </div>                      
                        
                        <div className="text-xs text-center mt-3">
                            <small className="text-xs text-seconday">All images, logos, names and concepts &copy; Copyright {getCurYear()} - One.Bad.Apple LLC</small>
                        </div>
                        <h5 className="mt-2 d-flex justify-content-center" style={{ cursor: isAuthenticated ? "pointer" : "" }} onClick={() => { isAuthenticated && setShowHistory(true); }}>
                            <AppNameInlineIcon iconName="code-working-outline" iconSize={"1.8rem"} name={(appVersion.phase.toLowerCase()) + "." + appVersion.num} showCopyright={false} />                               
                        </h5>
                    </Col>
                </Row>
            </Container>

        </>
    );

};

PageNavBottom.propTypes = {
    logo: PropTypes.string,
    logoAlt: PropTypes.string,
    logoMaxW: PropTypes.number,
    btnIconBsVariant: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    appVersion: PropTypes.object
}

PageNavBottom.defaultProps = {
    btnIconBsVariant: "icon-light"
};

export default PageNavBottom;

