import React from "react";
//import PropTypes from 'prop-types';
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    //isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import { Row, Col, /*Badge*/ } from 'react-bootstrap';
import BtnIcon from "../../buttons/BtnIcon";


const ConnectMsgNav = ({
    isDark,
}) => {

    return (
        <>
            <Row className="justify-content-start align-items-center gx-2">
                <Col xs="auto">
                    <div className="d-flex align-items-center justify-content-start m-0">
                        <div className="me-1" style={{ lineHeight: "1rem" }}>
                            <ion-icon style={{ fontSize: 30, }} name="person-circle-outline"></ion-icon>
                        </div>
                        <div style={{ lineHeight: "1rem" }}>
                            <div style={{ fontSize: "1rem" }}>one.bad.apple</div>
                            <div className={isDark ? "text-light-opaque" : "text-dark"} style={{ fontSize: ".70rem" }}>Last active: 8:03 PM, 08/28/22</div>
                        </div>
                    </div>
                </Col>

                <Col className="ms-auto" xs="auto">
                    <Row className="justify-content-end g-1">
                        <Col xs="auto">
                            <BtnIcon
                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                label="Members..."
                                iconSize={24}
                                iconName={"person-add-outline"}
                                iconClasses=""
                                btnClasses=""
                                handleClick={(e) => {
                                    void (0);
                                }}
                            />
                        </Col>
                        <Col xs="auto">
                            <BtnIcon
                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                label="Start a video chat..."
                                iconSize={24}
                                iconName={"videocam-outline"}
                                iconClasses=""
                                btnClasses=""
                                handleClick={(e) => {
                                    void (0);
                                }}
                            />
                        </Col>
                        <Col xs="auto">
                            <BtnIcon
                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                label="Back to recent messages..."
                                iconSize={24}
                                iconName="ellipsis-vertical-outline"
                                iconClasses=""
                                btnClasses=""
                                handleClick={(e) => {
                                    e.preventDefault();
                                    alert("This feature is under development ");
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );

};

export default ConnectMsgNav;