import React, { useState, useEffect, useRef } from "react";
import Config from "../data/Config";
import ProfileScreenFrame from "../components/shared/ProfileScreenFrame";
import LightboxMain from "../components/lightbox/LightboxMain";
import { Row, Col, } from "react-bootstrap";
import {
    //isDesktop, 
    //isMobileOnly,
    //isMobile,
    //isTablet,
} from "react-device-detect";
//import Cookies from 'universal-cookie';
import gtag from 'ga-gtag';
//import Config from "../data/Config";


import BtnStackedLink from "../components/buttons/BtnStackedLink";
import AppNameInline from "../components/text/AppNameInline";

import CollectionHeaderOnly from "../components/collections/CollectionHeaderOnly";
import CollectionRow from "../components/collections/CollectionRow";
import CollectionColumnNoHeader from "../components/collections/CollectionColumnNoHeader";
import SliderInsideScrollRow from "../components/collections/SliderInsideScrollRow";

import ConnectImageStreamPostCard from "../components/cards/ConnectImageStreamPostCard";

const ProfileAbout = ({
    user,
    isAuthenticated,
    isDark,
    toggleChat,
    toggleLive,
    setShowFlirtModal
}) => {

    const [_showAds, _setShowAds] = useState(true);
    const [_blur, _setBlur] = useState(false);
    const [_showLightbox, _setShowLightbox] = useState(false);
    const [_lightBoxContent, _setLightBoxContent] = useState(null);

    const _connectPhotoStream = Config.connectPhotoStream;
    const _gtagSent = useRef(false);
    // const _cookies = useRef(new Cookies());
    // const _cookieOptions = {
    //     maxAge: 3153600000,
    //     sameSite: true,
    //     path: window.location.origin.replace("www.", "")
    // }

    useEffect(() => {

        if (_showLightbox) {
            document.body.classList.add("lightbox-open");
        } else {
            document.body.classList.remove("lightbox-open");
        }

    }, [_showLightbox]);

    useEffect(() => {
        if (!_gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Profile About',
                page_title: 'Profile',
            });

            _gtagSent.current = true;
        }
    }, [_gtagSent]);

    const showLightBoxWindow = (data) => {

        _setLightBoxContent(data.imgs);
        _setShowLightbox(true);
    }

    const updateScrollPos = (target, scrollTo, type) => {

        target.scroll({ top: scrollTo, behavior: type });
    }

    return (
        <>
            <ProfileScreenFrame user={user} isDark={isDark} showCarousel={true} blur={_blur} handleClick={showLightBoxWindow} toggleChat={toggleChat}
toggleLive={toggleLive} setShowFlirtModal={setShowFlirtModal}>
                <CollectionHeaderOnly
                    appName="profile.about"
                    tagline={<>Learn more about them</>}
                    isDark={isDark}
                    showAds={_showAds}
                    setShowAds={_setShowAds}
                    showAdsBtn={false}
                    showScrollBtn={false}
                    showBlurBtn={true}
                    blur={_blur}
                    setBlur={_setBlur}
                    iconName="information-circle-outline"
                    iconSize={null}
                    appUrl={"/profile"}
                />
                <Row className="justify-content-between align-items-stretch gx-3 mt-3">
                    <Col lg="4">
                        <CollectionRow className={"align-items-stretch"}>
                            <CollectionColumnNoHeader
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                className={""}
                            >
                                <div className="mt-2">
                                    {/* <h5 className="mb-2">Overview</h5> */}
                                    <Row className="gy-2 mb-3">
                                        <Col lg="12">
                                            <div className="d-grid">
                                                <BtnStackedLink
                                                    bsVariant={isDark ? "dark" : "light"}
                                                    bsSize={"sm"}
                                                    alignLeft={true}
                                                    btnClasses={(isDark ? "bg-light-dark " : "bg-gray-light ") + "rounded-1 px-3 py-2"}
                                                    label={<><AppNameInline name="Overview" showCopyright={false} /></>}
                                                    sublabel={"General information"}
                                                    iconSize={32}
                                                    iconName="information-circle-outline"
                                                    useLink={true}
                                                    href="/profile/about"
                                                    target=""
                                                    handleClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        alert("This feature is currently under development.");
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                    </Row>
                                    <h5 className="mb-2">Details</h5>
                                    <Row className="gy-2 mb-3">
                                        <Col lg="12">
                                            <div className="d-grid">
                                                <BtnStackedLink
                                                    bsVariant={isDark ? "dark" : "light"}
                                                    bsSize={"sm"}
                                                    alignLeft={true}
                                                    btnClasses="rounded-1 px-3 py-2"
                                                    label={<><AppNameInline name="Couple" showCopyright={false} /></>}
                                                    sublabel={"Preferences, likes, and info"}
                                                    iconSize={32}
                                                    iconName="sync-outline"
                                                    useLink={true}
                                                    href="/profile/about"
                                                    target=""
                                                    handleClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        alert("This feature is currently under development.");
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="d-grid">
                                                <BtnStackedLink
                                                    bsVariant={isDark ? "dark" : "light"}
                                                    bsSize={"sm"}
                                                    alignLeft={true}
                                                    btnClasses="rounded-1 px-3 py-2"
                                                    label={<><AppNameInline name="Him" showCopyright={false} /></>}
                                                    sublabel={"Preferences, likes, and info"}
                                                    iconSize={32}
                                                    iconName="male-outline"
                                                    useLink={true}
                                                    href="/profile/about"
                                                    target=""
                                                    handleClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        alert("This feature is currently under development.");
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="d-grid">
                                                <BtnStackedLink
                                                    bsVariant={isDark ? "dark" : "light"}
                                                    bsSize={"sm"}
                                                    alignLeft={true}
                                                    btnClasses="rounded-1 px-3 py-2"
                                                    label={<><AppNameInline name="Her" showCopyright={false} /></>}
                                                    sublabel={"Preferences, likes, and info"}
                                                    iconSize={32}
                                                    iconName="female-outline"
                                                    useLink={true}
                                                    href="/profile/about"
                                                    target=""
                                                    handleClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        alert("This feature is currently under development.");
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </CollectionColumnNoHeader>
                        </CollectionRow>
                    </Col>
                    <Col lg>
                        <CollectionRow className={"align-items-stretch"}>
                            <CollectionColumnNoHeader
                                xs="12"
                                sm={null}
                                md={null}
                                lg={null}
                                xl={null}
                                isDark={isDark}
                                className={""}
                            >

                                <div className="pt-2 px-sm-2">
                                    <h4>Recent Uploads <small>(5)</small></h4>
                                    <div className="mb-4">
                                        <SliderInsideScrollRow showScrollIcons={false}>
                                            <Row className="flex-nowrap align-items-center justify-content-start gx-2">

                                                {_connectPhotoStream && _connectPhotoStream.map((item, i) => {

                                                    return (
                                                        <Col key={i} className="mb-2" xs="4" lg="20perc">

                                                            <ConnectImageStreamPostCard
                                                                isDark={isDark}
                                                                blur={_blur}
                                                                user={user}
                                                                status={i < 8 ? "online" : "recentlyOnline"}
                                                                data={item}
                                                                handleClick={showLightBoxWindow}
                                                                i={i}
                                                            />

                                                        </Col>
                                                    );
                                                })}

                                            </Row>
                                        </SliderInsideScrollRow>
                                    </div>
                                    <h4>Snapshot</h4>
                                    <Row className="ps-3 mb-4 g-1" style={{ fontSize: ".9rem" }}>
                                        <Col xs="3">
                                            Current Status:
                                        </Col>
                                        <Col xs="9">
                                            Married
                                        </Col>
                                        <Col xs="3">
                                            Lives In:
                                        </Col>
                                        <Col xs="9">
                                            Houston, TX Area
                                        </Col>
                                        <Col xs="3">
                                            Joined:
                                        </Col>
                                        <Col xs="9">
                                            08/23/22
                                        </Col>
                                        <Col xs="3">
                                            Interests:
                                        </Col>
                                        <Col xs="9">
                                            Nudists, Exhibitionists, Voyeurists, Lifestyle
                                        </Col>
                                        <Col xs="3">
                                            Years in Lifestyle:
                                        </Col>
                                        <Col xs="9">
                                            5 Yrs, 2 Mo
                                        </Col>
                                        <Col xs="3">
                                            Play Preferences:
                                        </Col>
                                        <Col xs="9">
                                            Single Women, Single Men, Couples
                                        </Col>
                                        <Col xs="3">
                                            Play Types:
                                        </Col>
                                        <Col xs="9">
                                            Soft, Full, Individual
                                        </Col>
                                    </Row>

                                    <h4>Summary</h4>
                                    <p className="ps-3 mb-4" style={{ fontSize: "1rem" }}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut bibendum diam vel lectus vestibulum, vitae viverra erat tempor. Suspendisse sit amet tincidunt lacus, ut rutrum velit. Phasellus non dapibus tellus. Orci varius natoque penatibus et magnis dis parturient montes...
                                    </p>
                                    <h4>Looking for</h4>
                                    <p className="ps-3 mb-4" style={{ fontSize: "1rem" }}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut bibendum diam vel lectus vestibulum, vitae viverra erat tempor. Suspendisse sit amet tincidunt lacus, ut rutrum velit. Phasellus non dapibus tellus. Orci varius natoque penatibus et magnis dis parturient montes...
                                    </p>
                                </div>

                            </CollectionColumnNoHeader>
                        </CollectionRow>
                    </Col>
                </Row>

            </ProfileScreenFrame>

            {_showLightbox && (
                <LightboxMain
                    lightBoxContent={_lightBoxContent}
                    user={user}
                    isDark={isDark}
                    showComments={true}
                    updateScrollPos={updateScrollPos}
                    setShowLightbox={_setShowLightbox}
                    blur={_blur}
                />
            )}
        </>
    );

};

export default ProfileAbout;