import React from "react";
import { Row, Col, } from "react-bootstrap";
import {
    //isDesktop, 
    isMobileOnly,
    isMobile,
    //isTablet,
} from "react-device-detect";

import ImageNameLG from "./ImageNameLG";

const StandardHeaderNav = ({
    children,
    showShortcutMenu,
    setShowShortcutMenu,
    user,
    isDark,
    showCarousel,
    setShowProfileModal,
    setShowNotificationsMenu,
    showNotificationsMenu,
    setShowSettingsModal,
    setShowProfileSelector,
}) => {

    return (
        <>
            <header className="pb-0 pb-lg-2">
                <>
                    <div
                        className={`${isDark ? "text-light" : "text-dark"} position-relative mb-2 mb-lg-2 pb-0`}
                        style={{
                            marginTop: showCarousel ? (isMobile ? -25 : -80) : 0,
                            zIndex: 5,
                            pointerEvents: "none"
                        }}
                    >
                        <Row className="align-items-end justify-content-between">
                            <Col xs="12" lg="4">
                                {/* PROFILE INFO */}
                                <ImageNameLG
                                    user={user}
                                    isDark={isDark}
                                    isMobileOnly={isMobileOnly}
                                    setShowShortcutMenu={setShowShortcutMenu}
                                    showShortcutMenu={showShortcutMenu}
                                    setShowProfileModal={setShowProfileModal}
                                    setShowNotificationsMenu={setShowNotificationsMenu}
                                    showNotificationsMenu={showNotificationsMenu}
                                    setShowSettingsModal={setShowSettingsModal}
                                    setShowProfileSelector={setShowProfileSelector}
                                />
                            </Col>
                            <Col
                                className="mt-2 mt-sm-5 mt-lg-0"
                                xs="12"
                                lg
                                style={{
                                    //height: isMobileOnly ? "auto" : 115,
                                    pointerEvents: "auto"
                                }}
                            >
                                {children}
                            </Col>
                        </Row>
                    </div>
                </>
            </header>
        </>
    );

};

export default StandardHeaderNav;