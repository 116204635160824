import React from "react";
//import PropTypes from 'prop-types';
import AppNameInline from "./AppNameInline";


const AppNameInlineIcon = ({
    name,
    fontWeight,
    showCopyright,
    iconName,
    iconSize
}) => {

    return (
        <>
            <div className="d-flex align-items-center">
                <div className="me-2" style={{ lineHeight: ".8em", fontSize: iconSize}}><ion-icon name={iconName}></ion-icon></div>
                <AppNameInline name={name} showCopyright={showCopyright} fontWeight={fontWeight} />
            </div>
        </>
    );


};

export default AppNameInlineIcon;