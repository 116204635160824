import React, { useState, useRef } from "react";
//import PropTypes from 'prop-types';
import {
    //isDesktop,
    isMobile,
    //isTablet,
    isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import { Row, Col, } from 'react-bootstrap';
import BtnIcon from "../buttons/BtnIcon";
import AppNameInlineIcon from "../text/AppNameInlineIcon";
import ChatPlayerAlertMngr from "../chatAlerts/ChatPlayerAlertMngr";
import BtnIconLink from "../buttons/BtnIconLink";
import ChatProfileHorizontal from "../profile/ChatProfileHorizontal";

const LiveWindow = ({
    isDark, 
    showLiveWindow,
    toggleLive,

}) => {

    const [_isExpanded, _setIsExpanded] = useState(false);
    const [_showVideoPlayer, _setShowVideoPlayer] = useState(true);
    //const [_videoAspect9by16, _setVideoAspect9by16] = useState(isMobile);
    const [_useChatMic, _setUseChatMic] = useState(true);
    const [_useVideo, _setUseVideo] = useState(true);
    const [_playCallingAnimation, _setPlayCallingAnimation] = useState(true);
    const [_showChatAlert, _setShowChatAlert] = useState(true);
    const [_chatAlertType, _setChatAlertType] = useState("incoming");

    // const _player = useRef();
    // const _incomingAlertShown = useRef(false);
    const _endCallSound = useRef();
    const _startCallSound = useRef();

    // const expandWindow = (e) => {

    //     e.preventDefault();

    //     _setIsExpanded(!_isExpanded);

    // };

    return (
        <div
            className={
                (isDark ? "bg-md-dark text-light border-light-gray " : "bg-light text-dark  ") +
                "border border-1 rounded-1 shadow connect-window position-fixed pt-2 mb-4 px-1 shadow" +
                (showLiveWindow || true ? " show-menu" : " ")
            }
            style={{
                //fontSize: ".9rem",
                zIndex: 1036,
                right: 15,
                bottom: 0,
                width: _isExpanded ? "60vw" : isMobileOnly ? 300 : 360,
                minWidth: 300,
                maxWidth: "calc(100vw - 30px)",
                height: "auto",
                maxHeight: "85vh"
            }}
        >
            <>
                <Row className="justify-content-between my-1 ms-2 gx-1">
                    <Col xs="auto">
                        <Row className="align-items-center gx-1">
                            <Col xs="auto">
                                <h4 className="d-flex align-items-center m-0">
                                    <AppNameInlineIcon name="live" fontWeight={400} showCopyright={false} iconName="videocam-outline" iconSize="1.6rem" />
                                </h4>
                            </Col>
                            <Col xs="auto">
                                <BtnIconLink
                                    bsVariant={(isDark ? "icon-light" : "icon-dark")}
                                    label="Open the live app..."
                                    iconSize={22}
                                    iconName="open-outline"
                                    iconClasses="text-primary"
                                    btnClasses=""
                                    useLink={true}
                                    href="/live/preview"
                                    target=""
                                />
                            </Col>
                        </Row>

                    </Col>
                    <Col xs="auto">
                        <Row className="align-items-center gx-1 gx-sm-2">

                            {/* {(!isMobileOnly) && (
                                <Col xs="auto">
                                    <BtnIcon
                                        bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                        label={_isExpanded ? "Minimize window.." : "Expand window..."}
                                        iconSize={22}
                                        iconName={_isExpanded ? "contract-outline" : "expand-outline"}
                                        iconClasses=""
                                        btnClasses=""
                                        handleClick={(e) => {
                                            expandWindow(e);
                                        }}
                                    />
                                </Col>
                            )} */}
                            <Col xs="auto">
                                <BtnIcon
                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                    label="Close link.connect window..."
                                    iconSize={22}
                                    iconName="arrow-down-outline"
                                    iconClasses=""
                                    btnClasses=""
                                    handleClick={() => {
                                        toggleLive();
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="position-relative py-2 px-1 px-lg-2">
                    <ChatProfileHorizontal 
                        isDark={isDark}
                        //showLightBoxWindow={showLightBoxWindow}
                    />
                    {_showChatAlert && (
                        <div
                            className={(isDark ? "text-light" : "text-dark") + " d-flex flex-column align-items-center justify-content-center position-absolute top-0 end-0 bottom-0 start-0 rounded-1"}
                            style={{
                                backgroundColor: isDark ? "rgba(0, 0, 0, .6)" : "rgba(255, 255, 255, .6)",
                                //backgroundColor: "rgba(0, 0, 0, .6)",
                                zIndex: 10,
                                //pointerEvents: "none"
                                //margin: -15,
                            }}
                        >
                            <ChatPlayerAlertMngr
                                isDark={isDark}
                                //toggleChat={toggleChat}
                                toggleLive={toggleLive}
                                setShowVideoPlayer={_setShowVideoPlayer}
                                setUseVideo={_setUseVideo}
                                //setVideoAspect9by16={_setVideoAspect9by16}
                                chatAlertType={_chatAlertType}
                                setShowChatAlert={_setShowChatAlert}
                                setChatAlertType={_setChatAlertType}
                                //setShowChatList={_setShowChatList}
                                setPlayCallingAnimation={_setPlayCallingAnimation}
                                playCallingAnimation={_playCallingAnimation}
                                profileName={"EandK-Having-Fun"}
                                profileRelationship={"Couple (M/F)"}
                                profileImage={"https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg"}
                            />
                        </div>
                    )}

                    <div
                        className="position-relative overflow-hidden d-flex align-items-center justify-content-center text-center rounded-1"
                        style={{
                            aspectRatio: _isExpanded ? "16 / 9" : "9 / 16",
                        }}
                    >
                        {/* VIDEO/AUDIO CONTROLS */}
                        <audio ref={_endCallSound} id="endCallSound" volume=".7" playsInline>
                            <source src="https://storage.googleapis.com/onebadappleprototype.appspot.com/sounds/mixkit-software-interface-back-2575.mp3" type="audio/mp3" />
                        </audio>

                        {!_showChatAlert && _showVideoPlayer && (

                            <div
                                className={"position-absolute top-0 end-0 me-2 mt-2"}
                                style={{
                                    zIndex: 6,
                                }}
                            >
                                <audio ref={_startCallSound} id="endCallSound" volume=".7" autoPlay playsInline>
                                    <source src="https://storage.googleapis.com/onebadappleprototype.appspot.com/sounds/mixkit-software-interface-start-2574.mp3" type="audio/mp3" />
                                </audio>
                                <Row className="flex-column g-2 mt-2">
                                    <Col xs="auto">
                                        <BtnIcon
                                            bsVariant={"danger"}
                                            label={"End this live call..."}
                                            iconSize={isMobile ? 20 : 24}
                                            iconName={"power-outline"}
                                            iconClasses=""
                                            btnClasses={"border-0"}
                                            handleClick={(e) => {

                                                _endCallSound.current.volume = .5;
                                                _endCallSound.current.play();
                                                _setShowVideoPlayer(false);
                                                _setChatAlertType("sendNewReq");
                                                _setPlayCallingAnimation(false);
                                                _setShowChatAlert(true);
                                            }}
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <BtnIcon
                                            bsVariant={!isDark && !_useVideo ? "icon-dark" : "icon-light"}
                                            label={"Toggle microphone on/off..."}
                                            iconSize={isMobile ? 20 : 24}
                                            iconName={_useChatMic ? "mic-outline" : "mic-off-outline"}
                                            iconClasses={_useChatMic ? "" : ""}
                                            btnClasses={_useChatMic ? "" : "active"}
                                            handleClick={(e) => {

                                                _setUseChatMic(!_useChatMic);
                                            }}
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <BtnIcon
                                            bsVariant={!isDark && !_useVideo ? "icon-dark" : "icon-light"}
                                            label={"Toggle camera on/off..."}
                                            iconSize={isMobile ? 20 : 24}
                                            iconName={_useVideo ? "videocam-outline" : "videocam-off-outline"}
                                            iconClasses={_useVideo ? "" : ""}
                                            btnClasses={_useVideo ? "" : "active"}
                                            handleClick={(e) => {

                                                _setUseVideo(!_useVideo);
                                            }}
                                        />
                                    </Col>
                                    {!isMobileOnly && (
                                        <Col xs="auto">
                                            <BtnIcon
                                                bsVariant={!isDark && !_useVideo ? "icon-dark" : "icon-light"}
                                                label={"Toggle landscape/portrait views..."}
                                                iconSize={isMobile ? 20 : 24}
                                                iconName={_isExpanded ? "tablet-landscape-outline" : "tablet-portrait-outline"}
                                                iconClasses={""}
                                                btnClasses={""}
                                                handleClick={(e) => {

                                                    _setIsExpanded(!_isExpanded);
                                                }}
                                            />
                                        </Col>
                                    )}

                                </Row>
                            </div>
                        )}

                        {/* VIDEO AREA */}
                        {(!_showChatAlert && _showVideoPlayer && _useVideo) && (
                            <>
                                <audio ref={_startCallSound} id="endCallSound" volume=".7" autoPlay playsInline>
                                    <source src="https://storage.googleapis.com/onebadappleprototype.appspot.com/sounds/mixkit-software-interface-start-2574.wav" type="audio/mp3" />
                                </audio>
                                <div
                                    className={"position-absolute top-0 bottom-0 d-flex flex-column align-items-start justify-content-end rounded-0 border-start border-end border-1 border-md-dark text-secondary"}
                                    style={{
                                        //height: _videoAspect9by16 && isDesktop ? "99%" : "100%",
                                        height: "auto",
                                        aspectRatio: _isExpanded ? "16 / 9" : "9 / 16",
                                        backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223032-large.jpg)",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        zIndex: 2,
                                    }}
                                >
                                    {/* // OBA BUG */}
                                    <div
                                        className="position-absolute bottom-0 start-0 ms-3 mb-1"
                                        style={{
                                            width: 10,
                                            zIndex: 1,
                                        }}
                                    >
                                        <img
                                            src="https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-text-Black.png" className="img-fluid" alt="Logo"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="position-absolute end-0 bottom-0 d-flex flex-column align-items-center justify-content-center rounded-1 shadow text-light me-2 mb-2"
                                    style={{
                                        width: _isExpanded ? "15%" : "25%",
                                        aspectRatio: _isExpanded ? "16 / 9" : "9 / 16",
                                        backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/316745629_1469313343558391_6465921652339739041_n-small.jpg)",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        zIndex: 3,
                                        //backgroundColor: "rgba(0, 0, 0, .4)"
                                    }}
                                >
                                    <div className="position-absolute bottom-0 end-0 rounded-circle me-1 mb-1">
                                        <BtnIcon
                                            bsVariant={("btn-light")}
                                            label={true ? "Hide portal..." : "Show portal..."}
                                            iconSize={20}
                                            iconName={"chevron-down-outline"}
                                            iconClasses=""
                                            btnClasses={false ? "active" : ""}
                                            handleClick={(e) => {
                                                void (0);
                                            }}
                                        />
                                    </div>
                                </div>
                            </>

                        )}
                        {/* VIDEO PLAYER */}
                        <div
                            className={`position-relative d-flex flex-column align-items-center justify-content-center rounded-1`}
                            style={{
                                height: "",
                                aspectRatio: !_isExpanded ? "9 / 16" : "16 / 9",
                                backgroundImage: _showVideoPlayer && _useVideo && !_showChatAlert ? "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223032-large.jpg)" : "",
                                //backgroundColor: "rgba(0,0,0,1)",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >

                            {!_showChatAlert && _showVideoPlayer && !_useVideo && (
                                <>
                                    <div
                                        className={`rounded-circle shadow mb-2`}
                                        style={{
                                            width: 140,
                                            aspectRatio: "1 / 1",
                                            backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg)",
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            //cursor: "pointer"
                                        }}
                                    // onClick={() => {
                                    //     showLightBoxWindow({
                                    //         imgs: [
                                    //             { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg", type: "image" },
                                    //         ]
                                    //     });
                                    // }}
                                    >
                                    </div>
                                    <h4
                                        className="d-flex align-items-center mb-0"
                                        style={{
                                            textShadow: "2px 2px 4px rgba(0,0,0,.4)"
                                        }}
                                    >
                                        <span className="me-2 fs-3" style={{ height: "1em" }}><ion-icon name="volume-high-outline"></ion-icon></span>
                                        Audio Only
                                    </h4>
                                </>

                            )}

                        </div>
                    </div>
                </div>
            </>
        </div >
    );
};

export default LiveWindow;