import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useLocation } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "./react-big-calendar-light.css";
import './index.css';

// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";

import App from './App';

const ScrollToTop = () => {

  const pathname = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    setTimeout(() => {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        //behavior: "instant", // Optional if you want to skip the scrolling animation
      });
    }, 300);
  }, [pathname]);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);




