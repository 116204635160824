import React from "react";
//import PropTypes from 'prop-types';
import { Container, } from 'react-bootstrap';
import { isMobileOnly } from "react-device-detect";
import FadeIn from "react-fade-in/lib/FadeIn";
import BtnStdLink from "../components/buttons/BtnStdLink";
import AppNameInlineIcon from "../components/text/AppNameInlineIcon";
import OBAName from "../components/text/OBAName";
import AppNameInline from "../components/text/AppNameInline";

const ContactContent = () => {

    return (
        <>
            <section className="px-5 pb-3 text-center" style={{ textShadow: "2px 2px 4px rgba(0,0,0,.1)" }}>
                <Container fluid className="container-1200">
                    <FadeIn>
                        <h1 className="d-flex justify-content-center align-items-center">
                            <AppNameInlineIcon name="contact" showCopyright={false} iconName="mail-outline" iconsSize="1.25em" />
                        </h1>
                    </FadeIn>
                </Container>
            </section>
            <section className="bg-md-dark text-light py-4 py-lg-5">
                <Container fluid className="container-1200">
                    <FadeIn>
                        <h1>
                            <OBAName /> <AppNameInline name="contact" showCopyright={false} />
                        </h1>
                        <h2>Want more info?</h2>
                        <p>
                            <OBAName fontWeight={500} /> is actively engaged in the discovery and model development phase. The one.bad.apple Team is currently connecting with investors, content developers, and general users to understand what type of products, features, offerings and entertainment interests them. If you have interested in investing, partnering, becoming a vendor, or just want more info about about our business, contact us using the link below. We will get back to you as soon as possible.
                        </p>
                        <p>
                            Thank you for visiting,<br />
                            <OBAName fontWeight={500} /> Team
                        </p>
                        <div className="mt-3 text-center d-flex justify-content-center">
                            <BtnStdLink
                                bsVariant="primary"
                                bsSize={isMobileOnly ? "" : "lg"}
                                btnClasses="rounded-pill shadow-sm pe-3"
                                label="Contact Us Here!"
                                iconSize={24}
                                iconName="mail-outline"
                                href="mailto:info@onebadapplenow.com"
                                target=""
                            />
                        </div>
                    </FadeIn>
                </Container>
            </section>
        </>
    );

};

// ContactContent.propTypes = {

// }

// ContactContent.defaultProps = {

// };

export default ContactContent;