import React from "react";
import { Row, Col, } from "react-bootstrap";
import BtnIconBadgeCountLink from "../buttons/BtnIconBadgeCountLink";

const LiveBtnsHorizontal = ({

    isDark,
    isMobileOnly,
    setShowSettingsModal

}) => {

    return (
        <>
            <Row className="align-items-start justify-content-around gy-3 gx-1 g-lg-0 gx-lg-2 mt-3 mt-lg-0" style={{ fontSize: "1rem", }}>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="connect updates..."
                            visibleText="live"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="videocam-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/live/preview"}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="connect updates..."
                            visibleText="members"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="checkmark-circle-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/live/members"}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="connect updates..."
                            visibleText="groups"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="sync-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/live/groups"}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="connect updates..."
                            visibleText="partners"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="git-compare-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/live/partners"}
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is currently under development.");
                            }}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="pics/vids/clips..."
                            visibleText="uploads"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="images-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/live/media"}
                            handleClick={(e) => {
                                // e.preventDefault();
                                // alert("This feature is currently under development.");
                            }}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="connect updates..."
                            visibleText="saved"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="bookmarks-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/live/history"}
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is currently under development.");
                            }}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="connect updates..."
                            visibleText="history"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="time-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/live/history"}
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is currently under development.");
                            }}
                        />
                    </div>
                </Col>
                <Col xs="3" lg className="d-flex justify-content-center">
                    <div className="sub-link-list position-relative d-flex flex-column align-items-center justify-content-center">
                        <BtnIconBadgeCountLink
                            bsVariant={(isDark ? "dark" : "light")}
                            isDark={isDark}
                            label="settings..."
                            visibleText="settings"
                            visibleTextSize={".95rem"}
                            iconSize={isMobileOnly ? 24 : 30}
                            iconName="cog-outline"
                            iconClasses=""
                            btnClasses={(isDark ? "border-md-dark" : "border-light-dark") + " shadow-sm rounded-pill border mb-1"}
                            badgeCount={null}
                            badgeBG={"primary"}
                            badgeText={"dark"}
                            badgeClasses=""
                            url={"/placeholder"}
                            handleClick={(e) => {
                                e.preventDefault();
                                setShowSettingsModal(true);
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default LiveBtnsHorizontal;