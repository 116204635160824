import React from "react";
import MainModal from "./MainModal";
//import { Routes, Route, useNavigate } from "react-router-dom";
import { Row, Col, } from 'react-bootstrap';
import BtnStd from "../buttons/BtnStd";



const Logout = ({
    setShowLogout,
    acctSignOut
}) => {

    return (
        <>
            <MainModal
                showNav={false}
                isDark={true}
                removeNaveSpacing={false}
                modalColor={"bg-dark text-light shadow"}
                bsModalSize=""
                dialogClassName=""
                contentClassName="rounded-1"
                showModal={true}
                closeModal={() => {
                    setShowLogout(false);
                }}
            >
                <div className="px-lg-3 pb-lg-3 pt-3">
                    <Row className="align-items-center justify-content-center g-2 mb-3 pb-2" style={{ lineHeight: "1em" }}>

                        <Col lg="auto" className="d-flex justify-content-center align-items-center">
                            <h3 className="m-0 p-0">log out?</h3>

                        </Col>
                    </Row>
                    <Row className="justify-content-center g-3">
                        <Col xs="auto">
                            <BtnStd
                                bsVariant="light"
                                bsSize="sm"
                                btnClasses="rounded-pill shadow-sm pe-3"
                                label="Close"
                                iconSize={22}
                                iconName="remove-circle-outline"
                                handleClick={() => {

                                    setShowLogout(false);

                                }}
                            />
                        </Col>
                        <Col xs="auto">
                            <BtnStd
                                bsVariant="dark"
                                bsSize="sm"
                                btnClasses="rounded-pill border border-2 border-primary shadow-sm pe-3"
                                label="log out"
                                iconSize={22}
                                iconName="arrow-redo-sharp"
                                handleClick={() => {

                                    acctSignOut();
                                    setShowLogout(false);

                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </MainModal>
        </>
    );

};

export default Logout;