import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";



const Invest = () => {

    return (
        <>
            <Container className="text-center pt-5" fluid style={{ maxWidth: 300 }}>
                <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/codes/qr-code-oba-invest.png" className="img-fluid" alt="Now QR code" />
                <div>
                    <Link to="/?code=by5iLmEuaW52ZXN0">Create Account Here</Link>
                </div>
            </Container>
        </>
    )

};

export default Invest;