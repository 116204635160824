import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Container, Accordion, Form, } from "react-bootstrap";

import BtnStd from "../../buttons/BtnStd";

import LoadingOverlayAbsolute from "../../shared/LoadingOverlayAbsolute";
import ErrorOverlayFixed from "../../shared/ErrorOverlayFixed";


const AppSetings = ({
    isDark,
    isMobileOnly,
    setShowSettingsModal,
    showToastMsg
}) => {

    const [_activeSettingSection, _setActiveSettingSection] = useState("theme");
    const [_showSaving, _setShowSaving] = useState(false);
    const [_showError, _setShowError] = useState(false);

    const _errorData = useRef();

    const ThemeSettings = () => {

        return (
            <>
                <section className="pb-2">
                    <h5 className="mb-1">Theme</h5>
                    <p className="mb-3" style={{ fontSize: ".80rem" }}>Settings are saved on your current device only</p>
                    <Row
                        className="align-items-center justify-content-center g-3 ms-0 ms-lg-2 mb-2 mb-sm-4"
                        style={{
                            fontSize: isMobileOnly ? ".80rem" : ".9rem"
                        }}
                    >
                        <Col className="d-flex align-items-center justify-content-start" xs="6">
                            <div className="me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"invert-mode-outline"}></ion-icon></div>
                            <div>Use Dark</div>
                        </Col>
                        <Col className="d-flex justify-content-start" xs="6">
                            <Form.Check
                                type="switch"
                                label={<>On <small style={{ fontSize: ".75rem" }}>(Default)</small></>}
                                className=""
                                style={{}}
                                checked={isDark ? true : false}
                                //reverse
                                onChange={(e) => {

                                    e.stopPropagation();
                                    //setIsDark(!isDark);

                                }}
                                onClick={(e) => {

                                    e.stopPropagation();

                                }}
                            />
                        </Col>
                    </Row>

                    <Row
                        className="align-items-center justify-content-center g-3 ms-0 ms-lg-2 mb-2 mb-sm-4"
                        style={{
                            fontSize: isMobileOnly ? ".80rem" : ".9rem"
                        }}
                    >
                        <Col className="d-flex align-items-center justify-content-start" xs="6">
                            <div className="me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"eye-off-outline"}></ion-icon></div>
                            <div>Use Image Blur</div>
                        </Col>
                        <Col className="d-flex justify-content-start" xs="6">
                            <Form.Check
                                type="switch"
                                label={<>On</>}
                                className=""
                                style={{}}
                                checked={false}
                                //reverse
                                onChange={(e) => {

                                    e.stopPropagation();
                                    //setIsDark(!isDark);

                                }}
                                onClick={(e) => {

                                    e.stopPropagation();

                                }}
                            />
                        </Col>
                    </Row>
                </section>
                <section className="pb-2">
                    <h5 className="mb-1">Layout</h5>
                    <p className="mb-3" style={{ fontSize: ".80rem" }}>Settings are saved on your current device only</p>
                    <Row
                        className="align-items-start justify-content-center g-3 ms-0 ms-lg-2 mb-2 mb-sm-4"
                        style={{
                            fontSize: isMobileOnly ? ".80rem" : ".9rem"
                        }}
                    >
                        <Col className="d-flex align-items-center justify-content-start" xs="6">
                            <div className="me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"document-text-outline"}></ion-icon></div>
                            <div>Content View</div>
                        </Col>
                        <Col className="d-flex flex-column justify-content-start" xs="6">
                            <div className="ms-1 mb-0">
                                <Form.Check
                                    defaultChecked
                                    type={"radio"}
                                    name={"defaultLayout"}
                                    id={`scroll`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"swap-horizontal-outline"}></ion-icon></div>
                                                <div>Slider <small style={{ fontSize: ".75rem" }}>(Default)</small></div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                            <div className="ms-1 mb-0">
                                <Form.Check
                                    type={"radio"}
                                    name={"defaultLayout"}
                                    id={`grid`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"grid-outline"}></ion-icon></div>
                                                <div>Grid</div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                </section>

            </>
        );
    };

    const PostSettings = () => {

        return (
            <>
                <section className="pb-2">
                    <h5 className="mb-1">Uploads - <small>Images, videos &amp; clips</small></h5>
                    <p className="mb-3" style={{ fontSize: ".80rem" }}>Settings are saved and shared between devices</p>
                    <Row
                        className="align-items-start justify-content-center g-3 ms-0 ms-lg-2 mb-2 mb-sm-4"
                        style={{
                            fontSize: isMobileOnly ? ".80rem" : ".9rem"
                        }}
                    >
                        <Col className="d-flex align-items-center justify-content-start" xs="6">
                            <div className="me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"folder-outline"}></ion-icon></div>
                            <div>Public</div>
                        </Col>
                        <Col className="d-flex flex-column justify-content-start" xs="6">

                            <div className="ms-1 mb-0">
                                <Form.Check
                                    defaultChecked
                                    type={"radio"}
                                    name={"myUploads"}
                                    id={`members`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"share-social-outline"}></ion-icon></div>
                                                <div>Members <small style={{ fontSize: ".75rem" }}>(Default)</small></div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                            <div className="ms-1 mb-0">
                                <Form.Check
                                    type={"radio"}
                                    name={"myUploads"}
                                    id={`friends`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"checkmark-circle-outline"}></ion-icon></div>
                                                <div>Friends Only</div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row
                        className="align-items-start justify-content-center g-3 ms-0 ms-lg-2 mb-2 mb-sm-4"
                        style={{
                            fontSize: isMobileOnly ? ".80rem" : ".9rem"
                        }}
                    >
                        <Col className="d-flex align-items-center justify-content-start" xs="6">
                            <div className="me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"eye-off-outline"}></ion-icon></div>
                            <div>Private</div>
                        </Col>
                        <Col className="d-flex flex-column justify-content-start" xs="6">

                            <div className="ms-1 mb-0">
                                <Form.Check
                                    defaultChecked
                                    type={"radio"}
                                    name={"myPrivate"}
                                    id={`members`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"share-social-outline"}></ion-icon></div>
                                                <div>Members <small style={{ fontSize: ".75rem" }}>(Default)</small></div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                            <div className="ms-1 mb-0">
                                <Form.Check
                                    type={"radio"}
                                    name={"myPrivate"}
                                    id={`friends`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"checkmark-circle-outline"}></ion-icon></div>
                                                <div>Friends</div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                            <div className="ms-1 mb-0">
                                <Form.Check
                                    type={"radio"}
                                    name={"myPrivate"}
                                    id={`invite`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"add-circle-outline"}></ion-icon></div>
                                                <div>Invite Only</div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row
                        className="align-items-start justify-content-center g-3 ms-0 ms-lg-2 mb-2 mb-sm-4"
                        style={{
                            fontSize: isMobileOnly ? ".80rem" : ".9rem"
                        }}
                    >
                        <Col className="d-flex align-items-center justify-content-start" xs="6">
                            <div className="me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"images-outline"}></ion-icon></div>
                            <div>Profile &amp; Cover</div>
                        </Col>
                        <Col className="d-flex flex-column justify-content-start" xs="6">

                            <div className="ms-1 mb-0">
                                <Form.Check
                                    defaultChecked
                                    type={"radio"}
                                    name={"myProfileCover"}
                                    id={`members`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"share-social-outline"}></ion-icon></div>
                                                <div>Members <small style={{ fontSize: ".75rem" }}>(Default)</small></div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                            <div className="ms-1 mb-0">
                                <Form.Check

                                    type={"radio"}
                                    name={"myProfileCover"}
                                    id={`friends`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"checkmark-circle-outline"}></ion-icon></div>
                                                <div>Friends Only</div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                </section>
                <section className="pb-2">
                    <h5 className="mb-1">Posts</h5>
                    <p className="mb-3" style={{ fontSize: ".80rem" }}>Settings are saved and shared between devices</p>
                    <Row
                        className="align-items-start justify-content-center g-3 ms-0 ms-lg-2 mb-2 mb-sm-4"
                        style={{
                            fontSize: isMobileOnly ? ".80rem" : ".9rem"
                        }}
                    >
                        <Col className="d-flex align-items-center justify-content-start" xs="6">
                            <div className="me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"create-outline"}></ion-icon></div>
                            <div>My Timeline</div>
                        </Col>
                        <Col className="d-flex flex-column justify-content-start" xs="6">

                            <div className="ms-1 mb-0">
                                <Form.Check
                                    defaultChecked
                                    type={"radio"}
                                    name={"myPosts"}
                                    id={`members`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"share-social-outline"}></ion-icon></div>
                                                <div>Members <small style={{ fontSize: ".75rem" }}>(Default)</small></div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                            {/* <div className="ms-1 mb-0">
                                <Form.Check
                                    type={"radio"}
                                    name={"myPosts"}
                                    id={`subscribers`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"shield-checkmark-outline"}></ion-icon></div>
                                                <div>Subscribers Only</div>
                                            </div>
                                        </>
                                    }
                                />
                            </div> */}
                            <div className="ms-1 mb-0">
                                <Form.Check
                                    type={"radio"}
                                    name={"myPosts"}
                                    id={`friends`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"checkmark-circle-outline"}></ion-icon></div>
                                                <div>Friends Only</div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row
                        className="align-items-start justify-content-center g-3 ms-0 ms-lg-2 mb-2 mb-sm-4"
                        style={{
                            fontSize: isMobileOnly ? ".80rem" : ".9rem"
                        }}
                    >
                        <Col className="d-flex align-items-center justify-content-start" xs="6">
                            <div className="me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"create-outline"}></ion-icon></div>
                            <div>My Profile(s)</div>
                        </Col>
                        <Col className="d-flex flex-column justify-content-start" xs="6">

                            <div className="ms-1 mb-0">
                                <Form.Check
                                    defaultChecked
                                    type={"radio"}
                                    name={"myProfile"}
                                    id={`profile`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"share-social-outline"}></ion-icon></div>
                                                <div>Members <small style={{ fontSize: ".75rem" }}>(Default)</small></div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                            <div className="ms-1 mb-0">
                                <Form.Check
                                    type={"radio"}
                                    name={"myProfile"}
                                    id={`members`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"checkmark-circle-outline"}></ion-icon></div>
                                                <div>Sender Only</div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row
                        className="align-items-start justify-content-center g-3 ms-0 ms-lg-2 mb-2 mb-sm-4"
                        style={{
                            fontSize: isMobileOnly ? ".80rem" : ".9rem"
                        }}
                    >
                        <Col className="d-flex align-items-center justify-content-start" xs="6">
                            <div className="me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"create-outline"}></ion-icon></div>
                            <div>Other Profiles</div>
                        </Col>
                        <Col className="d-flex flex-column justify-content-start" xs="6">

                            <div className="ms-1 mb-0">
                                <Form.Check
                                    defaultChecked
                                    type={"radio"}
                                    name={"otherProfile"}
                                    id={`members`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"share-social-outline"}></ion-icon></div>
                                                <div>Members <small style={{ fontSize: ".75rem" }}>(Default)</small></div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                            <div className="ms-1 mb-0">
                                <Form.Check

                                    type={"radio"}
                                    name={"otherProfile"}
                                    id={`profile`}
                                    label={
                                        <>
                                            <div className="d-flex flex-nowrap align-items-center justify-content-start">
                                                <div className="ms-1 me-2" style={{ lineHeight: "1em" }}><ion-icon style={{ fontSize: isMobileOnly ? 18 : 22 }} name={"checkmark-circle-outline"}></ion-icon></div>
                                                <div>Recipient Only</div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                </section>
            </>
        );

    };

    useEffect(() => {

        if (!_showError && _errorData.current) _errorData.current = null;

    }, [_showError, _errorData]);

    const showErrorMsg = (msgData) => {
        
        _errorData.current = msgData;
        _setShowError(true);
    };

    return (
        <>
            <Container fluid className="px-1 px-lg-3 pb-4">
                <h5 className="ms-2 ms-lg-0">App Settings</h5>
                <Accordion className={"border-md-dark border-top border-bottom border-1"} defaultActiveKey="0" flush>
                    <Accordion.Item
                        className={(isDark ? "bg-md2-dark " : "bg-light-dark ") + "border-light-gray text-light d-grid"}
                        eventKey="0"
                    >
                        <Accordion.Button
                            bsPrefix={(_activeSettingSection === "theme" && (isDark ? "bg-light-dark " : "bg-gray-light ")) + (isDark ? " btn-dark " : " btn-light ") + "position-relative btn rounded-0 border-0"}
                            style={{
                                //pointerEvents: _activeSettingSection === "theme" ? "none" : "auto",
                                boxShadow: "none"
                            }}
                            onClick={(e) => {
                                _activeSettingSection === "theme" ? _setActiveSettingSection("") : _setActiveSettingSection("theme");
                            }}
                        >
                            <div
                                className="position-absolute top-0 end-0 mt-3 me-3"
                                style={{
                                    fontSize: isMobileOnly ? 18 : 24,
                                }}

                            >
                                <ion-icon name={_activeSettingSection === "theme" ? "remove-outline" : "chevron-down-outline"}></ion-icon>
                            </div>
                            <Row className="align-items-center justify-content-center flex-nowrap gx-3">
                                <Col xs="auto">
                                    <div
                                        className=""
                                        style={{
                                            lineHeight: "1em",
                                            height: "1em",
                                            fontSize: isMobileOnly ? 30 : 34
                                        }}
                                    >
                                        <ion-icon name={"color-palette-outline"}></ion-icon>
                                    </div>
                                </Col>
                                <Col className="d-flex flex-column align-items-start justify-content-start py-1">
                                    <div className="mb-1" style={{ fontWeight: 400, lineHeight: "1.1em", }}>Theme &amp; Layout</div>
                                    <div style={{ fontWeight: 400, lineHeight: "1.1em", fontSize: ".9em" }}>Screen color, image blur, content view</div>

                                </Col>
                            </Row>
                        </Accordion.Button>
                        <Accordion.Body
                            className={"pt-4 ps-2 ps-lg-4"}
                        >
                            <ThemeSettings />

                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item
                        className={(isDark ? "bg-md2-dark " : "bg-light-dark ") + "border-light-gray text-light d-grid"}
                        eventKey="1"
                    >
                        <Accordion.Button
                            bsPrefix={(_activeSettingSection === "uploads" && (isDark ? "bg-light-dark " : "bg-gray-light ")) + (isDark ? " btn-dark " : " btn-light ") + "position-relative btn rounded-0 border-0"}
                            style={{
                                //pointerEvents: _activeSettingSection === "uploads" ? "none" : "auto",
                                boxShadow: "none"
                            }}
                            onClick={(e) => {
                                _activeSettingSection === "uploads" ? _setActiveSettingSection("") : _setActiveSettingSection("uploads");
                            }}
                        >
                            <div
                                className="position-absolute top-0 end-0 mt-3 me-3"
                                style={{
                                    fontSize: isMobileOnly ? 18 : 24,
                                }}

                            >
                                <ion-icon name={_activeSettingSection === "uploads" ? "remove-outline" : "chevron-down-outline"}></ion-icon>
                            </div>
                            <Row className="align-items-center justify-content-center flex-nowrap gx-3">
                                <Col xs="auto">
                                    <div
                                        className=""
                                        style={{
                                            lineHeight: "1em",
                                            height: "1em",
                                            fontSize: isMobileOnly ? 30 : 34
                                        }}
                                    >
                                        <ion-icon name={"cloud-upload-outline"}></ion-icon>
                                    </div>
                                </Col>
                                <Col className="d-flex flex-column align-items-start justify-content-start py-1">
                                    <div className="mb-1" style={{ fontWeight: 400, lineHeight: "1.1em", }}>Uploads &amp; Posts</div>
                                    <div style={{ fontWeight: 400, lineHeight: "1.1em", fontSize: ".9em" }}>Images, videos, clips, posts</div>

                                </Col>
                            </Row>
                        </Accordion.Button>
                        <Accordion.Body
                            className={"pt-4 ps-2 ps-lg-4"}
                            style={{
                                fontSize: "1rem"
                            }}
                        >

                            <PostSettings />

                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item
                        className={(isDark ? "bg-md2-dark " : "bg-light-dark ") + "border-light-gray text-light d-grid"}
                        eventKey="2"
                    >
                        <Accordion.Button
                            bsPrefix={(_activeSettingSection === "profile" && (isDark ? "bg-light-dark " : "bg-gray-light ")) + (isDark ? " btn-dark " : " btn-light ") + "position-relative btn rounded-0 border-0"}
                            style={{
                                //pointerEvents: _activeSettingSection === "profile" ? "none" : "auto",
                                boxShadow: "none"
                            }}
                            onClick={(e) => {
                                _activeSettingSection === "profile" ? _setActiveSettingSection("") : _setActiveSettingSection("profile");
                            }}
                        >
                            <div
                                className="position-absolute top-0 end-0 mt-3 me-3"
                                style={{
                                    fontSize: isMobileOnly ? 18 : 24,
                                }}

                            >
                                <ion-icon name={_activeSettingSection === "profile" ? "remove-outline" : "chevron-down-outline"}></ion-icon>
                            </div>
                            <Row className="align-items-center justify-content-center flex-nowrap gx-3">
                                <Col xs="auto">
                                    <div
                                        className=""
                                        style={{
                                            lineHeight: "1em",
                                            height: "1em",
                                            fontSize: isMobileOnly ? 30 : 34
                                        }}
                                    >
                                        <ion-icon name={"information-circle-outline"}></ion-icon>
                                    </div>
                                </Col>
                                <Col className="d-flex flex-column align-items-start justify-content-start py-1">
                                    <div className="mb-1" style={{ fontWeight: 400, lineHeight: "1.1em", }}>Profile(s)</div>
                                    <div style={{ fontWeight: 400, lineHeight: "1.1em", fontSize: ".9em" }}>Who can see your information and when</div>

                                </Col>
                            </Row>
                        </Accordion.Button>
                        <Accordion.Body
                            className={"pt-4 ps-2 ps-lg-4"}
                            style={{
                                fontSize: "1rem"
                            }}
                        >
                            <h5 className="mb-1">Privacy</h5>
                            <p className="mb-3" style={{ fontSize: ".80rem" }}>Settings are saved and shared between devices</p>

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Row className="justify-content-between gx-3 mt-4">
                    <Col className="me-auto" xs="auto">
                        <BtnStd
                            bsVariant={isDark ? "link" : "link"}
                            bsSize="xs"
                            btnClasses="rounded-pill border-2 px-0"
                            label="Restore"
                            labelSize=""
                            iconSize={20}
                            iconName="arrow-undo-outline"
                            handleClick={() => {
                                setShowSettingsModal(false);
                            }}
                        />
                    </Col>
                    <Col xs="auto">
                        <BtnStd
                            bsVariant={isDark ? "light" : "dark"}
                            bsSize={isMobileOnly ? "xs" : "sm"}
                            btnClasses="rounded-pill border-2 ps-2 pe-2 pe-sm-3"
                            label="Close"
                            labelSize=""
                            iconSize={20}
                            iconName={isMobileOnly ? "" : "remove-circle-outline"}
                            handleClick={() => {
                                setShowSettingsModal(false);
                                showToastMsg(<><strong>Settings:</strong> Settings Saved!</>);
                            }}
                        />
                    </Col>
                    <Col xs="auto">
                        <BtnStd
                            bsVariant="dark"
                            bsSize={isMobileOnly ? "xs" : "sm"}
                            btnClasses="rounded-pill border border-2 border-primary ps-2 pe-2 pe-sm-3"
                            label="Save"
                            labelSize=""
                            iconSize={20}
                            iconName={isMobileOnly ? "" : "arrow-redo-sharp"}
                            handleClick={() => {

                                _setShowSaving(true);

                                setTimeout(() => {

                                    _setShowSaving(false);

                                    const msgData = {
                                        bgBsVariant: "dark",
                                        borderBsVariant: "primary",
                                        headerIconBsVariant: "primary",
                                        headerMsg: "Error Saving Settings",
                                        bodyMsg: "This is where a message about this settings error would be. It does not have to be long to inform the user.",
                                        customButtons: null,
                                    };

                                    showErrorMsg(msgData);

                                    //setShowSettingsModal(false);

                                }, 1500);

                            }}
                        />
                    </Col>
                </Row>
                {_showSaving && (
                    <>
                        <LoadingOverlayAbsolute
                            msgText="Saving changes..."
                            msgTextBsColor={"text-light"}
                            spinnerType="grow"
                            spinnerBsVariant={"primary"}
                            spinnerBsSize="sm"
                            fontSize=".9rem"
                            appName="settings"
                            showAppNameCopyright={false}
                            showAppLogo={false}
                            showOBALogo={true}
                            isDark={isDark}
                        />
                    </>
                )}
            </Container>

            {_showError && (
                <ErrorOverlayFixed
                    bgBsVariant={_errorData.current.bgBsVariant}
                    borderBsVariant={_errorData.current.borderBsVariant}
                    headerIconBsVariant={_errorData.current.headerIconBsVariant}
                    headerMsg={_errorData.current.headerMsg}
                    bodyMsg={_errorData.current.bodyMsg}
                    customButtons={_errorData.current.customButtons}
                    isDark={isDark}
                    showError={_showError}
                    setShowError={_setShowError}
                />
            )}
        </>
    );

};

export default AppSetings;