import React, { useState, useRef, } from "react";
import { useEffect } from "react";
import { isDesktop } from "react-device-detect";
import BtnIcon from "../buttons/BtnIcon";

const LightboxVideoPlayer = ({
    item,
    blur,
    selectedSlide,
    myIndex,

}) => {

    const [_videoPlaying, _setVideoPlaying] = useState(false);
    const _videoPlayer = useRef();

    useEffect(() => {

        if (myIndex !== selectedSlide && _videoPlayer.current && _videoPlaying) {

            _videoPlayer.current.pause();

            setTimeout(() => {

                _videoPlayer.current.currentTime = 0;

            }, 500);

        } 

    }, [myIndex, selectedSlide, _videoPlaying]);

    return (
        <>
            <div className="position-absolute top-0 end-0 bottom-0 start-0 d-flex justify-content-center" style={{ zIndex: 10 }}>
                {(item.type === "video" && isDesktop && !_videoPlaying) && (
                    <div
                        className="d-flex align-items-center justify-content-center position-absolute top-0 end-0 bottom-0 start-0"
                        style={{
                            zIndex: 5,
                            pointerEvents: "none"
                        }}
                    >
                        <BtnIcon
                            bsVariant={("icon-light")}
                            label={"play..."}
                            iconSize={46}
                            iconName="play-outline"
                            iconClasses=""
                            btnClasses={"shadow"}
                            handleClick={(e) => {

                                if (_videoPlayer.current.play) {

                                    _setVideoPlaying(true);

                                    _videoPlayer.current.play();
                                }
                            }}
                        />
                    </div>
                )}
                <video
                    ref={_videoPlayer}
                    preload="metadata"
                    onPause={(e) => {
                        _setVideoPlaying(false);
                    }}
                    onPlay={(e) => {
                        _setVideoPlaying(true);
                    }}
                    className={(blur ? "img-blur" : "")}
                    style={{
                        height: "100%",
                        width: "100%",
                        aspectRatio: "auto",
                    }}
                    allowFullScreen={false}
                    controls={true}
                    controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
                    disablePictureInPicture
                    autoPlay=
                    {
                        item.type === "reel" ? true : false
                    }
                    loop={false}
                    defaultmuted="true"
                    playsInline
                >
                    <source src={item.img} type="video/mp4" />
                    {/* <source src="movie.ogg" type="video/ogg" /> 
                                                                Your browser does not support the video tag.*/}
                </video>

            </div>
        </>
    )

};

export default LightboxVideoPlayer;