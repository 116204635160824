import React from "react";
//import PropTypes from 'prop-types';
import { Row, Col, /*Badge*/ } from 'react-bootstrap';
import {
    isDesktop,
    //isMobile,
    //isTablet,
    //isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import FadeIn from "react-fade-in/lib/FadeIn";
import Config from "../../data/Config";
import ScrollAreaVertical from "../shared/ScrollAreaVertical";
import BtnIcon from "../buttons/BtnIcon";
import BtnStd from "../buttons/BtnStd";

const CartList = ({
    showCartWindow,
}) => {

    const imgPath = Config.imgPath;
    const cartData = Config.cartList;

    const CartItem = ({
        item,
    }) => {

        return (
            <div>
                <a href={item.prodUrl} className="" onClick={(e) => { e.preventDefault(); alert("Feature under development.") }}>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <img alt={item.imgAlt} title={item.imgAlt} src={imgPath + item.imgThumb} className="img-fluid" style={{ maxWidth: isDesktop ? "70%" : "55%" }} />
                    </div>
                </a>
                <div className="text-center mt-1" style={{ fontSize: ".9rem", fontWeight: 400 }}>
                    {item.price}
                </div>
                <Row className="justify-content-around align-items-center g-1 mt-0 mb-3">
                    <Col xs="auto">
                        <BtnStd
                            bsVariant="white"
                            bsSize={""}
                            btnClasses="rounded-pill border-0 shadow-sm py-1 pe-3"
                            label={item.qty}
                            labelSize={".8em"}
                            iconSize={14}
                            iconName="chevron-down-outline"
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development");
                            }}
                        />
                    </Col>
                    <Col xs="auto">
                        <BtnIcon
                            bsVariant={"icon-dark"}
                            label="Remove from cart..."
                            iconSize={18}
                            iconName="trash-outline"
                            iconClasses=""
                            btnClasses="shadow-sm"
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development")
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );

    };

    return (
        <>
            <ScrollAreaVertical>
                {showCartWindow && (
                    <FadeIn delay={100} duration={200}>

                        {cartData.map((item, i) => {

                            return (
                                <CartItem
                                    key={i}
                                    item={item}
                                />
                            );
                        })}

                    </FadeIn>
                )}
            </ScrollAreaVertical>
        </>
    )

};

export default CartList;