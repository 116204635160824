import React from "react";
import { Container, Col } from "react-bootstrap";
//import FadeIn from "react-fade-in/lib/FadeIn";
//import AppNameInline from "../text/AppNameInline";
//import BtnIcon from "../buttons/BtnIcon";


const CollectionColumnNoHeader = ({
    children,
    className,
    xs,
    sm,
    md,
    lg,
    xl,
    isDark,
}) => {

    return (
        <>
            <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={`mb-3 mb-lg-4 px-1 px-lg-3 ${(className && className !== "") && " " + className}`}>
                <section className="">
                    <Container fluid className={`${isDark ? "bg-md2-dark text-light" : "bg-white text-dark"} position-relative border border-light-dark rounded-2 shadow-sm pt-1 pb-2`} style={{ overflow: "hidden" }}>
                        {children}
                    </Container>
                </section>
            </Col>
        </>
    )

};

export default CollectionColumnNoHeader;