import React from "react";
import { Spinner, } from 'react-bootstrap';
import OBAName from "../text/OBAName";
import AppNameInline from "../text/AppNameInline";


const LoadingOverlayFixed = ({
    msgText,
    msgTextBsColor,
    spinnerType,
    spinnerBsVariant,
    spinnerBsSize,
    fontSize,
    appName,
    showAppNameCopyright,
    showAppLogo,
    showOBALogo

}) => {


    return (
        <>
            <div
                className="position-fixed start-0 end-0 top-0 bottom-0 d-flex flex-column align-items-center justify-content-center"
                style={{
                    backgroundColor: "rgba(0, 0, 0, .85)",
                    zIndex: 20000
                }}
            >
                {(showOBALogo || showAppLogo) && (
                    <h2 className="text-center text-light m-0 mb-2" style={{ lineHeight: "1.1rem" }}>
                        {showOBALogo && (
                            <>
                                <OBAName />{" "}
                            </>
                        )}
                        {showAppLogo && (
                            < AppNameInline name={appName} showCopyright={showAppNameCopyright} />
                        )}

                    </h2>
                )}
                <div className={(msgTextBsColor ? msgTextBsColor + " " : "text-light ") + "text-center m-0 d-flex align-items-center"}>
                    <Spinner
                        animation={spinnerType}
                        size={spinnerBsSize}
                        variant={"secondary"}
                        className="me-2"
                    />
                    <Spinner
                        animation={spinnerType}
                        size={spinnerBsSize}
                        variant={"light"}
                        className="me-2"
                    />
                    <Spinner
                        animation={spinnerType}
                        size={spinnerBsSize}
                        variant={"primary"}
                        className="me-2"
                    />
                    <span style={{ fontSize: fontSize && fontSize !== "" ? fontSize : "auto" }}>{msgText}</span>
                </div>

            </div>
        </>
    );
};

export default LoadingOverlayFixed;