import React, { useEffect, useRef } from "react";
//import { Container, Row, Col } from 'react-bootstrap';
import Config from "../data/Config";
import gtag from 'ga-gtag';
import FadeIn from "react-fade-in/lib/FadeIn";
import HubIntroContent from "../content/HubIntroContent";
import BtnStackedLink from "../components/buttons/BtnStackedLink";
import LinkConnectFollow from "../banners/LinkConnectFollow";
import AppNameInline from "../components/text/AppNameInline";

const HubIntro = ({
    isAuthenticated,
}) => {

    const gtagSent = useRef(false);

    useEffect(() => {

        if (!gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Hub Intro',
                page_title: 'Hub Intro',
            });

            gtagSent.current = true;

        }

    }, [gtagSent]);

    return (
        <>
            <FadeIn>
                <LinkConnectFollow>
                    <BtnStackedLink
                        bsVariant={"dark"}
                        bsSize={""}
                        btnClasses="rounded-pill border border-2 border-primary shadow-sm pe-3"
                        label={<><AppNameInline name="hub" showCopyright={false} /> prototype</>}
                        sublabel={"See the latest preview screens"}
                        iconSize={36}
                        iconName="globe-outline"
                        useLink={true}
                        href="/hub/preview"
                        target=""
                    />
                </LinkConnectFollow>
            </FadeIn>
            <HubIntroContent
                isAuthenticated={isAuthenticated}
            />
        </>
    );

};

export default HubIntro;