import React from "react";
import { Container, Row, Col, } from "react-bootstrap";
import {
    //isDesktop, 
    isMobileOnly,
    //isMobile,
    //isTablet,
} from "react-device-detect";

import ProfileModalHeaderNav from "../profile/ProfileModalHeaderNav";
import BtnStackedLink from "../buttons/BtnStackedLink";
import BtnIcon from "../buttons/BtnIcon";

const ProfileModalFrame = ({
    children,
    isDark,
    blur,
    user,
    showCarousel,
    showLightBoxWindow,
    setShowProfileModal,

}) => {

    return (
        <>
            <div className={`${isDark ? "bg-dark" : "bg-gray-light"} pb-0`}>

                {showCarousel && (
                    <>
                        <Container
                            fluid
                            className={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} ${showCarousel ? "" : "pt-4"} container- shadow-sm px-0 px-sm-0`}
                        >
                            <div
                                className={`${blur ? "img-blur " : ""}position-relative rounded-0 shadow`}
                                style={{
                                    aspectRatio: isMobileOnly ? "16 / 9" : "21 / 9",
                                    //width: "100%",
                                    backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223201-large.jpg)",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    cursor: "pointer"
                                }}
                                onClick={() => {

                                    showLightBoxWindow({
                                        imgs: [
                                            { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223201-large.jpg", type: "image" },
                                        ]
                                    })
                                }}
                            >
                                <div
                                    className="position-absolute end-0 bottom-0 mb-3 me-3"
                                >
                                    <Row className="align-items-center gx-2">
                                        <Col xs="auto">
                                            <BtnStackedLink
                                                bsVariant={"light"}
                                                bsSize={"sm"}
                                                btnClasses="rounded-pill shadow ps-3 pe-3"
                                                label={<>Friends</>}
                                                sublabel={null}
                                                iconSize={28}
                                                iconName="checkmark-circle-outline"
                                                useLink={false}
                                                href="/live"
                                                target=""
                                                handleClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    alert("This feature is currently under development.");
                                                }}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <BtnIcon
                                                bsVariant={"dark"}
                                                iconSize={isMobileOnly ? 20 : 24}
                                                iconName="videocam-outline"
                                                iconClasses=""
                                                btnClasses={"shadow-sm border border-2 border-primary"}
                                                handleClick={(e) => {

                                                    e.stopPropagation();
                                                    alert("This feature is currently under development.");

                                                }}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <BtnIcon
                                                bsVariant={"dark"}
                                                iconSize={isMobileOnly ? 20 : 24}
                                                iconName="chatbubbles-outline"
                                                iconClasses=""
                                                btnClasses={"shadow-sm border border-2 border-primary"}
                                                handleClick={(e) => {

                                                    e.stopPropagation();
                                                    alert("This feature is currently under development.");
                                                    // toggleChat(false);

                                                }}
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <BtnIcon
                                                bsVariant={"dark"}
                                                iconSize={isMobileOnly ? 20 : 24}
                                                iconName="heart-outline"
                                                iconClasses=""
                                                btnClasses={"shadow-sm border border-2 border-primary"}
                                                handleClick={(e) => {

                                                    e.stopPropagation();
                                                    alert("This feature is currently under development.");
                                                    // setShowFlirtModal(true);

                                                }}
                                            />
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Container>
                    </>
                )}

                <Container
                    fluid
                    className={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} ${showCarousel ? "" : "pt-4"} shadow-sm px-3`}
                >
                    {/* PROFILE */}
                    <ProfileModalHeaderNav
                        showCarousel={showCarousel}
                        user={user}
                        isDark={isDark}
                    >
                        <div className="me-sm-3">
                            <BtnStackedLink
                                bsVariant={isDark ? "dark" : "light"}
                                bsSize={"xs"}
                                btnClasses="rounded-pill shadow-sm border border-2 border-primary ps-2 pe-3"
                                label={<><span className="text-primary">EXPLORE</span> Full Profile</>}
                                sublabel={"Info, posts, uploads, more"}
                                iconSize={22}
                                iconName="arrow-redo-sharp"
                                useLink={true}
                                href="/profile/activity"
                                target=""
                                handleClick={(e) => {

                                    e.stopPropagation();
                                    setShowProfileModal(false);
                                }}
                            />
                        </div>
                    </ProfileModalHeaderNav>
                    {children}
                </Container>
            </div>
        </>
    );

};

export default ProfileModalFrame;