import React from "react";
//import PropTypes from 'prop-types';


const AppNameInline = ({
    name,
    fontWeight,
    showCopyright
}) => {

    return (
        <>

            <span style={{ fontWeight: fontWeight ? fontWeight : "" }}>
                <span className="text-primary">[</span>
                {name}
                <span className="text-primary">]</span>
                {showCopyright === true && (
                    <small className="text-muted" style={{ fontSize: ".85rem", }}> &copy;</small>
                )}

            </span>

        </>
    );


};

export default AppNameInline;