import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Modal, } from 'react-bootstrap';
import BtnIcon from "../buttons/BtnIcon";
import LoadingOverlayAbsolute from "./LoadingOverlayAbsolute";


const MainModal = ({
    children,
    bsModalSize,
    showNav,
    isDark,
    useDark,
    removeNaveSpacing,
    modalColor,
    dialogClassName,
    contentClassName,
    showModal,
    closeModal,
    showLoading,
    loadingMsg,
    //handleLoadingLoading,
}) => {

    const [_showLoading, _setShowLoading] = useState(false);

    let defaultContentClassName = `${modalColor && modalColor !== "" ? ` ${modalColor}` : " bg-md-dark text-light"}`;
    defaultContentClassName += (contentClassName && contentClassName !== "") ? " " + contentClassName : "";

    useEffect(() => {

        if (!showLoading) return;

        _setShowLoading(true);

        setTimeout(() => {

            _setShowLoading(false);

        }, 1500);
    }, [showLoading]);

    return (
        <>
            <Modal
                centered
                size={bsModalSize}
                show={showModal}
                backdrop="static"
                keyboard={false}
                dialogClassName={dialogClassName}
                contentClassName={defaultContentClassName}
                style={{
                    overflowY: "scroll",
                }}
            >
                {showNav && (
                    <div
                        className={"px-lg-3 py-lg-2" + (removeNaveSpacing ? " position-absolute" : "")}
                        style={{
                            zIndex: 10
                        }}
                    >
                        <BtnIcon
                            bsVariant={((isDark || removeNaveSpacing || useDark) ? "icon-light" : "icon-dark")}
                            iconSize={24}
                            iconName="arrow-back-outline"
                            iconClasses=""
                            handleClick={closeModal}
                        />
                    </div>
                )}

                {_showLoading ? (
                    <div style={{ minHeight: 360, textShadow: "2px 2px 4px rgba(0,0,0,.1)" }}>
                        <LoadingOverlayAbsolute
                            msgText={loadingMsg && loadingMsg !== "" ? loadingMsg : "Loading..."}
                            msgTextBsColor={isDark ? "text-light" : "text-dark"}
                            spinnerType="grow"
                            spinnerBsVariant={isDark ? "light" : "dark"}
                            spinnerBsSize="sm"
                            fontSize=""
                            appName=""
                            showAppNameCopyright={false}
                            showAppLogo={false}
                            showOBALogo={true}
                            isDark={isDark}
                        />
                    </div>

                ) : (
                    <>
                        {children}
                    </>
                )}


            </Modal>
        </>
    );

};

MainModal.propTypes = {
    children: PropTypes.any,
    bsModalSize: PropTypes.string,
    showNav: PropTypes.bool,
    dialogClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
}

MainModal.defaultProps = {
    bsModalSize: "",
};

export default MainModal;