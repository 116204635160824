import React, { useEffect, useRef, useState } from "react";
import {
    isDesktop,
    isMobile,
    isMobileOnly,
    isTablet
} from "react-device-detect";
import Cookies from 'universal-cookie';
import gtag from 'ga-gtag';
import Config from "../data/Config";
import {
    Row, Col,
} from "react-bootstrap";

import BtnIcon from "../components/buttons/BtnIcon";
//import BtnStackedLink from "../components/buttons/BtnStackedLink";
import MainModal from "../components/shared/MainModal";
import CollectionRow from "../components/collections/CollectionRow";
import CollectionColumn from "../components/collections/CollectionColumn";
import CollectionHeaderOnly from "../components/collections/CollectionHeaderOnly";
import CollectionColumnNoHeader from "../components/collections/CollectionColumnNoHeader";
import SliderInsideScrollRow from "../components/collections/SliderInsideScrollRow";
import LightboxMain from "../components/lightbox/LightboxMain";
import RecentChatList from "../components/connect/RecentChatList";
import MemberCard from "../components/cards/MemberCard";
import GroupCard from "../components/cards/GroupCard";
import AdSelectorHub from "../components/ads/AdSelectorHub";
import LiveScreenFrameWithProfile from "../components/shared/LiveScreenFrameWithProfile";
import ChatPlayerAlertMngr from "../components/chatAlerts/ChatPlayerAlertMngr";
import ChatProfileVertical from "../components/profile/ChatProfileVertical";
import BtnStdLink from "../components/buttons/BtnStdLink";


const ConnectLivePreview = ({
    user,
    isDark,
    setShowProfileModal,
    setShowSettingsModal,
    showNotificationsMenu,
    setShowNotificationsMenu,
    setShowProfileSelector,
    toggleChat,
    toggleLive,
    showChatWindow,
    setShowFlirtModal
}) => {

    const [_showMainModal, _setShowMainModal] = useState(false);
    const [_mainModalContent, _setMainModalContent] = useState(<></>);
    const [_mainModalMaxW, _setMainModalMaxW] = useState("");
    const [_showSliders, _setShowSliders] = useState(true);
    const [_blur, _setBlur] = useState(false);
    //const [_videoBGBlur, _setVideoBGBlur] = useState(false);
    const [_showVideoPlayer, _setShowVideoPlayer] = useState(false);
    const [_videoAspect9by16, _setVideoAspect9by16] = useState(isMobile);
    const [_showAds, _setShowAds] = useState(true);
    const [_showLightbox, _setShowLightbox] = useState(false);
    const [_lightBoxContent, _setLightBoxContent] = useState(null);
    const [_showFullWidth, _setShowFullWidth] = useState(false);
    const [_showChatAlert, _setShowChatAlert] = useState(false);
    const [_chatAlertType, _setChatAlertType] = useState("");
    const [_showChatList, _setShowChatList] = useState(false);
    const [_useChatMic, _setUseChatMic] = useState(true);
    const [_useVideo, _setUseVideo] = useState(true);
    const [_playCallingAnimation, _setPlayCallingAnimation] = useState(false);

    const _player = useRef();
    const _incomingAlertShown = useRef(false);
    const _endCallSound = useRef();
    const _startCallSound = useRef();


    const _activeMembers = Config.memberStatusList; //Active Members

    useEffect(() => {

        if (!_showChatAlert && !_incomingAlertShown.current) {

            _setChatAlertType("incoming");
            _setShowChatAlert(true);
            _setPlayCallingAnimation(true);

            // TEMP
            _incomingAlertShown.current = true;

            setTimeout(() => {

                const elem = _player.current;
                const rect = elem.getBoundingClientRect();

                window.scrollTo(0, (rect.top - 100));

            }, 1500);
        }

    }, [_showChatAlert, _setShowChatAlert, _incomingAlertShown]);

    const _gtagSent = useRef(false);
    const _cookies = useRef(new Cookies());
    const _cookieOptions = {
        maxAge: 3153600000,
        sameSite: true,
        path: window.location.origin.replace("www.", "")
    }

    useEffect(() => {
        if (!_gtagSent.current) {

            gtag('event', 'screen_view', {
                app_name: Config.appName,
                app_version: Config.appVersionHistory[0].num,
                screen_name: 'Live Activity',
                page_title: 'Live',
            });

            _gtagSent.current = true;
        }
    }, [_gtagSent]);

    useEffect(() => {

        if (_showLightbox || _showFullWidth) {
            document.body.classList.add("lightbox-open");
        } else {
            document.body.classList.remove("lightbox-open");
        }

        //_setShowLightbox(false);
        //_setLightBoxContent(null);

    }, [_showLightbox, _showFullWidth]);

    useEffect(() => {

        const sectionViewCookie = _cookies.current.get("sectionView", { path: window.location.origin.replace("www.", "") });

        if (sectionViewCookie === "slider") {

            !_showSliders && _setShowSliders(true);

        } else if (sectionViewCookie === "grid") {

            _showSliders && _setShowSliders(false);

        }

    }, [_showSliders, _cookies]);

    const setSliders = () => {

        if (_showSliders) {

            _cookies.current.set("sectionView", "grid", { _cookieOptions });
            _setShowSliders(false);

        } else {

            _cookies.current.set("sectionView", "slider", { _cookieOptions });
            _setShowSliders(true);
        }

    };

    const updateScrollPos = (target, scrollTo, type) => {

        target.scroll({ top: scrollTo, behavior: type });
    }

    const showLightBoxWindow = (data) => {

        _setLightBoxContent(data.imgs);
        _setShowLightbox(true);
    }

    return (
        <>
            <LiveScreenFrameWithProfile
                user={user}
                isDark={isDark}
                showCarousel={_showAds}
                showFullWidth={_showFullWidth}
                setShowProfileModal={setShowProfileModal}
                setShowSettingsModal={setShowSettingsModal}
                showNotificationsMenu={showNotificationsMenu}
                setShowNotificationsMenu={setShowNotificationsMenu}
                setShowProfileSelector={setShowProfileSelector}
            >

                <div className="ms-1 pb-3">
                    <CollectionHeaderOnly
                        appName="live.preview"
                        tagline={<>Connect with live audio/video</>}
                        isDark={isDark}
                        showAds={_showAds}
                        setShowAds={_setShowAds}
                        showAdsBtn={true}
                        showScrollBtn={true}
                        showSliders={_showSliders}
                        setShowSliders={setSliders}
                        showBlurBtn={true}
                        blur={_blur}
                        setBlur={_setBlur}
                        iconName="videocam-outline"
                    />
                </div>

                <CollectionRow className={"position-relative align-items-stretch"}>
                    <CollectionColumnNoHeader
                        xs="12"
                        sm={null}
                        md={null}
                        lg={null}
                        xl={null}
                        isDark={isDark}
                        className={"pb-0"}
                    >
                        <div
                            className={(_showFullWidth ? `position-fixed start-0 top-0 end-0 bottom-0 py-1 px-2 px-sm-4 py-sm-3 ${isMobileOnly ? "isMobileOnly " : ""}${isDark ? "bg-md2-dark" : "bg-light"}` : "position-relative")}
                            style={{
                                zIndex: _showFullWidth ? 1035 : 1,

                            }}
                        >
                            <>
                                {_showChatAlert && (
                                    <div
                                        className={(isDark ? "text-light" : "text-dark") + " d-flex flex-column align-items-center justify-content-center position-absolute top-0 end-0 bottom-0 start-0 rounded-1"}
                                        style={{
                                            backgroundColor: isDark ? "rgba(0, 0, 0, .6)" : "rgba(255, 255, 255, .6)",
                                            //backgroundColor: "rgba(0, 0, 0, .6)",
                                            zIndex: 10,
                                            //pointerEvents: "none"
                                            margin: -15,
                                        }}
                                    >
                                        <ChatPlayerAlertMngr
                                            isDark={isDark}
                                            toggleChat={toggleChat}
                                            toggleLive={toggleLive}
                                            setShowVideoPlayer={_setShowVideoPlayer}
                                            setUseVideo={_setUseVideo}
                                            setVideoAspect9by16={_setVideoAspect9by16}
                                            chatAlertType={_chatAlertType}
                                            setShowChatAlert={_setShowChatAlert}
                                            setChatAlertType={_setChatAlertType}
                                            setShowChatList={_setShowChatList}
                                            setPlayCallingAnimation={_setPlayCallingAnimation}
                                            playCallingAnimation={_playCallingAnimation}
                                            profileName={"EandK-Having-Fun"}
                                            profileRelationship={"Couple (M/F)"}
                                            profileImage={"https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg"}
                                        />
                                    </div>
                                )}
                                <Row ref={_player} className="align-items-center justify-content-between gx-2 mt-1 pb-0">
                                    <Col xs="auto" lg={"auto"}>
                                        <h4 className="m-0">Live Chat</h4>
                                    </Col>
                                    <Col className=" d-flex align-items-center justify-content-end" xs="auto">
                                        <Row className="align-items-center gx-1 gx-lg-2">
                                            <Col xs="auto">
                                                <BtnStdLink
                                                    bsVariant="dark"
                                                    bsSize={"xs"}
                                                    btnClasses="rounded-pill border border-2 border-primary text-light shadow-sm pe-3"
                                                    iconClasses=""
                                                    label={<><span className="text-primary" style={{ fontWeight: 600 }}>NEW</span></>}
                                                    iconSize={17}
                                                    iconName="arrow-redo-sharp"
                                                    useLink={false}
                                                    handleClick={() => {

                                                        return;
                                                        //_setShowChatList(!_showChatList);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                    label={_showChatList ? "Hide list..." : "Show list..."}
                                                    iconSize={26}
                                                    iconName={_showChatList ? "reorder-four-outline" : "reorder-four-outline"}
                                                    iconClasses=""
                                                    btnClasses={_showChatList ? "active" : ""}
                                                    handleClick={(e) => {
                                                        _setShowChatList(!_showChatList);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="auto">
                                                <BtnIcon
                                                    bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                                    label={_showFullWidth ? "Contract window..." : "Expand window..."}
                                                    iconSize={26}
                                                    iconName={_showFullWidth ? "contract-outline" : "expand-outline"}
                                                    iconClasses=""
                                                    btnClasses=""
                                                    handleClick={(e) => {
                                                        _setShowFullWidth(!_showFullWidth);
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                                <Row
                                    className="position-relative overflow-hidden align-items-stretch gx-3 py-2"
                                >
                                    <div
                                        className={(isDark ? "bg-md2-dark " : "bg-light ") + (_showChatList ? "show-menu " : "") + "chat-list position-absolute top-0 bottom-0 d-flex flex-column py-3"}
                                        style={{
                                            zIndex: 8,
                                            width: "95vw",
                                            maxWidth: 400,
                                        }}
                                    >
                                        {/* CHAT LIST */}
                                        <div className="pb-1 mb-2">
                                            <Row className="justify-content-between align-items-center g-1 ps-3 pe-2">
                                                <Col xs="auto">
                                                    <h4 className="d-flex align-items-center justify-content-start m-0">
                                                        Recent Calls
                                                    </h4>
                                                </Col>
                                                <Col xs="auto">
                                                    <BtnIcon
                                                        bsVariant={"icon-light"}
                                                        label="Minimize cart window..."
                                                        iconSize={20}
                                                        iconName="arrow-back-outline"
                                                        iconClasses=""
                                                        btnClasses=""
                                                        handleClick={() => {
                                                            _setShowChatList(false);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="position-relative" style={{ height: "100%", fontSize: ".9rem" }}>

                                            {_showChatList && (
                                                <div className="my-3">
                                                    <RecentChatList
                                                        isDark={isDark}
                                                        showMinMsgs={false}
                                                        setShowMinMsgs={null}
                                                        isExpanded={!isMobileOnly}
                                                        updateScrollPos={updateScrollPos}
                                                        setShowChatAlert={null}
                                                    />
                                                </div>
                                            )}

                                        </div>
                                    </div>

                                    {isDesktop && (
                                        <Col lg={"2"}>

                                            {/* CHAT PROFILE DESKTOP */}
                                            <ChatProfileVertical
                                                pageName="live"
                                                blur={_blur}
                                                isDark={isDark}
                                                showLightBoxWindow={showLightBoxWindow}
                                                setShowFlirtModal={setShowFlirtModal}
                                                setShowChatAlert={_setShowChatAlert}
                                                setChatAlertType={_setChatAlertType}
                                                setPlayCallingAnimation={_setPlayCallingAnimation}
                                                setShowProfileModal={setShowProfileModal}
                                                toggleChat={toggleChat}
                                                toggleLive={toggleLive}
                                                showVideoPlayer={_showVideoPlayer}
                                            />
                                        </Col>
                                    )}

                                    <Col lg>

                                        <div
                                            className="position-relative overflow-hidden d-flex align-items-center justify-content-center text-center rounded-1"
                                            style={{
                                                aspectRatio: isMobile ? "9 / 16" : "16 / 9",
                                            }}
                                        >
                                            {/* VIDEO/AUDIO CONTROLS */}
                                            <audio ref={_endCallSound} id="endCallSound" playsInline>
                                                <source src="https://storage.googleapis.com/onebadappleprototype.appspot.com/sounds/mixkit-software-interface-back-2575.mp3" type="audio/mp3" />
                                            </audio>

                                            {!_showChatAlert && _showVideoPlayer && (

                                                <div
                                                    className={"position-absolute top-0 end-0 me-2 mt-2"}
                                                    style={{
                                                        zIndex: 4,
                                                    }}
                                                >
                                                    <audio ref={_startCallSound} id="endCallSound" autoPlay playsInline>
                                                        <source src="https://storage.googleapis.com/onebadappleprototype.appspot.com/sounds/mixkit-software-interface-start-2574.mp3" type="audio/mp3" />
                                                    </audio>
                                                    <Row className="flex-column gy-2 mt-1">
                                                        <Col xs="auto">
                                                            <BtnIcon
                                                                bsVariant={"danger"}
                                                                label={"End this live call..."}
                                                                iconSize={isMobile ? 20 : 24}
                                                                iconName={"power-outline"}
                                                                iconClasses=""
                                                                btnClasses={"border-0"}
                                                                handleClick={(e) => {

                                                                    _endCallSound.current.volume = .3;
                                                                    _endCallSound.current.play();
                                                                    _setShowVideoPlayer(false);
                                                                    _setChatAlertType("sendNewReq");
                                                                    _setPlayCallingAnimation(false);
                                                                    _setShowChatAlert(true);
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xs="auto">
                                                            <BtnIcon
                                                                bsVariant={"icon-light"}
                                                                label={"Send connect messages..."}
                                                                iconSize={isMobile ? 20 : 24}
                                                                iconName={"chatbubbles-outline"}
                                                                iconClasses=""
                                                                btnClasses={showChatWindow ? "active" : ""}
                                                                handleClick={(e) => {

                                                                    toggleChat();
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xs="auto">
                                                            <BtnIcon
                                                                bsVariant={"icon-light"}
                                                                label={"Toggle microphone on/off..."}
                                                                iconSize={isMobile ? 20 : 24}
                                                                iconName={_useChatMic ? "mic-outline" : "mic-off-outline"}
                                                                iconClasses={_useChatMic ? "" : ""}
                                                                btnClasses={_useChatMic ? "" : "active"}
                                                                handleClick={(e) => {

                                                                    _setUseChatMic(!_useChatMic);
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xs="auto">
                                                            <BtnIcon
                                                                bsVariant={"icon-light"}
                                                                label={"Toggle camera on/off..."}
                                                                iconSize={isMobile ? 20 : 24}
                                                                iconName={_useVideo ? "videocam-outline" : "videocam-off-outline"}
                                                                iconClasses={_useVideo ? "" : ""}
                                                                btnClasses={_useVideo ? "" : "active"}
                                                                handleClick={(e) => {

                                                                    _setUseVideo(!_useVideo);
                                                                }}
                                                            />
                                                        </Col>
                                                        {isDesktop && (
                                                            <Col xs="auto">
                                                                <BtnIcon
                                                                    bsVariant={"icon-light"}
                                                                    label={"Toggle landscape/portrait views..."}
                                                                    iconSize={isMobile ? 20 : 24}
                                                                    iconName={_videoAspect9by16 ? (isMobileOnly ? "phone-portrait-outline" : "tablet-portrait-outline") : (isMobileOnly ? "phone-landscape-outline" : "tablet-landscape-outline")}
                                                                    iconClasses={""}
                                                                    btnClasses={""}
                                                                    handleClick={(e) => {

                                                                        _setVideoAspect9by16(!_videoAspect9by16);
                                                                    }}
                                                                />
                                                            </Col>
                                                        )}

                                                    </Row>
                                                </div>
                                            )}

                                            {/* VIDEO AREA */}
                                            {(_showVideoPlayer && _useVideo) && (
                                                <>
                                                    <audio ref={_startCallSound} id="endCallSound" autoPlay playsInline>
                                                        <source src="https://storage.googleapis.com/onebadappleprototype.appspot.com/sounds/mixkit-software-interface-start-2574.wav" type="audio/mp3" />
                                                    </audio>
                                                    <div
                                                        className={"position-absolute top-0 bottom-0 d-flex flex-column align-items-start justify-content-end bg-dark rounded-0 border-start border-end border-1 border-md-dark text-secondary" + (_videoAspect9by16 ? " shadow mt-1" : "")}
                                                        style={{
                                                            height: _videoAspect9by16 && isDesktop ? "99%" : "100%",
                                                            width: "auto",
                                                            aspectRatio: _videoAspect9by16 ? ("9 / 16") : ("16 / 9"),
                                                            backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223032-large.jpg)",
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center",
                                                            zIndex: 2,
                                                        }}
                                                    >
                                                        {/* // OBA BUG */}
                                                        <div
                                                            className="position-absolute bottom-0 start-0 ms-3 mb-1"
                                                            style={{
                                                                width: 120,
                                                                zIndex: 1,
                                                            }}
                                                        >
                                                            <img
                                                                src="https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-text-Black.png" className="img-fluid" alt="Logo"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="position-absolute end-0 bottom-0 d-flex flex-column align-items-center justify-content-center rounded-1 shadow text-light me-2 mb-2"
                                                        style={{
                                                            width: isMobile ? "25%" : (_videoAspect9by16 ? "10%" : "15%"),
                                                            aspectRatio: _videoAspect9by16 ? "9 / 16" : "16 / 9",
                                                            backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/316745629_1469313343558391_6465921652339739041_n-small.jpg)",
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center",
                                                            zIndex: 3,
                                                            //backgroundColor: "rgba(0, 0, 0, .4)"
                                                        }}
                                                    >
                                                        <div className="position-absolute bottom-0 end-0 rounded-circle me-1 mb-1">
                                                            <BtnIcon
                                                                bsVariant={("btn-light")}
                                                                label={true ? "Hide portal..." : "Show portal..."}
                                                                iconSize={20}
                                                                iconName={"chevron-down-outline"}
                                                                iconClasses=""
                                                                btnClasses={false ? "active" : ""}
                                                                handleClick={(e) => {
                                                                    void (0);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>

                                            )}
                                            {/* VIDEO PLAYER */}
                                            <div
                                                className={`${(_videoAspect9by16 && _useVideo && isDesktop) ? "img-blur opacity-75" : ""} position-relative d-flex flex-column align-items-center justify-content-center rounded-1 bg-dark`}
                                                style={{
                                                    height: "100%",
                                                    aspectRatio: isMobile ? "9 / 16" : "16 / 9",
                                                    backgroundImage: _showVideoPlayer && _useVideo && !_showChatAlert ? "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223032-large.jpg)" : "",
                                                    backgroundColor: "rgba(0,0,0,1)",
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center",
                                                }}
                                            >
                                                {!_showChatAlert && !_showVideoPlayer && (
                                                    <>
                                                        {/* <div className="mb-3" style={{ fontSize: 120, height: "1em"}}>
                                                            <ion-icon name="call-outline"></ion-icon>
                                                        </div> */}
                                                        <div className="text-start w-100 px-2 px-lg-4">
                                                            <h3 className="text-start">Recent Calls</h3>
                                                            <SliderInsideScrollRow showScrollIcons={false}>
                                                                {/* SCROLL VIEW */}
                                                                <Row className={(isMobileOnly ? "gx-2 " : "") + "justify-content-start flex-nowrap"}>
                                                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                                                        <MemberCard
                                                                            isDark={isDark}
                                                                            blur={_blur}
                                                                            status={"online"}
                                                                            linkStatus={"friends"}
                                                                            data={{}}
                                                                            handleClick={() => {

                                                                                void (0);
                                                                            }}
                                                                        />

                                                                    </Col>
                                                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                                                        <MemberCard
                                                                            isDark={isDark}
                                                                            blur={_blur}
                                                                            status={"online"}
                                                                            linkStatus={"friends"}
                                                                            data={{}}
                                                                            handleClick={() => {

                                                                                void (0);
                                                                            }}
                                                                        />

                                                                    </Col>
                                                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                                                        <MemberCard
                                                                            isDark={isDark}
                                                                            blur={_blur}
                                                                            status={"online"}
                                                                            linkStatus={"friends"}
                                                                            data={{}}
                                                                            handleClick={() => {

                                                                                void (0);
                                                                            }}
                                                                        />

                                                                    </Col>
                                                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                                                        <MemberCard
                                                                            isDark={isDark}
                                                                            blur={_blur}
                                                                            status={"recentlyOnline"}
                                                                            linkStatus={"friends"}
                                                                            data={{}}
                                                                            handleClick={() => {

                                                                                void (0);
                                                                            }}
                                                                        />

                                                                    </Col>
                                                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                                                        <MemberCard
                                                                            isDark={isDark}
                                                                            blur={_blur}
                                                                            status={"recentlyOnline"}
                                                                            linkStatus={"friends"}
                                                                            data={{}}
                                                                            handleClick={() => {

                                                                                void (0);
                                                                            }}
                                                                        />

                                                                    </Col>
                                                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                                                        <MemberCard
                                                                            isDark={isDark}
                                                                            blur={_blur}
                                                                            status={"recentlyOnline"}
                                                                            linkStatus={"friends"}
                                                                            data={{}}
                                                                            handleClick={() => {

                                                                                void (0);
                                                                            }}
                                                                        />

                                                                    </Col>
                                                                </Row>
                                                            </SliderInsideScrollRow>
                                                            <Row className="justify-content-center mt-5 gx-2 gx-lg-3">
                                                                <Col xs="auto">
                                                                    <BtnStdLink
                                                                        bsVariant="dark"
                                                                        bsSize={"sm"}
                                                                        btnClasses="rounded-pill border border-2 border-primary text-light shadow-sm pe-3"
                                                                        iconClasses=""
                                                                        label={<><span className="text-primary d-none d-sm-inline" style={{ fontWeight: 600 }}>MORE</span> Recent</>}
                                                                        iconSize={16}
                                                                        iconName="reorder-four-outline"
                                                                        useLink={false}
                                                                        handleClick={() => {

                                                                            _setShowChatList(!_showChatList);
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col xs="auto">
                                                                    <BtnStdLink
                                                                        bsVariant="light"
                                                                        bsSize={"sm"}
                                                                        btnClasses="rounded-pill shadow-sm pe-3"
                                                                        iconClasses=""
                                                                        label={<><span className="d-none d-sm-inline">Call </span>History</>}
                                                                        iconSize={16}
                                                                        iconName="time-outline"
                                                                        useLink={true}
                                                                        href="/live/history"
                                                                        target=""
                                                                    />
                                                                </Col>
                                                                <Col xs="auto">
                                                                    <BtnStdLink
                                                                        bsVariant="light"
                                                                        bsSize={"sm"}
                                                                        btnClasses="rounded-pill shadow-sm pe-3"
                                                                        iconClasses=""
                                                                        label={<><span className="d-none d-sm-inline">Search </span>Members</>}
                                                                        iconSize={16}
                                                                        iconName="checkmark-circle-outline"
                                                                        useLink={true}
                                                                        href="/live/members"
                                                                        target=""
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </>

                                                )}
                                                {_showVideoPlayer && !_useVideo && (
                                                    <>
                                                        <div
                                                            className={`${_blur ? "img-blur" : ""} rounded-circle shadow mb-2`}
                                                            style={{
                                                                width: "15%",
                                                                aspectRatio: "1 / 1",
                                                                backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg)",
                                                                backgroundSize: "cover",
                                                                backgroundPosition: "center",
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={() => {
                                                                showLightBoxWindow({
                                                                    imgs: [
                                                                        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg", type: "image" },
                                                                    ]
                                                                });
                                                            }}
                                                        >
                                                        </div>
                                                        <h4
                                                            className="d-flex align-items-center mb-0"
                                                            style={{
                                                                textShadow: "2px 2px 4px rgba(0,0,0,.4)"
                                                            }}
                                                        >
                                                            <span className="me-2 fs-3" style={{ height: "1em" }}><ion-icon name="volume-high-outline"></ion-icon></span>
                                                            Audio Only
                                                        </h4>
                                                    </>

                                                )}

                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </>
                        </div >
                    </CollectionColumnNoHeader>
                </CollectionRow>

                {_showAds && (
                    <div className="pb-3">
                        <AdSelectorHub
                            isDark={isDark}
                            isMobileOnly={isMobileOnly}
                            isTablet={isTablet}
                            useAlt={false}
                        />
                    </div>

                )}

                <CollectionRow className={"pt-2"}>
                    <CollectionColumn
                        xs="12"
                        sm={null}
                        md={null}
                        lg={null}
                        xl={null}
                        isDark={isDark}
                        heading={<span className="text-primary">Online Now!</span>}
                        smallHeading={<> <small className="ps-1" style={{ fontSize: ".65em", }}>{<>({_activeMembers.length})</>}</small></>}
                        iconName={"sync-outline"}
                        label={"See all members in the connect app..."}
                        appName="events"
                    >
                        <div className={(isDark ? "text-light" : "text-dark") + " d-flex flex-column"}>
                            {_showSliders ? (
                                <SliderInsideScrollRow showScrollIcons={false}>
                                    {/* SCROLL VIEW */}
                                    <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                        <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                        <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                            <MemberCard
                                                isDark={isDark}
                                                blur={_blur}
                                                status={"online"}
                                                linkStatus={"friends"}
                                                data={{}}
                                                handleClick={() => {

                                                    void (0);
                                                }}
                                            />

                                        </Col>
                                    </Row>
                                </SliderInsideScrollRow>
                            ) : (

                                <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                    {/* GRID VIEW */}
                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                        <MemberCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"online"}
                                            linkStatus={"friends"}
                                            data={{}}
                                            handleClick={() => {

                                                void (0);
                                            }}
                                        />

                                    </Col>
                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                        <MemberCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"online"}
                                            linkStatus={"friends"}
                                            data={{}}
                                            handleClick={() => {

                                                void (0);
                                            }}
                                        />

                                    </Col>
                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                        <MemberCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"online"}
                                            linkStatus={"friends"}
                                            data={{}}
                                            handleClick={() => {

                                                void (0);
                                            }}
                                        />

                                    </Col>
                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                        <MemberCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"online"}
                                            linkStatus={"friends"}
                                            data={{}}
                                            handleClick={() => {

                                                void (0);
                                            }}
                                        />

                                    </Col>
                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                        <MemberCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"online"}
                                            linkStatus={"friends"}
                                            data={{}}
                                            handleClick={() => {

                                                void (0);
                                            }}
                                        />

                                    </Col>
                                    <Col className="mb-2" xs="6" sm="4" lg={_showFullWidth ? "2" : "2"}>

                                        <MemberCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"online"}
                                            linkStatus={"friends"}
                                            data={{}}
                                            handleClick={() => {

                                                void (0);
                                            }}
                                        />

                                    </Col>
                                </Row>
                            )}
                        </div>
                    </CollectionColumn>
                </CollectionRow>

                {_showAds && (
                    <div className="pb-3">
                        <AdSelectorHub
                            isDark={isDark}
                            isMobileOnly={isMobileOnly}
                            isTablet={isTablet}
                            useAlt={false}
                        />
                    </div>
                )}

                <CollectionRow className={""}>
                    <CollectionColumn
                        xs="12"
                        sm={null}
                        md={null}
                        lg={null}
                        xl={null}
                        isDark={isDark}
                        heading={<span>Your Groups</span>}
                        smallHeading={<> <small className="ps-1" style={{ fontSize: ".85rem", }}>{"(12)"}</small></>}
                        iconName={"images-outline"}
                        label={"See all member updates in the link app..."}
                        appName="events"
                    >
                        {_showSliders ? (
                            <SliderInsideScrollRow showScrollIcons={false}>
                                {/* SCROLL VIEW */}
                                <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-nowrap"}>
                                    <Col className="mb-1" xs="12" sm="3" lg={_showFullWidth ? "2" : "20perc"}>

                                        <GroupCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"member"}
                                            data={{}}
                                            handleClick={() => {
                                                void (0);
                                            }}
                                        />

                                    </Col>
                                    <Col className="mb-1" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                        <GroupCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"member"}
                                            data={{}}
                                            handleClick={() => {
                                                void (0);
                                            }}
                                        />

                                    </Col>
                                    <Col className="mb-1" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                        <GroupCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"member"}
                                            data={{}}
                                            handleClick={() => {
                                                void (0);
                                            }}
                                        />

                                    </Col>
                                    <Col className="mb-1" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                        <GroupCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"member"}
                                            data={{}}
                                            handleClick={() => {
                                                void (0);
                                            }}
                                        />

                                    </Col>
                                    <Col className="mb-1" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                        <GroupCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"member"}
                                            data={{}}
                                            handleClick={() => {
                                                void (0);
                                            }}
                                        />

                                    </Col>
                                    <Col className="mb-1" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                        <GroupCard
                                            isDark={isDark}
                                            blur={_blur}
                                            status={"member"}
                                            data={{}}
                                            handleClick={() => {
                                                void (0);
                                            }}
                                        />

                                    </Col>
                                </Row>
                            </SliderInsideScrollRow>
                        ) : (

                            <Row className={(isMobileOnly && "gx-2 ") + "justify-content-start flex-wrap"}>
                                {/* GRID VIEW */}
                                <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                    <GroupCard
                                        isDark={isDark}
                                        blur={_blur}
                                        status={"member"}
                                        data={{}}
                                        handleClick={() => {
                                            void (0);
                                        }}
                                    />

                                </Col>
                                <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                    <GroupCard
                                        isDark={isDark}
                                        blur={_blur}
                                        status={"member"}
                                        data={{}}
                                        handleClick={() => {
                                            void (0);
                                        }}
                                    />

                                </Col>
                                <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                    <GroupCard
                                        isDark={isDark}
                                        blur={_blur}
                                        status={"member"}
                                        data={{}}
                                        handleClick={() => {
                                            void (0);
                                        }}
                                    />

                                </Col>
                                <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                    <GroupCard
                                        isDark={isDark}
                                        blur={_blur}
                                        status={"member"}
                                        data={{}}
                                        handleClick={() => {
                                            void (0);
                                        }}
                                    />

                                </Col>
                                <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                    <GroupCard
                                        isDark={isDark}
                                        blur={_blur}
                                        status={"member"}
                                        data={{}}
                                        handleClick={() => {
                                            void (0);
                                        }}
                                    />

                                </Col>
                                <Col className="mb-4" xs="12" sm="4" lg={_showFullWidth ? "2" : "20perc"}>

                                    <GroupCard
                                        isDark={isDark}
                                        blur={_blur}
                                        status={"member"}
                                        data={{}}
                                        handleClick={() => {
                                            void (0);
                                        }}
                                    />

                                </Col>
                            </Row>
                        )}
                    </CollectionColumn>
                </CollectionRow>

            </LiveScreenFrameWithProfile>

            <MainModal
                showNav={true}
                isDark={isDark}
                removeNaveSpacing={false}
                modalColor={`${isDark ? "bg-dark text-light" : "bg-light text-dark"} shadow`}
                bsModalSize=""
                dialogClassName={(`${_mainModalMaxW && _mainModalMaxW !== "" ? ` ${_mainModalMaxW}` : ""}`)}
                contentClassName={`rounded-0${isDark ? " border border-dark" : " border border-secondary"}`}
                showModal={_showMainModal}
                closeModal={() => {
                    _setShowMainModal(false);
                    _setMainModalContent(<></>);
                    _setMainModalMaxW("");
                }}
            >
                {_showMainModal && (
                    <>
                        {_mainModalContent}
                    </>
                )}

            </MainModal>

            {_showLightbox && (
                <LightboxMain
                    lightBoxContent={_lightBoxContent}
                    user={user}
                    isDark={isDark}
                    showComments={true}
                    updateScrollPos={updateScrollPos}
                    setShowLightbox={_setShowLightbox}
                    blur={_blur}
                />
            )}
        </>
    );

};

export default ConnectLivePreview;