import React from "react";
import { Link } from "react-router-dom";
import {
    //isDesktop,
    isMobileOnly,
    //isMobile,
    //isTablet,
} from "react-device-detect";
import { Carousel, CarouselItem } from "react-bootstrap";

const CarouselFullWidth = ({
    interval,
}) => {

    return (
        <>
            <Carousel
                fade
                indicators={false}
                interval={interval && interval > 0 ? interval : 10000}
                className="shadow-sm"
            >
                <CarouselItem
                    className="position-relative"
                >
                    <Link to="/">
                        <div
                            className={`position-relative rounded-0 shadow`}
                            style={{
                                aspectRatio: isMobileOnly ? "21 / 9" : "21 / 5",
                                //width: "100%",
                                backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/header-imgs/LLV-Banner-2.png)",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                cursor: "pointer"
                            }}
                        >
                            
                        </div>
                    </Link>
                </CarouselItem>
                <CarouselItem
                    className="position-relative"
                >
                    <Link to="/">
                        <div
                            className={`position-relative rounded-0 shadow`}
                            style={{
                                aspectRatio: isMobileOnly ? "21 / 9" : "21 / 5",
                                //width: "100%",
                                backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/header-imgs/LLV-Banner-3.png)",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                cursor: "pointer"
                            }}
                        >

                        </div>
                    </Link>
                </CarouselItem>
            </Carousel>
        </>
    );

};

export default CarouselFullWidth;