import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";



const Member = () => {

    return (
        <>
            <Container className="text-center pt-5" fluid style={{ maxWidth: 300 }}>
                <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/codes/qr-code-oba-friends.png" className="img-fluid" alt="Now QR code" />
                <div>
                    <Link to="/?code=by5iLmEuZnJpZW5kcw==">Create Account Here</Link>
                </div>
            </Container>
        </>
    )

};

export default Member;