
import OBAName from "../components/text/OBAName";
import { Row, Col } from 'react-bootstrap'


const Config = {

    imgPath: "https://storage.googleapis.com/onebadappleprototype.appspot.com/",

    appName: "OBA.Protoype[Web]",

    appVersionHistory: [
        { phase: "Prototype", num: "0.1.0.0", description: "Main Layout and UI" },
        { phase: "Prototype", num: "0.1.1.0", description: "Top Menu Dropdowns" },
        { phase: "Prototype", num: "0.1.2.0", description: "Top Menu Dropdowns" },
        { phase: "Prototype", num: "0.1.3.0", description: "Chat Preview Window" },
        { phase: "Prototype", num: "0.1.4.0", description: "Search Menu" },
        { phase: "Prototype", num: "0.1.5.0", description: "Badge Counts" },
        { phase: "Prototype", num: "0.1.6.0", description: "Logo/App Components" },
        { phase: "Prototype", num: "0.1.7.0", description: "Cart Preview Window" },
        { phase: "Prototype", num: "0.1.8.0", description: "Firebase Setup" },
        { phase: "Prototype", num: "0.1.9.0", description: "Cloud asset storage" },
        { phase: "Prototype", num: "0.1.9.1", description: "Buttons w/App Name Components" },
        { phase: "Prototype", num: "0.1.9.2", description: "Text Logo Components" },
        { phase: "Prototype", num: "0.1.9.3", description: "Release version list" },
        { phase: "Prototype", num: "0.1.10.0", description: "UI updates for tablet" },
        { phase: "Prototype", num: "0.1.10.1", description: "Tablet, Icon & copy updates" },
        { phase: "Prototype", num: "0.1.10.2", description: "Update menu styles for tablet" },
        { phase: "Prototype", num: "0.1.10.3", description: "Make menus close when another opens and update version history" },
        { phase: "Prototype", num: "0.1.11.0", description: "Mobile visitor user menu and icon" },
        { phase: "Prototype", num: "0.1.12.0", description: "Reorganize menues & create item components" },
        { phase: "Prototype", num: "0.1.12.1", description: "Reorganize menues & create item components" },
        { phase: "Prototype", num: "0.1.12.2", description: "Menue linked normalization" },
        { phase: "Prototype", num: "0.1.12.3", description: "Normalize text and spacing for menus" },
        { phase: "Prototype", num: "0.1.12.4", description: "Switch logo img URL to public URL" },
        { phase: "Prototype", num: "0.1.13.0", description: "Handheld device formatting: menus & mobile detection" },
        { phase: "Prototype", num: "0.1.13.1", description: "Handheld device formatting: Main nav and icons" },
        { phase: "Prototype", num: "0.1.13.2", description: "Mobile detection logic fixes" },
        { phase: "Prototype", num: "0.1.14.0", description: "Add visitor news and contact screens" },
        { phase: "Prototype", num: "0.1.14.1", description: "Add feature unavailable message" },
        { phase: "Prototype", num: "0.1.14.2", description: "General alignment/spacing fixes & rename services" },
        { phase: "Prototype", num: "0.1.14.3", description: "Add auth cookie" },
        { phase: "Prototype", num: "0.1.14.4", description: "Add loading animation overlay component" },
        { phase: "Prototype", num: "0.1.15.0", description: "Add chat window expanded view" },
        { phase: "Prototype", num: "0.1.15.1", description: "Add full chat view panels" },
        { phase: "Prototype", num: "0.1.15.2", description: "Add seach/icon bars to link.connect expanded view and fix scrolling areas" },
        { phase: "Prototype", num: "0.1.15.3", description: "Add seach/icon bars and message view to link.connect minimized view" },
        { phase: "Prototype", num: "0.1.15.4", description: "Add message list and fix scolling to link.connect minimized view" },
        { phase: "Prototype", num: "0.1.15.5", description: "General styling and formatting improvements" },
        { phase: "Prototype", num: "0.1.15.6", description: "Update styling and make recent chat list dynamic" },
        { phase: "Prototype", num: "0.1.15.7", description: "Add options menu for chat bubbles" },
        { phase: "Prototype", num: "0.1.15.8", description: "Update list animation and add scroll to position" },
        { phase: "Prototype", num: "0.1.15.9", description: "[Update] Move scroll bar logic to components fix dark light fomatting" },
        { phase: "Prototype", num: "0.1.16.0", description: "[Feature] Add cart window with mock items" },
        { phase: "Prototype", num: "0.1.16.1", description: "[Update] Add cart list/items component & add scroll area component" },
        { phase: "Prototype", num: "0.1.16.2", description: "[Update] Add subtotal, go to cart button and resized font for product count" },
        { phase: "Prototype", num: "0.1.16.3", description: "[Update] Adjust cart sizes for mobile and format expanded view of chat for iPad" },
        { phase: "Prototype", num: "0.1.16.4", description: "[Fix] Move authentication to level" },
        { phase: "Prototype", num: "0.1.16.5", description: "[Fix] Add nav bar height detection" },
        { phase: "Prototype", num: "0.1.17.0", description: "[Feature] Add search interactivity and results list." },
        { phase: "Prototype", num: "0.1.17.1", description: "[Fix] Fix link CSS & add chat window to open detection for cart menu open." },
        { phase: "Prototype", num: "0.1.18.0", description: "[Feature] Add secure authentication." },
        { phase: "Prototype", num: "0.1.18.1", description: "[Update] Add session persistence for login." },
        { phase: "Prototype", num: "0.1.19.0", description: "[Feature] Add invite code for create account and fix logout redirect." },
        { phase: "Prototype", num: "0.1.20.0", description: "[Feature] Add One.Bad.Apple LLC to footer." },
        { phase: "Prototype", num: "0.1.21.0", description: "[Feature] Add routing for standard screens/pages." },
        { phase: "Prototype", num: "0.1.22.0", description: "[Feature] Add routing for individual app info screens." },
        { phase: "Prototype", num: "0.1.22.5", description: "[Update] Add routing for individual app info screens while authenticated." },
        { phase: "Prototype", num: "0.1.23.0", description: "[Feature] Add Hub preview page." },
        { phase: "Prototype", num: "0.1.24.0", description: "[Feature] Add Google Analytics events." },
        { phase: "Prototype", num: "0.1.24.1", description: "[Feature] Add Google Analytics event params for screen_view with app_version and app_name." },
        { phase: "Prototype", num: "0.1.25.0", description: "[Feature] Add Hub preview page with real images and ads." },
        { phase: "Prototype", num: "0.1.25.2", description: "[Feature] Add Event and Lightbox modals to Hub" },
        { phase: "Prototype", num: "0.1.25.3", description: "[Feature] Add Chat Feed to Lightbox modal." },
        { phase: "Prototype", num: "0.1.26.0", description: "[Feature] Add Dark Mode Toggle to User Menu, and properties to hub page and modals" },
        { phase: "Prototype", num: "0.1.26.5", description: "[Feature] Add two new section for connect and connect.live" },
        { phase: "Prototype", num: "0.1.26.7", description: "[Feature] Add Slider/Grid toggle and Blur/Visible toggle" },
        { phase: "Prototype", num: "0.1.26.9", description: "[Feature] Add Connect section. Recent msg and notifications" },
        { phase: "Prototype", num: "0.1.26.10", description: "[Feature] Add new media window component" },
        { phase: "Prototype", num: "0.1.26.11", description: "[Feature] Add new connect live message section" },
        { phase: "Prototype", num: "0.1.27.0", description: "[Feature] Add new top ad banner and update styles too" },
        { phase: "Prototype", num: "0.1.27.1", description: "[Feature] Add new video player component" },
        { phase: "Prototype", num: "0.1.27.3", description: "[Feature] Add new active member section for connect and connect.live" },
        { phase: "Prototype", num: "0.1.27.5", description: "[Feature] Add new data & component set for member lists" },
        { phase: "Prototype", num: "0.1.27.6", description: "[Feature] Add new shop data and grid view" },
        { phase: "Prototype", num: "0.1.27.8", description: "[Features] Add grid view for calendar and shop" },
        { phase: "Prototype", num: "0.1.28.0", description: "[Features] Add live video stream & connect image stream" },
        { phase: "Prototype", num: "0.1.29.0", description: "[Features] Add link preview screen and sections" },
        { phase: "Prototype", num: "0.1.29.5", description: "[Feature] Add new posts and image/videos lightbox to link preview; code new image preview collage" },
        { phase: "Prototype", num: "0.1.29.6", description: "[Update] Create components for Media Grid fomatting" },
        { phase: "Prototype", num: "0.1.29.7", description: "[Update] Create components member post" },
        { phase: "Prototype", num: "0.1.30.0", description: "[Feature] Create new media and clip search/sort screens" },
        { phase: "Prototype", num: "0.1.31.0", description: "[Feature] Create new event search/sort screen and update search bar" },
        { phase: "Prototype", num: "0.1.31.1", description: "[Feature] Create new calendar search/sort screen and update search bar" },
        { phase: "Prototype", num: "0.1.31.3", description: "[Feature] Create new member search/sort screen and update search bar" },
        { phase: "Prototype", num: "0.1.31.5", description: "[Feature] Create new groups search/sort screen and update search bar - Add new group card" },
        { phase: "Prototype", num: "0.1.32.0", description: "[Feature] Create new link screen frame template and normalize all link screens" },
        { phase: "Prototype", num: "0.1.32.1", description: "[Feature] Create new Hub screen frame template and normalize all hub screens" },
        { phase: "Prototype", num: "0.1.33.0", description: "[Feature] Add new connect screen, frame, ads, and fix chat for page viewing." },
        { phase: "Prototype", num: "0.1.33.5", description: "[Feature] Add new full screen to chat area and also for mobile." },
        { phase: "Prototype", num: "0.1.33.7", description: "[Feature] Add member and groups pages." },
        { phase: "Prototype", num: "0.1.34.0", description: "[Feature] Add new profile page for personal, group and partners." },
        { phase: "Prototype", num: "0.1.34.2", description: "[Update] Profile updates, new banner image, lightbox, text spacing, etc." },
        { phase: "Prototype", num: "0.1.34.4", description: "[Update] Profile about screen and Add scroller to about screen" },
        { phase: "Prototype", num: "0.1.34.5", description: "[Update] Profile uploads screen with search and filters" },
        { phase: "Prototype", num: "0.1.34.6", description: "[Update] Profile clips screen with search and filters" },
        { phase: "Prototype", num: "0.1.34.8", description: "[Update] Profile friends and groups screen with search and filters" },
        { phase: "Prototype", num: "0.1.35.0", description: "[Feature] Add new profile modal." },
        { phase: "Prototype", num: "0.1.35.1", description: "[Update] Add button action to open chat." },
        { phase: "Prototype", num: "0.1.35.2", description: "[Update] Add flirt modal" },
        { phase: "Prototype", num: "0.1.35.3", description: "[Update] Add settings modal" },
        { phase: "Prototype", num: "0.1.35.5", description: "[Update] Finalize seting modal with components, and icons and mobile updates for modal" },
        { phase: "Prototype", num: "0.1.35.6", description: "[Update] Add post settings component method" },
        { phase: "Prototype", num: "0.1.36.0", description: "[Feature] Add notification menu and components" },
        { phase: "Prototype", num: "0.1.36.2", description: "[Update] Add settings and shortcuts call to user menu" },
        { phase: "Prototype", num: "0.1.36.4", description: "[Feature, Update] Add error message update format notifications menu components." },
        { phase: "Prototype", num: "0.1.37.0", description: "[Feature] Add new profile selector modal and method" },
        { phase: "Prototype", num: "0.1.38.0", description: "[Feature] Add new connect live page." },
        { phase: "Prototype", num: "0.1.38.1", description: "[Feature] Add new connect live page with UI updates" },
        { phase: "Prototype", num: "0.1.38.3", description: "[Update] Add new connect live UI and button actions" },
        { phase: "Prototype", num: "0.1.38.5", description: "[Update] Add new alert components and ringtone and animations for outgoing/incoming calls" },
        { phase: "Prototype", num: "0.1.38.6", description: "[Update] Start new mobile view for live player" },
        { phase: "Prototype", num: "0.1.38.8", description: "[Update] Add stop video and start video with audio only option" },
        { phase: "Prototype", num: "0.1.38.9", description: "[Update] Fix profile menu for different scrren types" },
        { phase: "Prototype", num: "0.1.39.0", description: "[Feature] Add live wondow view and update methods for buttons" },
        { phase: "Prototype", num: "0.1.40.0", description: "[Feature] Add screen for free cup offer." },
        { phase: "Prototype", num: "0.1.40.1", description: "[Feature] Add success page for promotions" },
        

    ].reverse(),

    inviteCodes: [
        "o.b.a.admin", // Admin //by5iLmEuYWRtaW4=
        "o.b.a.friends", // FB Group // by5iLmEuZnJpZW5kcw==
        "o.b.a.invest", // Potential Investor // by5iLmEuaW52ZXN0
        "o.b.a.member", // Investor/Member // by5iLmEubWVtYmVy
        "o.b.a.now", // Demos // by5iLmEubm93

    ],

    AppList: [
        {
            iconName: "globe-outline",
            heading: "hub",
            tempH: "home",
            text: "newest updates in one place",
            url: "/hub",
            selected: true,
            isNavDefault: true,
            badgeCount: null,
            promoBadge: "",
        },
        {
            iconName: "share-social-outline",
            heading: "link",
            text: "find others, share and upload",
            url: "/link",
            selected: true,
            isNavDefault: false,
            badgeCount: null,
            promoBadge: "",
        },
        {
            iconName: "chatbubbles-outline",
            heading: "connect",
            text: "private messages and chat",
            url: "/connect",
            selected: true,
            isNavDefault: false,
            badgeCount: null,
            promoBadge: "",
        },
        {
            iconName: "videocam-outline",
            heading: "connect.live",
            text: "live video/audio chats",
            url: "/live",
            selected: true,
            isNavDefault: false,
            badgeCount: null,
            promoBadge: "",
        },
        {
            iconName: "person-circle-outline",
            heading: "follow",
            text: "profiles you follow",
            url: "/follow",
            selected: false,
            isNavDefault: false,
            badgeCount: null,
            promoBadge: "Coming Soon",
        },
        {
            iconName: "people-circle-outline",
            heading: "follow.me",
            text: "personal follow me page",
            url: "/me",
            selected: false,
            isNavDefault: false,
            badgeCount: null,
            promoBadge: "Coming Soon",
        },
        {
            iconName: "git-compare-outline",
            heading: "partner",
            text: "bussines profile/presence",
            url: "/partners",
            selected: false,
            isNavDefault: false,
            badgeCount: null,
            promoBadge: "Try It!",
        },
        {
            iconName: "cart-outline",
            heading: "shop",
            text: "one.bad.apple merchandise",
            url: "/shop",
            selected: false,
            isNavDefault: false,
            badgeCount: null,
            promoBadge: "",
        },
        
    ],

    eventData: [
        //{ img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/colette_NYE.jpg", vendor: "Colette, Houston" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/collete_houston_halloween.gif", vendor: "TPC Hempstead, Houston" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/halloween.jpg", vendor: "TPC N. Houston" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/pantiless.jpg", vendor: "Colette Houston" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/red_dress.jpg", vendor: "TNG Houston" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/anything-for-a-buck-1110522-IG-1080x1080.jpg", vendor: "Live Oak Resort" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/birtthday-slider-all-clubs-generic.jpg", vendor: "Emerald Lake" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/thirty-dollar-thursdays.jpg", vendor: "Colette Austin" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/collete_houston_halloween.gif", vendor: "TPC Hempstead, Houston" },
        // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/collete_houston_halloween.gif", vendor: "TPC Hempstead, Houston" },
        // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/halloween.jpg", vendor: "TPC N. Houston" },
        // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/pantiless.jpg", vendor: "Colette Houston" },
        // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/red_dress.jpg", vendor: "TNG Houston" },
        // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/anything-for-a-buck-1110522-IG-1080x1080.jpg", vendor: "Live Oak Resort" },
        // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/birtthday-slider-all-clubs-generic.jpg", vendor: "Emerald Lake" },
        // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/thirty-dollar-thursdays.jpg", vendor: "Colette Austin" },
        // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/banner_ads/300x250/collete_houston_halloween.gif", vendor: "TPC Hempstead, Houston" },
    ],

    cartList: [
        {
            imgAlt: "12 oz Can/Bottle Cooler",
            imgThumb: "promo_items/can-12oz-standard-proof.jfif",
            prodUrl: "/",
            price: "$29.99",
            showPromoPrice: true,
            promoPrice: "24.98",
            qty: "1"
        },
        {
            imgAlt: "12 oz Tall Can Cooler",
            imgThumb: "promo_items/can-12oz-tall-proof.jfif",
            prodUrl: "/",
            price: "$29.99",
            showPromoPrice: true,
            promoPrice: "24.98",
            qty: "1"
        },
        {
            imgAlt: "30 oz Tumbler",
            imgThumb: "promo_items/cup-30oz-proof.jfif",
            prodUrl: "/",
            price: "$34.99",
            showPromoPrice: true,
            promoPrice: "27.98",
            qty: "2"
        },
        {
            imgAlt: "Placeholder",
            imgThumb: "logos/logo-top-White.png",
            prodUrl: "/",
            price: "$0.01",
            qty: "1"
        },
        {
            imgAlt: "Placeholder",
            imgThumb: "logos/logo-top-White.png",
            prodUrl: "/",
            price: "$0.01",
            qty: "1"
        },
        {
            imgAlt: "Placeholder",
            imgThumb: "logos/logo-top-White.png",
            prodUrl: "/",
            price: "$0.01",
            qty: "1"
        }

    ],

    newsArticles: [
        {
            heading: (
                <>
                    <OBAName fontWeight={500} /> get's its SWAG on!
                </>
            ),
            subHeading: (
                null
            ),
            date: "09/19/2022",
            body: (
                <>
                    <h3>
                        <OBAName fontWeight={500} /> has our first promotional items on the way!
                    </h3>
                    <p>
                        Coming soon! Promotional items to help get the name and logo out. Items will be available at select events and venues for <strong className="text-primary fs-3">FREE!</strong>, just in time for the annual Halloween bash this year. The plan is to have ballcaps, tanks/T's, stickers, and the big one, RTIC branded 30 oz cups, and 12 oz can coolers both standard and tall (for Truly, Mich Ultra, etc...). Designs will evolve over time as the name, products and logos become more defined and finalized, but it's a start!
                    </p>
                    <div className="bg-white text-dark py-4">
                        <Row className="align-items-center justify-content-center fs-6">
                            <Col sm="12" lg="4" className="d-flex flex-column align-items-center justify-content-center">
                                <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/bumper-1.png" alt="Bumper sticker..." className="img-fluid" />
                                <div className="text-center mt-2"> 7.5" x 1.95" Inline Logo Bumper Sticker</div>
                            </Col>
                            <Col sm="12" lg="2" className="d-flex flex-column align-items-center justify-content-center">
                                <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/window-1.png" alt="Window preasure sticker..." className="img-fluid" />
                                <div className="text-center mt-2">3.16" x 4" Logo Top Window Sticker</div>
                            </Col>
                            <Col sm="4" lg="2" className="d-flex flex-column align-items-center justify-content-center">
                                <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/cup-30oz-proof.jfif" alt="30 oz standard tumbler..." className="img-fluid" />
                                <div className="text-center mt-2">RTIC 30 oz. Tumbler</div>
                            </Col>
                            <Col sm="4" lg="2" className="d-flex flex-column align-items-center justify-content-center">
                                <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/can-12oz-standard-proof.jfif" alt="12 oz standard can cooler..." className="img-fluid" />
                                <div className="text-center mt-2">RTIC 12 oz. Can/Bottle</div>
                            </Col>
                            <Col sm="4" lg="2" className="d-flex flex-column align-items-center justify-content-center">
                                <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/can-12oz-tall-proof.jfif" alt="12 oz tall can cooler..." className="img-fluid" />
                                <div className="text-center mt-2">RTIC 12 oz. Tall Can</div>
                            </Col>

                        </Row>
                    </div>
                </>
            ),
            signature: (
                <>
                    <p>
                        Thanks again for visiting,<br />
                        <OBAName fontWeight={400} /> Team
                    </p>
                </>
            ),
            showSignature: true,
            showImg: false,
            imgPos: "left",

        },
        {
            heading: (
                <>
                    <OBAName fontWeight={500} /> is now OFFICIAL!
                </>
            ),
            subHeading: (
                null
            ),
            date: "09/13/2022",
            body: (
                <>
                    <p>
                        <OBAName fontWeight={500} /> is now incorporated as One.Bad.Apple LLC.
                    </p>
                    <p>
                        Once the legal work is complete and the stamp of approval is final, things will begin to move more quickly. So far we are about 65% with prototype v 0.1.x of the app and we have verbally secured investments that put us about 40% toward the ultimate goal (more info on this after the docs are official).
                    </p>
                    <p>
                        We will be posting links and sending invite logins to the new prototype site in the coming weeks, as well as presenting what our goals are for seeding and initial investments funds around the same time. Many legal processes to complete, but progress has happened in a big way in a very short time. We look forward to sharing more with you all soon!
                    </p>
                </>
            ),
            signature: (
                <>
                    <p>
                        Thanks again for visiting,<br />
                        <OBAName fontWeight={400} /> Team
                    </p>
                </>
            ),
            showSignature: true,
            showImg: true,
            imgPos: "right",

        },
        {
            heading: (
                <>
                    <OBAName fontWeight={400} /> lives!
                </>
            ),
            subHeading: (
                null
            ),
            date: "08/22/2022",
            body: (
                <>
                    <p>
                        <OBAName fontWeight={500} /> is the culmination of visions & ideas born over the past few years. It is designed to cater to adults and will support a market that is well underserved to date. Our goal is to offer merchandise, networking, events, and more specifically for adults.
                    </p>
                    <p>
                        We welcome you all along for the ride as <OBAName fontWeight={500} /> begins to take shape. Thank you for the visit and for your support. This would not work without the great people we've met and the friendships developed over the last few  years.
                        So, buckle up and let's enjoy this ride together!
                    </p>
                </>
            ),
            signature: (
                <>
                    <p>
                        Thank you for visiting,<br />
                        <OBAName fontWeight={500} /> Team
                    </p>
                </>
            ),
            showSignature: true,
            showImg: true,
            imgPos: "left",

        },
        {
            heading: (
                <>
                    The beginning is now...
                </>
            ),
            subHeading: (
                null
            ),
            date: "08/20/2022",
            body: (
                <>
                    <p>
                        <OBAName fontWeight={500} /> is off and running! Check back often for updates, news, feature releases.all our new apps to come...
                    </p>
                </>
            ),
            signature: (
                <>
                    <p>
                        Thank you for visiting,<br />
                        <OBAName fontWeight={500} /> Team
                    </p>
                </>
            ),
            showSignature: true,
            showImg: true,
            imgPos: "right",

        },
    ],

    memberPhotoVideoPosts: [
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/315330316_469096865093976_5633322688535297539_n-small.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/315313052_611243747447764_7570547157215204194_n-small.jpg", type: "image" }
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/315330316_469096865093976_5633322688535297539_n-large.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/315313052_611243747447764_7570547157215204194_n-large.jpg", type: "image" }
            ],
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20201231_213211-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20201231_213211-large.jpg", type: "image" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/jenni-small.PNG", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/jenni-large.PNG", type: "image" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223032-small.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223201-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223032-large.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223201-large.jpg", type: "image" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_5649-small.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20200101_003556-small.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20220904_005637-small.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_1407-small.JPEG", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20200101_003556-large.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20220904_005637-large.jpg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_1407-large.JPEG", type: "image" },
            ]
        },

        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/284972914_566171688408801_5982695052161447819_n-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/284972914_566171688408801_5982695052161447819_n-large.jpg", type: "image" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_4395-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_4395-large.jpg", type: "image" },
            ]
        },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture3-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture14-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture15-small.PNG", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture3-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture14-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture15-large.PNG", type: "image" },
        //     ]
        // },

        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20210710_222324-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20210710_222324-large.jpg", type: "image" },
            ]
        },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture4-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20210627_132639-small.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/video-thumbs/20210627_132655_Edit-thumb.jpg", type: "video" },

        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture4-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20210627_132639-large.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/videos/K_G-Bobs-Swallow-1080.mp4", type: "video" },

        //     ]
        // },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/295684300_768637494105194_2187618695812835552_n-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/295684300_768637494105194_2187618695812835552_n-large.jpg", type: "image" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_998019894255887-small.jpeg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_998019894255887-large.jpeg", type: "image" },
            ]
        },

        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim2.jpg", type: "image" },
        //         // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim3.jpg", type: "image" },


        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/E_Kim.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim2.jpg", type: "image" },
        //         // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim3.jpg", type: "image" },
        //     ]
        // },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_2368450536655791-small.jpeg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/316745629_1469313343558391_6465921652339739041_n-small.jpg", type: "image" },


            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_2368450536655791-large.jpeg", type: "image" },
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/316745629_1469313343558391_6465921652339739041_n-large.jpg", type: "image" },
            ]
        },


        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_2368450536655791-small.jpeg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_2368450536655791-large.jpeg", type: "image" },
        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20221029_231341-small.jpg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20221029_231341-large.jpg", type: "image" },
        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/video-thumbs/E_K-Anal-thumb.jpg", type: "video" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/videos/E_K-Anal.mp4", type: "video" },
        //     ]
        // },
    ],

    memberClips: [
        {
            postedBy: "One.Bad.Apple",
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/LakeTravisHawt.jpg", type: "reel" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/LakeTravisHawt.mp4", type: "reel" },
            ]
        },
        {
            postedBy: "One.Bad.Apple",
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/SunnyDazeClip.jpg", type: "reel" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/SunnyDazeClip.mp4", type: "reel" },
            ]
        },
        {
            postedBy: "One.Bad.Apple",
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/X-mas-2022-thumb2.jpg", type: "reel" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/X-mas-2022-2.mp4", type: "reel" },
            ]
        },
        {
            postedBy: "One.Bad.Apple",
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/SeeMyFollow.Me-reel-thumb.jpg", type: "reel" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/SeeMyFollow.Me-reel.mp4", type: "reel" },
            ]
        },
        // {
        //     postedBy: "One.Bad.Apple",
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/SundayFundayByThePool-720-NoFrame-thumb.jpg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/SundayFundayByThePool-720-NoFrame.mp4", type: "reel" },
        //     ]
        // },
        // {
        //     postedBy: "One.Bad.Apple",
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/ELR-Saturday-Night-720-thumb.jpg", type: "reel" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/ELR-Saturday-Night-720.mp4", type: "reel" },
        //     ]
        // },

        // {
        //     postedBy: "One.Bad.Apple",
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/K_G_E_SundayFunday-720-thumb.jpg", type: "reel" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/K_G_E_SundayFunday-720.mp4", type: "reel" },
        //     ]
        // },
        {
            postedBy: "One.Bad.Apple",
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/LakeTravisHawt.jpg", type: "reel" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/LakeTravisHawt.mp4", type: "reel" },
            ]
        },
        // {
        //     postedBy: "One.Bad.Apple",
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/SeeMyFollow.Me-reel-thumb.jpg", type: "reel" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/SeeMyFollow.Me-reel.mp4", type: "reel" },
        //     ]
        // },
        // {
        //     postedBy: "One.Bad.Apple",
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/SundayFundayByThePool-720-NoFrame-thumb.jpg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/SundayFundayByThePool-720-NoFrame.mp4", type: "reel" },
        //     ]
        // },
        // {
        //     postedBy: "One.Bad.Apple",
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/ELR-Saturday-Night-720-thumb.jpg", type: "reel" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/ELR-Saturday-Night-720.mp4", type: "reel" },
        //     ]
        // },
        {
            postedBy: "One.Bad.Apple",
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/Morning_wood_reel-720-thumb.jpg", type: "reel" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/Morning_wood_reel-720.mp4", type: "reel" },
            ]
        },
        {
            postedBy: "One.Bad.Apple",
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/SeeMyFollow.Me-reel-thumb.jpg", type: "reel" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/SeeMyFollow.Me-reel.mp4", type: "reel" },
            ]
        },
        {
            postedBy: "One.Bad.Apple",
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/X-mas-2022-thumb2.jpg", type: "reel" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/X-mas-2022-2.mp4", type: "reel" },
            ]
        },
        // {
        //     postedBy: "One.Bad.Apple",
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/K_G_E_SundayFunday-720-thumb.jpg", type: "reel" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/K_G_E_SundayFunday-720.mp4", type: "reel" },
        //     ]
        // },
        {
            postedBy: "One.Bad.Apple",
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-thumbs/SeeMyFollow.Me-reel-thumb.jpg", type: "reel" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/reel-videos/SeeMyFollow.Me-reel.mp4", type: "reel" },
            ]
        },


    ],

    memberStatusList: [
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_4395-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_4395-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_998019894255887-small.jpeg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_998019894255887-large.jpeg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_1407-small.JPEG", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_1407-large.JPEG", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_4395-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_4395-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_998019894255887-small.jpeg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_998019894255887-large.jpeg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_1407-small.JPEG", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_1407-large.JPEG", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_4395-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_4395-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_998019894255887-small.jpeg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_998019894255887-large.jpeg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_1407-small.JPEG", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_1407-large.JPEG", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_4395-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_4395-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_998019894255887-small.jpeg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_998019894255887-large.jpeg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_1407-small.JPEG", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_1407-large.JPEG", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_4395-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_4395-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_998019894255887-small.jpeg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_998019894255887-large.jpeg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_1407-small.JPEG", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_1407-large.JPEG", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_4395-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_4395-large.jpg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_998019894255887-small.jpeg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_998019894255887-large.jpeg", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_1407-small.JPEG", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_1407-large.JPEG", type: "image" },
            ],
            status: "online",
            profileUrl: "/link/profile"
        },
    ],

    shopProducts: [
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/cup-30oz-proof.jfif", label: "RTIC 30 oz Tumbler" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/can-12oz-standard-proof.jfif", label: "RTIC 12 oz Can/Bottle Cooler" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/can-12oz-tall-proof.jfif", label: "RTIC 12 oz Tall Can Cooler" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/cup-30oz-proof.jfif", label: "RTIC 30 oz Tumbler" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/can-12oz-standard-proof.jfif", label: "RTIC 12 oz Can/Bottle Cooler" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/can-12oz-tall-proof.jfif", label: "RTIC 12 oz Tall Can Cooler" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/cup-30oz-proof.jfif", label: "RTIC 30 oz Tumbler" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/can-12oz-standard-proof.jfif", label: "RTIC 12 oz Can/Bottle Cooler" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/can-12oz-tall-proof.jfif", label: "RTIC 12 oz Tall Can Cooler" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/cup-30oz-proof.jfif", label: "RTIC 30 oz Tumbler" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/can-12oz-standard-proof.jfif", label: "RTIC 12 oz Can/Bottle Cooler" },
        { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/promo_items/can-12oz-tall-proof.jfif", label: "RTIC 12 oz Tall Can Cooler" }
    ],

    calendarEvents: [
        {
            date: "Mon, 12/05/22",
            events: [
                { title: "Disco Night: TPC North - Houston, TX", saved: true },
                { title: "Panitless Friday!: Colette, Houston, TX", saved: false }
            ],
        },
        {
            date: "Tue, 12/06/22",
            events: [
                { title: "Newbie Night: TPC N., Houston, TX", saved: false }
            ],
        },
        {
            date: "Wed, 12/07/22",
            events: [
                { title: "Newbie Night: TPC N., Houston, TX", saved: false }
            ],
        },
        {
            date: "Ths, 12/08/22",
            events: [],
        },
        {
            date: "Fri, 12/09/22",
            events: [
                { title: "Disco Night: TPC North - Houston, TX", saved: true },
                { title: "Panitless Friday!: Colette, Houston, TX", saved: false }
            ],
        },
        {
            date: "Sat, 12/10/22",
            events: [
                { title: "Christmas Theme!: Colette, Houston, TX", saved: true }
            ],
        },
        {
            date: "Sun, 12/11/22",
            events: [],
        },
        {
            date: "Mon, 12/12/22",
            events: [],
        },
        {
            date: "Tue, 12/13/22",
            events: [],
        },
        {
            date: "Wed, 12/14/22",
            events: [
                { title: "Newbie Night: TPC N., Houston, TX", saved: false }
            ],
        },
        {
            date: "Ths, 12/15/22",
            events: [],
        },
        {
            date: "Fri, 12/16/22",
            events: [
                { title: "Disco Night: TPC North - Houston, TX", saved: true },
                { title: "Panitless Friday!: Colette, Houston, TX", saved: false }
            ],
        },
        {
            date: "Sat, 12/17/22",
            events: [
                { title: "Christmas Theme!: Colette, Houston, TX", saved: true }
            ],
        },
        {
            date: "Sun, 12/18/22",
            events: [],
        },
    ],

    connectPhotoStream: [
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/315330316_469096865093976_5633322688535297539_n-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/315330316_469096865093976_5633322688535297539_n-large.jpg", type: "image" },
            ],
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20201231_213211-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20201231_213211-large.jpg", type: "image" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/jenni-small.PNG", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/jenni-large.PNG", type: "image" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg", type: "image" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_5649-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg", type: "image" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/284972914_566171688408801_5982695052161447819_n-small.jpg", type: "image" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/284972914_566171688408801_5982695052161447819_n-large.jpg", type: "image" },
            ]
        },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_4395-small.jpg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_4395-large.jpg", type: "image" },
        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture3-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture14-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture15-small.PNG", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture3-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture14-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture15-large.PNG", type: "image" },
        //     ]
        // },

        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20210710_222324-small.jpg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20210710_222324-large.jpg", type: "image" },
        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture4-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20210627_132639-small.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/video-thumbs/20210627_132655_Edit-thumb.jpg", type: "video" },

        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture4-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20210627_132639-large.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/videos/K_G-Bobs-Swallow-1080.mp4", type: "video" },

        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/295684300_768637494105194_2187618695812835552_n-small.jpg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/295684300_768637494105194_2187618695812835552_n-large.jpg", type: "image" },
        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_998019894255887-small.jpeg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_998019894255887-large.jpeg", type: "image" },
        //     ]
        // },

        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim2.jpg", type: "image" },
        //         // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim3.jpg", type: "image" },


        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/E_Kim.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim2.jpg", type: "image" },
        //         // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim3.jpg", type: "image" },
        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_2368450536655791-small.jpeg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/316745629_1469313343558391_6465921652339739041_n-small.jpg", type: "image" },


        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_2368450536655791-large.jpeg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/316745629_1469313343558391_6465921652339739041_n-large.jpg", type: "image" },
        //     ]
        // },

    ],

    liveVideoStream: [
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/315330316_469096865093976_5633322688535297539_n-small.jpg", type: "video" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/315330316_469096865093976_5633322688535297539_n-large.jpg", type: "video" },
            ],
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20201231_213211-small.jpg", type: "video" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20201231_213211-large.jpg", type: "video" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/jenni-small.PNG", type: "video" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/jenni-large.PNG", type: "video" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg", type: "video" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20190901_223003-large.jpg", type: "video" },
            ]
        },
        {
            thumbs: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_5649-small.jpg", type: "video" },
            ],
            full: [
                { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_5649-large.jpg", type: "video" },
            ]
        },

        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/284972914_566171688408801_5982695052161447819_n-small.jpg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/284972914_566171688408801_5982695052161447819_n-large.jpg", type: "image" },
        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/IMG_4395-small.jpg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/IMG_4395-large.jpg", type: "image" },
        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture3-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture14-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture15-small.PNG", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture3-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture14-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture15-large.PNG", type: "image" },
        //     ]
        // },

        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20210710_222324-small.jpg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20210710_222324-large.jpg", type: "image" },
        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/Capture4-small.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20210627_132639-small.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/video-thumbs/20210627_132655_Edit-thumb.jpg", type: "video" },

        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/Capture4-large.PNG", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/20210627_132639-large.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/videos/K_G-Bobs-Swallow-1080.mp4", type: "video" },

        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/295684300_768637494105194_2187618695812835552_n-small.jpg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/295684300_768637494105194_2187618695812835552_n-large.jpg", type: "image" },
        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_998019894255887-small.jpeg", type: "image" },
        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_998019894255887-large.jpeg", type: "image" },
        //     ]
        // },

        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim2.jpg", type: "image" },
        //         // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim3.jpg", type: "image" },


        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/E_Kim.jpg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim2.jpg", type: "image" },
        //         // { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/E_Kim3.jpg", type: "image" },
        //     ]
        // },
        // {
        //     thumbs: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/received_2368450536655791-small.jpeg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/316745629_1469313343558391_6465921652339739041_n-small.jpg", type: "image" },


        //     ],
        //     full: [
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/received_2368450536655791-large.jpeg", type: "image" },
        //         { img: "https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/full/316745629_1469313343558391_6465921652339739041_n-large.jpg", type: "image" },
        //     ]
        // },

    ],
}

export default Config;

