
import React from "react";
import { Row, Col, Badge } from "react-bootstrap";

import BtnIcon from "../buttons/BtnIcon";


const MemberCard = ({
    isDark,
    blur,
    status,
    linkStatus,
    data,
    handleClick,

}) => {

    // const getMediaType = (type) => {

    //     let icon = "";

    //     switch (type) {

    //         case "image":
    //             icon = "image-outline";
    //             break;

    //         case "video":
    //             icon = "videocam-outline";
    //             break;

    //         case "reel":
    //             icon = "film-outline";
    //             break;

    //         default:
    //             icon = "square-outline";

    //     }

    //     return icon;
    // }

    const getStatusColor = () => {

        let color = "";

        switch (status) {

            case "online":
                color = "primary";
                break;
            case "recentlyOnline":
                color = "yellow";
                break;
            case "offline":
                color = "danger";
                break;

            case null:
            case undefined:
            case "unknown":
            default:
                color = "light";
                break;
        }

        return color;
    };

    const getStatusText = () => {

        let text = "";

        switch (status) {

            case "online":
                text = "Online Now...";
                break;
            case "recentlyOnline":
                text = "Recently Online...";
                break;
            case "offline":
                text = "Offline...";
                break;

            case null:
            case undefined:
            case "unknown":
            default:
                text = "Status Unknown...";
                break;
        }

        return text;
    };

    const getStatusIcon = () => {

        let name = "";

        switch (status) {

            case "online":
                name = "checkmark-circle-sharp";
                break;
            case "recentlyOnline":
                name = "time-sharp";
                break;
            case "offline":
                name = "ban-sharp";
                break;

            case null:
            case undefined:
            case "unknown":
            default:
                name = "remove-circle-sharp";
                break;
        }

        return name;
    };

    return (
        <>
            <div className={`${isDark ? "border-light-gray" : "border-light-dark"} friend-card border-3 border-top pt-2`}>
                <div className="mb-2">
                    <h5 className="mb-1" style={{ height: "1.1em", fontSize: ".95rem", }}>One.Bad.Apple</h5>
                    <div className="mb-1" style={{ height: "1.1em", fontSize: ".85rem", }}>Couple - Houston, TX</div>
                    <div className="mb-1" style={{ height: "1.1em", fontSize: ".85rem", }}>Member for: 1 yr 3 mo</div>
                </div>
                <div
                    className={`${blur ? "img-blur" : ""} position-relative rounded-1 shadow d-flex align-items-end justify-content-end mb-2`}
                    style={{
                        aspectRatio: "1 / 1",
                        backgroundImage: "url(https://storage.googleapis.com/onebadappleprototype.appspot.com/member_posts/thumb/20190901_223003-small.jpg)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        handleClick();
                    }}
                >
                    <div
                        className="d-flex align-items-center position-absolute top-0 end-0 me-1 mt-1"
                        style={{
                            zIndex: 5,
                        }}
                    >
                        <Badge
                            bg={"dark"}
                            text={getStatusColor()}
                            title={getStatusText()}
                            className="rounded-circle shadow-sm"
                            style={{
                                fontSize: ".85rem",
                                paddingTop: 3,
                                paddingBottom: 2,
                                paddingLeft: 5,
                                paddingRight: 5,
                            }}
                        >
                            <ion-icon name={getStatusIcon()}></ion-icon>
                        </Badge>
                    </div>
                    <div
                        className="d-flex align-items-center position-absolute bottom-0 end-0 rounded-1 bg-dark text-light pb-1 pt-1 px-2 me-1 mb-1"
                        style={{
                            fontSize: ".90rem",
                            //height: "1.4em"
                        }}
                        title="Your are friends..."
                    >
                        <span className="emoji-link"></span> 
                        {/* <span className="ms-1" style={{ fontWeight: 400, fontSize: ".75rem", textTransform: "capitalize" }}>{linkStatus}</span> */}

                    </div>
                </div>
                <Row className="gx-0">
                    <Col xs="auto">
                        <BtnIcon
                            bsVariant={(isDark ? "icon-light" : "icon-dark")}
                            label={"Section settings..."}
                            iconSize={22}
                            iconName="checkmark-circle-outline"
                            iconClasses=""
                            btnClasses=""
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development ");
                            }}
                        />
                    </Col>
                    <Col xs="auto">
                        <BtnIcon
                            bsVariant={(isDark ? "icon-light" : "icon-dark")}
                            label={"Section settings..."}
                            iconSize={22}
                            iconName="heart-outline"
                            iconClasses=""
                            btnClasses=""
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development ");
                            }}
                        />
                    </Col>
                    <Col xs="auto">
                        <BtnIcon
                            bsVariant={(isDark ? "icon-light" : "icon-dark")}
                            label={"Section settings..."}
                            iconSize={22}
                            iconName="chatbubbles-outline"
                            iconClasses=""
                            btnClasses=""
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development ");
                            }}
                        />
                    </Col>
                    <Col xs="auto">
                        <BtnIcon
                            bsVariant={(isDark ? "icon-light" : "icon-dark")}
                            label={"Section settings..."}
                            iconSize={22}
                            iconName="videocam-outline"
                            iconClasses=""
                            btnClasses=""
                            handleClick={(e) => {
                                e.preventDefault();
                                alert("This feature is under development ");
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default MemberCard;