import React, { useRef, useEffect } from "react";


const ScrollAreaHorizontal = ({
    children,
    scrollToPos,
}) => {

    const scrollArea = useRef(0);

    useEffect(() => {        

        scrollArea.current.scroll({ left: scrollToPos, behavior: "smooth" });        

    }, [scrollToPos]);

    return (
        <div className="position-relative">
            <div
                ref={scrollArea}
                className="scroll-div-horizontal pb-2"
                style={{
                    overflowX: "scroll",
                    overflowY: "hidden",
                }}
                onScroll={(e) => {
                    e.stopPropagation();
                }}
            >
                {children}
            </div>
        </div>
    );

}

export default ScrollAreaHorizontal;