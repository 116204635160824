import React from "react";
import { Row, Col, } from "react-bootstrap";
import FadeIn from "react-fade-in/lib/FadeIn";

const AdSelectorHub = ({
    isDark,
    isMobileOnly,
    isTablet,
    useAlt,
}) => {

    return (
        <>
            {(!isTablet && !isMobileOnly && useAlt) && (
                <FadeIn>
                    <Row className="align-items-center justify-content-around mt-3 mb-2 gx-3">
                        <Col className="" xs="auto">
                            <div className={(isDark ? "text-light bg-md-dark border-light-gray " : "text-dark bg-light border-light-dark") + " position-relative d-flex flex-column align-items-center justify-content-center border rounded-1 shadow-sm text-center"} style={{ width: 300, height: 250 }}>
                                <div className="position-absolute end-0 bottom-0 me-1 mb-1" style={{ zIndex: 1, }}>
                                    <div className="text-center text-muted" style={{ fontSize: ".80rem" }}>Branded/Partner Ad Space 300x250</div>
                                </div>
                                <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                                    <a
                                        href={"/"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            void (0);
                                        }}
                                    >
                                        <Row className="justify-content-center">
                                            <Col lg="10" className="position-relative">
                                                <img src={isDark ? "https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-Web-Adress-White-Logo.png" : "https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo_1470_shadow.png"} className="img-fluid" alt="Logo for one.bad.apple" />
                                            </Col>
                                        </Row> 
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col className="" xs="auto">
                            <div
                                className={(isDark ? "text-light bg-md-dark border-light-gray " : "text-dark bg-light border-light-dark") + " position-relative d-flex flex-column align-items-center justify-content-center border rounded-1 shadow-sm p-3"}
                                style={{
                                    width: 970,
                                    height: 250,
                                }}
                            >
                                <div className="position-absolute end-0 bottom-0 me-1 mb-1" style={{ zIndex: 1, }}>
                                    <div className="text-center text-muted" style={{ fontSize: ".80rem" }}>Branded/Partner Ad Space 970x250</div>
                                </div>
                                <Row className="justify-content-center">
                                    <Col lg="8" className="position-relative">
                                        <img src={isDark ? "https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-Web-Adress-White-Logo.png" : "https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo_1470_shadow.png"} className="img-fluid" alt="Logo for one.bad.apple" />
                                    </Col>
                                </Row>                           
                            </div>
                        </Col>
                    </Row>
                </FadeIn>

            )}

            {(!isTablet && !isMobileOnly && !useAlt) && (
                <FadeIn>
                    <Row className="align-items-center justify-content-center mt-3 mb-3">
                        <Col xs="auto">
                            <div
                                className={(isDark ? "text-light bg-md-dark border-light-gray " : "text-dark bg-light border-light-dark") + " position-relative d-flex flex-column align-items-center justify-content-center border rounded-1 shadow-sm text-center"}
                                style={{
                                    width: 1280,
                                    height: 100,
                                }}
                            >
                                <div className="position-absolute end-0 bottom-0 me-1 mb-1" style={{ zIndex: 5, }}>
                                    <div className="text-center text-muted" style={{ fontSize: ".80rem" }}>Branded/Partner Ad Space 1280x100</div>
                                </div>
                                <Row className="align-items-center justify-content-center">
                                    <Col lg="3" className="position-relative">
                                        <img src={isDark ? "https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo-Web-Adress-White-Logo.png" : "https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo_1470_shadow.png"} className="img-fluid" alt="Logo for one.bad.apple" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </FadeIn>

            )}

            {isTablet && (
                <FadeIn>
                    <Row className="justify-content-around mt-2 mb-3">
                        <Col className="d-flex justify-content-center">
                            <div
                                className={(isDark ? "text-light bg-md-dark border-light-gray " : "text-dark bg-light border-light-dark") + " position-relative d-flex flex-column align-items-center justify-content-center border rounded-1 shadow-sm text-center"}
                                style={{
                                    width: 728,
                                    height: 90,
                                }}
                            >
                                <div className="position-absolute end-0 bottom-0 me-1 mb-1" style={{ zIndex: 1, }}>
                                    <div className="text-center text-muted" style={{ fontSize: ".80rem" }}>Branded/Partner Ad Space 728x90</div>
                                </div>
                                <Row className="align-items-center justify-content-center">
                                    <Col xs="6" className="position-relative">
                                        <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo_1470_shadow.png" className="img-fluid" alt="Logo for one.bad.apple" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </FadeIn>

            )}

            {isMobileOnly && (
                <FadeIn>
                    <Row className="justify-content-center mt-2 mb-3">
                        <Col className="d-flex justify-content-center">
                            <div
                                className={(isDark ? "text-light bg-md-dark border-light-gray " : "text-dark bg-light border-light-dark") + " position-relative d-flex flex-column align-items-center justify-content-center border rounded-1 shadow-sm text-center"}
                                style={{
                                    width: 320,
                                    height: 100,
                                }}
                            >
                                <div className="position-absolute end-0 bottom-0 me-1 mb-1" style={{ zIndex: 1, }}>
                                    <div className="text-center text-muted" style={{ fontSize: ".80rem" }}>Branded/Partner Ad Space 320x100</div>
                                </div>
                                <Row className=" align-items-centerjustify-content-center">
                                    <Col xs="10" className="position-relative">
                                        <img src="https://storage.googleapis.com/onebadappleprototype.appspot.com/logos/logo_1470_shadow.png" className="img-fluid" alt="Logo for one.bad.apple" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </FadeIn>

            )}
        </>
    );

};

export default AdSelectorHub;