import React from "react";
import { Col } from "react-bootstrap";
//import FadeIn from "react-fade-in/lib/FadeIn";
//import AppNameInline from "../text/AppNameInline";
//import BtnIcon from "../buttons/BtnIcon";


const CollectionColumnNoFrame = ({
    children,
    className,
    xs,
    sm,
    md,
    lg,
    xl,
    isDark,
}) => {

    return (
        <>
            <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={`mb-3 mb-lg-4 px-1 px-lg-3 ${(className && className !== "") && " " + className}`}>
                <section className="">
                    {children}
                </section>
            </Col>
        </>
    )

};

export default CollectionColumnNoFrame;