import React from "react";
//import PropTypes from 'prop-types';
import {
    //isDesktop,
    //isMobile,
    //isTablet,
    //isMobileOnly,
    //useMobileOrientation
} from 'react-device-detect';
import { Row, Col, /*Badge*/ } from 'react-bootstrap';
import BtnIcon from "../../buttons/BtnIcon";

const NavTopRecentChats = ({
    isDark,
}) => {

    return (
        <>
            <Row className="align-items-center justify-content-between g-1">
                <Col xs="auto">
                    <Row className="align-items-center justify-content-end g-1">
                        <Col xs="auto">
                            <BtnIcon
                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                label="Contacts..."
                                iconSize={22}
                                iconName="list-outline"
                                iconClasses=""
                                btnClasses=""
                                handleClick={(e) => {
                                    e.preventDefault();
                                    alert("This feature is under development ");
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs="auto">
                    <Row className="align-items-center justify-content-end g-1">
                        <Col xs="auto">
                            <BtnIcon
                                bsVariant={(isDark ? "icon-light" : "icon-dark ")}
                                label="Mark all as read..."
                                iconSize={22}
                                iconName="checkmark-circle-outline"
                                iconClasses=""
                                btnClasses=""
                                handleClick={(e) => {
                                    e.preventDefault();
                                    alert("This feature is under development ");
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );

};

export default NavTopRecentChats;