import React from "react";
//import { useNavigate } from "react-router-dom";
import { Row, Col, } from "react-bootstrap";
import {
    //isDesktop, 
    isMobileOnly,
    isMobile,
    //isTablet,
} from "react-device-detect";
import AppNameInlineIcon from "../text/AppNameInlineIcon";
import AppNameStacked from "../text/AppNameStacked";
//import AppNameStacked from "../text/AppNameStacked";
import BtnIcon from "../buttons/BtnIcon";

const CollectionHeaderOnly = ({
    appName,
    tagline,
    isDark,
    showAds,
    setShowAds,
    showAdsBtn,
    showScrollBtn,
    showSliders,
    setShowSliders,
    showBlurBtn,
    blur,
    setBlur,
    iconName,
    iconSize,
    appUrl

}) => {

    //const nav = useNavigate();

    return (
        <>
            <main className={`${isDark ? "text-light" : "text-dark"} pt-3`}>
                <Row className="justify-content-between align-items-center">
                    <Col xs="auto">
                        {!isMobile ? (
                            <div>
                                <AppNameStacked name={appName} tagLine={tagline} tagLineSize={"1rem"} iconName={iconName} iconSizeEm={iconSize ? iconSize : "2.5em"} headingSize="h3" />
                            </div>
                        ) : (
                            <h3 className="d-flex justify-content-start align-items-center m-0">
                                <AppNameInlineIcon name={appName} showCopyright={false} iconName={iconName} iconSize="1.4em" />
                            </h3>
                        )}
                    </Col>
                    <Col xs="auto">
                        <Row className="align-items-center gx-0">

                            {showBlurBtn && (
                                <Col xs="auto">
                                    <BtnIcon
                                        bsVariant={(isDark ? "icon-light" : "icon-dark")}
                                        label={blur ? "show images..." : "hide images..."}
                                        iconSize={isMobileOnly ? 18 : 22}
                                        iconName={blur ? "eye-outline" : "eye-off-outline"}
                                        //iconName={blur ? "sunny-outline" : "partly-sunny-outline"}
                                        iconClasses=""
                                        btnClasses={"ms-1"}
                                        handleClick={(e) => {
                                            e.preventDefault();
                                            setBlur(!blur);
                                        }}
                                    />
                                </Col>
                            )}

                            {showScrollBtn && (
                                <Col xs="auto">
                                    <BtnIcon
                                        bsVariant={(isDark ? "icon-light" : "icon-dark")}
                                        label={showSliders ? "grid view..." : "slider view..."}
                                        iconSize={isMobileOnly ? 18 : 22}
                                        iconName={showSliders ? "grid-outline" : "swap-horizontal-outline"}
                                        iconClasses=""
                                        btnClasses={"ms-1"}
                                        handleClick={(e) => {
                                            e.preventDefault();
                                            setShowSliders(!showSliders);
                                        }}
                                    />
                                </Col>
                            )}
                            {showAdsBtn && (
                                <Col xs="auto">
                                    <BtnIcon
                                        bsVariant={(isDark ? "icon-light" : "icon-dark")}
                                        label={showAds ? "hide ads..." : "show ads..."}
                                        iconSize={isMobileOnly ? 18 : 22}
                                        iconName={showAds ? "ban-outline" : "albums-outline"}
                                        iconClasses=""
                                        btnClasses="ms-1"
                                        handleClick={(e) => {

                                            setShowAds(!showAds);
                                        }}
                                    />
                                </Col>
                            )}

                        </Row>
                    </Col>
                </Row>
            </main>
        </>
    );
};

export default CollectionHeaderOnly;