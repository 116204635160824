import React, { useRef } from "react";


const ScrollAreaVertical = ({
    children,
}) => {

    const scrollArea = useRef(0);

    return (
        <div className="position-relative" style={{ height: "100%" }}>
            <div
                ref={scrollArea}
                className="position-absolute top-0 bottom-0 start-0 end-0 scroll-div-vertical py-0 my-0 pe-1"
                style={{
                    overflowX: "hidden",
                    overflowY: "auto",
                }}
                onScroll={(e) => {
                    e.stopPropagation();
                }}
            >
                {children}
            </div>
        </div>
    );

}

export default ScrollAreaVertical;