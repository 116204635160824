import React from "react";
import { Row, Col, } from "react-bootstrap";
import {
    //isDesktop, 
    isMobileOnly,
    isMobile,
    //isTablet,
} from "react-device-detect";
import AppNameInlineIcon from "../text/AppNameInlineIcon";
import AppNameStacked from "../text/AppNameStacked";
import BtnIcon from "../buttons/BtnIcon";
import BtnIconLink from "../buttons/BtnIconLink";

const Collection = ({
    children,
    appName,
    tagline,
    isDark,
    showAds,
    setShowAds,
    showScrollBtn,
    showSliders,
    setShowSliders,
    showBlurBtn,
    blur,
    setBlur,
    iconName,
    appUrl

}) => {

    return (
        <>
            <main className={`${isDark ? "text-light" : "text-dark"} pt-4 pb-0`}>
                <Row className="justify-content-between align-items-center mb-3">
                    <Col xs="auto">
                        {!isMobile ? (
                            <div>
                                <AppNameStacked name={appName} tagLine={tagline} tagLineSize={"1rem"} iconName={iconName} iconSizeEm={"2.5em"} headingSize="h3" />
                            </div>
                        ) : (
                            <h3 className="d-flex justify-content-start align-items-center m-0">
                                <AppNameInlineIcon name={appName} showCopyright={false} iconName={iconName} iconSize="1.4em" />
                            </h3>
                        )}
                    </Col>
                    <Col xs="auto">
                        <Row className="align-items-center gx-0">

                            {showBlurBtn && (
                                <Col xs="auto">
                                    <BtnIcon
                                        bsVariant={(isDark ? "icon-light" : "icon-dark")}
                                        label={blur ? "show images..." : "hide images..."}
                                        iconSize={isMobileOnly ? 18 : 20}
                                        iconName={blur ? "eye-outline" : "eye-off-outline"}
                                        //iconName={blur ? "sunny-outline" : "partly-sunny-outline"}
                                        iconClasses=""
                                        btnClasses={"ms-1"}
                                        handleClick={(e) => {
                                            e.preventDefault();
                                            setBlur(!blur);
                                        }}
                                    />
                                </Col>
                            )}

                            {showScrollBtn && (
                                <Col xs="auto">
                                    <BtnIcon
                                        bsVariant={(isDark ? "icon-light" : "icon-dark")}
                                        label={showSliders ? "grid view..." : "slider view..."}
                                        iconSize={isMobileOnly ? 18 : 20}
                                        iconName={showSliders ? "grid-outline" : "swap-horizontal-outline"}
                                        iconClasses=""
                                        btnClasses={"ms-1"}
                                        handleClick={(e) => {
                                            e.preventDefault();
                                            setShowSliders(!showSliders);
                                        }}
                                    />
                                </Col>
                            )}
                            <Col xs="auto">
                                <BtnIcon
                                    bsVariant={(isDark ? "icon-light" : "icon-dark")}
                                    label={showAds ? "hide ads..." : "show ads..."}
                                    iconSize={isMobileOnly ? 18 : 20}
                                    iconName={showAds ? "ban-outline" : "albums-outline"}
                                    iconClasses=""
                                    btnClasses="ms-1"
                                    handleClick={(e) => {

                                        setShowAds(!showAds);
                                    }}
                                />
                            </Col>
                            {/* <Col xs="auto">
                                <BtnIcon
                                    bsVariant={(isDark ? "icon-light" : "icon-dark")}
                                    label={appName + " settings..."}
                                    iconSize={isMobileOnly ? 18 : 20}
                                    iconName="ellipsis-vertical-outline"
                                    iconClasses=""
                                    btnClasses="ms-1"
                                    handleClick={(e) => {
                                        e.preventDefault();
                                        alert("This feature is under development ");
                                    }}
                                />
                            </Col> */}
                            <Col xs="auto">

                                <BtnIconLink
                                    bsVariant={isDark ? "icon-light" : "icon-dark"}
                                    label={"Open the " + appName + " app..."}
                                    iconSize={isMobileOnly ? 18 : 20}
                                    iconName="open-outline"
                                    iconClasses=""
                                    btnClasses=""
                                    href={appUrl}
                                    target=""
                                />

                                {/* <BtnIcon
                                    bsVariant={(isDark ? "icon-light" : "icon-dark")}
                                    label={"Open the " + appName + " app..."}
                                    iconSize={isMobileOnly ? 18 : 20}
                                    iconName="open-outline"
                                    iconClasses=""
                                    btnClasses="me-1"
                                    handleClick={(e) => {

                                        if (appUrl && appUrl !== "") {

                                            nav(appUrl);

                                        } else {

                                            e.preventDefault();
                                            alert("This feature is under development");
                                        }
                                    }}
                                /> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {children}
            </main>
        </>
    );
};

export default Collection;